/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
// import { debounce } from 'lodash';
import { Popover, Badge, Table, Popconfirm, Drawer, Button as ButtonAnt } from 'antd';
import { testIdFactory } from '@/tests';
import { AiOutlineTeam, AiOutlineEdit, AiOutlineClose, AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai';
import { getStatus } from '../../utils/getStatus';
import DropDownAction from '../DropDownAction/DropDownAction.component';
import Button from '../Button2/Button.component';
import { checkMinimalConsultationTimeRange } from '../../utils/checkMinimalConsultationTimeRange';
import { FORMATS } from '../../constants/dates.constants';
import { MaskedText } from '../MaskedText';

const testId = testIdFactory('calendar-service');

const contentConsultationCount = <p className="text-gray-500">Liczba konsultacji.</p>;

const columns = (calendarChange) => [
  {
    title: 'Lp.',
    dataIndex: 'Lp',
    key: 'Lp',
  },
  {
    title: 'Lekarz zlecający',
    dataIndex: 'requestingDoctor',
    key: 'Lekarzzlecajacy',
  },
  {
    title: 'Konsultant - Lekarz/Grupa',
    dataIndex: 'consultantDoctor',
    key: 'Konsultant_LekarzGrupa',
  },
  {
    title: 'Pacjent',
    // dataIndex: 'patient',
    key: 'Pacjent',
    render: (record) => (
      <div>
        <p>{record.patient}</p>
        <p><MaskedText>{record.patientIdentity}</MaskedText></p>
      </div>
    ),
  },
  {
    title: 'Tryb',
    colSpan: 2,
  },
  Table.EXPAND_COLUMN,
  {
    fixed: 'right',
    colSpan: 0,
    render: (record) => (
      <>
        <Link to={`/consultations/${record.uuid}`}>
          <ButtonAnt className="mr-1" type="primary" color="darkblue" size="large" link={`/consultations/${record.uuid}`}>
            Szczegóły
          </ButtonAnt>
        </Link>
        <DropDownAction idConsultation={record.uuid} consultationState={record.state} consultantList={record.consultantList} isGroup={calendarChange.value !== null} />
      </>
    ),
  },
];

const onDelete = (eventConfirm, modal, setMode, mutation) => {
  eventConfirm.event.setExtendedProp('openPopOver', !eventConfirm.event.extendedProps.openPopOver);
  modal.confirm({
    title: 'Czy usunąć wolny termin?',
    centered: true,
    okText: 'Tak',
    cancelText: 'Nie',
    onOk() {
      setMode('delete');
      mutation.mutate({ slotId: eventConfirm.event.id, isGroupSlot: eventConfirm.event.extendedProps.group });
    },
  });
};

const ok = (eventConfirm, calendarChange, mutation, calendarRef, user) => {
  const remappedValues = { dateFrom: null, dateTo: null, repeat: null, repeatDays: null, repeatDate: null, maxNumber: null };
  remappedValues.dateFrom = moment(eventConfirm.event.start).utc().format('YYYY-MM-DD[T]HH:mm:ss');
  remappedValues.dateTo = moment(eventConfirm.event.end).utc().format('YYYY-MM-DD[T]HH:mm:ss');
  remappedValues.maxNumber = eventConfirm.event.extendedProps.maxNumber;
  if (calendarChange.value === null) mutation.mutate({ ...remappedValues, userId: user.id, id: eventConfirm.event.id });
  else mutation.mutate({ ...remappedValues, groupId: calendarChange.value, id: eventConfirm.event.id });
  eventConfirm.event.setExtendedProp('open', false);
  calendarRef.current.getApi().setOption('editable', true);
  calendarRef.current.getApi().setOption('selectable', true);
};

const cancel = (eventConfirm, calendarRef) => {
  if (eventConfirm && eventConfirm.event.extendedProps.open) {
    // setTimeout(() => { eventConfirm.revert(); }, 200);
    eventConfirm.revert();
    eventConfirm.event.setExtendedProp('open', false);
  }
  calendarRef.current.getApi().setOption('editable', true);
  calendarRef.current.getApi().setOption('selectable', true);
};

const contentFreeTime = (eventConfirm, modal, setMode, mutation, showModalEdit, setCheckedList, setDisableList, setSelectedTime, selectedTime, form, setIsModalOpen) => (
  <div className="flex">
    <Button onClick={() => onDelete(eventConfirm, modal, setMode, mutation)} type="primary" size="sm" icon={<AiOutlineClose />}>
      Usuń
    </Button>
    <Button
      onClick={() => showModalEdit(eventConfirm, setMode, setCheckedList, setDisableList, setSelectedTime, selectedTime, form, setIsModalOpen)}
      type="primary"
      color="darkblue"
      size="sm"
      className="ml-4"
      icon={<AiOutlineEdit />}
    >
      Edycja terminu
    </Button>
  </div>
);

export const filterEvents = (calendarApi, consultationFreeTime, consultationTime, consultationGroup) => {
  calendarApi.batchRendering(() => {
    const currentEvents = calendarApi.getEvents();
    for (let i = 0; i < currentEvents.length; i += 1) {
      const event = currentEvents[i];
      if (event.extendedProps.his === true) {
        event.setProp('display', 'auto');
        event.setProp('editable', false);
      }
      if (event.extendedProps.freeTime === true && consultationFreeTime === false) {
        event.setProp('display', 'none');
      }
      if (event.extendedProps.freeTime === false && consultationTime === false) {
        event.setProp('display', 'none');
      }
      if (event.extendedProps.freeTime === true && consultationFreeTime === true) {
        if (moment(event.end).format(FORMATS.DATE_TIME) < moment().format(FORMATS.DATE_TIME)) event.setProp('editable', false);
        event.setProp('display', 'auto');
      }
      if (event.extendedProps.freeTime === false && consultationTime === true) {
        if (event.extendedProps.group === true && consultationGroup === true) {
          event.setProp('display', 'auto');
          event.setProp('editable', false);
        }
        if (event.extendedProps.group === true && consultationGroup === false) {
          event.setProp('display', 'none');
        }
        if (event.extendedProps.group === false) {
          event.setProp('display', 'auto');
          event.setProp('editable', false);
        }
      }
    }
  });
};

export const eventDrop = (info, setMode, calendarRef, setEventConfirm) => {
  setMode('editSelect');
  calendarRef.current.getApi().setOption('editable', false);
  calendarRef.current.getApi().setOption('selectable', false);
  info.event.setExtendedProp('openPopOver', false);
  info.event.setExtendedProp('open', true);
  setEventConfirm(info);
};

export const eventResize = (info, setMode, openNotification, setEventConfirm, calendarRef) => {
  setMode('editSelect');
  if (
    moment(info.oldEvent.start).format('YYYY-MM-DD') !== moment(info.event.start).format('YYYY-MM-DD') ||
    moment(info.oldEvent.end).format('YYYY-MM-DD') !== moment(info.event.end).add(-1, 'minutes').format('YYYY-MM-DD')
  ) {
    info.revert();
    openNotification('Proszę zaznaczyć wolny termin w tym samym dniu.');
    setEventConfirm(info);
  } else if (checkMinimalConsultationTimeRange(moment(info.event.start), moment(info.event.end))) {
    info.revert();
    calendarRef.current.getApi().unselect();
    openNotification('Minimalny czas konsultacji wynosi 30 minut!');
    setEventConfirm(info);
  } else {
    calendarRef.current.getApi().setOption('editable', false);
    calendarRef.current.getApi().setOption('selectable', false);
    info.event.setExtendedProp('open', true);
    setEventConfirm(info);
  }
};

export const loadEventsEdit = (setEvents, calendarChange, doctorSlots, groupSlots) => {
  setEvents([]);
  if (calendarChange.value === null) {
    doctorSlots.refetch();
  }
  if (calendarChange.value !== null) {
    groupSlots.refetch();
  }
};

export const loadEvents = (setEvents) => {
  setEvents([]);
};

export const resizeCalendar = (width, calendarApi, eventConfirm) => {
  // if (!eventConfirm?.event?.extendedProps?.openPopOver) {
  //   const { xs, sm, md, lg, xl, xxl } = {
  //     xs: '480',
  //     sm: '576',
  //     md: '768',
  //     lg: '992',
  //     xl: '1200',
  //     xxl: '1600',
  //   };
  //   if (width < sm) {
  //     calendarApi.setOption('duration', { days: 2 });
  //   } else if (width < md) {
  //     calendarApi.setOption('duration', { days: 3 });
  //   } else if (width < lg) {
  //     calendarApi.setOption('duration', { days: 4 });
  //   } else if (width < xl) {
  calendarApi.setOption('duration', { days: 5 });
  //   } else if (width < xxl) {
  //     calendarApi.setOption('duration', { days: 6 });
  //   } else {
  //     calendarApi.setOption('duration', { days: 7 });
  //   }
  // } else {
  //   calendarApi.setOption('duration', calendarApi.getOption('duration'));
  // }
};

export const renderEventContent = (
  eventInfo,
  consultationTime,
  consultationFreeTime,
  eventConfirm,
  setEventConfirm,
  setMode,
  showModalEdit,
  calendarChange,
  mutation,
  calendarRef,
  modal,
  user,
  setCheckedList,
  setDisableList,
  setSelectedTime,
  selectedTime,
  form,
  setIsModalOpen,
  setEvents,
  doctorSlots,
  groupSlots,
) => {
  if (eventInfo.event.extendedProps.group === true && eventInfo.event.extendedProps.freeTime === false && consultationTime === true) {
    return (
      <Badge
        count={
          <Popover trigger="hover" placement="topLeft" color="#FFFAEB" content={contentConsultationCount}>
            <p className="ant-badge-count" {...testId('consultationsCount')}>
              {eventInfo.event.extendedProps.teleconsultations.length}
            </p>
          </Popover>
        }
        className="absolute h-full w-full -translate-x-0.5"
      >
        <Drawer
          className="ant-drawer-header-styles mt-32"
          title="Szczegóły"
          width={window.innerWidth > 1280 ? window.innerWidth * 0.8 : window.innerWidth}
          onClick={() => {
            eventConfirm.event.setExtendedProp('openPopOver', false);
            // debounce(() => {
            //   loadEventsEdit(setEvents, calendarChange, doctorSlots, groupSlots);
            // }, 500)();
          }}
          onClose={() => {
            eventConfirm.event.setExtendedProp('openPopOver', false);
            // debounce(() => {
            //   loadEventsEdit(setEvents, calendarChange, doctorSlots, groupSlots);
            // }, 500)();
          }}
          open={eventInfo.event.extendedProps.openPopOver}
          bodyStyle={{
            paddingBottom: 80,
          }}
        >
          <div className="flex w-full justify-center sm:justify-start">
            <div className="ml-10 mb-10 grid grid-cols-1 sm:grid-cols-3 sm:gap-32">
              <div className="pt-5">
                <AiOutlineCalendar className="mr-4 text-5xl text-gray-400 sm:text-7xl" />
                <p className="whitespace-nowrap text-2xl font-semibold">{moment(eventInfo.event.start).format('YYYY.MM.DD').toString()}</p>
                <p className="text-base">Data konsultacji</p>
              </div>
              <div className="pt-5">
                <AiOutlineClockCircle className="mr-4 text-5xl text-gray-400 sm:text-7xl" />
                <p className="whitespace-nowrap text-2xl font-semibold">{eventInfo.timeText}</p>
                <p className="text-base">Czas konsultacji</p>
              </div>
              <div className="pt-5">
                <AiOutlineTeam className="mr-4 text-5xl text-gray-400 sm:text-7xl" />
                <p className="whitespace-nowrap text-2xl font-semibold">{eventInfo.event.extendedProps.teleconsultations.length}</p>
                <p className="text-base">Liczba konsultacji</p>
              </div>
            </div>
          </div>
          <div>
            <div>
              <Table
                className="table-drawer-styles overflow-x-auto overflow-y-hidden"
                // eslint-disable-next-line react/no-unstable-nested-components
                expandedRowRender={(record) => (
                  <div className="ml-10 bg-black bg-opacity-20 p-2">
                    <p className="float-left font-semibold">
                      <span>Uzasadnienie: </span>
                    </p>
                    <p>
                      <span {...testId('description')}>{record.description}</span>
                    </p>
                  </div>
                )}
                // eslint-disable-next-line react/no-unstable-nested-components
                expandIcon={({ expanded, record, onExpand }) =>
                  expanded ? (
                    <ButtonAnt
                      className="ant-button-mode-styles mx-1 mr-10 w-max p-2 py-0 text-sm"
                      // onClick={(e) => {
                      //   onExpand(record, e);
                      // }}
                      type="text"
                    >
                      {getStatus(record.mode)}
                    </ButtonAnt>
                  ) : (
                    <ButtonAnt
                      className="ant-button-mode-styles mx-1 mr-10 w-max p-2 py-0 text-sm"
                      // onClick={(e) => {
                      //   if (record.description !== (null || undefined)) onExpand(record, e);
                      // }}
                      type="text"
                    >
                      {getStatus(record.mode)}
                    </ButtonAnt>
                  )
                }
                style={{ whiteSpace: 'pre' }}
                dataSource={eventInfo.event.extendedProps.teleconsultations}
                columns={columns(calendarChange)}
                pagination={false}
                {...testId('consultationTable')}
              />
            </div>
          </div>
        </Drawer>
        <div
          className="inline-block h-full w-full overflow-hidden whitespace-nowrap p-1 pt-0"
          onClick={() => {
            eventInfo.event.setExtendedProp('openPopOver', true);
            setEventConfirm(eventInfo);
            setMode('cancel');
          }}
        >
          <p className="inline-block text-base" {...testId('consultationTimeRange')}>
            {eventInfo.timeText}
          </p>{' '}
          <AiOutlineTeam className="float-right text-2xl" />
        </div>
      </Badge>
    );
  }
  if (eventInfo.event.extendedProps.group === false && eventInfo.event.extendedProps.freeTime === false && consultationTime === true) {
    return (
      <Badge
          count={
            <Popover trigger="hover" placement="topLeft" color="#FFFAEB" content={contentConsultationCount}>
              <p className="ant-badge-count" {...testId('consultationsCount')}>
                {eventInfo.event.extendedProps.teleconsultations.length}
              </p>
            </Popover>
          }
          className="absolute h-full w-full -translate-x-0.5"
        >
          <Drawer
            className="ant-drawer-header-styles mt-32"
            title="Szczegóły"
            width={window.innerWidth > 1440 ? window.innerWidth * 0.8 : window.innerWidth}
            onClick={() => {
              eventConfirm.event.setExtendedProp('openPopOver', false);
              // debounce(() => {
              //   loadEventsEdit(setEvents, calendarChange, doctorSlots, groupSlots);
              // }, 500)();
            }}
            onClose={() => {
              eventConfirm.event.setExtendedProp('openPopOver', false);
              // debounce(() => {
              //   loadEventsEdit(setEvents, calendarChange, doctorSlots, groupSlots);
              // }, 500)();
            }}
            open={eventInfo.event.extendedProps.openPopOver}
          >
            <div className="flex w-full justify-center sm:justify-start">
              <div className="ml-10 mb-10 grid grid-cols-1 gap-32 sm:grid-cols-3">
                <div className="pt-5">
                  <AiOutlineCalendar className="mr-4 text-5xl text-gray-400 sm:text-7xl" />
                  <p className="whitespace-nowrap text-2xl font-semibold">{moment(eventInfo.event.start).format('YYYY.MM.DD').toString()}</p>
                  <p className="text-base">Data konsultacji</p>
                </div>
                <div className="pt-5">
                  <AiOutlineClockCircle className="mr-4 text-5xl text-gray-400 sm:text-7xl" />
                  <p className="whitespace-nowrap text-2xl font-semibold">{eventInfo.timeText}</p>
                  <p className="text-base">Czas konsultacji</p>
                </div>
                <div className="pt-5">
                  <AiOutlineTeam className="mr-4 text-5xl text-gray-400 sm:text-7xl" />
                  <p className="whitespace-nowrap text-2xl font-semibold">{eventInfo.event.extendedProps.teleconsultations.length}</p>
                  <p className="text-base">Liczba konsultacji</p>
                </div>
              </div>
            </div>
            <div>
              <div>
                <Table
                  className="table-drawer-styles overflow-x-auto overflow-y-hidden"
                  // eslint-disable-next-line react/no-unstable-nested-components
                  expandedRowRender={(record) => (
                    <div className="ml-10 bg-black bg-opacity-20 p-2">
                      <p className="float-left font-semibold">
                        <span>Uzasadnienie: </span>
                      </p>
                      <p>
                        <span {...testId('description2')}>{record.description}</span>
                      </p>
                    </div>
                  )}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  expandIcon={({ expanded, record, onExpand }) =>
                    expanded ? (
                      <ButtonAnt
                        className="ant-button-mode-styles mx-1 mr-10 w-max p-2 py-0 text-sm"
                        // onClick={(e) => {
                        //   onExpand(record, e);
                        // }}
                        type="text"
                      >
                        {getStatus(record.mode)}
                      </ButtonAnt>
                    ) : (
                      <ButtonAnt
                        className="ant-button-mode-styles mx-1 mr-10 w-max p-2 py-0 text-sm"
                        // onClick={(e) => {
                        //   if (record.description !== (null || undefined)) onExpand(record, e);
                        // }}
                        type="text"
                      >
                        {getStatus(record.mode)}
                      </ButtonAnt>
                    )
                  }
                  style={{ whiteSpace: 'pre' }}
                  dataSource={eventInfo.event.extendedProps.teleconsultations}
                  columns={columns(calendarChange)}
                  pagination={false}
                  {...testId('consultationTable2')}
                />
              </div>
            </div>
          </Drawer>
          <div
            className="inline-block h-full w-full overflow-hidden whitespace-nowrap p-1 pt-0"
            onClick={() => {
              eventInfo.event.setExtendedProp('openPopOver', true);
              setEventConfirm(eventInfo);
              setMode('cancel');
            }}
          >
            <p className="inline-block text-base" {...testId('consultationTimeRange2')}>
              {eventInfo.timeText}
            </p>
          </div>
        </Badge>
    );
  }
  if (eventInfo.event.extendedProps.group === false && eventInfo.event.extendedProps.freeTime === true && consultationFreeTime === true) {
    return (
      <Popconfirm
        title="Czy zachować zmiany?"
        className="ant-popconfirm-styles"
        open={eventInfo.event.extendedProps.open}
        onConfirm={() => {
          ok(eventConfirm, calendarChange, mutation, calendarRef, user);
        }}
        onCancel={() => cancel(eventConfirm, calendarRef)}
        onOpenChange={() => cancel(eventConfirm, calendarRef)}
        okText="Tak"
        cancelText="Nie"
        okType="primary"
      >
        <Popover
          trigger={eventInfo.event.extendedProps.open ? '' : 'click'}
          placement="top"
          content={() =>
            contentFreeTime(eventConfirm, modal, setMode, mutation, showModalEdit, setCheckedList, setDisableList, setSelectedTime, selectedTime, form, setIsModalOpen)
          }
          open={eventInfo.event.extendedProps.openPopOver}
          onClick={() => {
            if (!eventInfo.event.extendedProps.open) {
              setEventConfirm(eventInfo);
              setMode('delete');
            }
          }}
          onOpenChange={() => {
            if (moment(eventInfo.event.end).format(FORMATS.DATE_TIME) > moment().format(FORMATS.DATE_TIME))
              eventInfo.event.setExtendedProp('openPopOver', !eventInfo.event.extendedProps.openPopOver);
          }}
        >
          <div className="inline-block h-full w-full overflow-hidden whitespace-nowrap p-1 pt-0">
            <p className="inline-block text-base" {...testId('consultationTimeRange2')}>
              {eventInfo.timeText}
            </p>
          </div>
        </Popover>
      </Popconfirm>
    );
  }
  if (eventInfo.event.extendedProps.group === true && eventInfo.event.extendedProps.freeTime === true && consultationFreeTime === true) {
    return (
      <Popconfirm
        title="Czy zachować zmiany?"
        open={eventInfo.event.extendedProps.open}
        onConfirm={() => ok(eventConfirm, calendarChange, mutation, calendarRef, user)}
        onCancel={() => cancel(eventConfirm, calendarRef)}
        onOpenChange={() => cancel(eventConfirm, calendarRef)}
        okText="Tak"
        cancelText="Nie"
      >
        <Popover
          trigger="click"
          placement="top"
          content={() =>
            contentFreeTime(eventConfirm, modal, setMode, mutation, showModalEdit, setCheckedList, setDisableList, setSelectedTime, selectedTime, form, setIsModalOpen)
          }
          open={eventInfo.event.extendedProps.openPopOver}
          onClick={() => {
            setEventConfirm(eventInfo);
            setMode('delete');
          }}
          onOpenChange={() => {
            if (moment(eventInfo.event.end).format(FORMATS.DATE_TIME) > moment().format(FORMATS.DATE_TIME))
              eventInfo.event.setExtendedProp('openPopOver', !eventInfo.event.extendedProps.openPopOver);
          }}
        >
          <div className="inline-block h-full w-full overflow-hidden whitespace-nowrap p-1 pt-0">
            <p className="inline-block text-base" {...testId('consultationTimeRange3')}>
              {eventInfo.timeText}
            </p>
          </div>
        </Popover>
      </Popconfirm>
    );
  }
  if (eventInfo.event.extendedProps.his === true) {
    return (
      <div className="inline-block h-full w-full overflow-hidden whitespace-nowrap p-1 pt-0">
        <p className="inline-block text-base" {...testId('consultationTimeRange4')}>
          {eventInfo.timeText}
        </p>
        <p className="float-right inline-block text-base">HIS</p>
      </div>
    );
  }
  return (
    <div className="inline-block h-full w-full overflow-hidden whitespace-nowrap p-1 pt-0">
      <p className="inline-block text-base text-black" {...testId('consultationTimeRange5')}>
        {eventInfo.timeText}
      </p>
    </div>
  );
};
