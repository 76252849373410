import http from '../utils/http';
import withAccessToken from '../utils/withAccessToken';

export type DictionaryName = 'ICD10';

type DictionaryItem = {
  code: string;
  label: string;
};

export type Dictionary = {
  dictionary: DictionaryItem[];
};

export const getDictionaryValues = (dictionaryName: string, query?: string) => {
  const baseUrl = `/dictionaries/${dictionaryName}`;
  const searchParams = new URLSearchParams();

  if (query) {
    searchParams.append('query', query);
  }

  const params = searchParams.toString();

  const url = params ? `${baseUrl}?${params}` : baseUrl;

  return http.get<unknown, { data: Dictionary }>(url, withAccessToken()).then((response) => response);
};
