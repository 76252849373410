import React from 'react';
import { Col } from 'antd';
import { testId } from '@/tests';
import Card from '../Card/Card.component';

const DoctorDetails = ({ data }) => (
  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
    <Card
      title={
        <div className="flex flex-row content-center items-center justify-between">
          <div>Lekarz zlecający</div>
        </div>
      }
    >
      <div className="flex h-[120px] flex-col">
        <div className="bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
          <dt className="font-semibold">Imię i nazwisko:</dt>
          <dd className="sm:col-span-2 sm:mt-0" {...testId('doctor-name-surname')}>
            {data && data.doctor.doctorName} {data && data.doctor.doctorSurname}
          </dd>
        </div>
        <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
          <dt className="font-semibold">Jednostka:</dt>
          <dd className="sm:col-span-2 sm:mt-0" {...testId('doctor-unit')}>
            {data && data.doctor.doctorUnitName}
          </dd>
        </div>
      </div>
    </Card>
  </Col>
);

export default DoctorDetails;
