import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
// import { BiMessageRoundedEdit } from 'react-icons/bi';
// import { AiOutlinePlus } from 'react-icons/ai';
// import Button from '../components/Button2/Button.component';

const UserProfilePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <div className="flex flex-col gap-5 h-[40rem] bg-white p-10"> */}
      {/*  <div className="flex gap-2 items-end"> */}
      {/*    <Button icon={<BiMessageRoundedEdit />} size="xs"> XS - Button </Button> */}
      {/*    <Button size="sm"> SM - Button </Button> */}
      {/*    <Button disabled size="md"> MD - Button </Button> */}
      {/*    <Button size="lg"> LG - Button </Button> */}
      {/*    <Button size="xl"> XL- Button </Button> */}
      {/*  </div> */}
      {/*  <div className="flex gap-2 items-end"> */}
      {/*    <Button color="default" size="sm"> Default - Button </Button> */}
      {/*    <Button color="dark" size="sm"> Dark - Button </Button> */}
      {/*    <Button color="green" size="sm"> Green - Button </Button> */}
      {/*    <Button icon={<AiOutlinePlus className="h-5 w-4" />} color="greenlight" size="sm"> GreenLight - Button </Button> */}
      {/*    <Button color="red" size="sm"> Red - Button </Button> */}
      {/*    <Button color="yellow" size="sm"> Yellow- Button </Button> */}
      {/*    <Button color="purple" size="sm"> Purple- Button </Button> */}
      {/*  </div> */}
      {/*  <div className="flex gap-2 items-end"> */}
      {/*    <Button rounded color="default" size="sm"> Default - Button </Button> */}
      {/*    <Button rounded color="dark" size="sm"> Dark - Button </Button> */}
      {/*    <Button rounded color="green" size="sm"> Green - Button </Button> */}
      {/*    <Button icon={<BiMessageRoundedEdit className="h-5 w-5" />} rounded color="red" size="sm"> Red - Button </Button> */}
      {/*    <Button rounded color="yellow" size="sm"> Yellow- Button </Button> */}
      {/*    <Button rounded color="purple" size="sm"> Purple- Button </Button> */}
      {/*  </div> */}
      {/*  <div className="flex gap-2 items-end"> */}
      {/*    <Button outline color="default" size="sm"> Default - Button </Button> */}
      {/*    <Button outline color="dark" size="sm"> Dark - Button </Button> */}
      {/*    <Button icon={<BiMessageRoundedEdit className="h-5 w-5" />} outline color="green" size="sm"> Green - Button </Button> */}
      {/*    <Button icon={<BiMessageRoundedEdit className="h-5 w-5" />} outline color="greenlight" size="sm"> Green - Button </Button> */}
      {/*    <Button outline color="red" size="sm"> Red - Button </Button> */}
      {/*    <Button outline color="yellow" size="sm"> Yellow- Button </Button> */}
      {/*    <Button outline color="purple" size="sm"> Purple- Button </Button> */}
      {/*  </div> */}
      {/* </div> */}
      {/* <Button loading> XS - Button </Button> */}
      Profil użytkownika
    </>
  );
};

UserProfilePage.propTypes = {};

export default UserProfilePage;
