import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Transition as TransitionUi } from '@headlessui/react';

const Transition = ({ children, show }) => (
  <TransitionUi
    as={Fragment}
    enter="transition ease-out duration-100"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
    show={show}
  >
    {children}
  </TransitionUi>
);

Transition.propTypes = {
  children: PropTypes.element,
};

Transition.defaultProps = {
  children: undefined,
};

export default Transition;
