import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const onJoinMeetingClick = (userName, url) => {
  Modal.confirm({
    title: 'Info',
    icon: <ExclamationCircleOutlined />,
    content: 'Video konferencja zostanie rozpoczęta w nowym oknie.',
    okText: 'Ok',
    cancelText: 'Anuluj',
    onOk: () => {
      window.open(url, '_blank');
    },
  });
};

export default onJoinMeetingClick;
