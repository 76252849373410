import http from '../utils/http';
import withAccessToken from '../utils/withAccessToken';

export const login = (requestBody) => () => http.post('/user/auth', requestBody, withAccessToken()).then((response) => response);
export const authenticateInUnit = () => () => http.get('/user/token/update', withAccessToken()).then((response) => response);
export const verify = () => () => http.get('/user/verify', withAccessToken()).then((response) => response);
export const getAuthRequest = () => () => http.get('/authRequest', withAccessToken()).then((response) => response);
export const sendMail = (requestBody) => () => http.post('/user/reset', requestBody, withAccessToken()).then((response) => response); 
export const resetPassword = ({resetPasswordToken, requestBody}) => http.put(`/user/reset?resetPasswordToken=${resetPasswordToken}`, requestBody, withAccessToken()).then((response) => response);
export const changePassword = (requestBody) => http.put(`/user/change`, requestBody, withAccessToken()).then((response) => response);
export const verifyToken = (resetPasswordToken) => http.get(`/user/reset/verify?resetPasswordToken=${resetPasswordToken}`, withAccessToken()).then((response) => response);

const LoginService = {
  login,
  verify,
  sendMail,
  resetPassword,
  changePassword,
  verifyToken,
};

export default LoginService;
