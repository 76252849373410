import React, { useContext, useEffect, useRef, useState } from 'react';
// import { useLocation } from 'react-router-dom';
// import PropTypes from 'prop-types';
import { Col, Modal } from 'antd';
import Draggable from 'react-draggable';
import { FiVideo } from 'react-icons/fi';
// import { BsArrowsFullscreen } from 'react-icons/bs';
// import { useZoom } from '../../hooks/useZoom.hook';
import { useParams } from 'react-router-dom';
import Card from '../Card/Card.component';
import Button from '../Button2/Button.component';
import AuthContext from '../../contexts/Auth.context';
import zoomContext from '../../contexts/Zoom.context';
import { onJoinMeetingClick } from '../../utils/onJoinMeetingClick';

const ZoomConsultation = ({ data }) => {
  const { user } = useContext(AuthContext);
  const { zoomMeeting } = data;
  const { id } = useParams();
  const userName = `${user.name} ${user.lastname}`;
  const url = `/zoom/${data.zoomMeeting}/${id}/${userName}`;

  const {
    // joinMeeting,
    isFullScreen,
    isConnecting,
    setMeetingNumber,
    setUserName,
    // onFullScreen,
    isLoadingSignature,
    endMeeting,
    zoomStatus,
  } = useContext(zoomContext);

  const draggleRef = useRef(null);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    // const initValue = {
    //   meetingNumber: zoomMeeting,
    //   userName: `${user.name} ${user.lastname}`,
    //   userEmail: user.email,
    // };
    setMeetingNumber(zoomMeeting);
    setUserName(`${user.name} ${user.lastname}`);
  }, [data]);

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <div className={isFullScreen ? 'fullscreen-zoom' : 'w-full'}>
        <Draggable
          axis="both"
          handle=".handle"
          defaultPosition={{ x: 0, y: 0 }}
          position={isFullScreen ? null : { x: 0, y: 0 }}
          positionOffset={isFullScreen ? { x: -900, y: -100 } : { x: 0, y: 0 }}
          grid={[25, 25]}
          scale={1}
          disabled={!isFullScreen}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
          nodeRef={draggleRef}
        >
          <div className="z-20 w-full" ref={draggleRef}>
            <Card
              className="w-full bg-gray-700"
              title={
                <div className="handle flex cursor-pointer flex-row justify-between">
                  <span>Video rozmowa</span>
                </div>
              }
            >
              <div className="flex flex-col justify-center">
                <div id="meetingSDKElement" style={{ width: isFullScreen ? 900 : '100%' }} />

                {zoomStatus !== 'Connected' && (
                  <Button disabled={!zoomMeeting} onClick={() => onJoinMeetingClick(userName, url)} color="darkblue" icon={<FiVideo className="h-6 w-6" />} loading={isLoadingSignature}>
                    {isConnecting ? 'trwa łączenie...' : 'Dołącz'}
                  </Button>
                )}

                {zoomStatus === 'Connected' && (
                  <Button onClick={() => endMeeting()} color="red">
                    Rozłącz
                  </Button>
                )}
              </div>

              {/* {isFullScreen && isJoined && <span className="text-blue-500">w trakcie</span>} */}
              {/* {isJoined && ( */}
              {/* <div> */}
              {/*  <Button className="mt-7" onClick={onFullScreen} block color="default" icon={<BsArrowsFullscreen className="mr-2 h-6 w-6" />}> */}
              {/*    {isJoined && isFullScreen ? 'zamknij pełny ekran' : 'pełny ekran'} */}
              {/*  </Button> */}
              {/* </div> */}
              {/* )} */}
            </Card>
          </div>
        </Draggable>
      </div>
    </Col>
  );
};

ZoomConsultation.propTypes = {};

export default ZoomConsultation;
