type Currents = 'referral' | 'default';

type Mode = {
  title: string;
  description: string;
};

type Modes = {
  add: Mode;
  edit: Mode;
};

type AvailableModes = 'add' | 'edit'

const modalTitle: Record<Currents, Modes> = {
  referral: {
    add: {
      title: 'DODAJ SKIEROWANIE',
      description: 'Wprowadź dane skierowania'
    },
    edit: {
      title: 'EDYCJA SKIEROWANIA',
      description: 'Wprowadź dane skierowania'
    },
  },
  default: {
    add: {
      title: 'DODAJ KONSULTACJE',
      description: 'Wprowadź dane konsultacji'
    },
    edit: {
      title: 'EDYCJA KONSULTACJI',
      description: 'Wprowadź dane konsultacji'
    },
  },
};

export const getModalTitle = (current: Currents, mode: AvailableModes): Mode=> {
  const keys = Object.keys(modalTitle);
  if (keys.includes(current)) {
    return modalTitle[current][mode];
  }
  return modalTitle.default[mode];
};
