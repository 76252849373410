/* eslint-disable */
import http from '../utils/http';
import withAccessToken from '../utils/withAccessToken';
import httpFile from '../utils/httpFile';
import httpMeddream from '../utils/httpMeddream';

// Dodawanie nowego badania z plikiem NIE-dicom
export const addExamination = (requestBody) => http.post(`/documentation/${requestBody.id}/binary`, requestBody, withAccessToken()).then((response) => response);

// Pobieranie istniejącego badania z plikami
export const getExamination = (id) => () => http.get(`/documentation/${id}/examination`, withAccessToken()).then((response) => response);

// Dodawanie pliku NIE-dicom do istniejącego badania
export const addFile = (requestBody) =>
  http.put(`/documentation/${requestBody.id}/examination/${requestBody.examinationId}/binary`, requestBody, withAccessToken()).then((response) => response);

// Edycja istniejącego pliku - przeniesienie pliku do innego badania
export const editFile = (requestBody) =>
  http
    .put(`/documentation/${requestBody.id}/examination/${requestBody.examinationIdSource}/binary/${requestBody.fileId}`, requestBody, withAccessToken())
    .then((response) => response);

// Usuwanie pliku z istniejącego badania
export const deleteFile = (requestBody) =>
  http
    .delete(`/documentation/${requestBody.id}/examination/${requestBody.examinationId}/binary/${requestBody.fileId}`, withAccessToken(), requestBody)
    .then((response) => response);

// Usuwanie pliku z poczekalni - files_service
export const deleteFileFromService = (requestBody) =>
  httpFile.delete(`/unit/${requestBody.unit.id}/documentation/${requestBody.id}/binary/${requestBody.fileName}`, withAccessToken(), requestBody).then((response) => response);

// Usuwanie całego badania
export const deleteExamination = (requestBody) =>
  http.delete(`/documentation/${requestBody.id}/examination/${requestBody.examinationId}/binary`, withAccessToken(), requestBody).then((response) => response);

// Edycja nazwy i opisu badania
export const editExamination = (requestBody) =>
  http.put(`/documentation/${requestBody.id}/examination/${requestBody.examinationId}/binary/edit`, requestBody, withAccessToken()).then((response) => response);

// Beraer token dla plików nie-dicom
export const testToken = (url) => () => httpFile.get(url, withAccessToken()).then((response) => response);

// Generowanie tokenu dla MedDream - DICOM
export const generateMeddreamToken = (uuid, userId) => () => httpMeddream.get(`/token/create/user/${userId}/consultation/${uuid}`, withAccessToken()).then((response) => response);

// DOCUMENTATION DICOM SERIES CONTROLLER

// Generowanie uuid dla serii dicom
export const dicomSeriesGeneration = (id) => () => http.get(`/documentation/${id}/generateDicomSeriesUuid`, withAccessToken()).then((response) => response);

// Tworzenia badania z plikami DICOM
export const addDicomExamination = (requestBody) => http.post(`/documentation/${requestBody.id}/dicom`, requestBody, withAccessToken()).then((response) => response);

// Dodawanie serii DICOM do istniejącego badania
export const addDicomSeriesToExisting = (requestBody) =>
  http.put(`/documentation/${requestBody.id}/examination/${requestBody.examinationId}/dicom`, requestBody, withAccessToken()).then((response) => response);

// Usuwanie badanie DICOM
export const deleteDicomSeries = (requestBody) =>
  http
    .delete(`/documentation/${requestBody.id}/examination/${requestBody.examinationId}/dicom/${requestBody.dicomSeriesId}`, withAccessToken(), requestBody)
    .then((response) => response);

// DICOM SERIES FILES CONTROLLER

// Dodawanie plików z serii DICOM
// używany bezpośrednio w action
export const addDicomFile = (requestBody) =>
  httpFile.post(`/unit/${requestBody.unit.id}/documentation/${requestBody.id}/dicom/${requestBody.dicomUuid}`, requestBody, withAccessToken()).then((response) => response);

// Usuwaniu plików DICOM z dysku/SERVICE
export const deleteDicomFileFromService = (requestBody) =>
  httpFile.delete(`/unit/${requestBody.unit.id}/documentation/${requestBody.id}/dicom/${requestBody.dicomUuid}`, withAccessToken(), requestBody).then((response) => response);

// Przeniesienie serii dicom z katalogu stagging do właściwej lokalizacji
export const moveStaggingDicom = (requestBody) =>
  httpFile.put(`/unit/${requestBody.unit.id}/documentation/${requestBody.id}/dicom/${requestBody.dicomUuid}`, requestBody, withAccessToken()).then((response) => response);

const FilesService = {
  addExamination,
  getExamination,
  addFile,
  editFile,
  deleteFile,
  deleteFileFromService,
  deleteExamination,
  editExamination,
  testToken,
  generateMeddreamToken,
  dicomSeriesGeneration,
  addDicomFile,
  addDicomExamination,
  addDicomSeriesToExisting,
  deleteDicomSeries,
  deleteDicomFileFromService,
  moveStaggingDicom,
};

export default FilesService;
