import { BellIcon } from '@heroicons/react/24/outline';
import { Avatar } from 'antd';
import React, { createContext, useContext, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import Logout from '@/components/Logout/Logout.component';

import logoLg from '../assets/images/logo-lg.svg';
import Auth from '../components/Auth/Auth.component';
import DropDown from '../components/DropDown/DropDown.component';
import HeaderDownBar from '../components/HeaderDownBar/HeaderDownBar.component';
import Navbar from '../components/Navbar/Navbar.component';
import { userMenu } from '../constants/dropdown.constant';
import { navigation } from '../constants/navigation.constant';
import AuthContext from '../contexts/Auth.context';

export const TimerContext = createContext();
export const useTimer = () => useContext(TimerContext);

const AuthLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const currentPath = `/${pathname.split('/')[1]}`;
  const [timerContextValue, setTimerContextValue] = useState({});
  const { user } = useContext(AuthContext);

  const onChangeDropDown = (key) => {
    if (key === 'logout') {
      navigate('/auth');
      sessionStorage.clear();
      localStorage.removeItem('token');
      localStorage.removeItem('unitId');
    }
  };

  const navigationElementFilter = navigation.filter((item) => user.provideConsultation || item.route !== '/schedule');
  return (
    <Auth>
      <TimerContext.Provider value={timerContextValue}>
        <div id="meetingSDKElement2" className="fixed top-1 left-1 z-20 max-w-min" />
        <Navbar
          items={navigationElementFilter}
          defaultSelectedKeys={currentPath}
          logo={logoLg}
          placementMenu="center"
          fixed
          rightRender={
            <div className="flex items-center justify-end space-x-3 md:flex-1">
              <button
                type="button"
                className="rounded-full bg-gray-800 p-1 text-gray-400 transition duration-200 ease-in hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
              >
                <span className="sr-only">Powiadomienia</span>
                <BellIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              <DropDown
                items={userMenu}
                placement="bottomRight"
                arrow={false}
                type="basic"
                img={
                  <Avatar
                    size={32}
                    style={{
                      backgroundColor: 'rgb(31 41 55 / var(--tw-bg-opacity))',
                      verticalAlign: 'middle',
                      color: 'rgb(156 163 175 / var(--tw-text-opacity))',
                      fontSize: '15px',
                    }}
                  >
                    {user?.name?.substring(0, 1)}
                    {user?.lastname?.substring(0, 1)}
                  </Avatar>
                }
                onClick={onChangeDropDown}
              />

              <Logout setTimerContextValue={setTimerContextValue} />
            </div>
          }
        />
        <HeaderDownBar />

        {/* Container */}
        <div
          className={`mx-auto max-w-screen-2xl px-2 sm:px-6 lg:px-8 ${
            user.remainingDaysOfPasswordExpiration !== null && user.remainingDaysOfPasswordExpiration <= 7 ? 'mt-48' : 'mt-40'
          }`}
        >
          <Outlet />
          <div className="py-10" />
        </div>
      </TimerContext.Provider>
    </Auth>
  );
};

AuthLayout.propTypes = {};

AuthLayout.defaultProps = {};

export default AuthLayout;
