import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Form, Radio, Select, Spin } from 'antd';

import { nanoid } from 'nanoid';
import { TiInfo } from 'react-icons/ti';
import { useQuery } from 'react-query';
import GreenPoint from '@/components/GreenPoint/GreenPoint.component';
import moment from 'moment';
import _ from 'lodash';
import AuthContext from '@/contexts/Auth.context';
import { getListOfDoctors, getListOfUnits, getListSlotsOfDoctor } from '../../../services/consultations.service';
import { getConsultant } from '../../../helpers/getConsultant';
import ConsultantCalendar from './ConsultantCalendar.component';

const { Option } = Select;

const transformDate = (date) => {
  if (!date) {
    return {
      dateFrom: undefined,
      dateTo: undefined,
    };
  }

  const currentMonth = moment().format('M');
  const monthFromDate = moment(date).format('M');
  const dateFrom = currentMonth === monthFromDate ? moment().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') : moment(date.startOf('month')).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
  const dateTo = moment(date.endOf('month')).utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
  return {
    dateFrom,
    dateTo,
  };
};

const extractConsultantId = (consultantId) => parseInt(consultantId.substr(3, consultantId.length), 10);

const getNearestDate = (consultantId, doctorsArr, groupsArr) => {
  if (consultantId && doctorsArr && groupsArr) {
    const id = extractConsultantId(consultantId);

    if (consultantId.includes('dr')) {
      const doctor = doctorsArr.find((item) => item.id === id);
      const { nearestTimeSlot } = doctor;
      return nearestTimeSlot ? moment(nearestTimeSlot) : moment();
    }

    if (consultantId.includes('gr')) {
      const group = groupsArr.find((item) => item.id === id);
      const { nearestTimeSlot } = group;
      return nearestTimeSlot ? moment(nearestTimeSlot) : moment();
    }
  }

  return moment();
};

const Consultant = ({ defaultMode, form, data }) => {
  const [mode, setMode] = useState(defaultMode || form.getFieldValue('mode'));
  const [unitId, setUnitId] = useState(null || form.getFieldValue('consultantUnitId'));
  const [consultantId, setConsultantId] = useState(null || form.getFieldValue('consultant'));
  const [oldConsultant] = useState(null || form.getFieldValue('consultant'));
  const [date, setDate] = useState(form.getFieldValue('consultationDate') ? moment(form.getFieldValue('consultationDate')) : moment());
  const [slot, setSlot] = useState(form.getFieldValue('slotId'));
  const [doctorInitialValue] = useState(form.getFieldValue('consultant'));
  const [unitInitialValue] = useState(form.getFieldValue('consultantUnitId'));
  const [showWarning, setShowWarning] = useState(false);

  const { user } = useContext(AuthContext);

  // Lista wszystkich jednostek
  const {
    data: units,
    // refetch: refetchUnits,
    isFetching: isFetchingUnits,
  } = useQuery(['units'], getListOfUnits(), {
    // enabled: false,
    // retry: false,
  });

  // Lista lekarzy i grup
  const reqBody = { consultantUnitId: unitId, ...transformDate(date) };
  const {
    data: doctorsAndGroups,
    refetch,
    isFetching: isFetchingUnit,
  } = useQuery(['consultants', unitId], getListOfDoctors(reqBody), {
    enabled: false,
    retry: false,
  });

  // Lista slotów na podstawie wybranego lekarza i jednostki
  const {
    data: slots,
    refetch: refetchSlots,
    isFetching: isFetchingSlots,
  } = useQuery(['slots', consultantId, transformDate(date)], getListSlotsOfDoctor({ ...getConsultant(consultantId), ...transformDate(date) }), {
    enabled: false,
    keepPreviousData: true,
  });

  // Zmiana jednostki
  const onChangeUnit = (id) => {
    setUnitId(id);
    setConsultantId(null);
    setSlot(null);
    form.setFieldValue('consultant', undefined);
    // form.setFieldValue('mode', undefined);
    form.setFieldValue('slotId', undefined);

    if (id !== unitInitialValue && unitInitialValue !== null) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  // Zmiana lekarza/grupy
  const onChangeConsultant = (id) => {
    setConsultantId(id);
    setDate(getNearestDate(id, doctorsAndGroups?.data.doctors, doctorsAndGroups?.data.groups));
    setSlot(null);
    form.setFieldValue('slotId', undefined);

    // Jeśli jest tylko jedna grupa dla wybranego lekarza - ustaw ją
    const selectedDoctorGroups = id ? doctorsAndGroups?.data.doctors.find((item) => item.id === extractConsultantId(id))?.groups || [] : [];

    if (selectedDoctorGroups.length === 1) {
      form.setFieldValue('groupForSingleDoctor', selectedDoctorGroups[0].id);
    } else {
      form.setFieldValue('groupForSingleDoctor', undefined);
    }

    if (id !== doctorInitialValue && doctorInitialValue !== null) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  };

  // Zmiana trybu konsultacji
  const onChangeMode = (e) => {
    setMode(e.target.value);
    if (e.target.value === 'URGENT') {
      form.setFieldValue('consultant', undefined);
    }
  };

  // Zmiana daty w slotach
  const onChangeDate = (d) => {
    setDate(d);
  };

  useEffect(() => {
    if (unitId !== null && unitId !== undefined) {
      refetch();
    }
  }, [date]);

  const dates = useMemo(() => {
    // Dodaję slot już wcześniej zarejestrowany, sytuacja gdy edytujemy już wcześniej zarejestrowany termin
    if (slots) {
      const newSlots = [...slots.data.doctorSlots, ...slots.data.groupSlots];

      const oldConsultationDate = _.has(data, 'deadline.consultationDate') ? data.deadline.consultationDate : null;
      const consultant = form.getFieldValue('consultant');
      const oldSlotId = _.has(data, 'deadline.slotId') ? data.deadline.slotId : null;

      if (oldConsultationDate && moment(oldConsultationDate).format('YYYY-MM') === date.format('YYYY-MM') && consultant === oldConsultant) {
        newSlots.push({
          id: oldSlotId,
          dateFrom: oldConsultationDate,
          isBusy: true,
        });
        form.setFieldValue('slotId', oldSlotId);
      }

      return _.orderBy(_.uniqBy(newSlots, 'id'), ['dateFrom'], ['asc']);
    }
    return [];
  }, [slots, data]);

  // Wybór slot-a
  const onChangeSlot = (slotId) => {
    const slotTemp = dates.find((item) => item.id === slotId);
    setSlot(slotId);
    form.setFieldValue('consultationDateString', `${moment(slotTemp.dateFrom).format('YYYY-MM-DD')} godz. ${moment(slotTemp.dateFrom).format('HH:mm')}`);
    // setIsVisible(false);
  };

  useEffect(() => {
    if (unitId !== null && unitId !== undefined) {
      refetch();
    }
  }, [unitId]);

  useEffect(() => {
    if (consultantId && mode === 'PLANNED') refetchSlots();
  }, [consultantId, date, mode]);

  return (
    <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
      <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
        <div>
          <h1 className="text-lg font-semibold">Termin konsultacji Konsultant</h1>
          <p className="text-gray-500">Lekarz/Grupa</p>
        </div>
      </div>

      <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
        <Spin spinning={isFetchingUnit || isFetchingSlots || isFetchingUnits} className="w-full">
          {/* <FormItem name="slotId"> */}
          {/*  <input type="text" value={slot} /> */}
          {/* </FormItem> */}

          <Form.Item
            label="Tryb konsultacji"
            name="mode"
            rules={[
              {
                required: true,
                message: 'Pole obowiązkowe!',
              },
            ]}
          >
            <Radio.Group onChange={onChangeMode}>
              <Radio value="PLANNED">planowa</Radio>
              <Radio value="URGENT">nagła</Radio>
              <Radio value="OFFLINE">offline</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label="Jednostka"
            name="consultantUnitId"
            onChange={onChangeConsultant}
            rules={[
              {
                required: true,
                message: 'Pole obowiązkowe!',
              },
            ]}
          >
            <Select className="drop-shadow-sm" placeholder="wybierz" onChange={onChangeUnit}>
              {units?.data.units.map((item) => (
                <Option value={item.id} key={nanoid()}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {showWarning && (
            <div className="pb-4">
              <Alert
                message={
                  <span>
                    Karta kwalifikacji jest powiązana z danym lekarzem lub grupą konsultującą dlatego zmiana <span className="font-bold">Jednostki</span> lub{' '}
                    <span className="font-bold">Lekarza/Grupy konsultującej</span> przypisanych do konsultacji, może powodować utratę danych w karcie kwalifikacji.
                  </span>
                }
                type="warning"
              />
            </div>
          )}
          <Form.Item
            label={mode === 'URGENT' ? 'Grupa' : 'Lekarz/Grupa'}
            name="consultant"
            className="w-full"
            rules={[
              {
                required: true,
                message: 'Pole obowiązkowe!',
              },
            ]}
          >
            <Select className="drop-shadow-sm" placeholder="wybierz" onChange={onChangeConsultant} disabled={!doctorsAndGroups || !unitId}>
              {mode !== 'URGENT' &&
                doctorsAndGroups?.data.doctors.map((doctor) => {
                  if (doctor.name === user.name && doctor.surname === user.lastname) {
                    return null;
                  }
                  return (
                    <Option value={`dr-${doctor.id}`} key={`dr-${doctor.id}`}>
                      <div className="flex items-center gap-2">
                        {mode === 'PLANNED' && <GreenPoint isActive={doctor.hasAvailableSlots} testId="green-point" />}
                        {doctor.name} {doctor.surname}
                        <span className="text-sm text-gray-500"> - {doctor.specialty}</span>
                      </div>
                    </Option>
                  );
                })}
              {doctorsAndGroups?.data.groups.map((group) => (
                <Option value={`gr-${group.id}`} key={`gr-${group.id}`}>
                  <div className="flex items-center gap-2">
                    {mode === 'PLANNED' && <GreenPoint isActive={group.hasAvailableSlots} testId="green-point" />} {group.name}
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>

          <div>
            {mode !== 'URGENT' && consultantId && unitId && (
              <>
                {consultantId.startsWith('dr') && (
                  <Form.Item
                    label="Komórka org. lekarza"
                    name="groupForSingleDoctor"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: 'Pole obowiązkowe!',
                      },
                    ]}
                  >
                    <Select className="drop-shadow-sm" placeholder="Wybierz komórkę" disabled={!doctorsAndGroups || !unitId}>
                      {doctorsAndGroups?.data.doctors
                        .find((item) => item.id === extractConsultantId(consultantId))
                        ?.groups.map((group) => (
                          <Option value={group.id} key={group.id}>
                            {group.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                {mode === 'PLANNED' && (
                  <Form.Item
                    // noStyle
                    label="Wybrany termin"
                    name="slotId"
                    rules={[
                      {
                        required: true,
                        message: 'Wybierz termin!',
                      },
                    ]}
                  >
                    <ConsultantCalendar
                      onChangeDate={onChangeDate}
                      onChange={onChangeSlot}
                      dates={dates}
                      date={date}
                      slot={slot}
                      disabled={!consultantId}
                      // activeDate={{
                      //   consultationDateFrom: data?.deadline.consultationDateFrom,
                      //   consultationDateTo: data?.deadline.consultationDateTo,
                      //   slotId: data?.deadline.slotId,
                      // }}
                    />
                  </Form.Item>
                )}
              </>
            )}
            {mode === 'URGENT' && (
              <div className="consultant-warning-alert">
                <Alert
                  description="Prośba o konsultacje nagłą zostanie bezzwłocznie przekazana do lekarza dyżurnego.
                  Prosimy przejść do detali konsultacji i dołączyć na spotkania ZOOM (przycisk na dole strony po prawej [Dołącz]). Lekarz dyżurny dołączy najszybciej jak będzie mógł."
                  type="error"
                  showIcon
                  icon={<TiInfo />}
                />
              </div>
            )}
            {mode === 'OFFLINE' && (
              <div className="consultant-info-alert">
                <Alert
                  description="Tryb offline pozwala zlecić konsultacje bez konieczności interaktywnej komunikacji między stronami. Konsultant może przeprowadzić konsultacje i przesłać wynik konsultacji lekarzowi zlecającemu w uzgodnionym dogodnym dla siebie terminie."
                  type="info"
                  showIcon
                  icon={<TiInfo />}
                />
              </div>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Consultant;

Consultant.propTypes = {
  defaultMode: PropTypes.string,
};

Consultant.defaultProps = {
  defaultMode: null,
};
