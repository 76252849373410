import React, { FC, useEffect } from 'react';
import { Row, Col } from 'antd';
import { DisplayField, DisplayFieldGroup } from './DisplayField';

import { Field, FormTemplate } from './types';

import { useTemplateEngineContext } from './TemplateEngineContext';
import { hasValue } from './service';

interface ResultRendererProps {
  template?: FormTemplate | null;
  values?: Record<string, unknown> | null;
  displayEmptyValues?: boolean;
}

export const ResultRenderer: FC<ResultRendererProps> = ({ template, values, displayEmptyValues }) => {
  const { setTemplate } = useTemplateEngineContext();

  useEffect(() => {
    setTemplate(template);
  }, [template]);

  if (!template) return null;
  if (!values && !displayEmptyValues) return null;

  const formValues = values ?? {};
  const getFieldValue = (name: string) => formValues[name];

  if (!template) {
    console.error('Template is not defined');
    return null;
  }

  return (
    <Row className="dynamic-form-result" gutter={[16, 10]}>
      {template.name && (
        <Col span={24}>
          <h1 className="font-semibold uppercase">{template.name}</h1>
        </Col>
      )}
      {template.groups.map((group, index) => {
        const filteredFields = group.fields.filter((field) => displayEmptyValues || hasValue(formValues[field.name]));
        const shouldRenderGroup = filteredFields.length > 0;

        return shouldRenderGroup ? (
          <DisplayFieldGroup key={index} variant={group.variant} label={group.label}>
            {group.fields.map((field) => (
              <Col key={field.name} xs="24" sm="24" md="12" lg="8" xl={field.width ?? 24}>
                {hasValue(formValues[field.name]) || displayEmptyValues ? (
                  <DisplayField
                    className="dynamic-field"
                    field={{ ...field, value: null } as Field}
                    formTemplate={template}
                    value={formValues[field.name]}
                    getFieldValue={getFieldValue}
                  />
                ) : null}
              </Col>
            ))}
          </DisplayFieldGroup>
        ) : null;
      })}
    </Row>
  );
};
