import { Testable, testIdFactory } from '@/tests';
import { Tag } from 'antd';
import React, { FC, PropsWithChildren } from 'react';
import { hasValue } from '../../service';

interface FieldValueProps extends PropsWithChildren, Testable {
  name: string;
  value: unknown;
  isNecessary: boolean;
  fieldHasNoData: boolean;
}
export const FieldValue: FC<FieldValueProps> = ({ name, value, isNecessary, children, testId, fieldHasNoData }) => {
  const testAttribute = testIdFactory(testId);

  if (hasValue(value)) {
    return <div {...testAttribute(name)}>{children}</div>;
  }

  return isNecessary ? (
    <Tag color="#d42b2e" className="break-words" {...testAttribute(`${name}-required`)}>
      pole obowiązkowe
    </Tag>
  ) : (
    <div className="text-gray-600" {...testAttribute(`${name}-no-data`)}>
      {fieldHasNoData ? 'brak danych' : <span>&nbsp;</span>}
    </div>
  );
};
