import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { Row, Col, Space } from 'antd';
import { Field, Group } from '../types';

interface DisplayGroupProps extends Omit<Group<Field>, 'fields'> {
  children?: ReactNode;
}

const COLUMN_GAP = 10;
const ROW_GAP = 0;

export const DisplayFieldGroup: FC<DisplayGroupProps> = ({ variant, label, children }) => {
  const commonClasses = classNames('');

  const paragraphClasses = classNames('font-semibold last:pb-0');

  const getGroupClasses = () => {
    switch (variant) {
      case 'card':
      case 'collapse':
        return classNames(commonClasses, 'bg-gray-100 rounded-md px-6 py-4 mt-2');
      case 'transparent':
      default:
        return classNames(commonClasses);
    }
  };

  return (
    <Col className="dynamic-form-group" span={24}>
      <Space style={{ width: '100%' }} direction="vertical" className={getGroupClasses()}>
        {label && <p className={paragraphClasses}>{label}</p>}
        <Row gutter={[COLUMN_GAP, ROW_GAP]}>{children}</Row>
      </Space>
    </Col>
  );
};
