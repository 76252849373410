import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Button from '../Button2/Button.component';

const { confirm } = Modal;

const SupplementButton = ({ titleBtn, titleMessage, contentMessage, okText, cancelText, errorMessage, successMessage, request, refresh}) => {
  const mutation = useMutation(request, {
    onError: () => {
      message.error(errorMessage);
    },
    onSuccess: () => {
      if (typeof refresh === 'function') refresh();
      message.success(successMessage);
    },
  });

  const showConfirm = () => {
    confirm({
      title: titleMessage,
      icon: <ExclamationCircleOutlined />,
      content: contentMessage,
      okText,
      cancelText,
      okButtonProps: { className: 'bg-blue-700', loading: mutation.isLoading },
      onOk() {
        return new Promise((resolve, reject) => {
          mutation.mutate();
          if (!mutation.isLoading) {
            resolve();
          } else {
            reject();
          }
        }).catch((error) => console.error('Wystąpił błąd podczas zatwierdzania uzupełnienia konsultacji', error));
      },
    });
  };

  return (
    <Button color="red" size="sm" onClick={showConfirm}>
      <div>{titleBtn}</div>
    </Button>
  );
};

SupplementButton.propTypes = {
  titleBtn: PropTypes.string,
  contentMessage: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  refresh: PropTypes.func,
};

SupplementButton.defaultProps = {
  titleBtn: null,
  contentMessage: null,
  okText: null,
  cancelText: null,
  errorMessage: null,
  successMessage: null,
  refresh: () => null,
};

export default SupplementButton;
