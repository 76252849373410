import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Button from '../Button2/Button.component';

const { confirm } = Modal;

const CorrectionButton = ({ titleBtn, titleMessage, contentMessage, okText, cancelText, errorMessage, successMessage, request, onEdit, refresh, documentation }) => {
  const mutation = useMutation(request, {
    onError: () => {
      console.log(mutation.error);
      message.error(errorMessage);
    },
    onSuccess: () => {
      if (typeof refresh === 'function')
        refresh().then(() => {
          if (documentation?.state === 'IN_CORRECTION') onEdit();
        });
      message.success(successMessage);
    },
  });

  const showConfirm = () => {
    confirm({
      title: titleMessage,
      icon: <ExclamationCircleOutlined />,
      content: contentMessage,
      okText,
      cancelText,
      okButtonProps: { className: 'bg-blue-700', loading: mutation.isLoading },
      onOk() {
        return new Promise((resolve, reject) => {
          mutation.mutate();
          if (!mutation.isLoading) {
            resolve();
          } else {
            reject();
          }
        }).catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Anuluj');
      },
    });
  };

  return (
    <Button color="darkblue" size="sm" onClick={showConfirm}>
      <div>{titleBtn}</div>
    </Button>
  );
};

CorrectionButton.propTypes = {
  titleBtn: PropTypes.string,
  contentMessage: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  refresh: PropTypes.func,
  onEdit: PropTypes.func,
  documentation: PropTypes.objectOf(PropTypes.string),
};

CorrectionButton.defaultProps = {
  titleBtn: null,
  contentMessage: null,
  okText: null,
  cancelText: null,
  errorMessage: null,
  successMessage: null,
  refresh: () => null,
  onEdit: () => null,
  documentation: null,
};

export default CorrectionButton;
