import { FORMATS } from '../constants/dates.constants';

export const checkMinimalConsultationTimeRange = (start, end) => {
  if (start !== (null || undefined) && end !== (null || undefined)) {
    if (
      (end.minutes() - start.minutes() < 30 &&
        end.minutes() - start.minutes() > 0 &&
        end.hours() === start.hours()) ||
      (end.minutes() - start.minutes() < -30 &&
        end.minutes() - start.minutes() < 0 &&
        (end.hours() === start.hours() || end.hours() === start.hours() + 1)) ||
      start.format(FORMATS.DATE_TIME) === end.format(FORMATS.DATE_TIME)
    ) {
      return true;
    }
  }
  return false;
};
