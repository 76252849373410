import classNames from 'classnames';
import React from 'react';

import { testIdFactory } from '@/tests';
import { DisplayFieldComponent } from '../types';
import { CheckboxField } from '../../types';

export const CheckboxDisplayField: DisplayFieldComponent<CheckboxField> = ({ name, className, label, isHidden, variant = 'normal', value, testId }) => {
  if (isHidden) {
    return null;
  }

  const labelClasses = classNames('break-words font-semibold', {
    'text-black': Boolean(value),
    'line-through text-slate-500': !value,
    'pt-1': variant === 'normal',
  });

  const testAttribute = testIdFactory(testId);

  return (
    <div style={{ marginTop: variant === 'header' ? '-0.3rem' : 0 }} className={className}>
      <div className={labelClasses} {...testAttribute(name)}>
        {label}
      </div>
    </div>
  );
};
