import React from 'react';
import { Col, Spin, Timeline, Skeleton } from 'antd';
import { nanoid } from 'nanoid';
import { testId as testAttribute } from '@/tests';
import { getStatus } from '../../utils/getStatus';
import Card from '../Card/Card.component';

const StatusCard = ({ title, status, isStatusPriority, testId }) => (
  <div className=" mx-auto mb-5  max-w-lg border border-darkblue p-3 px-3">
    <div className="flex w-full items-center gap-2">
      <div className="flex items-center justify-center">
        <span className="block w-32">{title}</span>
      </div>
      <div className="h-10 border border-darkblue" />
      <div className="float-left max-w-fit">
        <span className={isStatusPriority ? 'block break-all text-base font-bold text-red-600 ' : 'block break-all text-base font-bold text-darkblue'} {...testAttribute(testId)}>
          {status}
        </span>
      </div>
    </div>
  </div>
);

const StatusDetails = ({ data, isLoading, isFetching }) => {
  const { teleconsultation, documentation, history } = data;

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Col span={24}>
      <Spin spinning={isFetching}>
        <Card title={<p>Status</p>}>
          <StatusCard title="Stan konsultacji" status={getStatus(teleconsultation?.state)} isStatusPriority={false} testId="consultation-state" />
          <StatusCard title="Tryb konsultacji" status={getStatus(teleconsultation?.mode)} isStatusPriority={false} testId="consultation-mode" />
          <StatusCard title="Stan dokumentacji" status={getStatus(documentation?.state)} isStatusPriority testId="documentation-state" />

          <Timeline className="max-h-96 overflow-auto pt-1" reverse>
            {history.map((item, index) => (
              <Timeline.Item key={nanoid()}>
                <div className="ant-steps-item-content">
                  <div className="text-base" {...testAttribute(`timeline-${index}-date`)}>
                    {item.date}
                  </div>
                  <div className="ant-steps-item-description" {...testAttribute(`timeline-${index}-summary`)}>
                    {item.changeSummary}
                  </div>
                </div>
              </Timeline.Item>
            ))}
          </Timeline>
        </Card>
      </Spin>
    </Col>
  );
};

export default StatusDetails;
