import React, { useContext, useState } from 'react';
import { Dropdown, Form, Input, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { testId } from '@/tests';
import { useConsultationActions } from '@/utils/useConsultationActions';
import BtnWithRequest from '../BtnWithRequest/BtnWithRequest.component';
import { cancelConsultation, deleteConsultation, rejectConsultation, revokeConsultation, supplementConsultation, requestStartCall } from '../../services/consultations.service';
import BtnWithRequestStartCall from '../BtnWithRequest/BtnWithRequestStartCall.component';

import AuthContext from '../../contexts/Auth.context';
import { DropDownActionButton } from './DropDownActionButton';
import Button from '../Button2/Button.component';

const DropDownAction = ({
  idConsultation,
  consultationState,
  documentationState,
  consultantList,
  isGroup,
  doctorId,
  deadline,
  onInviteToConsultationClicked,
  consultationMode,
  data,
}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [formMessage, setMessage] = useState({});

  const { canAct, canPerformAnyAction } = useConsultationActions({
    hasDeadline: Boolean(deadline),
    consultationMode,
    consultationStatus: consultationState,
    documentationStatus: documentationState,
    usersData: {
      doctorId,
      consultantList,
      userDoctorId: user.doctorId,
    },
  });
  const isStartCall = consultantList && consultantList.some((item) => item.consultantId === user.doctorId);

  // const isStartCall = consultantList && consultantList.some((item) => item.consultantId === user.doctorId);
  // Lekarz zlecający/Konsultant

  const isVisibleSupplement = consultationState === 'ORDERED' && consultantList.some((item) => item.consultantId === user.doctorId);

  if (!canPerformAnyAction(['RELEASE_TIMESLOT', 'REJECT_CONSULTATION', 'CANCEL_CONSULTATION', 'DELETE_CONSULTATION', 'INVITE_TO_CONSULTATION'])) {
    return null;
  }

  const items = [];
  const refresh = () => {
    if (pathname === '/schedule') {
      if (isGroup) queryClient.refetchQueries(['groupSlots']);
    } else {
      queryClient.refetchQueries(['doctorSlots']);
    }

    if (pathname.includes('consultations')) {
      queryClient.refetchQueries(['status/history']);
      queryClient.refetchQueries(['consultation-id', idConsultation]);
    } else {
      navigate('/consultations');
    }
  };

  const contentReject = (
    <div className="flex flex-col gap-5">
      <div>Odrzucenie terminu spowoduje usunięcie zlecenia oraz powiadomienie zlecającego, iż konsultacja nie zostanie wykonana.</div>
      <Form form={form} layout="vertical" name="form_rejected" onValuesChange={(values) => setFormData(values)}>
        <Form.Item
          name="rejectReason"
          label="Uzasadnienie"
          rules={[
            {
              required: true,
              message: 'Pole obowiązkowe!',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </div>
  );

  const contentSupplement = (
    <div className="flex flex-col gap-5">
      <Form form={form} layout="vertical" name="form_supplement" onValuesChange={(values) => setMessage(values)}>
        <Form.Item
          name="descriptionSupplement"
          label="Opis"
          rules={[
            {
              required: true,
              message: 'Pole obowiązkowe!',
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </div>
  );

  if (isVisibleSupplement)
    // TODO przerobić na canAct
    items.push({
      key: 'supplement',
      label: (
        <BtnWithRequest
          type="text"
          className="text-sm"
          request={supplementConsultation({ uuid: idConsultation, message: formMessage.descriptionSupplement })}
          titleBtn="Poproś o uzupełnienie dokumentacji"
          titleMessage="Prośba o uzupełnienie dokumentacji"
          content={contentSupplement}
          okText="Wyślij"
          cancelText="Anuluj"
          errorMessage="Problem z wysłaniem prośby o uzupełnienie dokumentacji."
          successMessage="Prośba została została wysłana."
          onSuccess={() => {
            if (pathname.includes('consultations')) {
              queryClient.refetchQueries(['status/history']);
            }
          }}
          form={form}
        />
      ),
    });
  if (isStartCall)
    items.push({
      key: 'startCall',
      label: (
        <BtnWithRequestStartCall
          type="text"
          className="text-sm"
          request={requestStartCall(idConsultation)}
          titleBtn="Wdzwoń uczestników"
          titleMessage="Czy chcesz odrzucić konsultację?"
          errorMessage="Problem z wysłaniem prośby o wdzwonienie."
          successMessage="Prośba o wdzwonienie została wysłana."
          consultantList={consultantList}
          data={data}
          onSuccess={() => {
            queryClient.refetchQueries(['status/history']);
            queryClient.refetchQueries(['consultation-id', idConsultation]);
          }}
        />
      ),
    });

  if (canAct('RELEASE_TIMESLOT') && deadline)
    items.push({
      key: 'schedule',
      label: (
        <BtnWithRequest
          type="text"
          className="text-sm"
          request={revokeConsultation(idConsultation)}
          titleBtn="Zwolnij termin"
          titleMessage="Czy chcesz zwolnić termin?"
          content="Czy chcesz zwolnić termin przypisany do konsultacji? Aby w przyszłości wykonać konsultację należy ponowie przypisać ją do jednego z dostępnych terminów."
          okText="Zwolnij termin"
          cancelText="Rezygnuję"
          errorMessage="Problem ze zwolnieniem terminu."
          successMessage="Termin został zwolniony."
          onSuccess={refresh}
        />
      ),
    });

  if (canAct('REJECT_CONSULTATION'))
    items.push({
      key: 'reject',
      label: (
        <BtnWithRequest
          type="text"
          className="text-sm"
          request={rejectConsultation(idConsultation, formData)}
          titleBtn="Odrzuć konsultację"
          titleMessage="Czy chcesz odrzucić konsultację?"
          content={contentReject}
          okText="Odrzuć konsultację"
          cancelText="Rezygnuję"
          errorMessage="Problem z odrzuceniem konsultacji."
          successMessage="Konsultacja została odrzucona"
          onSuccess={() => {
            if (data?.deadline?.groupId !== null && data?.doctor?.doctorId === user?.doctorId) {
              queryClient.refetchQueries(['consultation-id', idConsultation]);
              queryClient.refetchQueries(['status/history']);
            } else {
              navigate('/consultations');
            }
          }}
          form={form}
        />
      ),
    });

  if (canAct('CANCEL_CONSULTATION'))
    items.push({
      key: 'cancel',
      label: (
        <BtnWithRequest
          type="text"
          className="text-sm"
          request={cancelConsultation(idConsultation)}
          titleBtn="Anuluj konsultację"
          titleMessage="Czy chcesz anulować konsultację?"
          content="Anulowanie konsultacji spowoduje usunięcie zlecenia oraz zwolnienie przypisanego terminu."
          okText="Anuluj konsultację"
          cancelText="Rezygnuję"
          errorMessage="Problem z anulowaniem konsultacji."
          successMessage="Konsultacja została anulowana."
          onSuccess={refresh}
        />
      ),
    });

  if (canAct('DELETE_CONSULTATION'))
    items.push({
      key: 'delete',
      label: (
        <BtnWithRequest
          type="text"
          className="text-sm"
          request={deleteConsultation(idConsultation)}
          titleBtn="Usuń konsultację"
          titleMessage="Czy chcesz usunąć konsultację?"
          content="Usunięcie konsultacji jest nieodwracalne."
          okText="Usuń konsultację"
          cancelText="Rezygnuję"
          errorMessage="Problem z usunięciem konsultacji."
          successMessage="Konsultacja została usunięta."
          onSuccess={() => {
            navigate('/consultations');
            queryClient.refetchQueries(['consultations']);
          }}
        />
      ),
      danger: true,
    });

  if (canAct('INVITE_TO_CONSULTATION')) {
    items.push(
      {
        type: 'divider',
      },
      {
        key: 'invite',
        label: (
          <DropDownActionButton className="text-sm" onClick={onInviteToConsultationClicked}>
            Zaproś lekarza
          </DropDownActionButton>
        ),
      },
    );
  }
  const menu = <Menu items={items} />;

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <Button color="darkblue" size="sm" {...testId('consultation-actions-button')}>
        <div className="flex items-center gap-1">
          Akcje <DownOutlined className="text-[10px]" />
        </div>
      </Button>
    </Dropdown>
  );
};

DropDownAction.propTypes = {
  idConsultation: PropTypes.string,
  consultationState: PropTypes.string,
  consultantList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isGroup: PropTypes.bool,
  doctorId: PropTypes.number,
};

DropDownAction.defaultProps = {
  idConsultation: null,
  consultationState: null,
  consultantList: [],
  isGroup: false,
  doctorId: null,
};

export default DropDownAction;
