import React, { useState } from 'react';
import { Button as ButtonAnt, Col, Dropdown, Menu, Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { deleteReferral } from '@/services/consultations.service';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import BtnWithRequest from '../BtnWithRequest/BtnWithRequest.component';
import Card from '../Card/Card.component';

const ReferralDetails = ({ data, onEdit, indicator, initialDisplayRows, showActionBtn }) => {
  const { id: uuid } = useParams();
  const queryClient = useQueryClient();
  const items = (item) => [
    {
      label: (
        <button type="button" onClick={onEdit('referral', 'edit', item)} className="block w-full py-1 px-2 text-left">
          Edycja
        </button>
      ),
      key: 'edit',
    },
    {
      label: (
        <BtnWithRequest
          type="text"
          className="text-sm"
          request={deleteReferral(uuid, item.id)}
          titleBtn="Usuń skierowanie"
          titleMessage="Czy chcesz usunąć skierowanie?"
          content="Usunięcie skierowania jest nieodwracalne."
          okText="Usuń skierowanie"
          cancelText="Rezygnuję"
          errorMessage="Problem z usunięciem skierowania."
          successMessage="Skierowanie zostało usunięte."
          onSuccess={async () => {
            await queryClient.refetchQueries(['referrals', uuid]);
          }}
        />
      ),
      key: 'delete',
    },
  ];

  const [displayedRows, setDisplayedRows] = useState(initialDisplayRows);
  const finalData = data.slice(0, displayedRows);
  const allRowsShown = displayedRows === data.length;
  const buttonLabel = allRowsShown ? 'Zwiń' : 'Pokaż więcej';

  const handleShowAll = () => {
    if (allRowsShown) {
      setDisplayedRows(initialDisplayRows);
    } else {
      const remainingRows = data.length - displayedRows;
      const nextDisplayedRows = displayedRows + Math.min(remainingRows, indicator);
      setDisplayedRows(nextDisplayedRows);
    }
  };

  const menu = (item) => <Menu items={items(item)} />;

  const defaultColumns = [
    {
      title: 'PIN / Nr skierowania',
      dataIndex: 'referralCode',
      // width: 200,
      render: (_, record) => (
        <div className="">
          <span className="break-all">{record.referralCodeType === 'REFERRAL_NUMBER' ? 'Nr skier.' : 'PIN'}</span> <p className="break-words">{record.referralCode}</p>
        </div>
      ),
    },
    {
      title: 'Nr tel. do pacjenta',
      dataIndex: 'patientPhoneNumber',
      render: (_, record) => (
        <span>
          {record.patientPhoneNumberPrefix} {record.patientPhoneNumber}
        </span>
      ),
    },
  ];

  if (showActionBtn) {
    defaultColumns.push({
      title: 'Akcje',
      dataIndex: 'operation',
      className: 'files-cell-buttons',
      fixed: 'right',
      width: 80,
      render: (_, record) => {
        if (!showActionBtn) return null;
        return (
          <div className="flex justify-end">
            <Dropdown overlay={menu(record)} placement="bottomRight">
              <ButtonAnt className="font-extrabold">. . .</ButtonAnt>
            </Dropdown>
          </div>
        );
      },
    });
  }

  const columns = defaultColumns.map((col) => ({
    ...col,
    onCell: (record) => ({
      record,
      dataindex: col.dataIndex,
      title: col.title,
    }),
  }));

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Card
        title={
          <div className="flex flex-row items-center justify-between">
            <p className="mr-4">Skierowania</p>
            <div>
              {showActionBtn && (
                <Tooltip title={data?.length === 20 && 'Maksymalna liczba skierowań to 20'}>
                  <ButtonAnt type="link" disabled={data?.length === 20} onClick={onEdit('referral', 'add')}>
                    dodaj
                  </ButtonAnt>
                </Tooltip>
              )}
            </div>
          </div>
        }
      >
        {isEmpty(data) ? (
          <div>Brak skierowań</div>
        ) : (
          <>
            <Table
              className="table-styles w-full"
              pagination={false}
              bordered
              dataSource={finalData}
              columns={columns}
              scroll={{
                x: 400,
              }}
            />

            <div className="mt-2  flex flex-row items-center">
              <p>Liczba skierowań: {data.length}</p>
              {data.length > indicator && (
                <ButtonAnt type="link" onClick={handleShowAll}>
                  {buttonLabel}
                </ButtonAnt>
              )}
            </div>
          </>
        )}
      </Card>
    </Col>
  );
};

ReferralDetails.propTypes = {
  indicator: PropTypes.number,
  initialDisplayRows: PropTypes.number,
  showActionBtn: PropTypes.bool,
};

ReferralDetails.defaultProps = {
  indicator: 2,
  initialDisplayRows: 2,
  showActionBtn: true,
};

export default ReferralDetails;
