import React from 'react';
import PropTypes from 'prop-types';
import { testId } from '@/tests';
import Card from '../Card/Card.component';
// import PropTypes from 'prop-types';

const ConsultationCountWidget = ({ count, title, onClick, alert }) => (
  <Card title={title} titleNoWrap alert={alert}>
    <div className="flex h-14 items-center justify-center py-5 text-3xl font-medium" {...testId('mode-count')}>
      {count}
    </div>
    <span className={`flex cursor-pointer justify-end text-sm ${alert ? 'text-gray-50 hover:text-white' : 'hover:text-lightblue'}`} onClick={onClick} {...testId('show-button')}>
      pokaż
    </span>
  </Card>
);

ConsultationCountWidget.propTypes = {
  count: PropTypes.number,
  title: PropTypes.string,
  onClick: PropTypes.func,
  alert: PropTypes.bool,
};

ConsultationCountWidget.defaultProps = {
  count: 0,
  title: null,
  onClick: () => null,
  alert: false,
};

export default ConsultationCountWidget;
