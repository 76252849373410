import React from 'react';
import { Alert, Col } from 'antd';
import { TiInfo } from 'react-icons/ti';
// import { cardiologyQualificationCard as qualificationCardTemplate } from '@/fakes/qualificationCardTemplates';
import Headline from '../Headline/Headline.component';
import Card from '../Card/Card.component';

import { ResultRenderer } from '../TemplateEngine';

const QualificationCardDetails = ({ data, status, onEdit, showEditButton }) => {
  const { teleconsultation } = status;

  const templateValues = data.qualificationCard;
  const template = data.templateQualificationCard; // backend
  // const template = qualificationCardTemplate; // local fake values

  const isQualificationCardHidden = ['NOT_ORDERED'].some((state) => state === teleconsultation?.state);

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Card
        title={
          <Headline titleContent="Karta kwalifikacji" disabled={isQualificationCardHidden || !showEditButton} status={status} onEditTitle={onEdit} testId="qualification-card" />
        }
      >
        <div>
          {isQualificationCardHidden ? (
            <div className="flex-col">
              <Alert className="mx-auto max-w-md" description="W celu wyświetlenia karty kwalifikacji należy zlecić konsultację." type="info" showIcon icon={<TiInfo />} />
            </div>
          ) : (
            <ResultRenderer template={template} values={templateValues} displayEmptyValues />
          )}
        </div>
      </Card>
    </Col>
  );
};

export default QualificationCardDetails;
