/* eslint-disable */
import { DeleteOutlined } from '@ant-design/icons';
import { Button as ButtonAnt, Form, message, Popconfirm, Skeleton, Table } from 'antd';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import { AiOutlineCheck, AiOutlinePlus } from 'react-icons/ai';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../contexts/Auth.context';

import {
  deleteDicomFileFromService,
  deleteDicomSeries,
  deleteExamination,
  deleteFile,
  deleteFileFromService,
  dicomSeriesGeneration,
  editExamination,
  getExamination,
} from '../../../services/files.service';
import Button from '../../Button2/Button.component';
import ModalFilesUpload from './ModalFilesUpload.component';
import Cell from './Cell.component';

const FilesUpload = ({ type, accept }) => {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState('');
  const [examinationId, setExaminationId] = useState();
  const [fileId, setfileId] = useState();
  const { id } = useParams();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { unit } = useContext(AuthContext);

  const { data: response, isLoading } = useQuery(['documentation', id], getExamination(id));
  const data = response ? response.data : null;
  const { data: dicomSeries } = useQuery(['dicom-series', id], dicomSeriesGeneration(id));

  const handleShowModal = (modalMode, examinationId) => () => {
    setMode(modalMode);
    setExaminationId(examinationId);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onEditFile = (fileId, examinationId) => {
    setfileId(fileId);
    setExaminationId(examinationId);
    setMode('editFile');
    setOpen(true);
  };

  //EDYCJA NAZWY BADANIA
  const mutationOptionsEditName = {
    onSuccess: async () => {
      message.success('Nazwa badania została zmieniona.');
      if (id) await queryClient.refetchQueries(['documentation', id]);
      if (id) await queryClient.refetchQueries(['consultation-id', id]);
    },
    onError: async (err) => {
      if (id) await queryClient.refetchQueries(['documentation', id]);
      message.error(`Problem z poprawną zmianą nazwy badania. Opis: ${err.response.data.message}.`);
    },
  };

  const mutationEditName = useMutation(editExamination, mutationOptionsEditName);

  const onChangeName = (examinationId) => {
    mutationEditName.mutate({
      id,
      examinationId: examinationId.examinationId,
      name: examinationId.examinationName,
    });
  };

  //USUWANIE PLIKU/SERII
  const fileDeletionMutationOptions = {
    onSuccess: async () => {
      {
        type === 'others' ? message.success('Plik został poprawnie usunięty.') : message.success('Seria DICOM została poprawnie usunięta.');
      }

      if (id) await queryClient.refetchQueries(['documentation', id]);
      if (id) await queryClient.refetchQueries(['consultation-id', id]);
      if (id) await queryClient.refetchQueries(['status/history', id]);
    },
    onError: (err) => {
      message.error('Problem z poprawnym usunięciem pliku DICOM.');
    },
  };

  const mutation = useMutation(deleteFile, fileDeletionMutationOptions);

  const serviceOptionsForOtherFilesCallingTwoEndpoints = {
    onSuccess: async (_, variables) => {
      await mutation.mutate({ fileId: variables.fileId, examinationId: variables.examinationId, id });
    },
    onError: (err) => {
      console.log(err);
    },
  };

  const mutationOtherService = useMutation(deleteFileFromService, serviceOptionsForOtherFilesCallingTwoEndpoints);

  const onDeleteFile = ({ fileName, fileId, examinationId }) => {
    mutationOtherService.mutate({
      id,
      fileName,
      fileId,
      examinationId,
      unit,
    });
  };

  const mutationDicom = useMutation(deleteDicomSeries, fileDeletionMutationOptions);

  const mutationDicomServiceOptions = {
    onSuccess: async (_, variables) => {
      await mutationDicom.mutate({ examinationId: variables.examinationId, dicomSeriesId: variables.dicomSeriesId, id });
      if (id) await queryClient.refetchQueries(['documentation', id]);
      if (id) await queryClient.refetchQueries(['meddream-token']);
    },
    onError: (err) => {
      {
        type === 'others' ? message.error('Problem z poprawnym usunięciem pliku SERVICE.') : message.error('Problem z poprawnym usunięciem SERII SERVICE.');
      }
    },
  };
  const mutationDeleteDicomSeriesService = useMutation(deleteDicomFileFromService, mutationDicomServiceOptions);

  const onDeleteDicomService = ({ dicomSeriesId, examinationId, dicomUuid }) => {
    mutationDeleteDicomSeriesService.mutate({
      examinationId,
      id,
      dicomSeriesId,
      dicomUuid,
      unit,
    });
    queryClient.refetchQueries(['dicom-series', id]);

    form.resetFields();
  };

  //USUWANIE BADANIA
  const mutationService = {
    onSuccess: async () => {
      message.success('Badanie zostało usunięte.');
      if (id) await queryClient.refetchQueries(['documentation', id]);
      if (id) await queryClient.refetchQueries(['consultation-id', id]);
      if (id) await queryClient.refetchQueries(['status/history', id]);
      queryClient.refetchQueries(['meddream-token']);
    },
    onError: (err) => {
      message.error('Problem z poprawnym usunięciem badania.');
    },
  };

  const mutationDeleteExamination = useMutation(deleteExamination, mutationService);

  const handleDeleteRow = (examinationId, dicomSeriesId, dicomUuid, dicomSeriesList) => {
    const deleteDicomSeriesIfRequired = () => {
      if (type === 'dicom' && dicomSeriesList.length !== 0) {
        return Promise.all(
          dicomSeriesList.map((listItem) => {
            return mutationDeleteDicomSeriesService.mutateAsync({
              examinationId,
              id,
              unit: unit,
              dicomUuid: listItem.dicomSeriesFolderName,
              dicomSeriesId: listItem.dicomSeriesId,
            });
          }),
        );
      }
      return Promise.resolve();
    };
    deleteDicomSeriesIfRequired().then(() =>
      mutationDeleteExamination.mutate({
        examinationId,
        id,
        dicomSeriesId,
        dicomSeriesFolderName: dicomUuid,
        unit,
      }),
    );
  };

  const defaultColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => (
        <div>{((type === 'others' && record.files.length > 0) || (type === 'dicom' && record.dicomSeriesList.length > 0)) && <AiOutlineCheck className="ml-2 h-6 w-6" />}</div>
      ),
    },
    {
      title: 'Nazwa',
      dataIndex: 'examinationName',

      render: (text, record) => (
        <Cell
          examinationId={record.examinationId}
          text={text}
          record={record}
          handleShowModal={handleShowModal('editFile', record.examinationId)}
          onEditFile={onEditFile}
          onDeleteFile={onDeleteFile}
          onDeleteDicomService={onDeleteDicomService}
          onChangeName={onChangeName}
          type={type}
          form={form}
        />
      ),
    },
    {
      title: '',
      dataIndex: 'id',
      className: 'files-cell-buttons',
      render: (_, record) => (
        <div className="flex justify-end">
          <ButtonAnt type="link" onClick={handleShowModal(type === 'others' ? 'addFile' : 'addDicomSeries', record.examinationId)}>
            {type === 'others' ? 'dodaj plik' : 'dodaj serie'}
          </ButtonAnt>
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'operation',
      className: 'files-cell-buttons',
      render: (_, record, index) => (
        <div className="flex justify-end">
          <Popconfirm
            title={type === 'others' ? 'Czy chcesz usunąć badanie?' : 'Czy chcesz usunąć serie badań DICOM?'}
            okText="Tak"
            cancelText="Nie"
            onConfirm={() => {
              {
                type === 'others'
                  ? handleDeleteRow(record.examinationId)
                  : handleDeleteRow(
                      record.examinationId,
                      record.dicomSeriesList[index]?.dicomSeriesId,
                      record.dicomSeriesList[index]?.dicomSeriesFolderName,
                      record.dicomSeriesList,
                    );
              }
            }}
          >
            <ButtonAnt danger icon={<DeleteOutlined />}>
              Usuń badanie
            </ButtonAnt>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataindex: col.dataIndex,
        title: col.title,
      }),
    };
  });

  if (isLoading) {
    return <Skeleton />;
  }

  const dataSource = data.details.filter((item) => item.examinationType === (type === 'others' ? 'OTHER' : 'DICOM'));

  return (
    <div className="grid grid-cols-6">
      <div className="col-span-6 col-start-1">
        <div className="flex flex-col">
          <Table
            className="table-styles w-full"
            pagination={false}
            rowClassName={(record) => ((type === 'others' ? record.files.length > 0 : record.dicomSeriesList.length > 0) ? 'table-status' : null)}
            bordered
            dataSource={dataSource}
            columns={columns}
            rowKey={(record) => record.examinationId}
            scroll={{ x: 725 }}
          />
          <div className="mr-2 flex flex-row pt-2">
            <Button
              onClick={type === 'others' ? handleShowModal('addExamination') : handleShowModal('addDicomExamination')}
              icon={<AiOutlinePlus className="h-5 w-5" />}
              color="default"
              size="xs"
            >
              Dodaj badanie
            </Button>
            <ModalFilesUpload
              open={open}
              onClose={onClose}
              type={type}
              mode={mode}
              data={data && data.details}
              examinationId={examinationId}
              fileId={fileId}
              accept={accept}
              dicomSeries={dicomSeries}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FilesUpload;

FilesUpload.propTypes = {
  accept: PropTypes.string,
  type: PropTypes.string,
};

FilesUpload.defaultProps = {
  accept: null,
  type: null,
};
