import { ConsultantsList, ConsultationMode, ConsultationStatus, Doctor, DocumentationStatus, User } from '@/api/consultation.types';
import { useCallback } from 'react';

const isDefined = (value: unknown): value is string | number | boolean | object => value !== null && value !== undefined;

type AvailableActions =
  | 'EDIT_PATIENT'
  | 'EDIT_QUALIFICATION_CARD'
  | 'EDIT_FILES'
  | 'EDIT_CONSULTATION_RESULT'
  | 'SELECT_DOCTORS_TO_SIGN'
  | 'SIGN_CONSULTATION_RESULT'
  | 'ORDER_CONSULTATION'
  | 'DELETE_CONSULTATION'
  | 'RELEASE_TIMESLOT'
  | 'REJECT_CONSULTATION'
  | 'CANCEL_CONSULTATION'
  | 'CORRECT_CONSULTATION'
  | 'ASK_FOR_COMPLETION'
  | 'INVITE_TO_CONSULTATION'
  | 'DISPLAY_RESULT_PDF'
  | 'ADD_REFERRAL';

type ActionCondition = {
  consultationType?: 'NEW' | 'RELEASED_DEADLINE';
  consultationMode?: ConsultationMode[];
  consultationStatus?: ConsultationStatus[];
  documentationStatus?: DocumentationStatus[];
};

const actionsForRequestingDoctor: Record<AvailableActions, ActionCondition[]> = {
  EDIT_PATIENT: [
    {
      consultationStatus: ['NOT_ORDERED', 'ORDERED'],
    },
  ],
  EDIT_QUALIFICATION_CARD: [
    {
      consultationStatus: ['ORDERED'],
    },
    {
      consultationType: 'RELEASED_DEADLINE',
      consultationStatus: ['NOT_ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  EDIT_FILES: [
    {
      consultationStatus: ['ORDERED'],
    },
    {
      consultationStatus: ['NOT_ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  EDIT_CONSULTATION_RESULT: [],
  SELECT_DOCTORS_TO_SIGN: [],
  SIGN_CONSULTATION_RESULT: [
    {
      consultationStatus: ['TO_SIGN'],
      documentationStatus: ['COMPLETE'],
    },
  ],
  ORDER_CONSULTATION: [
    {
      consultationStatus: ['NOT_ORDERED', 'ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  DELETE_CONSULTATION: [
    {
      consultationStatus: ['NOT_ORDERED', 'ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  RELEASE_TIMESLOT: [
    {
      consultationMode: ['PLANNED'],
      consultationStatus: ['ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  REJECT_CONSULTATION: [],
  CANCEL_CONSULTATION: [
    {
      consultationStatus: ['ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  CORRECT_CONSULTATION: [],
  ASK_FOR_COMPLETION: [],
  INVITE_TO_CONSULTATION: [],
  DISPLAY_RESULT_PDF: [
    {
      consultationStatus: ['COMPLETED'],
    },
  ],
  ADD_REFERRAL: [
    {
      consultationStatus: ['NOT_ORDERED', 'ORDERED', 'TO_SIGN', 'COMPLETED', 'IN_CORRECTION'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE', 'IN_CORRECTION', 'TO_COMPLETE', 'FINISHED'],
    },
  ],
};

const actionsForConsultingDoctor: Record<AvailableActions, ActionCondition[]> = {
  EDIT_PATIENT: [
    {
      consultationStatus: ['IN_CORRECTION'],
    },
  ],
  EDIT_QUALIFICATION_CARD: [
    {
      consultationStatus: ['IN_CORRECTION'],
    },
  ],
  EDIT_FILES: [
    {
      consultationStatus: ['IN_CORRECTION'],
    },
  ],
  EDIT_CONSULTATION_RESULT: [
    {
      consultationStatus: ['NOT_ORDERED', 'ORDERED', 'IN_CORRECTION'],
    },
  ],
  SELECT_DOCTORS_TO_SIGN: [
    {
      consultationStatus: ['NOT_ORDERED', 'ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  SIGN_CONSULTATION_RESULT: [
    {
      consultationStatus: ['TO_SIGN'],
    },
  ],
  ORDER_CONSULTATION: [],
  DELETE_CONSULTATION: [],
  REJECT_CONSULTATION: [
    {
      consultationStatus: ['ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  RELEASE_TIMESLOT: [
    {
      consultationMode: ['PLANNED'],
      consultationStatus: ['ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  CANCEL_CONSULTATION: [],
  CORRECT_CONSULTATION: [
    {
      consultationStatus: ['TO_SIGN'],
    },
  ],
  ASK_FOR_COMPLETION: [
    {
      consultationStatus: ['ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  INVITE_TO_CONSULTATION: [
    {
      consultationStatus: ['ORDERED'],
      documentationStatus: ['IN_PREPARATION', 'COMPLETE'],
    },
  ],
  DISPLAY_RESULT_PDF: [
    {
      consultationStatus: ['COMPLETED'],
    },
  ],
  ADD_REFERRAL: [],
};

type UseConsultationActionsParams = {
  hasDeadline: boolean;
  consultationStatus?: ConsultationStatus;
  documentationStatus?: DocumentationStatus;
  consultationMode?: ConsultationMode;
  usersData: {
    userDoctorId?: User['id'];
    doctorId?: Doctor['doctorId'];
    consultantList?: ConsultantsList;
  };
};

export const useConsultationActions = ({ hasDeadline, consultationStatus, consultationMode, documentationStatus, usersData }: UseConsultationActionsParams) => {
  const { userDoctorId, doctorId, consultantList } = usersData;

  const isCurrentUserConsultingDoctor = isDefined(consultantList) && isDefined(userDoctorId) && isDefined(consultantList.find((item) => item.consultantId === userDoctorId));
  const isCurrentUserRequestingDoctor = isDefined(doctorId) && isDefined(userDoctorId) && doctorId === userDoctorId;

  const canAct = useCallback(
    (action: AvailableActions) => {
      if (
        !isDefined(consultationStatus) ||
        !isDefined(documentationStatus) ||
        !isDefined(userDoctorId) ||
        !isDefined(doctorId) ||
        !isDefined(consultantList) ||
        !isDefined(consultationMode)
      )
        return false;

      const actionConditions = [
        ...(isCurrentUserConsultingDoctor ? actionsForConsultingDoctor[action] : []),
        ...(isCurrentUserRequestingDoctor ? actionsForRequestingDoctor[action] : []),
      ];

      if (actionConditions.length === 0) {
        return false;
      }

      return actionConditions.some((condition) => {
        const { consultationType, consultationStatus: consultationStatuses, documentationStatus: documentationStatuses, consultationMode: consultationModes } = condition;

        const isConsultationStatusCorrect = isDefined(consultationStatuses) ? consultationStatuses.includes(consultationStatus) : true;

        const isConsultationModeCorrect = isDefined(consultationModes) ? consultationModes.includes(consultationMode) : true;
        const isConsultationTypeCorrect = isDefined(consultationType) ? (hasDeadline ? consultationType === 'NEW' : consultationType === 'RELEASED_DEADLINE') : true;
        const isDocumentationStatusCorrect = isDefined(documentationStatuses) ? documentationStatuses.includes(documentationStatus) : true;

        return isConsultationTypeCorrect && isConsultationStatusCorrect && isDocumentationStatusCorrect && isConsultationModeCorrect;
      });
    },
    [consultationMode, consultationStatus, documentationStatus, userDoctorId, doctorId, consultantList, isCurrentUserConsultingDoctor, isCurrentUserRequestingDoctor, hasDeadline],
  );

  const canPerformAnyAction = useCallback((actions: AvailableActions[]) => actions.some(canAct), [canAct]);

  return {
    canAct,
    canPerformAnyAction,
  };
};
