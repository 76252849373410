import { find, isArray, isNumber } from 'lodash';
import {
  FieldDefaultBehaviour,
  FieldDynamicBehavior,
  FormField,
  GetFieldValueFunction,
  Group,
  RadioField,
  DynamicBehaviorDescription,
  ConditionalBehaviorDescription,
  FormTemplate,
  FormValues,
  ValueDependentBehaviorDescription,
  FieldPurpose,
} from './types';
import { useDynamicField } from './useDynamicField';

export const createFieldDescriptorFinder =
  (formGroups: Group<FormField>[]) =>
  (name: string): FormField | undefined => {
    const allFields = formGroups.map((group) => group.fields).flat();
    const fieldDescriptor = find(allFields, (field) => field.name === name);

    return fieldDescriptor;
  };

export const createRelatedRadioGroupFieldsDescriptorFinder =
  (formGroups: Group<FormField>[]) =>
  (fieldName: string, radioGroupName: string): RadioField[] | undefined =>
    formGroups
      .map((group) => group.fields)
      .flat()
      .filter((field) => (field as RadioField).radioGroup === radioGroupName && field.name !== fieldName) as RadioField[];

type BehaviorDescription = string | DynamicBehaviorDescription | ValueDependentBehaviorDescription;

const isSimpleBehaviorDescription = (behavior: BehaviorDescription): behavior is string => behavior && typeof behavior === 'string';

const isDynamicBehaviorDescription = (behavior: BehaviorDescription): behavior is DynamicBehaviorDescription => {
  if (isSimpleBehaviorDescription(behavior)) {
    return false;
  }
  if (!behavior.hasOwnProperty('condition')) {
    return false;
  }
  if (!behavior.hasOwnProperty('fields')) {
    return false;
  }
  return isArray((behavior as DynamicBehaviorDescription).fields);
};

const isValueDependentBehaviorDescription = (behavior: BehaviorDescription): behavior is ValueDependentBehaviorDescription => {
  if (isSimpleBehaviorDescription(behavior)) {
    return false;
  }
  if (isDynamicBehaviorDescription(behavior)) {
    return false;
  }
  if (!behavior.hasOwnProperty('field')) {
    return false;
  }
  if (!behavior.hasOwnProperty('check')) {
    return false;
  }
  if (!behavior.hasOwnProperty('value')) {
    return false;
  }
  return true;
};

const isConditionalBehavior = (field: string | ConditionalBehaviorDescription): field is ConditionalBehaviorDescription =>
  field && typeof field !== 'string' && Boolean(field.condition) && Boolean(field.field);

export const hasValue = (value: unknown) => value !== null && value !== undefined && value !== false && value !== '';

const isConditionApplicable = (field: string | ConditionalBehaviorDescription, getFieldValue: GetFieldValueFunction): boolean => {
  if (isConditionalBehavior(field)) {
    const conditionalField = field;
    const fieldValue = getFieldValue(conditionalField.field) as number;

    if (!hasValue(fieldValue)) {
      return false;
    }

    switch (conditionalField.condition) {
      case 'IS_LESS_THAN':
        return fieldValue < conditionalField.value;
      case 'IS_GREATER_THAN':
        return fieldValue > conditionalField.value;
      default:
        return false;
    }
  } else {
    return hasValue(getFieldValue(field));
  }
};

const stateCheckFactory =
  (baseState: keyof FieldDefaultBehaviour, behaviorStateCompliant: keyof FieldDynamicBehavior, behaviorStateOpposite: keyof FieldDynamicBehavior) =>
  // eslint-disable-next-line consistent-return
  (formField: FormField, getFieldValue: GetFieldValueFunction): boolean => {
    // If field is disabled by default
    if (formField[baseState]) {
      // Check if we should enable it by condition from related fields
      const related = formField.behavior && (formField.behavior[behaviorStateOpposite] as string | DynamicBehaviorDescription | undefined);

      // If no related fields return disabled
      if (!related) {
        return true;
      }
      // Get the related fields and check their values
      if (isDynamicBehaviorDescription(related)) {
        // If any of related fields has value return enabled
        const { condition, fields } = related;

        if (condition === 'ALL_PRESENT') {
          return !fields.every((relatedField) => isConditionApplicable(relatedField, getFieldValue));
        }
        if (condition === 'ONE_OF_PRESENT') {
          return !fields.some((relatedField) => isConditionApplicable(relatedField, getFieldValue));
        }
      }

      if (isValueDependentBehaviorDescription(related)) {
        const { field, check, value } = related;
        if (check === 'IS_EQUAL') {
          return getFieldValue(field) !== value;
        }
        if (check === 'IS_NOT_EQUAL') {
          return getFieldValue(field) === value;
        }
      }

      // If single related field has a value return enabled
      return !getFieldValue(related as string);
    }
    // If field is enabled by default
    // Check if we should disable it by condition from related fields
    const related = formField.behavior && (formField.behavior[behaviorStateCompliant] as string | DynamicBehaviorDescription | undefined);
    // If no related fields return enabled
    if (!related) {
      return false;
    }
    // Get the related fields and check their values
    if (isDynamicBehaviorDescription(related)) {
      const { condition, fields } = related;
      // If array then check whether we should look for all fields or only one
      if (condition === 'ALL_PRESENT') {
        return fields.every((relatedField) => isConditionApplicable(relatedField, getFieldValue));
      }
      if (condition === 'ONE_OF_PRESENT') {
        return fields.some((relatedField) => isConditionApplicable(relatedField, getFieldValue));
      }
    }

    if (isValueDependentBehaviorDescription(related)) {
      const { field, check, value } = related;
      if (check === 'IS_EQUAL') {
        return getFieldValue(field) === value;
      }
      if (check === 'IS_NOT_EQUAL') {
        return getFieldValue(field) !== value;
      }
    }

    // Return disabled if single related field has values
    return hasValue(getFieldValue(related as string));
  };

export const getFieldRequiredState = stateCheckFactory('required', 'requireWhen', 'optionalWhen');
export const getFieldHiddenState = stateCheckFactory('hidden', 'hideWhen', 'showWhen');
export const getFieldDisabledState = stateCheckFactory('disabled', 'disableWhen', 'enableWhen');
export const getFieldNecessaryState = stateCheckFactory('necessary', 'necessaryWhen', 'unnecessaryWhen');
export const getFieldResultVisibilityState = stateCheckFactory('visibleInResult', 'includeInResultWhen', 'omitInResultWhen');

export const getNoDataFieldName = (fieldDescriptor: FormField) => `${fieldDescriptor.name}-noData`;

export const fieldSetAsNoData = (fieldDescriptor: FormField): boolean => 'allowNoData' in fieldDescriptor && fieldDescriptor.allowNoData;

export const doesFieldHasNoDataChecked = (fieldDescriptor: FormField, getFieldValue: GetFieldValueFunction): boolean =>
  fieldSetAsNoData(fieldDescriptor) ? Boolean(getFieldValue(getNoDataFieldName(fieldDescriptor))) : false;

export const checkIfAllNecessaryFieldsProvided = (formTemplate: FormTemplate, formValues: FormValues): boolean => {
  const allFieldsFromTemplate = formTemplate.groups.map((group) => group.fields).flat();

  return allFieldsFromTemplate.reduce((result, fieldTemplate) => {
    // If at least one necessary field wasnt provided - false all the way to the end
    if (!result) {
      return false;
    }
    const getFieldValue = (fieldName) => formValues[fieldName];

    // If field is marked as having no data - then it is never necessary
    const hasNoData = doesFieldHasNoDataChecked(fieldTemplate, getFieldValue);
    const isFieldNecessary = hasNoData ? false : getFieldNecessaryState(fieldTemplate, getFieldValue);
    const isFieldRequired = hasNoData ? false : getFieldRequiredState(fieldTemplate, getFieldValue);

    // If field is necessary or required - check if it has a value, if not then treat it as provided
    return isFieldNecessary || isFieldRequired ? hasValue(formValues[fieldTemplate.name]) : true;
  }, true);
};

export const decimalFormatter = (precision: number) => (value: number | string) => {
  const formatter = new Intl.NumberFormat('pl-PL', { minimumFractionDigits: 0, maximumFractionDigits: precision });
  if (isNumber(value)) {
    return formatter.format(value);
  }
  try {
    const parsedStringValue = parseFloat(value);
    if (Number.isNaN(parsedStringValue)) {
      return value;
    }
    return formatter.format(parsedStringValue);
  } catch {
    return value;
  }
};

export const getRelatedFieldsNames = (field: FormField) => {
  const relatedFieldsNames = [field.name];

  // No data checkbox is related field
  if (fieldSetAsNoData(field)) {
    relatedFieldsNames.push(getNoDataFieldName(field));
  }

  if (!field.behavior) {
    return relatedFieldsNames;
  }

  // Calculate other related fields basing on relations in behavior description
  return relatedFieldsNames.concat(
    Object.values(field.behavior)
      .map((value) => {
        if (isSimpleBehaviorDescription(value)) {
          return value;
        }
        if (isDynamicBehaviorDescription(value)) {
          return value.fields.map((relatedField) => {
            if (isConditionalBehavior(relatedField)) {
              return relatedField.field;
            }
            return relatedField;
          });
        }
        if (isValueDependentBehaviorDescription(value)) {
          return value.field;
        }
        return undefined;
      })
      .flat()
      .filter(Boolean),
  );
};

export const getFormCompletionPercentage = (template: FormTemplate, values: FormValues) => {
  const getFieldValue = (fieldName) => values[fieldName];
  const allFieldsFromTemplate = template.groups.map((group) => group.fields).flat();

  const necessaryFields = allFieldsFromTemplate.reduce<string[]>((result, field) => {
    // If field is marked as having no data - then it is never necessary
    const hasNoData = doesFieldHasNoDataChecked(field, getFieldValue);
    const isFieldNecessary = hasNoData ? false : getFieldNecessaryState(field, getFieldValue);
    const isFieldRequired = hasNoData ? false : getFieldRequiredState(field, getFieldValue);

    // If field is necessary OR required - add its name to the list
    return isFieldNecessary || isFieldRequired ? [...result, field.name] : result;
  }, []);

  // If no necessary fields then documentation is complete
  if (necessaryFields.length === 0) {
    return 100;
  }

  // Else, check how many of necessary fields have been filled
  const filledUpFieldsCount = necessaryFields.reduce<number>((count, fieldName) => (hasValue(getFieldValue(fieldName)) ? count + 1 : count), 0);

  return Math.floor((filledUpFieldsCount / necessaryFields.length) * 100);
};

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T;
const onlyTruthy = <T>(value: T): value is Truthy<T> => !!value;

type FieldResult = {
  heading: boolean;
  headingLevel?: number;
  label: string;
  value: string;
  suffix?: string;
  wide?: boolean;
  purpose?: FieldPurpose;
};

type GroupResult = {
  label: string;
  fields: FieldResult[];
};

const ARRAY_DELIMITER = ';';

const transformFieldValue = (value: unknown, hasNoData: boolean): string | undefined => {
  if (hasNoData) {
    return null;
  }
  if (Array.isArray(value)) {
    return value.join(ARRAY_DELIMITER);
  }
  if (value === undefined || value === null) {
    return undefined;
  }
  return value.toString();
};

export const renderResultsToJSON = (template: FormTemplate, values: FormValues): Array<FieldResult> => {
  const getFieldValue = (fieldName) => values[fieldName];

  const filteredGroups = template.groups
    .map<Group<FormField>>((group) => {
      const filteredFields = group.fields.filter((field) => hasValue(values[field.name]));
      const shouldRenderGroup = filteredFields.length > 0;

      if (!shouldRenderGroup) {
        return null;
      }

      return group;
    })
    .filter(onlyTruthy);

  const jsonResult = filteredGroups.map<GroupResult>((group) => {
    const fieldToInclude = group.fields
      .map((field) => {
        const { getFieldBehavior, getFieldDescriptor } = useDynamicField(field.name, template);
        const fieldValue = getFieldValue(field.name);
        const fieldDescriptor = getFieldDescriptor();
        const fieldHasNoData = doesFieldHasNoDataChecked(fieldDescriptor, getFieldValue);
        const fieldBehavior = getFieldBehavior(getFieldValue, fieldHasNoData);

        const getHeadingLevel = (checkedField: FormField) => {
          if (checkedField.type !== 'label') {
            return undefined;
          }
          switch (checkedField.variant) {
            case 'header':
              return 2;
            case 'subheader':
              return 1;
            case 'normal':
              return 0;
            default:
              return undefined;
          }
        };

        const fieldResult: FieldResult = {
          label: field.label,
          value: transformFieldValue(fieldValue, fieldHasNoData),
          suffix: 'suffix' in field ? field.suffix : undefined,
          heading: field.type === 'label',
          headingLevel: getHeadingLevel(field),
          wide: (field.width && field.width === 24) || field.type === 'label',
          purpose: field.purpose,
        };

        if (!fieldBehavior.isHidden && (fieldValue || fieldHasNoData || fieldBehavior.isVisibleInResult)) {
          return fieldResult;
        }

        return null;
      })
      .filter(onlyTruthy);

    return {
      label: group.label,
      fields: fieldToInclude,
    };
  });

  return jsonResult
    .map((group) => {
      if (group.label) {
        if (group.fields.every((field) => field.purpose)) {
          return group.fields;
        }
        return [
          {
            label: group.label,
            value: undefined,
            suffix: undefined,
            heading: true,
            headingLevel: 3,
            wide: true,
          },
          ...group.fields,
        ];
      }
      return group.fields;
    })
    .flat()
    .sort((a) => (a.purpose ? -1 : 0));
};
