import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon, EllipsisVerticalIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { nanoid } from 'nanoid';
import _ from 'lodash';
import { classNames } from '../../utils/classNames';
import Transition from '../Transition/Transition.component';

const DropDown = ({ placement, arrow, title, img, type, items, onClick, selectable, ellipsis, icon, defaultSelectedKey }) => {
  const [selectedKey, setSelectedKey] = useState(defaultSelectedKey);
  const placementCss = useMemo(() => {
    switch (placement) {
      case 'bottomLeft':
        return 'left-0';
      case 'bottomRight':
        return 'right-0';
      default:
        return DropDown.defaultProps.placement;
    }
  }, [placement]);

  const typeCss = useMemo(() => {
    switch (type) {
      case 'basic':
        return 'group rounded-md text-sm font-medium text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-offset-gray-100';
      default:
        return `group rounded-md border bg-white border-gray-300 ${
          ellipsis || !title ? 'px-2' : 'px-4'
        } py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none  focus:ring-offset-gray-100`;
    }
  }, [placement, ellipsis]);

  const onClickHandle = (key) => () => {
    setSelectedKey(key);
    if (onClick) {
      onClick(key);
    }
  };

  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div className="flex">
        <Menu.Button className={`group inline-flex h-10 w-full items-center justify-center space-x-2 ${typeCss}`}>
          {img}
          {title && <span className="whitespace-nowrap">{title}</span>}
          {arrow && <ChevronDownIcon className=" h-5 w-5" aria-hidden="true" />}
          {ellipsis === 'vertical' && <EllipsisVerticalIcon className="h-5 w-5 text-slate-400 group-hover:text-slate-600" />}
          {ellipsis === 'horizontal' && <EllipsisHorizontalIcon className="h-5 w-5 text-slate-400 group-hover:text-slate-600" />}
          {icon || null}
        </Menu.Button>
      </div>
      <Transition>
        <Menu.Items
          className={`absolute rounded-md ${placementCss} z-10 mt-1 w-auto min-w-48 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {items.map((item) => {
              if (_.has(item, 'type')) {
                return <div key={nanoid()} className="my-1 h-px bg-gray-100" />;
              }
              return (
                <div key={nanoid()}>
                  <Menu.Item key={item.key} onClick={onClickHandle(item.key)}>
                    {({ active }) => (
                      <div
                        type="button"
                        className={classNames(
                          'cursor-pointer',
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm',
                          selectable && selectedKey === item.key ? 'bg-gray-100' : undefined,
                        )}
                      >
                        {item.title}
                      </div>
                    )}
                  </Menu.Item>
                </div>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

DropDown.propTypes = {
  placement: PropTypes.oneOf(['bottomRight', 'bottomLeft']),
  arrow: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['basic', 'button', 'ellipsisHorizontal']),
  img: PropTypes.element,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      key: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
  selectable: PropTypes.bool,
  ellipsis: PropTypes.oneOf(['horizontal', 'vertical']),
  icon: PropTypes.element,
  defaultSelectedKey: PropTypes.string,
};

DropDown.defaultProps = {
  placement: 'bottomLeft',
  arrow: false,
  title: null,
  img: null,
  type: 'button',
  items: [],
  onClick: undefined,
  selectable: false,
  ellipsis: null,
  icon: null,
  defaultSelectedKey: null,
};

export default DropDown;
