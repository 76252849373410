import React, { FC } from 'react';
import { Form, Input } from 'antd';
import Button from '@/components/Button2/Button.component';

interface Props {
  isLoading?: boolean;
}

const SendMailForm: FC<Props> = ({ isLoading }) => (
  <>
    <Form.Item
      label="Adres e-mail"
      name="email"
      rules={[
        { required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' },
        { max: 256, message: 'Pole przyjmuje maksymalnie 256 znaków!', validateTrigger: 'onSubmit' },
        {
          type: 'email',
          message: 'Niepoprawny adres e-mail!',
          validateTrigger: 'onSubmit',
        },
      ]}
    >
      <Input disabled={isLoading} />
    </Form.Item>
    <Form.Item className="mb-0 mt-5">
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Button loading={isLoading} disabled={isLoading} submit block color="darkblue" className="w-full">
        Wyślij link resetujący hasło
      </Button>
    </Form.Item>
  </>
);

export default SendMailForm;
