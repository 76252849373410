import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// import PropTypes from 'prop-types';
import { testId } from '@/tests';

const Card = ({ title, bordered, description, children, titleNoWrap, className, extra, alert }) => {
  const headerText = useMemo(() => {
    if (title || description) {
      return (
        <div className={`flex items-center rounded-t-md px-4 py-2 ${alert ? 'bg-rose-700 text-white' : 'bg-white'}`} style={{ minHeight: 50 }}>
          {title && (
            <h3 className={`${titleNoWrap ? ' overflow-hidden text-ellipsis whitespace-nowrap' : undefined}  w-full font-medium leading-6 ${alert ? 'text-white' : ''}`}>
              {title}
            </h3>
          )}
          {description && <p className="mt-1 max-w-2xl text-sm">{description}</p>}
        </div>
      );
    }
    return null;
  }, [title, description, alert]);

  return (
    <div
      className={`${className}  rounded-md border border-gray-200 bg-gray-50  shadow-sm dark:border-gray-700 dark:bg-gray-800 ${alert ? 'bg-rose-600 text-white' : ''}`}
      {...testId('card')}
    >
      {headerText}
      {extra}
      {bordered && <div className="border-t border-gray-200" />}
      <div className="p-4">{children}</div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.string,
  className: PropTypes.string,
  bordered: PropTypes.bool,
  children: PropTypes.node,
  titleNoWrap: PropTypes.bool,
  alert: PropTypes.bool,
  extra: PropTypes.node,
};

Card.defaultProps = {
  title: null,
  bordered: false,
  description: null,
  children: null,
  titleNoWrap: false,
  className: null,
  extra: null,
  alert: false,
};

export default Card;
