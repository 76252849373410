import { isArray, isEqual } from 'lodash';
import moment from 'moment';

/**
 * This function ensures that the result will be an Array
 *
 * @param {any} value - a value to be serialized
 * @returns array - an array of some values
 */
export const ensureArray = <V = unknown>(value: V): V[] => {
  if (!value) {
    return [];
  }
  return isArray(value) ? value : [value];
};
/**
 * This function transforms the moment or Date object to string to be saved in URL as a string
 *
 * @param {any} value - a date object to be serialized
 * @returns stringDate - date in string format
 */
export const serializeDate = (value: unknown, format?: string, utc = true): string => {
  if (!value) {
    return undefined;
  }
  return (utc ? moment(value).utc() : moment(value)).format(format ?? 'YYYY-MM-DDTHH:mm:ss');
};

export const cleanParamsObject = (paramsObject: unknown) => {
  if (!paramsObject) {
    return {};
  }
  return Object.fromEntries(
    Object.entries(paramsObject).filter(([, value]) => {
      if (value === undefined) {
        return false;
      }
      if (value === null) {
        return false;
      }
      if (isEqual(value, [])) {
        return false;
      }
      return true;
    }),
  );
};
