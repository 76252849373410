import React, { useState, useRef, useLayoutEffect, useEffect, useContext, Fragment } from 'react';
import { useMutation, useQuery } from 'react-query';
import { DatePicker, Modal, Form, notification, message, Spin } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import moment from 'moment';
import { debounce, isEmpty } from 'lodash';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import plLocale from '@fullcalendar/core/locales/pl';
import { testIdFactory } from '@/tests';
import ScheduleModal, { showModal, showModalButton, showModalEdit } from './ScheduleModal.component';
import Card from '../Card/Card.component';
import Button from '../Button2/Button.component';
import AuthContext from '../../contexts/Auth.context';
import { renderEventContent, filterEvents, eventDrop, eventResize, loadEventsEdit, loadEvents, resizeCalendar } from './calendar.service';
// import { fakedEvents } from '../../fakes/scheduleEvents.fakes'
import { addConsultationTerm, getAllConsultation, editConsultationTerm, deleteConsultationTerm } from '../../services/consultations.service';
import { FORMATS } from '../../constants/dates.constants';

const testId = testIdFactory('schedule');

const NewCalendar = ({ timeRange, hourRange, consultationFreeTime, consultationTime, consultationGroup, calendarChange, onDateChange, initialDate, calendarsList }) => {
  const [currentDate, setCurrentDate] = useState(moment(initialDate).format());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableList, setDisableList] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [modal, contextHolderModal] = Modal.useModal();
  const [eventConfirm, setEventConfirm] = useState();
  const [form] = Form.useForm();
  const [mode, setMode] = useState();
  const [events, setEvents] = useState([]);
  const { user } = useContext(AuthContext);
  const calendarRef = useRef();

  const today = moment().utc();
  const startOfWeek = today.startOf('week').format(FORMATS.API_DATE_TIME);
  const endOfWeek = today.endOf('week').format(FORMATS.API_DATE_TIME);

  const disabledDate = (current) => {
    const currentDay = moment().startOf('day');
    const futureLimit = moment().add(10, 'years').startOf('day');
    if (current && (current > futureLimit || current < currentDay)) {
      return true;
    }
    return false;
  };

  const [selectedTime, setSelectedTime] = useState({
    date: moment().format(FORMATS.DATE),
    repeatDate: moment().format(FORMATS.DATE),
    time: [moment().format(FORMATS.TIME), moment().format(FORMATS.TIME)],
  });
  const [slotTimeRange, setSlotTimeRange] = useState({
    slotMinTime: moment(hourRange[0]).format(FORMATS.TIME).toString(),
    slotMaxTime: moment(hourRange[1]).format(FORMATS.TIME).toString(),
  });

  const openNotification = (info) => {
    api.info({
      message: info,
      placement: 'top',
      top: 500,
    });
  };

  const [request, setRequest] = useState({
    userId: user.id,
    dateFrom: startOfWeek,
    dateTo: endOfWeek,
  });

  const [requestGroup, setRequestGroup] = useState({
    groupId: 1,
    dateFrom: startOfWeek,
    dateTo: endOfWeek,
  });

  const doctorSlots = useQuery(['doctorSlots', request.userId, request.dateFrom, request.dateTo], getAllConsultation(request), {
    enabled: !isEmpty(calendarsList) && calendarChange.value === null,
    onSuccess: (res) => {
      const currentEvents = [];
      res.data.doctorSlots.map((event) => {
        const eventToAdd = {
          id: event.id,
          title: `event ${event.id.toString()}`,
          start: moment(event.dateFrom).local().format(FORMATS.DATE_TIME),
          end: moment(event.dateTo).local().format(FORMATS.DATE_TIME),
          maxNumber: event.maxNumber,
          freeTime: event.teleconsultations === null,
          unitId: event.unitId,
          group: false,
          open: false,
          openPopOver: false,
          teleconsultations: [],
          state: event.state,
          consultantList: event.consultantList,
          requestingDoctorId: event.requestingDoctorId,
          color: event.teleconsultations === null ? '#DAF6EC' : '#F67D8C',
          textColor: 'black',
          borderColor: event.teleconsultations === null ? '#39D8B0' : '#F67D8C',
        };
        if (event.teleconsultations !== null) {
          event.teleconsultations.map((teleconsultation) => {
            const patient = teleconsultation.patient.split(' ', 3);
            const teleconsultationToAdd = {
              ...teleconsultation,
              Lp: event.teleconsultations.indexOf(teleconsultation) + 1,
              key: (event.teleconsultations.indexOf(teleconsultation) + 1).toString(),
              patient: `${patient[0]} ${patient[1]}`,
              patientIdentity: `${patient[2]}`,
              requestingDoctor: `${teleconsultation.requestingDoctor}\n${teleconsultation.requestingUnit !== null ? teleconsultation.requestingUnit : ''}`,
              consultantDoctor: `${teleconsultation.consultantDoctor}\n${teleconsultation.consultantUnit !== null ? teleconsultation.consultantUnit : ''}`,
            };
            return eventToAdd.teleconsultations.push(teleconsultationToAdd);
          });
        }
        return currentEvents.push(eventToAdd);
      });
      setEvents([...currentEvents]);
      calendarRef.current.getApi().refetchEvents();
    },
    onError: (err) => {
      message.error(`Problem z pobraniem terminów. Kod błędu: ${err.response.status}. Opis: ${err.response.data.message}.`, 10);
    },
  });

  const groupSlots = useQuery(['groupSlots', requestGroup.userId, requestGroup.dateFrom, requestGroup.dateTo], getAllConsultation(requestGroup), {
    enabled: !isEmpty(calendarsList) && calendarChange.value !== null,
    onSuccess: (res) => {
      if (calendarChange.value !== null) {
        const currentEvents = [];
        res.data.groupSlots.map((event) => {
          const eventToAdd = {
            id: event.id,
            title: `event ${event.id.toString()}`,
            start: moment(event.dateFrom).local().format(FORMATS.DATE_TIME),
            end: moment(event.dateTo).local().format(FORMATS.DATE_TIME),
            maxNumber: event.maxNumber,
            freeTime: event.teleconsultations === null,
            group: true,
            open: false,
            openPopOver: false,
            teleconsultations: [],
            state: event.state,
            consultantList: event.consultantList,
            requestingDoctorId: event.requestingDoctorId,
            color: event.teleconsultations === null ? '#DAF6EC' : '#F67D8C',
            textColor: 'black',
            borderColor: event.teleconsultations === null ? '#39D8B0' : '#F67D8C',
          };
          if (event.teleconsultations !== null) {
            event.teleconsultations.map((teleconsultation) => {
              const patient = teleconsultation.patient.split(' ', 3);
              const teleconsultationToAdd = {
                ...teleconsultation,
                Lp: event.teleconsultations.indexOf(teleconsultation) + 1,
                key: (event.teleconsultations.indexOf(teleconsultation) + 1).toString(),
                patient: `${patient[0]} ${patient[1]}`,
                patientIdentity: `${patient[2]}`,
                requestingDoctor: `${teleconsultation.requestingDoctor}\n${teleconsultation.requestingUnit !== null ? teleconsultation.requestingUnit : ''}`,
                consultantDoctor: `${teleconsultation.consultantDoctor}\n${teleconsultation.consultantUnit !== null ? teleconsultation.consultantUnit : ''}`,
              };
              return eventToAdd.teleconsultations.push(teleconsultationToAdd);
            });
          }
          return currentEvents.push(eventToAdd);
        });
        setEvents([...currentEvents]);
        calendarRef.current.getApi().refetchEvents();
      }
    },
    onError: (err) => {
      message.error(`Problem z pobraniem terminów. Kod błędu: ${err.response.status}. Opis: ${err.response.data.message}.`, 10);
    },
  });

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    const calendarActiveStart = calendarApi.view.activeStart;
    const calendarActiveEnd = calendarApi.view.activeEnd;
    if (calendarChange.value !== undefined) {
      if (calendarChange.value === null) {
        setRequest({
          userId: user.id,
          dateFrom: moment(calendarActiveStart).utc().format(FORMATS.API_DATE_TIME),
          dateTo: moment(calendarActiveEnd).utc().format(FORMATS.API_DATE_TIME),
        });
      }
      if (calendarChange.value !== null) {
        setRequestGroup({
          groupId: calendarChange.value,
          dateFrom: moment(calendarActiveStart).utc().format(FORMATS.API_DATE_TIME),
          dateTo: moment(calendarActiveEnd).utc().format(FORMATS.API_DATE_TIME),
        });
      }
    }
  }, [events, calendarChange]);

  useEffect(() => {
    if (!isEmpty(calendarsList)) {
      loadEventsEdit(setEvents, calendarChange, doctorSlots, groupSlots);
    }
  }, [calendarChange.value]);

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    calendarApi.setOption('height', 'auto');
  }, [timeRange, hourRange[1], hourRange[0]]);

  useLayoutEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    const resize = () => resizeCalendar(window.innerWidth, calendarRef.current.getApi(), eventConfirm);
    const updateSize = debounce(() => {
      if (!eventConfirm?.event?.extendedProps?.openPopOver) loadEventsEdit(setEvents, calendarChange, doctorSlots, groupSlots);
    }, 500);
    if (!calendarApi) {
      return () => null;
    }
    resize();
    window.addEventListener('resize', resize);
    window.addEventListener('resize', updateSize);
    return () => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('resize', updateSize);
    };
  }, [calendarRef.current, window.innerWidth, eventConfirm?.event?.extendedProps?.openPopOver]);

  useLayoutEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    if (moment(hourRange[1]).format(FORMATS.TIME).toString() === '23:00') {
      setSlotTimeRange({ slotMinTime: moment(hourRange[0]).format(FORMATS.TIME).toString(), slotMaxTime: '24:00:00' });
    } else {
      setSlotTimeRange({ slotMinTime: moment(hourRange[0]).format(FORMATS.TIME).toString(), slotMaxTime: moment(hourRange[1]).format(FORMATS.TIME).toString() });
    }
    calendarApi.setOption('height', 100);
  }, [timeRange, hourRange[1], hourRange[0]]);

  useLayoutEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (!calendarApi) {
      return;
    }
    filterEvents(calendarApi, consultationFreeTime, consultationTime, consultationGroup);
  }, [calendarChange, consultationTime, consultationFreeTime, consultationGroup, events]);

  const getEndpoint = () => {
    if (mode === 'add') return addConsultationTerm;
    if (mode === 'edit') return editConsultationTerm;
    if (mode === 'editSelect') return editConsultationTerm;
    if (mode === 'delete') return deleteConsultationTerm;
    return null;
  };

  const mutation = useMutation(getEndpoint(), {
    onSuccess: async (data) => {
      setIsModalOpen(false);
      if (mode === 'add') {
        if (calendarRef.current.getApi().getEventById(0) !== null) calendarRef.current.getApi().getEventById(0).remove();
        if (form.getFieldsValue().repeat) loadEventsEdit(setEvents, calendarChange, doctorSlots, groupSlots);
        else {
          const eventToAdd = {
            id: Number(calendarChange.value !== null ? data.data.groupSlotId : data.data.doctorSlotId),
            title: `event ${Number(calendarChange.value !== null ? data.data.groupSlotId : data.data.doctorSlotId).toString()}`,
            start: moment(
              `${form.getFieldsValue().consultationDate.local().format(FORMATS.DATE).toString()}T${form
                .getFieldsValue()
                .consultationTime[0].format(FORMATS.HOUR_WITH_SECONDS)
                .toString()}`,
            )
              .local()
              .format(FORMATS.DATE_TIME),
            end: moment(
              `${form.getFieldsValue().consultationDate.local().format(FORMATS.DATE).toString()}T${form
                .getFieldsValue()
                .consultationTime[1].format(FORMATS.HOUR_WITH_SECONDS)
                .toString()}`,
            )
              .local()
              .format(FORMATS.DATE_TIME),
            maxNumber: form.getFieldsValue().maxNumber,
            freeTime: true,
            group: calendarChange.value !== null,
            open: false,
            openPopOver: false,
            teleconsultations: [],
            state: null,
            consultantList: null,
            requestingDoctorId: null,
            color: '#DAF6EC',
            textColor: 'black',
            borderColor: '#39D8B0',
          };
          if (moment(eventToAdd.end).format(FORMATS.HOUR_WITH_SECONDS).toString() === '00:00:00')
            eventToAdd.end = `${moment(eventToAdd.end).local().format(FORMATS.DATE).toString()} 24:00`;
          setEvents([...events, eventToAdd]);
          setEventConfirm(calendarRef.current.getApi().getEventById(Number(calendarChange.value !== null ? data.data.groupSlotId : data.data.doctorSlotId)));
        }
        message.success('Wolny termin został dodany poprawnie.');
      }
      if (mode === 'edit') {
        setEvents((currentEvents) => currentEvents.filter((event) => event.id.toString() !== eventConfirm.event.id.toString()));
        const eventToAdd = {
          id: Number(data.data.slotId),
          title: `event ${Number(data.data.slotId).toString()}`,
          start: moment(
            `${form.getFieldsValue().consultationDate.local().format(FORMATS.DATE).toString()}T${form
              .getFieldsValue()
              .consultationTime[0].format(FORMATS.HOUR_WITH_SECONDS)
              .toString()}`,
          )
            .local()
            .format(FORMATS.DATE_TIME),
          end: moment(
            `${form.getFieldsValue().consultationDate.local().format(FORMATS.DATE).toString()}T${form
              .getFieldsValue()
              .consultationTime[1].format(FORMATS.HOUR_WITH_SECONDS)
              .toString()}`,
          )
            .local()
            .format(FORMATS.DATE_TIME),
          maxNumber: form.getFieldsValue().maxNumber,
          freeTime: true,
          group: calendarChange.value !== null,
          open: false,
          openPopOver: false,
          teleconsultations: [],
          state: null,
          consultantList: null,
          requestingDoctorId: null,
          color: '#DAF6EC',
          textColor: 'black',
          borderColor: '#39D8B0',
        };
        setEvents((currentEvents) => [...currentEvents, eventToAdd]);
        message.success('Wolny termin został poprawnie zaktualizowany.');
      }
      if (mode === 'editSelect') {
        setEvents((currentEvents) => currentEvents.filter((event) => event.id.toString() !== eventConfirm.event.id.toString()));
        const eventToAdd = {
          id: Number(data.data.slotId),
          title: `event ${Number(data.data.slotId).toString()}`,
          start: moment(eventConfirm.event.start).local().format(FORMATS.DATE_TIME),
          end: moment(eventConfirm.event.end).local().format(FORMATS.DATE_TIME),
          maxNumber: eventConfirm.event.extendedProps.maxNumber,
          freeTime: true,
          group: calendarChange.value !== null,
          open: false,
          openPopOver: false,
          teleconsultations: [],
          state: null,
          consultantList: null,
          requestingDoctorId: null,
          color: '#DAF6EC',
          textColor: 'black',
          borderColor: '#39D8B0',
        };
        setEvents((currentEvents) => [...currentEvents, eventToAdd]);
        message.success('Wolny termin został poprawnie zaktualizowany.');
      }
      if (mode === 'delete') {
        setEvents((currentEvents) => currentEvents.filter((event) => event.id.toString() !== eventConfirm.event.id.toString()));
        calendarRef.current.getApi().getEventById(eventConfirm.event.id).remove();
        message.success('Wolny termin został poprawnie usunięty.');
      }
    },
    onError: (err) => {
      console.log(err);
      if (mode === 'add') message.error(err.response.data.message, 10);
      if (mode === 'edit' || mode === 'editSelect') message.error(`Problem z edycją terminu. Kod błędu: ${err.response.status}. Opis: ${err.response.data.message}.`, 10);
      if (mode === 'delete') message.error(`Problem z usunięciem terminu. Kod błędu: ${err.response.status}. Opis: ${err.response.data.message}.`, 10);
      if (mode === 'editSelect') eventConfirm.revert();
    },
  });

  if (doctorSlots.isError || groupSlots.isError) {
    return (
      <div className="flex flex-col gap-3">
        <span>Problem z pobraniem danych</span>
      </div>
    );
  }
  return (
    <>
      {contextHolder}
      <div className="w-full">
        <div className="float-right pt-4">
          <Button
            onClick={() => showModalButton(setMode, setCheckedList, setDisableList, setSelectedTime, selectedTime, form, checkedList, disableList, setIsModalOpen)}
            icon={<AiOutlinePlus className="h-5 w-4" />}
            color="darkblue"
            size="sm"
          >
            Dodaj wolny termin
          </Button>
        </div>
      </div>
      <div className="float-left">
        <Spin className="mt-36" spinning={doctorSlots.isLoading || groupSlots.isLoading || mutation.isLoading} size="large">
          <Card className="mt-5 mb-5" title="Terminy konsultacji">
            <div className="relative flex w-full justify-center">
              <DatePicker
                defaultValue={moment(currentDate, 'YYYY-MM')}
                value={moment(currentDate, 'YYYY-MM')}
                allowClear={false}
                picker="month"
                onChange={(date) => {
                  setCurrentDate(date);
                  calendarRef.current.getApi().gotoDate(moment(date).format('YYYY-MM'));
                  loadEvents(setEvents);
                }}
                className="absolute"
                disabledDate={disabledDate}
                inputReadOnly
              />
            </div>
            <FullCalendar
              timeZone="local"
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGrid"
              headerToolbar={{
                left: 'prev',
                right: 'today next',
              }}
              eventSources={[events]}
              // eventSources={[events, fakeEvents]}
              select={(start) =>
                showModal(
                  start,
                  setMode,
                  setDisableList,
                  setCheckedList,
                  setSelectedTime,
                  selectedTime,
                  form,
                  checkedList,
                  disableList,
                  calendarRef,
                  openNotification,
                  setEventConfirm,
                  setIsModalOpen,
                )
              }
              ref={calendarRef}
              customButtons={{
                today: {
                  text: 'dziś',
                  click: () => {
                    loadEvents(setEvents);
                    const calendarApi = calendarRef.current.getApi();
                    calendarApi.today();
                    setCurrentDate(moment(calendarApi.getDate()).format(FORMATS.DATE));
                  },
                },
                next: {
                  click: () => {
                    loadEvents(setEvents);
                    const calendarApi = calendarRef.current.getApi();
                    calendarApi.next();
                    setCurrentDate(moment(calendarApi.getDate()).format(FORMATS.DATE));
                  },
                },
                prev: {
                  click: () => {
                    loadEvents(setEvents);
                    const calendarApi = calendarRef.current.getApi();
                    calendarApi.prev();
                    setCurrentDate(moment(calendarApi.getDate()).format(FORMATS.DATE));
                  },
                },
              }}
              datesSet={onDateChange}
              selectOverlap={false}
              eventDrop={(info) => eventDrop(info, setMode, calendarRef, setEventConfirm)}
              eventResize={(info) => eventResize(info, setMode, openNotification, setEventConfirm, calendarRef)}
              eventOverlap={false}
              slotDuration="00:30"
              slotLabelInterval={timeRange}
              initialDate={moment(initialDate).format()}
              selectable="true"
              selectMirror="true"
              eventResizableFromStart="true"
              editable="true"
              locale="pl"
              locales={plLocale}
              eventContent={(eventInfo) =>
                renderEventContent(
                  eventInfo,
                  consultationTime,
                  consultationFreeTime,
                  eventConfirm,
                  setEventConfirm,
                  setMode,
                  showModalEdit,
                  calendarChange,
                  mutation,
                  calendarRef,
                  modal,
                  user,
                  setCheckedList,
                  setDisableList,
                  setSelectedTime,
                  selectedTime,
                  form,
                  setIsModalOpen,
                  setEvents,
                  doctorSlots,
                  groupSlots,
                )
              }
              allDaySlot={false}
              droppable="true"
              expandRows
              dayPopoverFormat={{ month: 'long', day: 'numeric', year: 'numeric' }}
              slotMinTime={slotTimeRange.slotMinTime}
              slotMaxTime={slotTimeRange.slotMaxTime}
              titleFormat={{
                month: 'long',
                year: 'numeric',
                meridiem: false,
              }}
              slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: false,
                hour12: false,
              }}
              navLinkDayClick={false}
              eventConstraint={{ start: moment().format(FORMATS.DATE_TIME), end: moment().add(10, 'years').format(FORMATS.DATE_TIME) }}
              selectConstraint={{ start: moment().format(FORMATS.DATE_TIME), end: moment().add(10, 'years').endOf('day').add(14, 'minutes').format(FORMATS.DATE_TIME) }}
              {...testId('scheduleConsultations')}
            />
          </Card>
        </Spin>
        <ScheduleModal
          selectedTime={selectedTime}
          mode={mode}
          form={form}
          calendarRef={calendarRef}
          eventConfirm={eventConfirm}
          calendarChange={calendarChange}
          mutation={mutation}
          user={user}
          checkedList={checkedList}
          setCheckedList={setCheckedList}
          disableList={disableList}
          setDisableList={setDisableList}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
        {contextHolderModal}
      </div>
    </>
  );
};

NewCalendar.propTypes = {};

NewCalendar.defaultProps = {};

export default NewCalendar;
