import React, { useContext } from 'react';

import { Form, Avatar, Image, Input } from 'antd';
import AuthContext from '../../contexts/Auth.context';
import doctorSvg from '../../assets/images/doctor.svg';

const ApprovedBySignIn = ({ data }) => {
  const { unit, user } = useContext(AuthContext);

  return (
    <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
      <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
        <div>
          <h1 className="text-lg font-semibold">Podpisywanie konsultacji:</h1>
          <p className="text-gray-500">Wprowadzanie hasła do certyfikatu ZUS</p>
        </div>
      </div>
      <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
        <div className="flex flex-col items-center justify-center pt-2">
          <Avatar className="h-20 w-20 border-4 border-sky-300" src={<Image className="h-20 w-20 rounded-full object-cover" src={doctorSvg} preview={false} />} />
        </div>
        <div className="flex flex-col items-center justify-center pt-2">
          <h1 className="w-max font-bold">
            dr {user.name} {user.lastname}
          </h1>
          <p className="w-max text-gray-500">{data.consultant.consultantList.find((item) => item.consultantId === user.doctorId).consultantSpecialty}</p>
          <p className="w-max text-gray-500">{unit.name}</p>
        </div>
        {data.consultant.consultantApprovedList !== null && data.consultant.consultantApprovedList.find((item) => item.consultantId === user.doctorId).consultantApproved ? (
          <div className="flex items-center justify-center pt-2">
            <p className="w-max font-bold text-gray-500">Konsultacja została podpisana wcześniej.</p>
          </div>
        ) : (
          <div className="flex items-start justify-center gap-2 pt-4">
            <p className="mt-1 text-gray-500">Wprowadź hasło certyfikatu ZUS:</p>
            <Form.Item
              name="certificatePassword"
              className="w-40"
              rules={[
                {
                  required: true,
                  message: 'Proszę podać hasło przypisane do certyfikatu.',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Input.Password autoFocus />
            </Form.Item>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovedBySignIn;
