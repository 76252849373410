/* eslint-disable */
// import { message } from 'antd';
import { useState } from 'react';
import { useQuery } from 'react-query';
// import { useQuery } from 'react-query';
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';
// import { ZoomMtg } from '@zoomus/websdk';
import { getSignature } from '../services/zoom.service';
// import { zoomSignature } from '../services/zoom.service';
// import { generateZoomSignature } from '../utils/generateZoomSignature';

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();

export const useZoom = () => {
  const [isJoined, setIsJoined] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [meetingNumber, setMeetingNumber] = useState(null);
  const [userName, setUserName] = useState(null);
  const [zoomStatus, setZoomStatus] = useState();
  const client = ZoomMtgEmbedded.createClient();
  const meetingElement = 'meetingSDKElement2';
  const sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;
  const role = process.env.REACT_APP_ZOOM_ROLE;

  const endMeeting = () => {
    client.endMeeting();
  };

  const { refetch: joinMeeting, isFetching: isLoadingSignature } = useQuery(['zoom-signature', meetingNumber], getSignature(meetingNumber), {
    onSuccess: (response) => {
      const { accessToken } = response.data;
      startMeeting(accessToken);
    },
    onError: () => {
      alert('Problem z wygenerowaniem podpisu Zoom');
    },
    enabled: false,
  });

  const onFullScreen = () => setIsFullScreen((fullscreen) => !fullscreen);

  const startMeeting = (signature) => {
    setIsJoined(true);
    setIsConnecting(true);
    const meetingSDKElement = document.getElementById(meetingElement);

    client
      .init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: 'pl-PL',
        viewLayout: 'ribbon',
        // minimumParticipantsForGalleryView: 1,
        customize: {
          video: {
            viewLayout: 'ribbon',
            isResizable: true,
            popper: {
              disableDraggable: false,
            },
            viewSizes: {
              default: {
                width: 500,
                height: 500,
              },
              ribbon: {
                width: 500,
                height: 500,
              },
            },
          },
          //   meetingInfo: ['topic', 'host', 'mn', 'pwd', 'telPwd', 'invite', 'participant', 'dc', 'enctype'],
          //   toolbar: {
          //     buttons: [
          //       {
          //         text: 'Custom Button',
          //         className: 'CustomButton',
          //         onClick: () => {
          //           console.log('custom button');
          //         },
          //       },
          //     ],
          //   },
        },
      })
      .then(() => {
        // alert('Success');
      });

    client.on('connection-change', ({ state }) => {
      console.log('Event zoom: ', state);

      setZoomStatus(state);

      if (state === 'Connected') {
        setIsConnecting(false);
      }

      if (state === 'Closed') {
        setIsJoined(false);
        setIsConnecting(false);
        window.location.reload();
      }

      if (state === 'Fail') {
        setIsJoined(false);
        setIsConnecting(false);
        // window.location.reload();
      }
    });

    // console.log('----------------------------');
    // console.log('sdkKey: ', sdkKey);
    // console.log('signature: ', signature);
    // console.log('role: ', role);
    // console.log('meetingNumber: ', meetingNumber);
    // console.log('----------------------------');

    client
      .join({
        sdkKey,
        signature,
        meetingNumber,
        // password: passWord,
        role,
        userName,
        // userEmail,
        // tk: registrantToken,
      })
      .then(() => {
        setIsJoined(true);
      })
      .catch(() => {
        setIsJoined(false);
      });
  };

  return {
    joinMeeting,
    isJoined,
    isFullScreen,
    onFullScreen,
    isLoadingSignature,
    endMeeting,
    zoomStatus,
    isConnecting,
    setMeetingNumber,
    setUserName,
  };
};
