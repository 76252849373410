/* eslint-disable */
import http from '../utils/http';
import withAccessToken from '../utils/withAccessToken';

// Pobieranie listy konsultacji
export const getAll = (requestBody) => () => http.post('/teleconsultation/search-by-criteria', requestBody, withAccessToken()).then((response) => response);

// Pobieranie danych do widgetów - lista konsultacji
export const getWidgetData = () => () => http.get('/teleconsultation/widget', withAccessToken()).then((response) => response);

// Pobieranie konsultacji - widok detali konsultacji
export const getConsultationsById = (id) => () => http.get(`/documentation/by-uuid?uuid=${id}`, withAccessToken()).then((response) => response);

// Pobieranie statusu i historii konsultacji - widok detali konsultacji
export const getStatusById = (id) => () => http.get(`/documentation/status/history/by-uuid?uuid=${id}`, withAccessToken()).then((response) => response);

// Dodawanie konsultacji w trybie roboczym
export const addConsultation = (requestBody) => http.post('/documentation', requestBody, withAccessToken()).then((response) => response);

// Edycja konsultacji - pacjent
export const editPatient = (requestBody) => http.put(`/documentation/${requestBody.uuid}/patient`, requestBody, withAccessToken()).then((response) => response);

// Edycja konsultacji - karta kwalifikacji
export const editQualificationCard = (requestBody) => http.put(`/documentation/${requestBody.uuid}/card`, requestBody, withAccessToken()).then((response) => response);

//Edycja konsultacji - wynik konsultacji
export const editConsultationResult = (requestBody) =>
  http.put(`/documentation/${requestBody.uuid}/result-consultation`, requestBody, withAccessToken()).then((response) => response);

// Usunięcie konsultacji
export const deleteConsultation = (idConsultation) => () => http.delete(`/documentation/${idConsultation}`, withAccessToken());

// Anulowanie konsultacji
export const cancelConsultation = (idConsultation) => () => http.get(`/teleconsultation/${idConsultation}/cancel`, withAccessToken());

// Odrzucanie konsultacji
export const rejectConsultation = (idConsultation, data) => () => http.post(`/teleconsultation/${idConsultation}/reject`, { idConsultation, ...data }, withAccessToken());

// Anulowanie konsultacji
export const revokeConsultation = (idConsultation) => () => http.get(`/teleconsultation/${idConsultation}/revoke`, withAccessToken());

// Zwolnienie terminu konsultacji
export const scheduleVisitConsultation = (idConsultation) => () => http.get(`/teleconsultation/${idConsultation}/schedule`, withAccessToken());

// Prośba o uzupełnienie dokumentacji w konsultacji

export const supplementConsultation = (requestBody) => () => http.post(`/documentation/documentation-completion`, requestBody, withAccessToken());
// Wdzwonienie uczestników  konsultacji
export const requestStartCall = (idConsultation) => (requestBody) =>
  http.post(`/documentation/${idConsultation}/notify`, requestBody, withAccessToken()).then((response) => response);

// Harmonogram
// Pobieranie listy wolnych terminów
export const getAllConsultation = (requestBody) => () => http.post('/doctor/search-slots-by-criteria', requestBody, withAccessToken()).then((response) => response);

// Dodawanie wolnego terminu
export const addConsultationTerm = (requestBody) => http.post('/teleconsultation/slot', requestBody, withAccessToken()).then((response) => response);

// Edycja wolnego terminu
export const editConsultationTerm = (requestBody) => http.put(`/doctor/slot/${requestBody.id}`, requestBody, withAccessToken()).then((response) => response);

// Usuwanie wolnego terminu
export const deleteConsultationTerm = (requestBody) =>
  http.delete(`/doctor/slot/${requestBody.slotId}/group/${requestBody.isGroupSlot}`, withAccessToken(), requestBody).then((response) => response);

// Dodawanie wolnego terminu // not used anymore, consider to remove
export const cancelConsultationTerm = (requestBody) =>
  http.post(`/doctor/slot/${requestBody.slotId}/group/${requestBody.isGroupSlot}`, requestBody, withAccessToken()).then((response) => response);

// Edycja konsultanta konsultanta - zatwierdzone przez
export const addConsultantApprovedBy = (requestBody) => http.put('/doctor/doctorApproved', requestBody, withAccessToken()).then((response) => response);

// Pobieranie listy wszystkich jednostek
export const getListOfUnits = () => () => http.get(`/teleconsultation/units`, withAccessToken()).then((response) => response);

// Pobieranie listy lekarz/grupa po wybraniu danej jednostki
export const getListOfDoctors = (requestBody) => () =>
  http.post(`/teleconsultation/${requestBody.consultantUnitId}/consultants`, requestBody, withAccessToken()).then((response) => response);

// Pobieranie wolnych terminów według zadanego przedziału czasowego oraz wybranego lekarza/grupy
export const getListSlotsOfDoctor = (requestBody) => () =>
  http.post('teleconsultation/search-available-slots-by-criteria', requestBody, withAccessToken()).then((response) => response);

// Aktualizacja (zapis wybranego terminu, trybu konsultacji, jednostki i lekarza konsultanta).
export const consultantUpdate = (requestBody) => http.put('/teleconsultation/consultant-update', requestBody, withAccessToken()).then((response) => response);

// Korekta telekonsultacji
export const correctionConsultation = (idConsultation, startCorrection) => () => http.get(`/teleconsultation/${idConsultation}/correction?startCorrection=${startCorrection}`, withAccessToken());

// PODPISY
// Dodanie podpisu - zatwierdzone przez
export const addSign = (requestBody) =>
  http.put(`/doctor/documentation/${requestBody.uuid}/doctorApproved/${requestBody.doctorId}/code/${requestBody.pin}`, requestBody, withAccessToken()).then((response) => response);

// Dodanie podpisu - certyfikat ZUS
export const addSignUsingCertificate = (requestBody) =>
  http
    .put(`/doctor/documentation/${requestBody.uuid}/doctorApproved/${requestBody.doctorId}/certificate/${requestBody.password}`, requestBody, withAccessToken())
    .then((response) => response);

// Podpisanie konsultacji bez logowania
export const getAnonymizedDataForSignatureWithoutLogging = (id, token) => () =>
  http.get(`/documentation/anonymized?documentationUuid=${id}&token=${token}`).then((response) => response);

// Anonimowa konsultacja podpisywana CERTYFIKATEM bez logowania
export const signAnonymizedCertificate = ({ token, password }) =>
  http.put(`/doctor/documentation/doctorApproved/anonymized/certificate?token=${token}&password=${password}`, withAccessToken()).then((response) => response);

// Dopraszanie lekarza do konsultacji
export const inviteToConsultation = (requestBody) => http.post(`/doctor/invite`, requestBody, withAccessToken()).then((response) => response);

// Dodanie skierowania
export const addReferral = (requestBody) => http.post(`/documentation/${requestBody.uuid}/referral`, requestBody, withAccessToken()).then((response) => response);

// Pobieranie listy skierowań - widok detali konsultacji
export const getReferralsById = (id) => () => http.get(`/documentation/${id}/referral`, withAccessToken()).then((response) => response);

// Aktualizacja skierowania
export const updateReferral = (requestBody) =>
  http.put(`/documentation/${requestBody.uuid}/referral/${requestBody.id}`, requestBody, withAccessToken()).then((response) => response);

// Usunięcie skierowania
export const deleteReferral = (uuid, id) => () => http.delete(`/documentation/${uuid}/referral/${id}`, withAccessToken()).then((response) => response);

const ConsultationsService = {
  getAll,
  getAllConsultation,
  getConsultationsById,
  addConsultation,
  addConsultationTerm,
  editPatient,
  editQualificationCard,
  editConsultationResult,
  editConsultationTerm,
  deleteConsultationTerm,
  cancelConsultationTerm, // not used anymore, consider to remove
  addConsultantApprovedBy,
  getListOfDoctors,
  consultantUpdate,
  getListOfUnits,
  addSign,
  correctionConsultation,
  getAnonymizedDataForSignatureWithoutLogging,
  signAnonymizedCertificate,
  inviteToConsultation,
  supplementConsultation,
  addReferral,
  getReferralsById,
  updateReferral,
  deleteReferral,
  requestStartCall,
};

export default ConsultationsService;
