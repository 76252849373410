import { ConsultationMode, ConsultationStatus, DocumentationStatus } from '@/api/consultation.types';

type StatusDescription = {
  className: string;
  mode?: ConsultationMode[];
  status?: ConsultationStatus[];
  documentation?: DocumentationStatus[];
};

const rowClassNamesRules: StatusDescription[] = [
  {
    className: 'red',
    mode: ['URGENT'],
  },
  { className: 'green', mode: ['PLANNED', 'OFFLINE'], status: ['ORDERED'], documentation: ['COMPLETE'] },
  { className: 'yellow', mode: ['PLANNED', 'OFFLINE'], status: ['IN_CORRECTION'], documentation: ['IN_CORRECTION'] },
  { className: 'yellow', mode: ['PLANNED', 'OFFLINE'], status: ['ORDERED'], documentation: ['TO_COMPLETE'] },
  { className: 'yellow', mode: ['PLANNED', 'OFFLINE'], status: ['TO_SIGN'] },
  { className: 'gray', mode: ['PLANNED', 'OFFLINE'], status: ['COMPLETED'] },
];

type RuleFulfillment = {
  mode: boolean;
  status: boolean;
  documentation: boolean;
};

export const getClassNameFromConsultationState = (mode: ConsultationMode, status: ConsultationStatus, documentation: DocumentationStatus): string | null =>
  rowClassNamesRules.reduce<string | null>((result, currentRule) => {
    if (result !== null) {
      return result;
    }

    const ruleFulfilled: RuleFulfillment = {
      mode: false,
      status: false,
      documentation: false,
    };

    if (currentRule.mode) {
      ruleFulfilled.mode = currentRule.mode.includes(mode);
    } else {
      ruleFulfilled.mode = true;
    }
    if (currentRule.status) {
      ruleFulfilled.status = currentRule.status.includes(status);
    } else {
      ruleFulfilled.status = true;
    }
    if (currentRule.documentation) {
      ruleFulfilled.documentation = currentRule.documentation.includes(documentation);
    } else {
      ruleFulfilled.documentation = true;
    }

    if (ruleFulfilled.mode && ruleFulfilled.status && ruleFulfilled.documentation) {
      return currentRule.className;
    }
    return null;
  }, null);
