import React from 'react';
import moment from 'moment';

import { DisplayFieldComponent } from '../types';
import { DatepickerField } from '../../types';
import { FieldValue } from '../components';
import { DisplayFieldLabel } from '../../components';

export const DatepickerDisplayField: DisplayFieldComponent<DatepickerField> = ({ name, value, label, isHidden, isNecessary, tooltip, testId, fieldHasNoData }) => {
  if (isHidden) {
    return null;
  }

  return (
    <div className="dynamic-field-result flex flex-col rounded bg-white p-2">
      <DisplayFieldLabel label={label} tooltip={tooltip} />
      <div className="flex break-all p-2 align-middle text-gray-600">
        <FieldValue name={name} testId={testId} value={value} isNecessary={isNecessary} fieldHasNoData={fieldHasNoData}>
          {moment(value).format('YYYY-MM-DD')}
        </FieldValue>
      </div>
    </div>
  );
};
