import React from 'react';
import { Col, Popover } from 'antd';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { testId } from '@/tests';
import Headline from '../Headline/Headline.component';
import Card from '../Card/Card.component';

const ConsultantDetails = ({ data, status, onEdit, showEditButton }) => {
  const contentTooltipForGroup = data.consultant.consultantList && (
    <div>
      {data.consultant.consultantList.map((person) => (
        <p key={nanoid()}>
          {person.consultantName} {person.consultantSurname}
        </p>
      ))}
    </div>
  );

  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Card title={<Headline titleContent="Konsultant" disabled={!showEditButton} status={status} onEditTitle={onEdit} />}>
        <div className="flex h-32 gap-4 bg-gray-50 sm:grid sm:px-4">
          <div className="flex-row justify-between">
            <div className="flex h-32 flex-col overflow-y-auto">
              {_.has(data, 'consultant.consultantList') && data.consultant.consultantList.length ? (
                data.consultant.consultantList.map((consultant) => (
                  <div className="flex content-center items-center" key={nanoid()}>
                    {data.consultant.groupName !== null ? null : (
                      <div className="pb-4">
                        <div {...testId('consultant-info')}>
                          {consultant.consultantName} {consultant.consultantSurname} - {consultant.consultantSpecialty}
                        </div>
                        <div className="w-fit text-gray-500 " {...testId('consultant-unit')}>
                          {consultant.consultantUnitName}
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <div className="flex">
                  <div className="rounded-md bg-red-600 py-1 px-2 text-xs text-white">Nie wybrano konsultanta</div>
                </div>
              )}
              <div>
                {_.has(data, 'consultant.groupName') && data.consultant.groupName && (
                  <div>
                    <Popover content={contentTooltipForGroup} title={data.consultant.groupName}>
                      <span className="cursor-pointer rounded-md bg-darkblue py-1 px-2 text-xs text-white" {...testId('consultant-groupname')}>
                        {data.consultant.groupName}
                      </span>
                    </Popover>
                    <p className=" pt-1 text-gray-500" {...testId('consultant-unit')}>
                      {data.consultant.consultantList && data?.consultant?.consultantList[0]?.consultantUnitName}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default ConsultantDetails;
