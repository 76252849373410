/* eslint-disable no-template-curly-in-string */
export const DEFAULT_VALIDATION_MESSAGES = {
  required: 'Pole wymagane',
  string: {
    len: 'Pole musi zawierać dokładnie ${min} znaki!',
    min: 'Pole musi zawierać minimum ${min} znaki!',
    max: 'Pole przyjmuje maksymalnie ${max} znaków!',
  },
  number: {
    min: 'Pole musi zawierać minimum ${min} znaki!',
    max: 'Pole przyjmuje maksymalnie ${max} znaków!',
  },
  pattern: {
    mismatch: 'Wprowadzona wartość nie pasuje do wzorca!',
  },
  custom: {
    minNumericValue: 'Wprowadzona wartość jest nieprawidłowa!',
    maxNumericValue: 'Wprowadzona wartość jest nieprawidłowa!',
    numericRange: 'Wprowadzona wartość jest nieprawidłowa!',
  },
};
