import { isArray } from 'lodash';
import moment from 'moment';
import { serializeDate } from '../service';

/**
 * CALENDAR
 */

// Filters
export const initialCalendarFilters = {
  time: '01:00',
  hour: [moment('10:00', 'HH:mm'), moment('20:00', 'HH:mm')],
  consultationFreeTime: true,
  consultationTime: true,
  consultationGroup: true,
  // selectedCalendar: { value: null, label: 'Kalendarz osobisty' },
  selectedCalendar: null,
};

export const serializeCalendarFilters = (calendarFilters) => {
  const { time, hour, selectedCalendar } = calendarFilters;

  return {
    time,
    hourStart: isArray(hour) ? hour[0].hour() : null,
    hourEnd: isArray(hour) ? hour[1].hour() : null,
    selectedCalendarValue: selectedCalendar?.value === null ? 'null' : selectedCalendar?.value ?? undefined,
    selectedCalendarLabel: selectedCalendar?.label ?? undefined,
  };
};

export const deserializeCalendarFilters = (queryParams) => {
  const { time, hourStart, hourEnd, selectedCalendarValue, selectedCalendarLabel } = queryParams;

  return {
    time: time || initialCalendarFilters.time,
    hour: [hourStart ? moment().hours(hourStart).minutes(0) : initialCalendarFilters.hour[0], hourEnd ? moment().hours(hourEnd).minutes(0) : initialCalendarFilters.hour[1]],
    selectedCalendar:
      selectedCalendarLabel && selectedCalendarValue
        ? {
            label: selectedCalendarLabel,
            value: selectedCalendarValue === 'null' ? null : Number.parseInt(selectedCalendarValue, 10),
          }
        : {},
  };
};

// State
export const initialCalendarState = {
  date: moment().format('YYYY-MM-DD'),
};

export const serializeCalendarState = (calendarState) => {
  const { date } = calendarState;

  return {
    date: serializeDate(date, 'YYYY-MM-DD', false),
  };
};

export const deserializeCalendarState = (queryParams) => {
  const { date } = queryParams;

  return {
    date: date ?? initialCalendarState.date,
  };
};
