import moment from 'moment/moment';
import _ from 'lodash';

export const createCalendarSlots = (dates, dateStart, dateEnd) => {
  // Grupowanie dat
  const groupDays = _.groupBy(_.uniqWith(dates, _.isEqual), (date) => moment(date.dateFrom).startOf('day').format());
  // Mapowanie dat
  const mapDays = _.map(groupDays, (group, day) => ({
    date: moment(day).format('YYYY-MM-DD'),
    slots: _.uniqBy(group, 'id'),
  }));

  const result = [];

  // Pętla po dniach w danym miesiącu
  let index = 0;
  for (let day = moment(dateStart); day.isBefore(dateEnd); day.add(1, 'days')) {
    const currentDate = day.format('YYYY-MM-DD');
    // Wyszukiwanie slotów
    const itemDay = mapDays.find((item) => item.date === currentDate);
    result.push({
      elementName: `date-element-${index}`,
      date: currentDate,
      dayOfWeek: day.format('dd'),
      slots: _.sortBy(itemDay?.slots || [], [(item) => item.dateFrom]),
    });
    index += 1;
  }

  return result;
};
