import React, { useState } from 'react';
import { Form, Input, Select } from 'antd';
import { PhoneInputDetailed } from '@/components/FormItems';
import { plural } from '@/utils/plural';

const validateMessages = {
  required: 'Pole wymagane!',
  min: (num) => `Pole musi zawierać conajmniej ${num} ${plural('znak', 'znaki', 'znaków', num)}!`,
  max: (num) => `Pole musi zawierać maksymalnie ${num} ${plural('znak', 'znaki', 'znaków', num)}!`,
};

const Referral = ({ form }) => {
  const [referralCodeType, setReferralCodeType] = useState(form.getFieldValue('referralCodeType') || 'PIN');

  const onChangeCodeType = (value) => {
    setReferralCodeType(value);
    form.validateFields();
    form.resetFields(['referralCode']);
  };

  const numberOnly = (e) => {
    console.log(e);
    const rgx = /^[0-9]*$/;
    if (!e.target.value.match(rgx)) {
      return e.target.value.slice(0, -e.target.value.length);
    }
    return e.target.value;
  };

  return (
    <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
      <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
        <div>
          <h1 className="text-lg font-semibold">Skierowanie</h1>
          <p className="text-gray-500">Dane</p>
        </div>
      </div>
      <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <Form.Item label="Skierowanie" required>
            <Input.Group compact>
              <Form.Item name="referralCodeType" noStyle rules={[{ required: true, message: 'Pole wymagane!' }]} initialValue={referralCodeType}>
                <Select
                  options={[
                    { value: 'PIN', label: 'PIN' },
                    { value: 'REFERRAL_NUMBER', label: 'Nr skierowania' },
                  ]}
                  // style={{ width: 150 }}
                  dropdownMatchSelectWidth={false}
                  onChange={onChangeCodeType}
                />
              </Form.Item>
              <Form.Item
                name="referralCode"
                getValueFromEvent={referralCodeType === 'PIN' && numberOnly}
                noStyle
                rules={[
                  { required: true, message: 'Pole wymagane!' },
                  { max: referralCodeType === 'PIN' ? 4 : 60, message: validateMessages.max(referralCodeType === 'PIN' ? 4 : 60) },
                ]}
              >
                <Input
                  style={{ width: '50%' }}
                  placeholder=""
                  maxLength={referralCodeType === 'PIN' ? 4 : 60}
                  onChange={referralCodeType === 'PIN' && numberOnly}
                  onPaste={referralCodeType === 'PIN' && numberOnly}
                />
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            name="patientPhone"
            label="Numer telefonu do pacjenta"
            required
            rules={[
              {
                validator(_, value) {
                  const { number, maxNumberOfCharacter } = value;

                  if (!number || number === '') return Promise.reject(validateMessages.required);
                  if (number.length > maxNumberOfCharacter) return Promise.reject(validateMessages.max(maxNumberOfCharacter));

                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInputDetailed />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default Referral;
