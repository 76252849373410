import { Col, Form, Row } from 'antd';
import React, { FC, useEffect } from 'react';
import moment from 'moment';
import { useEffectOnce } from 'react-use';

import { FormFieldGroup } from './FormField';
import { GlobalFormValidation } from './validation';

import { FormTemplate } from './types';
import { useTemplateEngineContext } from './TemplateEngineContext';

import { createFieldDescriptorFinder, getFieldDisabledState, getFieldHiddenState } from './service';

interface FormRendererProps {
  template: FormTemplate;
  copyContext?: Record<string, unknown>;
}

export const FormRenderer: FC<FormRendererProps> = ({ template, copyContext }) => {
  const { getFieldValue } = Form.useFormInstance();
  const { registerBeforeSubmitMapper, deleteBeforeSubmitMapper, onSuccessfulSubmission, setTemplate, setCopyContext } = useTemplateEngineContext();

  useEffect(() => {
    setTemplate(template);
    setCopyContext(copyContext);
  }, [template, copyContext]);

  const findFieldDescriptor = createFieldDescriptorFinder(template ? template.groups : []);

  useEffectOnce(() => {
    registerBeforeSubmitMapper(template.id, (formValues) =>
      Object.entries(formValues).reduce((result, [name, value]) => {
        if (name === 'globalValidation') {
          return result;
        }
        const field = findFieldDescriptor(name);
        // If field is not managed by template
        if (!field) {
          return { ...result, [name]: value };
        }
        const isDisabled = getFieldDisabledState(field, getFieldValue);
        const isHidden = getFieldHiddenState(field, getFieldValue);

        // If field is disabled or hidden do not include it while submitting form
        if (isDisabled || isHidden) {
          return result;
        }

        // If field is datepicker format it before submitting
        if (field.type === 'datepicker') {
          return { ...result, [name]: value ? moment(value).format('YYYY-MM-DD') : null };
        }

        return { ...result, [name]: value };
      }, {}),
    );
    return () => {
      onSuccessfulSubmission();
      deleteBeforeSubmitMapper(template.id);
    };
  });

  if (!template) {
    console.error('Template is not defined');
    return null;
  }

  return (
    <div className="dynamic-form">
      <GlobalFormValidation rules={template.globalValidationRules} />
      <Row gutter={[16, 16]}>
        {template.name && <Col span={24}>{template.name && <h1 className="font-semibold uppercase">{template.name}</h1>}</Col>}
        {template.groups.map((group, index) => (
          <FormFieldGroup key={index} variant={group.variant} label={group.label} fields={group.fields} template={template} />
        ))}
      </Row>
    </div>
  );
};
