import React from 'react';

import { DisplayFieldComponent } from '../types';
import { MemoField } from '../../types';
import { FieldValue } from '../components';
import { DisplayFieldLabel } from '../../components';

export const TextAreaDisplayField: DisplayFieldComponent<MemoField> = ({ name, testId, value, label, isHidden, isNecessary, tooltip, fieldHasNoData }) => {
  if (isHidden) {
    return null;
  }

  return (
    <div className="dynamic-field-result flex flex-col rounded bg-white p-2">
      <DisplayFieldLabel label={label} tooltip={tooltip} />
      <div className="flex break-all p-2 align-middle text-gray-600">
        <FieldValue name={name} testId={testId} value={value} isNecessary={isNecessary} fieldHasNoData={fieldHasNoData}>
          {value}
        </FieldValue>
      </div>
    </div>
  );
};
