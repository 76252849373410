import React, { useState, useContext } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment';
import { find } from 'lodash';
import { usePageParams, deserializeCalendarFilters, serializeCalendarFilters, initialCalendarFilters, serializeCalendarState, deserializeCalendarState } from '@/core/PageParams';
import { message } from 'antd';
import ScheduleControls from '../components/Schedule/ScheduleControls.component.js';
import AuthContext from '../contexts/Auth.context';
import Schedule from '../components/Schedule/Schedule.component.js';
import { getAllConsultation } from '../services/consultations.service';
import { FORMATS } from '../constants/dates.constants';

const defaultCalendar = { groupName: 'Kalendarz osobisty', groupId: null };

const SchedulePage = () => {
  const { user } = useContext(AuthContext);
  const { queryParams, setQueryParams } = usePageParams('CALENDAR', {
    calendarFilters: [serializeCalendarFilters, deserializeCalendarFilters],
    calendarState: [serializeCalendarState, deserializeCalendarState],
  });

  const { calendarFilters, calendarState } = queryParams;

  const [consultationFreeTime, setConsultationFreeTime] = useState(initialCalendarFilters.consultationFreeTime);
  const [consultationTime, setConsultationTime] = useState(initialCalendarFilters.consultationTime);
  const [consultationGroup, setConsultationGroup] = useState(initialCalendarFilters.consultationGroup);

  const handleCalendarFiltersChange = (newFilters) => {
    setQueryParams({ ...calendarState, ...calendarFilters, ...newFilters });
  };
  const handleLocalFiltersChange = (localFilters) => {
    setConsultationFreeTime(localFilters.consultationFreeTime);
    setConsultationTime(localFilters.consultationTime);
    setConsultationGroup(localFilters.consultationGroup);
  };

  const handleCalendarDateChange = (newDateRange) => {
    if (moment(newDateRange.start).isSame(moment(calendarState?.date), 'day')) {
      return;
    }
    setQueryParams({ ...calendarFilters, ...calendarState, date: newDateRange.start });
  };

  const initialCalendar = calendarFilters.selectedCalendar ?? defaultCalendar;

  const startDate = moment().utc().format(FORMATS.API_DATE_TIME);
  const endDate = moment().add(1, 'minutes').utc().format(FORMATS.API_DATE_TIME);

  const { data: availableCalendars } = useQuery(
    ['availableCalendars'],
    getAllConsultation({
      userId: user.id,
      dateFrom: startDate,
      dateTo: endDate,
      calendars: true,
    }),
    {
      initialData: [],
      onSuccess: (list) => {
        const calendarToSelect = find(list, (calendar) => calendar.label === initialCalendar.label && calendar.value === initialCalendar.value) ?? list[0];
        handleCalendarFiltersChange({ selectedCalendar: calendarToSelect });
      },
      select: (response) => response.data?.calendarList.map((item, index) => ({ value: item.groupId, label: item.groupName, key: index })),
      onError: (err) => {
        message.error(`Problem z pobraniem listy kalendarzy. Kod błędu: ${err.response.status}. Opis: ${err.response.data.message}.`, 10);
      },
    },
  );

  return (
    <>
      <ScheduleControls
        calendarsList={availableCalendars}
        calendarFilters={calendarFilters}
        onCalendarFiltersChange={handleCalendarFiltersChange}
        localFilters={{ consultationFreeTime, consultationTime, consultationGroup }}
        onLocalFiltersChange={handleLocalFiltersChange}
      />
      <Schedule
        initialDate={calendarState.date}
        timeRange={calendarFilters.time}
        hourRange={calendarFilters.hour}
        consultationFreeTime={consultationFreeTime}
        consultationTime={consultationTime}
        consultationGroup={consultationGroup}
        calendarsList={availableCalendars}
        calendarChange={calendarFilters.selectedCalendar}
        onDateChange={handleCalendarDateChange}
      />
    </>
  );
};

SchedulePage.propTypes = {};

export default SchedulePage;
