/* eslint-disable */
import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import ls from 'local-storage';
import { Alert, Form, Input, Select, Divider, Button as ButtonAnt } from 'antd';
import { useQuery } from 'react-query';
import Button from '@/components/Button2/Button.component';
import logoXlSvg from '@/assets/images/logo-xl-white.svg';
import { login, authenticateInUnit, getAuthRequest } from '@/services/auth.service';
import AuthContext from '@/contexts/Auth.context';
import { useCookie } from 'react-use';
import logoWezelKrajowy from '@/assets/images/logo-wezel-krajowy.svg';
import { redirectToAuthService } from '@/utils/redirectToAuthService';

const { Option } = Select;

const LoginPage = () => {
  const { setUnit, setUnits, units, setUser } = useContext(AuthContext);
  const [, updateUserIdCookie] = useCookie('userId');
  const [, updateUnitIdCookie] = useCookie('unitId');
  const [, updateTokenCookie] = useCookie('token');

  const [isCapsLockOn, setIsCapsLockOn] = useState(false);
  const [bodyRequest, setBodyRequest] = useState();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirect');

  const checkCapsLock = (event) => {
    if (event.getModifierState('CapsLock')) {
      setIsCapsLockOn(true);
    } else {
      setIsCapsLockOn(false);
    }
  };

  const {
    refetch: refetchAuthService,
    isError: isErrorLoadingAuthService,
    error: errorLoadingAuthService,
    isFetching: isFetchingAuthService,
  } = useQuery(['getAuthService'], getAuthRequest(), {
    enabled: false,
    retry: false,
    onSuccess: (res) => {
      const authRequest = res.data;
      // Wywołuję funkcję przekierowującą do węzła krajowego
      redirectToAuthService(authRequest);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const { isLoading, refetch, isError, error, data } = useQuery(['login', bodyRequest], login(bodyRequest), {
    enabled: false,
    retry: false,
    onSuccess: (res) => {
      setUnits(res.data.units);
      setUser(res.data.user);
      ls('userId', res.data.user.id);
      ls('token', res.data.token);
      ls('commonOptions', { version: '8.0.0' });
      ls('openedTabsList', [{ viewerTabId: 'viewer-tab-1683280168105' }]);
      console.log('ustawiam cookie');
      updateUserIdCookie(res.data.user.id);
      updateTokenCookie(res.data.token);
      // const token = res.headers.authorization;
      // ls('token', token);
      // Sprawdzam w ilu jednostkach jest lekarz
      if (res.data.units.length === 1) {
        ls('unitId', res.data.units[0].id);
        updateUnitIdCookie(res.data.units[0].id);
        setUnit(res.data.units[0]);
        if (res.data.user.forcePasswordChange) {
          navigate('/reset?changepassword=true');
        } else {
          if (redirectTo) {
            navigate(redirectTo);
          } else {
            navigate('/consultations');
          }
        }
      } else {
        setOpen(true);
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const { isLoading: isAuthenticateUnitLoading, refetch: authenticateInSelectedUnit } = useQuery(['authenticateInUnit'], authenticateInUnit, {
    enabled: false,
    retry: true,
    onSuccess: async (result) => {
      const response = await result;
      const unitToken = response.data.token;

      ls('token', unitToken);
      updateTokenCookie(unitToken);

      if (redirectTo) {
        navigate(redirectTo);
      } else {
        navigate('/consultations');
      }
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const onFinishUnit = (values) => {
    const unit = units.find((item) => item.id === values.unitId);
    ls('unitId', values.unitId);
    updateUnitIdCookie(values.unitId);
    setUnit(unit);
    authenticateInSelectedUnit();
  };

  const onFinish = (values) => {
    setBodyRequest(values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (bodyRequest) {
      refetch();
    }
  }, [bodyRequest]);

  const description = () => {
    if (error && error.code === 'ERR_NETWORK') return error.message;
    if (error.response.data.message === 'Liczba dozwolonych prób logowania została przekroczona. Konto zostało zablokowane.')
      return (<div><p>{error.response.data.message}</p><p>Możesz je zresetować <Link to="/reset"><ButtonAnt type="link" className="p-0">tutaj</ButtonAnt></Link>.</p></div>);
    return 'Nieprawidłowy użytkownik lub hasło.';
  };

  return (
    <div className=" h-screen overflow-auto bg-gray-800 p-10">
      <div className="flex flex-col items-center justify-center text-white">
        <img className="w-80 pb-10" src={logoXlSvg} alt="Logo E-konsylium" />
        <div className="my-form-wrapper w-[400px] rounded-md bg-white p-10">
          <h1 className="pb-10 text-center text-3xl font-medium text-gray-800">Logowanie</h1>
          {isError && <Alert message="Błąd" description={description()} type="error" showIcon className="mx-auto mb-5 max-w-[290px]" />}
          {open && (
            <Form
              layout="horizontal"
              name="loginUnit"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinishUnit}
              autoComplete="off"
              size="large"
            >
              <Form.Item
                name="unitId"
                label="Wybór jednostki"
                rules={[
                  {
                    required: true,
                    message: 'Pole obowiązkowe!',
                  },
                ]}
              >
                {/*<Select style={{ width: '100%' }} options={transformUnits} placeholder="wybierz" />*/}
                <Select style={{ width: '100%' }} placeholder="wybierz" className="ant-select-unit-styles">
                  {units.map((unit) => (
                    <Option key={unit.id} value={unit.id} className="hover:w-full" className="ant-select-unit-styles">
                      {unit.name}{' '}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 14, span: 10 }} className=" mb-0">
                <Button loading={isAuthenticateUnitLoading} disabled={isAuthenticateUnitLoading} submit block color="darkblue">
                  Wybierz
                </Button>
              </Form.Item>
            </Form>
          )}
          {!open && (
            <Form
              layout="horizontal"
              name="loginUser"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              size="large"
            >
              <Form.Item label="Użytkownik" name="username" rules={[{ required: true, message: 'Pole obowiązkowe!' }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Hasło" name="password" rules={[{ required: true, message: 'Pole obowiązkowe!' }]} className={`${!isCapsLockOn ? 'mb-2' : 'mb-0'}`}>
                <Input.Password onKeyUp={checkCapsLock} />
              </Form.Item>
              {isCapsLockOn && <p className="font-normal text-red-500">Caps Lock jest włączony!</p>}
              <Link to="/reset" className="-mt-10">
                Nie pamiętasz hasła?
              </Link>

              <Form.Item wrapperCol={{ offset: 14, span: 10 }} className="mb-0">
                <Button loading={isLoading} disabled={isLoading} submit block color="darkblue">
                  Zaloguj
                </Button>
              </Form.Item>
            </Form>
          )}

          {/* <Link to="/consultations"> */}
          {/*  <img src={loginGov} alt="login.gov.pl" /> */}
          {/* </Link> */}
          {/* <Link className="p-2 text-emerald-300" to="/consultations">Kliknij i przejdź do logowania pz.gov.pl</Link> */}
          <div className="mt-10 border-t">
            <button className="mt-5 w-full rounded-md  text-gray-800" onClick={() => refetchAuthService()}>
              <img src={logoWezelKrajowy} alt="Węzeł krajowy" className="mx-auto" />
              Węzeł Krajowy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
