/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { Button as ButtonAnt, DatePicker } from 'antd';
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from 'react-icons/ai';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { createCalendarSlots } from '../../../utils/createCalendarSlots';

const ConsultantCalendar = ({ dates, onChangeDate, onChange, date, slot, activeDate }) => {
  const [slotId, setSlotId] = useState(slot);
  const [currentDate, setCurrentDate] = useState(date);
  const dateStart = moment(currentDate).startOf('month');
  const dateEnd = moment(currentDate).endOf('month');
  const activeDay = moment().format('D');
  const activeMonth = moment().format('M-Y');
  const elementRef = useRef(null);

  const slots = createCalendarSlots(dates, dateStart, dateEnd);

  const isAnySlots = Boolean(slots.filter((item) => item.slots.length > 0).length);

  const activeSlot = slotId && slots.find((item) => item.slots.find((slotItem) => slotItem.id === slotId));

  useEffect(() => {
    setCurrentDate(date);
  }, [date]);

  useEffect(() => {
    const activeMonthFromDate = moment(currentDate).format('M-Y');
    const dateFromActiveSlot = dates.find((item) => item.id === slotId)?.dateFrom;
    const activeMonthFromSlot = dateFromActiveSlot ? moment(dateFromActiveSlot).format('M-Y') : null;
    const element = elementRef.current;

    if (slotId && activeSlot) {
      return scrollToDate(false, activeSlot.elementName);
    }

    if (dates) {
      if (slotId && activeMonthFromDate === activeMonthFromSlot) {
        scrollToSlot();
      } else {
        if (activeMonth === activeMonthFromDate) {
          scrollToDate(false, `date-element-${activeDay - 1}`);
        } else {
          if (element) scrollToDate(false, `date-element-0`);
        }
      }
    }
  }, [currentDate, dates, slotId, slots]);

  const onChangeDatePicker = (date) => {
    if (typeof onChangeDate === 'function') onChangeDate(date);
    setCurrentDate(date);
  };

  const handleClick = (id) => {
    setSlotId(id);

    if (typeof onChange === 'function') onChange(id);
  };

  const handleHorizontalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      // if (element.scrollLeft === 0) {
      //   setArrowDisable(true);
      // } else {
      //   setArrowDisable(false);
      // }
    }, speed);
  };

  const scrollToSlot = (animate) => {
    // Szukam identyfikatora elementu dat w divie
    const slotFounded = slotId ? slots.find((item) => item.slots.find((slotItem) => slotItem.id === slotId)) : {};

    if (!slotFounded) return null;

    // const width = elementRef.current.scrollWidth;
    const dateElement = document.getElementById(slotFounded.elementName);

    const element = document.getElementById('content-container');
    element.scrollTo({
      left: dateElement.offsetLeft - 20,
      behavior: animate ? 'smooth' : 'auto',
    });
  };

  const scrollToDate = (animate, element) => {
    const dateElement = document.getElementById(element);
    const container = document.getElementById('content-container');
    if (dateElement && container)
      container.scrollTo({
        left: dateElement.offsetLeft - 20,
        behavior: animate ? 'smooth' : 'auto',
      });
  };

  const disabledDate = (current) => {
    const today = moment().startOf('day');
    const futureLimit = moment().add(10, 'years').startOf('day');
    if (current && (current > futureLimit || current < today)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className=" rounded-md border border-gray-300 drop-shadow-sm">
        <div className="flex justify-between gap-4 rounded-t-md bg-gray-50 px-4 py-4 drop-shadow-sm">
          <Arrow
            icon={<AiOutlineDoubleLeft className="text-xs" />}
            onClick={() => {
              handleHorizontalScroll(elementRef.current, 25, elementRef.current.offsetWidth, -30);
            }}
          />
          <DatePicker
            onChange={onChangeDatePicker}
            defaultValue={currentDate}
            value={currentDate}
            allowClear={false}
            picker="month"
            className="w-28"
            disabledDate={disabledDate}
            inputReadOnly
          />
          <Arrow
            icon={<AiOutlineDoubleRight className="text-xs" />}
            onClick={() => {
              handleHorizontalScroll(elementRef.current, 25, elementRef.current.offsetWidth, 30);
            }}
          />
        </div>

        {!isAnySlots ? (
          <div className="m-10 mx-auto w-48 whitespace-nowrap rounded-md border border-gray-300 bg-gray-100 p-5">Brak wolnych terminów</div>
        ) : (
          <div id="content-container" className="flex gap-2 overflow-x-auto px-2 py-4" ref={elementRef}>
            {slots?.map((day, index) => (
              <Column id={day.elementName} key={nanoid()} day={day} onClick={handleClick} value={slotId} slots={day.slots} />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

const Column = ({ onClick, day, slots, value, id }) => {
  // console.log('Value', value);
  const { dayOfWeek, date } = day;
  const dateTxt = moment(date).format('DD.MM');
  const selectedCss = 'bg-blue-500 text-white hover:text-white focus:text-white focus:bg-blue-500 active:bg-blue-500 hover:bg-blue-500 border-blue-600';

  const handleClick = (slotId) => () => {
    if (typeof onClick === 'function') onClick(slotId);
  };

  return (
    <div id={id} className="mx-2 w-[50px]">
      <div className="card">
        <div className="flex flex-col items-center">
          <h1 className="flex font-semibold">{dayOfWeek}</h1>
          <p>{dateTxt}</p>
          <div className="flex flex-col items-center">
            {slots?.map((slot) => {
              return (
                <ButtonAnt
                  onClick={handleClick(slot.id)}
                  key={nanoid()}
                  className={`group mt-2 h-max w-max overflow-hidden  p-2 text-xs hover:shadow-lg ${slot.id === value ? selectedCss : 'bg-white'}`}
                >
                  <span>{moment(slot.dateFrom).local().format('HH:mm')}</span>

                  <span className="hidden group-hover:inline-block">- {moment(slot.dateTo).local().format('HH:mm')}</span>
                </ButtonAnt>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

const Arrow = ({ onClick, disabled, icon, className }) => {
  return (
    <div className="bg-red">
      <button
        onClick={onClick}
        type="button"
        className={`rounded-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none ${className}`}
      >
        {icon}
      </button>
    </div>
  );
};

export default ConsultantCalendar;
