import React from 'react';

import { Form, Input } from 'antd';

import { testIdFactory } from '@/tests';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { TextField } from '../../types';
import { getValidationRules } from '../service';

export const TextFormField: FormFieldComponent<TextField> = (props) => {
  const { name, label, description, suffix, isDisabled, isHidden, isRequired, isNecessary, tooltip, testId } = props;
  if (isHidden) {
    return null;
  }

  const validations = getValidationRules(props);

  const testAttribute = testIdFactory(testId);

  return (
    <Form.Item
      rules={validations}
      label={label ? <FormFieldLabel label={label} description={description} required={isRequired || isNecessary} fieldTooltip={tooltip} /> : null}
      className="mb-0 py-1"
      name={name}
      required={isRequired || isNecessary}
    >
      <Input className="w-full drop-shadow-sm" disabled={isDisabled} suffix={suffix} {...testAttribute(name)} />
    </Form.Item>
  );
};
