// Sprawdzam czy wybrane id to grupa lub doktor
export const getConsultant = (consultantId, groupForSingleDoctor) => {
  if (consultantId) {
    // wyciągam Id
    const id = parseInt(consultantId.substr(3, consultantId.length), 10);

    if (consultantId.includes('dr')) {
      return {
        doctorId: id,
        groupId: groupForSingleDoctor,
      };
    }

    if (consultantId.includes('gr')) {
      return {
        groupId: id,
      };
    }
  }

  return null;
};
