import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { testId } from '@/tests';
import logo from '../../assets/images/logo-xl-white.svg';

const LoadingPage = ({ loading, description }) => (
  <div className="flex h-screen items-center justify-center bg-gray-800 text-white">
    <div className="flex flex-col gap-1 text-center" {...testId('loading-page')}>
      <Spin size="large" spinning={loading} {...testId('loading-spinner')} />
      <img src={logo} alt="" />
      <span>{description}</span>
    </div>
  </div>
);

LoadingPage.propTypes = {
  loading: PropTypes.bool,
  description: PropTypes.string,
};

LoadingPage.defaultProps = {
  loading: false,
  description: 'Wczytywanie...',
};

export default LoadingPage;
