import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { correctionConsultation } from '../../services/consultations.service';
import CorrectionButton from './CorrectionButton.component';

const ConsultationCorrection = ({ idConsultation, status, onEdit, user }) => {
  const { teleconsultation, documentation } = status;
  const queryClient = useQueryClient();

  const refresh = async () => {
    queryClient.refetchQueries(['status/history']);
    await queryClient.refetchQueries(['consultation-id', idConsultation]);
  };

  return (
    <div>
      {user.provideConsultation && (teleconsultation?.state === 'TO_SIGN' || (teleconsultation?.state === 'IN_CORRECTION' && documentation?.state !== 'IN_CORRECTION')) && (
        <CorrectionButton
          titleBtn="Koryguj konsultację"
          titleMessage="Czy chcesz wprowadzić korektę?"
          contentMessage="Zatwierdzenie korekty wycofa już złożone podpisy oraz wymusi ponowne złożenie podpisów przez wszystkich poświadczających wynik konsultacji. Czy chcesz kontynuować?"
          okText="Wprowadź korektę"
          cancelText="Rezygnuję"
          errorMessage="Problem z wprowadzaniem korekty."
          successMessage="Korektę wprowadzono pomyślnie."
          documentation={documentation}
          request={correctionConsultation(idConsultation, false)}
          onEdit={() => onEdit()}
          refresh={refresh}
        />
      )}
      {user.provideConsultation && (teleconsultation?.state === 'IN_CORRECTION' || documentation?.state === 'IN_CORRECTION') && documentation?.state !== 'FINISHED' && (
        <CorrectionButton
          titleBtn="Zatwierdź korektę"
          titleMessage="Czy chcesz zatwerdzić korektę?"
          contentMessage="System zmieni stan dokumentacji na „Zamknięta” oraz stan konsultacji na „Do podpisu”."
          okText="Tak"
          cancelText="Rezygnuję"
          errorMessage="Problem z wprowadzaniem korekty."
          successMessage="Korektę wprowadzono pomyślnie."
          documentation={documentation}
          request={correctionConsultation(idConsultation, true)}
          onEdit={() => onEdit()}
          refresh={refresh}
        />
      )}
    </div>
  );
};

ConsultationCorrection.propTypes = {
  idConsultation: PropTypes.string,
  status: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  onEdit: PropTypes.func,
  user: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

ConsultationCorrection.defaultProps = {
  idConsultation: null,
  status: [],
  onEdit: null,
  user: [],
};

export default ConsultationCorrection;
