import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { ActiveStatus } from '@/components/ActiveStatus';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';

const timeToIdle = process.env.REACT_APP_TIME_TO_LOGOUT / 1000;
const timeToLogout = process.env.REACT_APP_SESSION_TIME_NOT_ACTIVE;

const Logout = ({ setTimerContextValue }) => {
  const [state, setState] = useState('Active');
  const [remaining, setRemaining] = useState(timeToLogout);
  const navigate = useNavigate();

  const onIdle = () => {
    setTimeout(() => {
      setState('Idle');
      localStorage.clear();
      sessionStorage.clear();
      navigate('/auth');
    }, 500);
    Modal.destroyAll();
    console.log('Wyloguj');
  };

  const onActive = () => {
    setState('Active');
  };

  const { getRemainingTime, pause, start } = useIdleTimer({
    onIdle,
    onActive,
    timeout: timeToLogout,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime]);

  useEffect(() => {
    setTimerContextValue({
      state,
      remaining,
      turnOnTimer: () => {
        start();
      },
      turnOffTimer: () => {
        pause();
      },
    });
  }, []);

  if (state === 'Active')
    return (
      <div className="fixed top-1 right-5 z-[2000] flex items-center gap-2">
        <ActiveStatus classNames="" status={remaining >= timeToIdle} />
        {remaining < timeToIdle && <span className="text-gray-200">sesja wygaśnie za {remaining} sek. </span>}
      </div>
    );
  return null;
};

export default Logout;
