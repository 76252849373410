import React from 'react';

import { SpaceField as SpaceFieldType } from '../../types';
import { CommonFieldComponent } from '../types';

export const SpaceField: CommonFieldComponent<SpaceFieldType> = ({ isHidden }) => {
  if (isHidden) {
    return null;
  }

  return <div />;
};
