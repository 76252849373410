import React, { FC } from 'react';

import { Form } from 'antd';

import classnames from 'classnames';
import { FormTemplate } from '../types';
import { useDynamicField } from '../useDynamicField';

import {
  TextFormField,
  CheckboxFormField,
  DictionaryFormField,
  DateFormField,
  NumberFormField,
  TextAreaFormField,
  LabelFormField,
  RadioFormField,
  SelectFormField,
  CalculatedFormField,
} from './Fields';
import { getNoDataCheckboxConfiguration } from './service';
import { doesFieldHasNoDataChecked, hasValue } from '../service';
import { SeparatorField, SpaceField } from '../CommonField/Fields';

interface FormFieldProps {
  className?: string;
  formTemplate: FormTemplate;
  name: string;
}

export const FormField: FC<FormFieldProps> = ({ name, className, formTemplate }) => {
  const { getFieldValue } = Form.useFormInstance();

  const { getFieldDescriptor, getFieldBehavior, isRelatedRadioSelected } = useDynamicField(name, formTemplate);

  const fieldDescriptor = getFieldDescriptor();
  const { type } = fieldDescriptor;

  const testIdPrefix = formTemplate.name ? `form-${formTemplate.name}` : 'form';

  const allowNoData = 'allowNoData' in fieldDescriptor ? fieldDescriptor.allowNoData : false;
  const fieldHasNoData = doesFieldHasNoDataChecked(fieldDescriptor, getFieldValue);

  const fieldBehavior = getFieldBehavior(getFieldValue, fieldHasNoData);

  const renderFieldComponent = () => {
    switch (type) {
      case 'text':
        return <TextFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      case 'checkbox':
        return <CheckboxFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      case 'datepicker':
        return <DateFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      case 'inputNumber':
        return <NumberFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      case 'memo':
        return <TextAreaFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      case 'label':
        return <LabelFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      case 'radio':
        return <RadioFormField {...fieldDescriptor} {...fieldBehavior} value={null} isRelatedRadioSelected={isRelatedRadioSelected(getFieldValue)} testId={testIdPrefix} />;
      case 'select':
        return <SelectFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      case 'separator':
        return <SeparatorField {...fieldDescriptor} {...fieldBehavior} value={null} />;
      case 'space':
        return <SpaceField {...fieldDescriptor} {...fieldBehavior} value={null} />;
      case 'calculated':
        return <CalculatedFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      case 'dictionary':
        return <DictionaryFormField {...fieldDescriptor} {...fieldBehavior} value={null} testId={testIdPrefix} />;
      default:
        return null;
    }
  };

  return (
    <div className={classnames(className, 'flex-column')} id={fieldDescriptor.name}>
      {renderFieldComponent()}
      {allowNoData && !hasValue(getFieldValue(fieldDescriptor.name)) && (
        <CheckboxFormField {...getNoDataCheckboxConfiguration(fieldDescriptor)} isHidden={fieldBehavior.isHidden} />
      )}
    </div>
  );
};
