import axios from 'axios';

export const defaultHeaders = {
  'Content-type': 'application/json',
  'Cache-Control': 'no-cache',
  // 'Access-Control-Allow-Origin': '*',
  // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  Pragma: 'no-cache',
  // 'Expires': '0',
};

const httpFile = axios.create({
  baseURL: process.env.REACT_APP_API_URL_FILES,
  headers: {
    ...defaultHeaders,
  },
});

httpFile.interceptors.response.use(
  (response) => response,
  (error) => {
    if (typeof error.response === 'undefined') {
      // alert
    }
    return Promise.reject(error);
  },
);

export default httpFile;
