/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { Button as ButtonAntd, Checkbox, Col, DatePicker, Form, Row, Spin, Table, Tooltip, Progress } from 'antd';
import { FiVideo } from 'react-icons/fi';
import { AiOutlineSearch } from 'react-icons/ai';
import moment from 'moment';
import { isEqual, isEmpty, omit } from 'lodash';
import { useSearchColumnTableHook } from '../hooks/useSearchColumnTable.hook';
import Card from '../components/Card/Card.component';
import ConsultationCountWidget from '../components/ConsultationCountWidget/ConsultationCountWidget.component';
import Button from '../components/Button2/Button.component';
import NextConsultation from '../components/NextConsultation/NextConsultation.component';
import { getAll, getWidgetData } from '../services/consultations.service';
import { objectToNull } from '../utils/objectToNull';
import peopleSvg from '../assets/images/peoples.svg';
import { usePageParams, deserializeFiltersInfo, deserializePagination, deserializeSorter, serializeFiltersInfo, serializePagination, serializeSorter } from '@/core/PageParams';
import { requestBody } from '../utils/requestBody';
import { getClassNameFromConsultationState } from '../utils/getConsultationRowClass';
import { FORMATS } from '../constants/dates.constants';
import { FaUser } from 'react-icons/fa';

const { RangePicker } = DatePicker;

const checkboxOptions = [
  { label: 'planowa', value: 'PLANNED' },
  { label: 'nagła', value: 'URGENT' },
  { label: 'offline', value: 'OFFLINE' },
];

const shouldDisableRangePicker = (form) => {
  const consultationModeFieldValues = form.getFieldValue('modeConsultation') ?? [];
  if (isEmpty(consultationModeFieldValues)) {
    return false;
  }
  return consultationModeFieldValues.every((value) => ['URGENT', 'OFFLINE'].includes(value));
};

const initialOptions = {
  withHours: true,
};
const transformRangeDate = (date, fromName, toName, options = initialOptions) => {
  if (!date) {
    return {
      [fromName]: undefined,
      [toName]: undefined,
    };
  }
  return {
    [fromName]: date[0].local().format('YYYY-MM-DDTHH:mm'),
    [toName]: date[1].local().format('YYYY-MM-DDTHH:mm'),
  };
};

const Consultations = () => {
  const [form] = Form.useForm();
  const [total, setTotal] = useState();

  const { queryParams, setQueryParams, clearQueryParams } = usePageParams('CONSULTATIONS', {
    paginationInfo: [serializePagination, deserializePagination],
    filtersInfo: [serializeFiltersInfo, deserializeFiltersInfo],
    sorterInfo: [serializeSorter, deserializeSorter],
  });
  const [isRangePickerDisabled, setRangePickerDisabled] = useState(shouldDisableRangePicker(form));

  useEffect(() => {
    if (isRangePickerDisabled) {
      form.setFieldValue('consultationDate', null);
    }
  }, [isRangePickerDisabled]);

  const { paginationInfo, filtersInfo, sorterInfo } = queryParams;

  // Synchronize AntD form with searchParams
  useEffect(() => {
    form.setFieldsValue({
      ...filtersInfo,
    });
    if (filtersInfo.registrationDateFrom && filtersInfo.registrationDateTo) {
      form.setFieldValue('registrationDate', [filtersInfo.registrationDateFrom, filtersInfo.registrationDateTo]);
    }
    if (filtersInfo.consultationDateFrom && filtersInfo.consultationDateTo) {
      form.setFieldValue('consultationDate', [filtersInfo.consultationDateFrom, filtersInfo.consultationDateTo]);
    }
    handleConsultationModeChange();
  }, []);

  const navigate = useNavigate();

  const { getColumnSearchProps } = useSearchColumnTableHook();
  // Endpoint - lista konsultacji
  const { isLoading, isError, data, error, isFetching } = useQuery(
    ['consultations', paginationInfo, filtersInfo, sorterInfo],
    getAll(requestBody(isRangePickerDisabled ? omit(filtersInfo, ['consultationDateFrom', 'consultationDateTo']) : filtersInfo, paginationInfo, sorterInfo)),
    {
      onSuccess: (res) => {
        console.log(filtersInfo);
        const { pagination } = res.data;
        setTotal(pagination.total);
      },
      onError: (err) => {
        console.log(err.response?.status);
        console.log(err);
      },
    },
  );

  // Endpoint - widgety
  const {
    isLoading: isLoadingWidget,
    isError: isErrorWidget,
    data: dataWidget,
    error: errorWidget,
    isFetching: isFetchingWidget,
  } = useQuery(['widgets'], getWidgetData(), {
    // onSuccess: (res) => {
    //   const { pagination } = res.data;
    //   setTotal(pagination.total);
    // },
    onError: (err) => {
      console.log(err.response?.status);
      console.log(err);
    },
  });

  // console.log(dataWidget);

  const onChangeTable = (newPagination, newFilters, newSorter) => {
    const filters = {
      ...filtersInfo,
      ...newFilters,
    };
    setQueryParams({
      ...newPagination,
      ...filters,
      ...newSorter,
    });
  };

  const onFinish = (values) => {
    // If date has been set by user and no consultation mode was selected - set it to PLANNED
    const isDateSet = form.getFieldValue('consultationDate') !== null && isEmpty(form.getFieldValue('modeConsultation') ?? []);
    if (isDateSet) {
      form.setFieldValue('modeConsultation', ['PLANNED']);
    }
    const newValues = {
      ...filtersInfo,
      statusConsultation: ['NOT_ORDERED', 'ORDERED', 'TO_SIGN', 'IN_CORRECTION'],
      ...values,
      modeConsultation: isDateSet ? 'PLANNED' : values.modeConsultation,
      ...transformRangeDate(values.registrationDate, 'registrationDateFrom', 'registrationDateTo'),
      ...transformRangeDate(values.consultationDate, 'consultationDateFrom', 'consultationDateTo'),
    };
    const newPagination = { ...paginationInfo, current: 1 };
    setQueryParams({ ...newPagination, ...newValues });
  };

  const displayClearFiltersButton = Object.values(filtersInfo)
    .filter(Boolean)
    .some((filterValue) => !isEqual(filterValue, []));

  const onClearFilters = () => {
    setRangePickerDisabled(false);
    form.setFieldValue('modeConsultation', null);
    form.setFieldValue('registrationDate', null);
    form.setFieldValue('consultationDate', null);
    clearQueryParams();
  };

  const onClickWidget = (type, statuses, sorter, date) => () => {
    // Zmiana paginacji
    const newPagination = { ...paginationInfo, current: 1 };
    // Zmiana wartości filtra na wszystkie poza zamkniętymi + Zmiana wartości dla trybu konsultacji
    const newFilters = {
      ...objectToNull(filtersInfo),
      consultationDateFrom: date ? moment(date).format(FORMATS.API_DATE_TIME) : undefined,
      consultationDateTo: date ? moment(date).format(FORMATS.API_DATE_TIME) : undefined,
      statusConsultation: statuses ? statuses : ['NOT_ORDERED', 'ORDERED', 'TO_SIGN', 'IN_CORRECTION'],
    };
    const newSorter = {
      ...objectToNull(sorterInfo),
      field: sorter,
      order: 'ascend',
    };
    setQueryParams({ ...newPagination, ...newFilters, ...newSorter, modeConsultation: type === null ? undefined : [type] });

    // Zmiana wartości w filtrach formularza
    form.setFieldValue('modeConsultation', [type]);
    form.setFieldValue('registrationDate', null);
    form.setFieldValue('consultationDate', null);

    handleConsultationModeChange();
  };

  const onShowDetail = (id) => () => {
    // console.log(id);
  };

  const onJoinMeetingClick = (zoom, detailsRoute) => () => {
    navigate(detailsRoute);
    // window.open(zoom, '_blank');
  };

  const columns = [
    {
      title: 'Typ konsultacji',
      dataIndex: 'type',
      width: 120,
      align: 'center',
      key: 'type',
      sorter: false,
      filteredValue: _.has(filtersInfo, 'type') ? filtersInfo.type : undefined,
      filters: [
        {
          text: 'Indywidualna',
          value: 'SINGLE',
        },
        {
          text: 'Grupowa',
          value: 'GROUP',
        },
      ],
      render: (text) => {
        if (text === 'GROUP') {
          return (
            <Tooltip title="Konsultacja grupowa">
              <div className="flex justify-center">
                <img src={peopleSvg} alt="Konsultacja grupowa" className="cursor-pointer" />
              </div>
            </Tooltip>
          );
        }
        if (text === 'SINGLE') {
          return (
            <Tooltip title="Konsultacja indywidualna">
              <div className="flex justify-center">
                <FaUser alt="Konsultacja indywidualna" className="cursor-pointer" color="#94A3B8" />
              </div>
            </Tooltip>
          );
        }
        return null;
      },
    },
    {
      title: 'Stan konsultacji',
      dataIndex: 'statusConsultation',
      width: 140,
      align: 'center',
      key: 'statusConsultation',
      sorter: false,
      filteredValue: _.has(filtersInfo, 'statusConsultation') ? filtersInfo.statusConsultation : undefined,
      filters: [
        {
          text: 'Niezlecona',
          value: 'NOT_ORDERED',
        },
        {
          text: 'Zlecona',
          value: 'ORDERED',
        },
        // {
        //   text: 'W trakcie',
        //   value: 'IN_PROGRESS',
        // },
        {
          text: 'Do podpisu',
          value: 'TO_SIGN',
        },
        {
          text: 'Zakończona',
          value: 'COMPLETED',
        },
        {
          text: 'Korygowana',
          value: 'IN_CORRECTION',
        },
      ],
      render: (text) => {
        switch (text) {
          case 'IN_PROGRESS':
            return <span>W trakcie</span>;
          case 'TO_SIGN':
            return <span>Do podpisu</span>;
          case 'COMPLETED':
            return <span>Zakończona</span>;
          case 'NOT_ORDERED':
            return <span>Niezlecona</span>;
          case 'IN_CORRECTION':
            return <span>Korygowana</span>;
          default:
            return <span>Zlecona</span>;

          // case 'ORDERED':
          //   return <span>zlecona</span>;
          // default:
          //   return <span>niezlecona</span>;
        }
      },
    },
    {
      title: 'Stan dokumentacji',
      dataIndex: 'statusDocumentation',
      width: 210,
      align: 'center',
      key: 'statusDocumentation',
      sorter: false,
      filteredValue: _.has(filtersInfo, 'statusDocumentation') ? filtersInfo.statusDocumentation : undefined,
      filters: [
        {
          text: 'W przygotowaniu',
          value: 'IN_PREPARATION',
        },
        {
          text: 'Kompletna',
          value: 'COMPLETE',
        },
        {
          text: 'Zamknięta',
          value: 'FINISHED',
        },
        {
          text: 'Zarchiwizowana',
          value: 'ARCHIVED',
        },
        {
          text: 'Do uzupełnienia',
          value: 'TO_COMPLETE',
        },
        {
          text: 'Korygowana',
          value: 'IN_CORRECTION',
        },
      ],
      render: (text, record) => {
        switch (text) {
          case 'COMPLETE':
            return <span>Kompletna</span>;
          case 'FINISHED':
            return <span>Zamknięta</span>;
          case 'ARCHIVED':
            return <span>Zarchiwizowana</span>;
          case 'IN_CORRECTION':
            return <span>Korygowana</span>;
          case 'TO_COMPLETE':
            return <span>Do uzupełnienia</span>;
          default:
            return (
              <div className="flex items-center gap-1">
                <Progress className="float-left" type="circle" percent={record.percentageQualificationCard ? record.percentageQualificationCard : 0} width={50} />
                <span>W przygotowaniu</span>
              </div>
            );
        }
      },
    },
    {
      title: 'Pacjent',
      dataIndex: 'patient',
      key: 'patient',
      filteredValue: _.has(filtersInfo, 'patient') ? filtersInfo.patient : undefined,
      width: 160,
      sorter: false,
      ...getColumnSearchProps('patient'),
      render: (text, record) => (
        <>
          <p>
            {record.patientName} {record.patientSecondName} {record.patientSurname}
          </p>
          <p className="text-gray-500">{record.patientIdentity}</p>
        </>
      ),
    },
    {
      title: 'Lekarz zlecający',
      dataIndex: 'requestingDoctor',
      key: 'requestingDoctor',
      filteredValue: _.has(filtersInfo, 'requestingDoctor') ? filtersInfo.requestingDoctor : undefined,
      // sorter: true,
      ...getColumnSearchProps('requestingDoctor'),
      render: (text, record) => (
        <>
          <p>{text}</p>
          <p className="text-gray-500">{record.orderingUnit}</p>
        </>
      ),
    },
    {
      title: 'Konsultant - Lekarz/Grupa',
      dataIndex: 'consultant',
      key: 'consultant',
      // sorter: true,
      filteredValue: _.has(filtersInfo, 'consultant') ? filtersInfo.consultant : undefined,
      ...getColumnSearchProps('consultant'),
      render: (text, record) => {
        if (!text) return <span className="text-red-500">brak</span>;
        return (
          <>
            <p>{text}</p>
            <p className="text-gray-500">{record.consultantUnit}</p>
          </>
        );
      },
    },
    // {
    //   title: 'Wynik konsultacji/podejrzenie',
    //   dataIndex: 'diagnosis',
    //   key: 'diagnosis',
    //   width: 200,
    //   // sorter: true,
    //   filteredValue: _.has(filtersInfo, 'diagnosis') ? filtersInfo.diagnosis : undefined,
    //   ...getColumnSearchProps('diagnosis'),
    //   render: (_, record) => (
    //     <>
    //       <p>
    //         wynik konsultacji: <span className="text-red-500">{record.result || 'brak'}</span>
    //       </p>
    //       <p>
    //         podejrzenie: <span className="text-red-500">{record.result || 'brak'}</span>
    //       </p>
    //     </>
    //   ),
    // },
    // {
    //   title: 'Data rejestracji',
    //   dataIndex: 'registrationDate',
    //   key: 'registrationDate',
    //   width: 120,
    //   sorter: true,
    //   render: (text) => (text ? moment(text).format('YYYY-MM-DD') : null),
    // },
    {
      title: 'Termin konsultacji',
      dataIndex: 'consultationDate',
      key: 'consultationDate',
      width: 120,
      sorter: true,
      sortOrder: sorterInfo.field === 'consultationDate' ? sorterInfo.order : null,
      render: (text) => {
        if (!text) return <span className="text-red-500">brak</span>;
        return (
          <>
            <p>{text ? moment(text).local().format('YYYY-MM-DD') : null}</p>
            <p>godz. {text ? moment(text).local().format('HH:mm') : null}</p>
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 100,
      align: 'center',
      fixed: 'right',
      render: (_, record) => (
        <div className="flex flex-col justify-center gap-1">
          {record.status === 'PLANNED' && (
            <ButtonAntd
              type="primary"
              block
              size="middle"
              className="flex w-[70px] justify-center"
              onClick={onJoinMeetingClick(record.zoom && record.zoom.link, `/consultations/${record.documentationId}?join=true`)}
              // color={record.status === 'IN_PROGRESS' ? 'darkblue' : 'default'}
            >
              <FiVideo className="h-5 w-5" />
            </ButtonAntd>
          )}
          <Link to={`/consultations/${record.documentationId}`}>
            <ButtonAntd className="flex w-[70px] justify-center" onClick={onShowDetail(record.documentationId)}>
              <AiOutlineSearch className="h-5 w-5 text-gray-500 group-hover:text-gray-700" />
            </ButtonAntd>
          </Link>
        </div>
      ),
    },
  ];

  // if (isLoading) {
  //   return <Skeleton />;
  // }

  if (isError) {
    return (
      <div className="flex flex-col gap-3">
        <span>Problem z pobraniem danych</span>
        <span>Error: {error.message}</span>
      </div>
    );
  }

  const disabledDate = (current) => {
    const tenYearsAgo = moment().subtract(10, 'years').startOf('day');
    const tenYearsFromNow = moment().add(10, 'years').startOf('day');

    if (current && (current > tenYearsFromNow || current < tenYearsAgo)) {
      return true;
    }
    return false;
  };

  const handleConsultationModeChange = () => {
    setRangePickerDisabled(shouldDisableRangePicker(form));
  };

  const setFormat = (getFieldValue, setFieldValue) => {
    if (getFieldValue('consultationDate') === undefined || getFieldValue('consultationDate') === null) return FORMATS.DATE;
    if (
      moment(getFieldValue('consultationDate')[0]).format(FORMATS.TIME) === moment('00:00', FORMATS.TIME).format(FORMATS.TIME) &&
      moment(getFieldValue('consultationDate')[1]).format(FORMATS.TIME) === moment('23:59', FORMATS.TIME).format(FORMATS.TIME)
    ) {
      return FORMATS.DATE;
    }
    return FORMATS.DATE_TIME;
  };

  return (
    <div>
      {/* Widgety */}
      <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
        <ConsultationCountWidget title="Planowa" count={dataWidget ? dataWidget.data.planned : 0} onClick={onClickWidget('PLANNED')} />
        <ConsultationCountWidget
          title="Nagła"
          count={dataWidget ? dataWidget.data.urgent : 0}
          onClick={onClickWidget('URGENT')}
          alert={dataWidget && dataWidget.data.urgent > 0 ? true : false}
        />
        <ConsultationCountWidget title="Offline" count={dataWidget ? dataWidget.data.offline : 0} onClick={onClickWidget('OFFLINE')} />
        <div className="border-gray-300 pl-0 lg:md:sm:border-l-2 lg:md:sm:pl-4">
          <ConsultationCountWidget title="Konsultacje zakończone" count={dataWidget ? dataWidget.data.completed : 0} onClick={onClickWidget(null, ['COMPLETED'])} />
        </div>

        <div className="sm:col-span-2">
          <NextConsultation
            dataWidget={dataWidget && dataWidget.data}
            isLoading={isLoadingWidget}
            onClick={onClickWidget(
              'PLANNED',
              ['ORDERED', 'TO_SIGN', 'IN_CORRECTION', 'COMPLETED'],
              'statusConsultation',
              dataWidget?.data?.nearetsSlotWithConsultations?.dateFromNearestConsultation,
            )}
          />
        </div>
      </div>
      {/* Lista konsultacji */}
      <Card className="mt-5" title="Lista konsultacji">
        <Spin spinning={isFetching}>
          <Form
            form={form}
            onFinish={onFinish}
            layout="vertical"
            // initialValues={{ modeConsultation: 'PLANNED' }}
          >
            <Row gutter={16}>
              {/*<Col>*/}
              {/*  <Form.Item className="mb-5" label="Data rejestracji" name="registrationDate">*/}
              {/*    <RangePicker disabledDate={disabledDate} className="mb-5" inputReadOnly />*/}
              {/*  </Form.Item>*/}
              {/*</Col>*/}
              <Col>
                <Form.Item shouldUpdate>
                  {({ getFieldValue, setFieldValue }) => (
                    <Form.Item className="mb-5" label="Termin konsultacji" name="consultationDate">
                      <RangePicker
                        disabledDate={disabledDate}
                        showTime={{
                          format: 'HH:mm',
                          defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
                        }}
                        className="mb-5"
                        inputReadOnly
                        minuteStep={30}
                        disabled={isRangePickerDisabled}
                        format={setFormat(getFieldValue, setFieldValue)}
                      />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Tryb konsultacji" name="modeConsultation">
                  <Checkbox.Group options={checkboxOptions} onChange={handleConsultationModeChange}></Checkbox.Group>
                </Form.Item>
              </Col>
              <Col>
                <Row className="gap-3">
                  <Form.Item label=" ">
                    <Button size="xs" color="darkblue" submit>
                      Pokaż
                    </Button>
                  </Form.Item>
                  {displayClearFiltersButton && (
                    <Form.Item label=" ">
                      <Button size="xs" color="red" onClick={onClearFilters}>
                        Usuń filtry
                      </Button>
                    </Form.Item>
                  )}
                </Row>
              </Col>
            </Row>
          </Form>
          <Table
            rowKey={(row) => row.id}
            rowClassName={(row) => {
              const className = getClassNameFromConsultationState(row.modeConsultation, row.statusConsultation, row.statusDocumentation);
              return className ? `consultations-list-row-${className}` : '';
            }}
            dataSource={data && data.data.data}
            columns={columns}
            bordered
            scroll={{ x: 1400 }}
            onChange={onChangeTable}
            pagination={{ ...paginationInfo, total }}
            locale={{ emptyText: isFetching ? 'Trwa pobieranie listy konsultacji...' : 'Brak danych' }}
          />
        </Spin>
      </Card>
    </div>
  );
};

Consultations.propTypes = {};

export default Consultations;
