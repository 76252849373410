import React from 'react';

import { Form, Select } from 'antd';

import { testIdFactory } from '@/tests';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { SelectField } from '../../types';
import { getValidationRules } from '../service';

export const SelectFormField: FormFieldComponent<SelectField> = (props) => {
  const { name, label, description, options, isDisabled, isHidden, isRequired, isNecessary, tooltip, testId } = props;
  if (isHidden) {
    return null;
  }

  const validations = getValidationRules(props);
  const testAttribute = testIdFactory(testId);

  return (
    <Form.Item
      rules={validations}
      label={label ? <FormFieldLabel label={label} description={description} required={isRequired || isNecessary} fieldTooltip={tooltip} /> : null}
      className="mb-0 py-1"
      name={name}
      required={isRequired || isNecessary}
    >
      <Select
        className="w-full drop-shadow-sm"
        options={options.map((option) => ({ label: option, value: option }))}
        disabled={isDisabled}
        placeholder="Wybierz"
        allowClear={!(isRequired || isNecessary)}
        {...testAttribute(name)}
      />
    </Form.Item>
  );
};
