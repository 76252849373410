import React from 'react';

import classNames from 'classnames';
import { DisplayFieldComponent } from '../types';
import { LabelField } from '../../types';

export const LabelDisplayField: DisplayFieldComponent<LabelField> = ({ className, label, variant = 'normal', isHidden }) => {
  if (isHidden) {
    return null;
  }

  const fieldLabelClasses = classNames(
    className,
    'py-1',
    {
      'font-semibold': variant === 'header',
    },
    {
      'pt-4 font-semibold text-slate-500 uppercase text-xs': variant === 'subheader',
    },
  );

  return (
    <div className={fieldLabelClasses}>
      <div className="break-words">{label}</div>
    </div>
  );
};
