/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Checkbox, DatePicker, Form, InputNumber, TimePicker } from 'antd';
import PropTypes from 'prop-types';
import AuthContext from '../../contexts/Auth.context';
import { checkMinimalConsultationTimeRange } from '../../utils/checkMinimalConsultationTimeRange';
import { testIdFactory } from '@/tests';
import { FORMATS } from '../../constants/dates.constants';

const testId = testIdFactory('schedule-modal');

const ContentScheduleModal = ({ mode, disableList, onChange, form }) => {
  const [minDate, setMinDate] = useState(null);
  const [secondCalendarDate, setSecondCalendarDate] = useState(null);
  const consultationDate = Form.useWatch('consultationDate', form);
  const { unit, user } = useContext(AuthContext);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);

  const options = [
    { label: 'pn', value: 'MONDAY' },
    { label: 'wt', value: 'TUESDAY' },
    { label: 'śr', value: 'WEDNESDAY' },
    { label: 'czw', value: 'THURSDAY' },
    { label: 'pt', value: 'FRIDAY' },
    { label: 'sb', value: 'SATURDAY' },
    { label: 'nd', value: 'SUNDAY' },
  ];

  const handleCheckbox = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < options.length);
  };
  const [componentDisabled, setComponentDisabled] = useState(disableList);

  const onFormLayoutChange = (disabled) => {
    setComponentDisabled(disableList);
  };

  useEffect(() => {
    form.setFieldsValue({ repeatDate: consultationDate });
    setMinDate(consultationDate);
  }, [consultationDate]);

  const disabledDate = (current) => {
    const today = moment().startOf('day');
    const tenYearsAhead = moment().add(10, 'years').add(1, 'days').startOf('day');
    if (current && (current < today || current > tenYearsAhead)) {
      return true;
    }
    return false;
  };

  const disabledRepeatDate = (current) => {
    const today = moment().startOf('day');
    const tenYearsAhead = moment().add(10, 'years').startOf('day');
    if (current && (current < today || current > tenYearsAhead || (minDate && current < minDate))) {
      return true;
    }
    return false;
  };

  const handleConsultationTimeChange = (value) => {
    if (!value) return form.setFieldValue('consultationTime', undefined);

    if (value[1].format(FORMATS.TIME) === '00:00') {
      form.setFieldValue('consultationTime' ,[value[0], moment(value[1]).subtract(1, 'minutes')])
    }
  };

  return (
    <>
      <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
        <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
          <div>
            <h1 className="text-lg font-semibold">Termin</h1>
          </div>
        </div>
        <div className="col-span-6 col-start-1 grid rounded-md bg-white p-5 drop-shadow md:col-start-2">
          <div className="xs:grid-cols-1 grid sm:grid-cols-2 md:grid-cols-3 ">
            <Form.Item className="mr-2 w-full font-semibold md:mt-1" label="Data konsultacji" name="consultationDate">
              <DatePicker allowClear={false} disabledDate={disabledDate} inputReadOnly className="w-40" {...testId('consultationDate')} />
            </Form.Item>
            <Form.Item
              className="ant-explain-error-styles w-full font-semibold md:mt-1"
              label="Czas konsultacji"
              name="consultationTime"
              rules={[
                {
                  type: 'array',
                  required: true,
                  message: 'Proszę wybrać czas konsultacji.',
                  validateTrigger: 'onSubmit',
                },
                {
                  pattern: /^$|\s+/,
                  message: 'Proszę wybrać czas konsultacji.',
                  validateTrigger: 'onSubmit',
                },
                {
                  validator: (rule, consultationTime, callback) => {
                    if (!consultationTime || !consultationTime[0] || !consultationTime[1]) {
                      return Promise.resolve();
                    }
                    if (
                      moment(
                        `${form.getFieldsValue().consultationDate.local().format(FORMATS.DATE).toString()}T${form
                          .getFieldsValue()
                          .consultationTime[0].format(FORMATS.HOUR_WITH_SECONDS)
                          .toString()}`,
                      ).format(FORMATS.DATE_TIME) < moment().format(FORMATS.DATE_TIME)
                    )
                      return Promise.reject(new Error('Nie można dodać terminu, \r\njeśli godzina rozpoczęcia jest wcześniejsza niż aktualna!'));
                    // return Promise.reject(new Error(${`Nie można dodać terminu,`${\n}`jeśli godzina rozpoczęcia jest wcześniejsza niż aktualna!`}));
                    if (consultationTime !== null && consultationTime.every((time) => time !== null && time !== undefined)) {
                      if (checkMinimalConsultationTimeRange(consultationTime[0], consultationTime[1]))
                        return Promise.reject(new Error('Minimalny czas konsultacji wynosi 30 minut!'));
                    }
                    return Promise.resolve();
                  },
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <TimePicker.RangePicker
                className="timepicker-style w-40"
                format="HH.mm"
                minuteStep={30}
                inputReadOnly
                {...testId('consultationTime')}
                onChange={handleConsultationTimeChange}
              />
            </Form.Item>
            <Form.Item
              className="w-full font-semibold md:mt-1"
              label="Maks. liczba konsultacji"
              name="maxNumber"
              rules={[
                {
                  required: true,
                  message: 'Proszę wybrać maksymalną liczbę konsultacji, która powinna być całkowita i większa od zera.',
                },
                {
                  pattern: /^[1-9][0-9]*$/,
                  message: 'Maksymalna liczba konsultacji powinna być całkowita i większa od zera.',
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <InputNumber className="w-40 drop-shadow-sm" parser={(value) => value.toString().match(/^[1-9][0-9]*$/)} {...testId('maxNumber')} />
            </Form.Item>
          </div>
          {mode === 'add' && (
            <>
              <div className="mb-2 flex">
                <Form.Item className="mb-0 font-semibold" name="repeat" valuePropName="checked">
                  <Checkbox className="font-semibold" name="repeatEvent" onChange={(e) => onChange(e.target.checked)} {...testId('repeat')}>
                    powtórz
                  </Checkbox>
                </Form.Item>
              </div>
              <div className="flex">
                <Form.Item className="font-semibold" name="repeatDays">
                  <Checkbox.Group className="font-semibold" options={options} onChange={handleCheckbox} disabled={!disableList} {...testId('repeatDays')} />
                </Form.Item>
              </div>
              <div className="flex">
                <p className="py-1 pr-4 font-semibold">do dnia</p>
                <Form.Item name="repeatDate">
                  <DatePicker format="YYYY-MM-DD" allowClear={false} disabled={!disableList} disabledDate={disabledRepeatDate} {...testId('repeatDate')} />
                </Form.Item>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
ContentScheduleModal.propTypes = {
  onChange: PropTypes.func,
};

ContentScheduleModal.defaultProps = {
  onChange: () => null,
};

export default ContentScheduleModal;
