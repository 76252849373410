import React, { Fragment } from 'react';
import { Modal, Form, Spin } from 'antd';
import moment from 'moment';
import Button from '../Button2/Button.component';
import ContentScheduleModal from './ContentScheduleModal.component.js';
import { FORMATS } from '../../constants/dates.constants';

export const showModal = (
  start,
  setMode,
  setDisableList,
  setCheckedList,
  setSelectedTime,
  selectedTime,
  form,
  checkedList,
  disableList,
  calendarRef,
  openNotification,
  setEventConfirm,
  setIsModalOpen,
) => {
  setMode('add');
  setDisableList(false);
  setCheckedList([]);
  setSelectedTime({
    ...selectedTime,
    date: moment(start.start).format(FORMATS.DATE),
    repeatDate: moment(start.start).format(FORMATS.DATE),
    time: [moment(start.start, FORMATS.TIME), moment(start.end, FORMATS.TIME)],
  });

  const end = moment(start.end);
  const timeEnd = end.format(FORMATS.TIME) === '00:00' ? end.subtract(1, 'minutes') : end;

  form.setFieldsValue({
    consultationDate: moment(start.start),
    consultationTime: [moment(start.start, FORMATS.TIME), moment(timeEnd, FORMATS.TIME)],
    repeatDate: moment(start.start),
    repeatDays: checkedList,
    repeat: disableList,
    maxNumber: null,
  });
  if (moment(start.start).format(FORMATS.DATE) !== moment(start.end).add(-1, 'minutes').format(FORMATS.DATE)) {
    calendarRef.current.getApi().unselect();
    openNotification('Proszę zaznaczyć wolny termin w tym samym dniu.');
  } else {
    calendarRef.current.getApi().addEvent({ ...start, id: 0 });
    setEventConfirm(start);
    setIsModalOpen(true);
  }
};

export const showModalButton = (setMode, setCheckedList, setDisableList, setSelectedTime, selectedTime, form, checkedList, disableList, setIsModalOpen) => {
  setMode('add');
  setCheckedList([]);
  setDisableList(false);
  setSelectedTime({ date: moment().format(FORMATS.DATE), repeatDate: moment(selectedTime.repeatDate).format(FORMATS.DATE), time: [null, null] });
  form.setFieldsValue({
    consultationDate: moment(),
    consultationTime: [null, null],
    repeatDate: moment(),
    repeatDays: checkedList,
    repeat: disableList,
    maxNumber: null,
  });
  setIsModalOpen(true);
};

export const showModalEdit = (eventConfirm, setMode, setCheckedList, setDisableList, setSelectedTime, selectedTime, form, setIsModalOpen) => {
  eventConfirm.event.setExtendedProp('openPopOver', false);
  setMode('edit');
  setCheckedList([]);
  setDisableList(false);
  setSelectedTime({ date: moment().format(FORMATS.DATE), repeatDate: moment(selectedTime.repeatDate).format(FORMATS.DATE), time: [null, null] });
  form.setFieldsValue({
    consultationDate: moment(eventConfirm.event.start),
    consultationTime: [moment(eventConfirm.event.start, FORMATS.TIME), moment(eventConfirm.event.end, FORMATS.TIME)],
    maxNumber: eventConfirm.event.extendedProps.maxNumber,
  });
  setIsModalOpen(true);
};

const ScheduleModal = ({
  selectedTime,
  mode,
  form,
  calendarRef,
  eventConfirm,
  calendarChange,
  mutation,
  user,
  checkedList,
  setCheckedList,
  disableList,
  setDisableList,
  isModalOpen,
  setIsModalOpen,
}) => {
  const onAfterClose = () => {
    setCheckedList([]);
    setDisableList(false);
    form.setFieldsValue({
      consultationDate: moment(selectedTime.date, FORMATS.DATE),
      consultationTime: selectedTime.time,
      repeatDate: moment(selectedTime.repeatDate, FORMATS.DATE),
      repeatDays: checkedList,
      repeat: disableList,
      maxNumber: null,
    });
    if (calendarRef.current.getApi().getEventById(0) !== null) calendarRef.current.getApi().getEventById(0).remove();
  };

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        let newValue = {
          ...values,
          dateFrom: moment(`${values.consultationDate.format(FORMATS.DATE).toString()}T${values.consultationTime[0].format(FORMATS.HOUR_WITH_SECONDS).toString()}`)
            .utc()
            .format(),
          dateTo: moment(`${values.consultationDate.format(FORMATS.DATE).toString()}T${values.consultationTime[1].format(FORMATS.HOUR_WITH_SECONDS).toString()}`)
            .utc()
            .format(),
        };
        if (values.consultationTime[1].format(FORMATS.HOUR_WITH_SECONDS).toString() === '00:00:00')
          newValue.dateTo = moment(`${values.consultationDate.format(FORMATS.DATE).toString()}T24:00:00`);
        if (mode === 'add') {
          newValue = { ...newValue, repeatDate: values.repeatDate.utc().format(FORMATS.DATE) };
          if (calendarChange.value === null) mutation.mutate({ ...newValue, userId: user.id });
          else mutation.mutate({ ...newValue, groupId: calendarChange.value });
        }
        if (mode === 'edit') {
          if (calendarChange.value === null) mutation.mutate({ ...newValue, userId: user.id, id: eventConfirm.event.id });
          else mutation.mutate({ ...newValue, groupId: calendarChange.value, id: eventConfirm.event.id });
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <Modal
      title={
        <div>
          <h1 className="text-xl font-extrabold uppercase">{mode === 'add' ? 'DODAJ DOSTĘPNY TERMIN' : 'EDYTUJ DOSTĘPNY TERMIN'}</h1>
          <p className=" text-sm text-gray-600">Wprowadź dane</p>
        </div>
      }
      width={1000}
      className="modal-styles"
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      afterClose={onAfterClose}
      footer={[
        <Fragment key="footer">
          <Button onClick={() => setIsModalOpen(false)} color="default" size="custom-lg" margin="mr-6">
            Anuluj
          </Button>
          <Button onClick={onFinish} color="darkblue" size="custom-lg">
            Zapisz
          </Button>
        </Fragment>,
      ]}
    >
      <Spin spinning={mutation.isLoading} size="large" delay={0}>
        <Form
          layout="vertical"
          form={form}
          name="schedule"
          initialValues={{
            consultationDate: moment(selectedTime.date),
            consultationTime: selectedTime.time,
            repeatDate: moment(selectedTime.repeatDate),
            repeatDays: checkedList,
            repeat: disableList,
            maxNumber: null,
          }}
          onFinish={onFinish}
        >
          <ContentScheduleModal mode={mode} disableList={disableList} onChange={() => setDisableList(!disableList)} form={form} />
        </Form>
      </Spin>
    </Modal>
  );
};

export default ScheduleModal;
