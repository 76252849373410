// Static per-field validation
export type StaticValidationDescriptionObject<T> = {
  value: T;
  message?: string;
};

export type StaticValidationDescription<T> = T | StaticValidationDescriptionObject<T>;

// Combined
export type FieldValidation = {
  maxLength: StaticValidationDescription<number>;
  minLength: StaticValidationDescription<number>;
  maxNumericValue: StaticValidationDescription<number>;
  minNumericValue: StaticValidationDescription<number>;
  numericRange: StaticValidationDescription<[number, number]>;
};

// Global form validation

export type GlobalValidationType = 'REQUIRED';

export interface GlobalValidationRule {
  fields: string | string[];
  condition?: 'ALL' | 'ONE_OF';
  requirement: {
    type: GlobalValidationType;
    message: string;
  };
}

type GlobalValidatorFunction = (value: unknown, param?: unknown) => boolean;

/*
 * Add here another types of global validationm rules if needed
 */

export const globalValidators: Record<GlobalValidationType, GlobalValidatorFunction> = {
  REQUIRED: (value) => Boolean(value),
};
