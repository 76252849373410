import React, { FC } from 'react';
import { Form, Input } from 'antd';
import Button from '@/components/Button2/Button.component';

interface Props {
  isLoading?: boolean;
}

const ChangePasswordForm: FC<Props> = ({ isLoading }) => {
  const form = Form.useFormInstance();
  return (
    <>
      <Form.Item
        label="Aktualne hasło"
        name="currentPassword"
        rules={[
          { required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' },
          // { min: 12, message: 'Pole przyjmuje minimalnie 12 znaków!', validateTrigger: 'onSubmit' },
          // { max: 64, message: 'Pole przyjmuje maksymalnie 64 znaków!', validateTrigger: 'onSubmit' },
          // {
          //   pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
          //   message: 'Hasło musi zawierać co najmniej jedną wielką literę, jedną małą literę, cyfrę oraz znak specjalny!',
          //   validateTrigger: 'onSubmit',
          // },
        ]}
        className="mb-2"
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Nowe Hasło"
        name="newPassword"
        rules={[
          { required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' },
          { min: 12, message: 'Pole przyjmuje minimalnie 12 znaków!', validateTrigger: 'onSubmit' },
          { max: 64, message: 'Pole przyjmuje maksymalnie 64 znaków!', validateTrigger: 'onSubmit' },
          {
            pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
            message: 'Hasło musi zawierać co najmniej jedną wielką literę, jedną małą literę, cyfrę oraz znak specjalny!',
            validateTrigger: 'onSubmit',
          },
          {
            pattern: /^((?!konsylium|ekonsylium|Konsylium|Ekonsylium).)*$/,
            message: 'Hasło nie może zawierać słów „ekonsylium” i „konsylium”!',
            validateTrigger: 'onSubmit',
          },
          {
            pattern: /^\S*$/,
            message: 'Hasło nie może zawierać znaku "Spacja"!',
            validateTrigger: 'onSubmit',
          },
        ]}
        className="mb-2"
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        label="Powtórz hasło"
        name="repeatedNewPassword"
        rules={[
          { required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' },
          { min: 12, message: 'Pole przyjmuje minimalnie 12 znaków!', validateTrigger: 'onSubmit' },
          // { max: 64, message: 'Pole przyjmuje maksymalnie 64 znaków!', validateTrigger: 'onSubmit' },
          {
            validator: async (_, repeatedNewPassword) => {
              if (form.getFieldValue('newPassword') !== repeatedNewPassword) {
                return Promise.reject(new Error('„Nowe Hasło” i „Powtórz hasło” muszą być takie same.'));
              }
            },
            validateTrigger: 'onSubmit',
          },
        ]}
        className="mb-0"
      >
        <Input.Password />
      </Form.Item>
      <Form.Item className="mb-0 mt-5">
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <Button loading={isLoading} disabled={isLoading} submit block color="darkblue" className="w-full">
          Ustaw hasło
        </Button>
      </Form.Item>
    </>
  );
};

export default ChangePasswordForm;
