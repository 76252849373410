export const getStatus = (status) => {
  if (!status) return 'brak statusu';

  switch (status.toUpperCase()) {
    case 'COMPLETED':
      return 'Zakończona';
    case 'COMPLETE':
      return 'Kompletna';
    case 'FINISHED':
      return 'Zamknięta';
    case 'PLANNED':
      return 'Planowa';
    case 'SINGLE':
      return 'Pojedyncza';
    case 'GROUP':
      return 'Grupowa';
    case 'CREATED':
      return 'Stworzona';
    case 'EXPEDITED':
      return 'Przyspieszona';
    case 'URGENT':
      return 'Nagła';
    case 'OFFLINE':
      return 'Offline';
    case 'IN_PREPARATION':
      return 'W przygotowaniu';
    case 'IN_PROGRESS':
      return 'W trakcie';
    case 'TO_SIGN':
      return 'Do podpisu';
    case 'ORDERED':
      return 'Zlecona';
    case 'NOT_ORDERED':
      return 'Niezlecona';
    case 'ARCHIVED':
      return 'Zarchiwizowana';
    case 'IN_CORRECTION':
      return 'Korygowana';
    case 'TO_COMPLETE':
      return 'Do uzupełnienia';
    default:
      return 'brak';
  }
};
