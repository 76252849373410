import React from 'react';

// import { cardiologyQualificationCard as qualificationCardTemplate } from '@/fakes/qualificationCardTemplates';
import { FormRenderer } from '../TemplateEngine';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const QualificationCard = ({ data }) => {
  const template = data.templateQualificationCard; // backend
  // const template = qualificationCardTemplate; // local fake values

  return (
    <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
      <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
        <div>
          <h1 className="text-lg font-semibold ">Karta kwalifikacji</h1>
        </div>
      </div>
      <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
        <FormRenderer template={template} />
      </div>
    </div>
  );
};

export default QualificationCard;
