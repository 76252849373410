import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ className, data }) => {
  const count = data.length;

  return (
    <nav className={classnames('flex', className)} aria-label="Breadcrumb" >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {data.map((item, index) => {
          if (index === 0) {
            return (
              <li key={nanoid()} className="inline-flex items-center">
                <Link to={item.link} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                  <svg className="mr-2 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                  </svg>
                  {item.title}
                </Link>
              </li>
            );
          }
          if (index > 0 && index + 1 !== count) {
            return (
              <li key={nanoid()} className="inline-flex items-center">
                <svg className="h-6 w-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                {item.link ? (
                  <Link to={item.link} className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    {item.title}
                  </Link>
                ) : (
                  <span className="ml-1 text-sm font-medium text-gray-500 dark:text-gray-400 md:ml-2">{item.title}</span>
                )}
              </li>
            );
          }

          return (
            <li key={nanoid()} aria-current="page">
              <div className="flex items-center">
                <svg className="h-6 w-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="ml-1 text-sm font-medium text-gray-500 dark:text-gray-400 md:ml-2">{item.title}</span>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
Breadcrumb.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

Breadcrumb.defaultProps = {
  className: null,
  data: [],
};

export default Breadcrumb;
