import React from 'react';

import { Divider } from 'antd';
import { SeparatorField as SeparatoFieldType } from '../../types';
import { CommonFieldComponent } from '../types';

export const SeparatorField: CommonFieldComponent<SeparatoFieldType> = ({ isHidden, label }) => {
  if (isHidden) {
    return null;
  }

  return <Divider plain>{label}</Divider>;
};
