import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

import { Row, Col, Space, Form, Collapse } from 'antd';
import { FormField as FormFieldType, Group, FormTemplate } from '../types';
import { FormField } from './FormField';
import { getFieldHiddenState } from '../service';
import { shouldUpdateForm } from './service';

interface FormGroupProps extends Group<FormFieldType> {
  template: FormTemplate;
}

const FIELD_STYLE = { marginRight: `-025.rem` };

export const FormFieldGroup: FC<FormGroupProps> = ({ fields, template, variant, label }) => {
  const commonClasses = classNames('');
  const { getFieldValue } = Form.useFormInstance();

  const paragraphClasses = classNames('font-semibold last:pb-0');

  const getGroupClasses = () => {
    switch (variant) {
      case 'card':
        return classNames(commonClasses, 'bg-gray-100 rounded-md px-6 py-4');
      case 'transparent':
      default:
        return classNames(commonClasses);
    }
  };

  const getGroupWrapper = (children: ReactNode): ReactNode => {
    switch (variant) {
      case 'collapse':
        return (
          <Collapse className="rounded-md bg-gray-100 py-1 px-2" bordered={false} defaultActiveKey={['1']}>
            <Collapse.Panel header={<p className={paragraphClasses}>{label}</p>} key="1">
              {children}
            </Collapse.Panel>
          </Collapse>
        );
      default:
        return (
          <Space style={{ width: '100%' }} direction="vertical" className={getGroupClasses()}>
            {label && <p className={paragraphClasses}>{label}</p>}
            {children}
          </Space>
        );
    }
  };

  return (
    <Col className="dynamic-form-group" span={24}>
      {getGroupWrapper(
        <Row>
          {fields.map((field) => (
            <Form.Item key={field.name} className={`ant-col ant-col-${field.width ?? 24} px-1`} style={FIELD_STYLE} shouldUpdate={shouldUpdateForm(field)}>
              {() => {
                const isFieldHidden = getFieldHiddenState(field, getFieldValue);
                return <FormField className={classNames({ invisible: isFieldHidden })} {...field} formTemplate={template} />;
              }}
            </Form.Item>
          ))}
        </Row>,
      )}
    </Col>
  );
};
