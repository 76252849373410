import React from 'react';

import { Form, InputNumber } from 'antd';

import { NumericField, decimalFormatter } from '@/components/TemplateEngine';
import { testIdFactory } from '@/tests';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { getValidationRules } from '../service';

export const NumberFormField: FormFieldComponent<NumericField> = (props) => {
  const { name, label, description, isDisabled, isHidden, isRequired, isNecessary, suffix, precision, tooltip, testId } = props;

  if (isHidden) {
    return null;
  }

  const validations = getValidationRules(props);

  const testAttribute = testIdFactory(testId);

  return (
    <Form.Item
      label={label ? <FormFieldLabel label={label} description={description} required={isRequired || isNecessary} fieldTooltip={tooltip} /> : null}
      labelCol={{ span: 24 }}
      className="mb-0 py-1 drop-shadow-sm"
      rules={validations}
      name={name}
      required={isRequired || isNecessary}
    >
      <InputNumber
        className="w-full break-words drop-shadow-sm"
        addonAfter={suffix}
        disabled={isDisabled}
        precision={precision}
        decimalSeparator=","
        formatter={decimalFormatter(precision)}
        {...testAttribute(name)}
      />
    </Form.Item>
  );
};
