import axios from 'axios';
import withAccessToken from './withAccessToken';
import { defaultHeaders } from './http';

const httpPdf = axios.create({
  headers: {
    ...defaultHeaders,
  },
});

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

// Wynik konsultacji wyświetlenie PDF
export const getConsultationResultPDF = (pdfUrl) =>
  httpPdf
    .get(pdfUrl, {
      responseType: 'arraybuffer',
      ...withAccessToken(),
    })
    .then((response) => arrayBufferToBase64(response.data));
