import {
  createFieldDescriptorFinder,
  getFieldDisabledState,
  getFieldHiddenState,
  getFieldRequiredState,
  getFieldNecessaryState,
  createRelatedRadioGroupFieldsDescriptorFinder,
  getFieldResultVisibilityState,
} from './service';
import { FieldBehavior, FormTemplate, GetFieldValueFunction } from './types';

export const useDynamicField = (fieldName: string, formTemplate: FormTemplate) => {
  const findFieldDescriptor = createFieldDescriptorFinder(formTemplate.groups);

  const getFieldDescriptor = () => findFieldDescriptor(fieldName);

  const getFieldBehavior = (getFieldValue: GetFieldValueFunction, hasNoData?: boolean) => {
    const field = findFieldDescriptor(fieldName);

    const fieldBehavior: FieldBehavior = {
      isDisabled: hasNoData ? true : getFieldDisabledState(field, getFieldValue),
      isHidden: getFieldHiddenState(field, getFieldValue),
      isRequired: hasNoData ? false : getFieldRequiredState(field, getFieldValue),
      isNecessary: hasNoData ? false : getFieldNecessaryState(field, getFieldValue),
      isVisibleInResult: getFieldResultVisibilityState(field, getFieldValue),
    };

    return fieldBehavior;
  };

  const isRelatedRadioSelected = (getFieldValue: GetFieldValueFunction): boolean => {
    const field = findFieldDescriptor(fieldName);

    if (field.type !== 'radio') {
      return false;
    }
    const getRelatedRadioFields = createRelatedRadioGroupFieldsDescriptorFinder(formTemplate.groups);

    const relatedRadioFields = getRelatedRadioFields(fieldName, field.radioGroup);

    if (relatedRadioFields.length === 0) {
      return false;
    }

    return relatedRadioFields.some((relatedRadioField) => {
      const value = getFieldValue(relatedRadioField.name);
      return value === true;
    });
  };

  return {
    getFieldDescriptor,
    getFieldBehavior,
    isRelatedRadioSelected,
  };
};
