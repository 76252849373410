import React from 'react';

import classNames from 'classnames';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { LabelField } from '../../types';

export const LabelFormField: FormFieldComponent<LabelField> = ({ label, variant = 'normal', description, isHidden, isRequired }) => {
  if (isHidden) {
    return null;
  }

  const fieldLabelClasses = classNames(
    'py-1',
    {
      'font-semibold': variant === 'header',
    },
    {
      'font-semibold text-slate-500 uppercase text-xs': variant === 'subheader',
    },
  );

  return <FormFieldLabel className={fieldLabelClasses} label={label} description={description} required={isRequired} />;
};
