import React from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { supplementConsultation } from '../../services/consultations.service';
import SupplementButton from './SupplementButton.component';

const ConsultationSupplement = ({ idConsultation, status, user }) => {
  const { documentation } = status;
  const queryClient = useQueryClient();

  const refresh = () => {
    queryClient.refetchQueries(['status/history']);
    queryClient.refetchQueries(['consultation-id', idConsultation]);
  };

  return (
    <div>
      {user.provideConsultation && (documentation?.state === 'TO_COMPLETE') && (
        <SupplementButton
          titleBtn={(<>Zatwierdź zmiany <p className="text-xs">Uzupełnienie dokumentacji</p></>)}
          titleMessage="Czy uzupełniono wszystkie elementy wymienione w prośbie o uzupełnienie dokumentacji?"
          // contentMessage=""
          okText="Tak"
          cancelText="Nie"
          errorMessage="Problem z zatwiedzeniem zmian."
          successMessage="Zmiany wprowadzono pomyślnie."
          documentation={documentation}
          request={supplementConsultation({uuid: idConsultation})}
          refresh={refresh}
        />
      )}
    </div>
  );
};

ConsultationSupplement.propTypes = {
  idConsultation: PropTypes.string,
  status: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  user: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
};

ConsultationSupplement.defaultProps = {
  idConsultation: null,
  status: [],
  user: [],
};

export default ConsultationSupplement;
