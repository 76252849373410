import { omit } from 'lodash';
import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { checkIfAllNecessaryFieldsProvided, getFormCompletionPercentage, renderResultsToJSON } from './service';
import { DEFAULT_VALIDATION_MESSAGES } from './validation/messages';
import { FormTemplate, FormValues } from './types';

type BeforeSubmitMapper = <V>(formValues: Record<string, V>) => Record<string, V>;
type BeforeSubmitMapperStore = Record<string, BeforeSubmitMapper>;

interface TemplateEngineContextProps {
  mappersStore: BeforeSubmitMapperStore;
  registerBeforeSubmitMapper: (name: string, mapper: BeforeSubmitMapper) => void;
  deleteBeforeSubmitMapper: (name: string) => void;
  getFinalFormValues: BeforeSubmitMapper;
  scrollToField: (fieldName: string, behavior: ScrollBehavior) => void;
  validationMessages: typeof DEFAULT_VALIDATION_MESSAGES;
  alreadySubmittedOnce: () => boolean;
  onSuccessfulSubmission: () => void;
  onErrorSubmission: () => void;
  setTemplate: (template: FormTemplate) => void;
  areAllNecessaryFieldsProvided: (formValues: FormValues) => boolean;
  getCompletionPercentage: (formValues: FormValues) => number | null;
  copyContext: Record<string, unknown> | null;
  setCopyContext: (template: Record<string, unknown> | null) => void;
  renderJsonResult: (formValues: Record<string, unknown>) => Array<unknown>;
}

export const TemplateEngineContext = createContext<TemplateEngineContextProps>({} as TemplateEngineContextProps);

export const TemplateEngineContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [template, setTemplate] = useState<FormTemplate | null>(null);
  const [copyContext, setCopyContext] = useState<Record<string, unknown> | null>(null);
  const [mappersStore, setMappersStore] = useState<BeforeSubmitMapperStore>({});
  const [submissionCount, setSubmissionCount] = useState<number>(0);

  const registerBeforeSubmitMapper = (name: string, mapper: BeforeSubmitMapper) => {
    setMappersStore((store) => ({ ...store, [name]: mapper }));
  };

  const deleteBeforeSubmitMapper = (name: string) => {
    setMappersStore((store) => ({ ...omit(store, name) }));
  };

  const getFinalFormValues = (formValues) => Object.values(mappersStore).reduce((result, mapper) => ({ ...result, ...mapper(formValues) }), {});

  const scrollToField = (name: string, behavior: ScrollBehavior = 'auto') => {
    const elementToScroll = document.getElementById(name);
    if (!elementToScroll) {
      return;
    }
    elementToScroll.scrollIntoView({ behavior, block: 'start', inline: 'nearest' });
  };

  const onSuccessfulSubmission = () => {
    setSubmissionCount(0);
  };

  const onErrorSubmission = () => {
    setSubmissionCount((count) => count + 1);
  };

  const alreadySubmittedOnce = () => submissionCount > 0;

  const areAllNecessaryFieldsProvided = (formValues: FormValues) => {
    if (!template) {
      return false;
    }
    return checkIfAllNecessaryFieldsProvided(template, formValues);
  };

  const getCompletionPercentage = (formValues: FormValues) => {
    if (!template) {
      return null;
    }
    return getFormCompletionPercentage(template, formValues);
  };

  const renderJsonResult = (formValues: Record<string, unknown>) => renderResultsToJSON(template, formValues);

  return (
    <TemplateEngineContext.Provider
      value={{
        alreadySubmittedOnce,
        onSuccessfulSubmission,
        onErrorSubmission,
        mappersStore,
        registerBeforeSubmitMapper,
        deleteBeforeSubmitMapper,
        getFinalFormValues,
        scrollToField,
        validationMessages: DEFAULT_VALIDATION_MESSAGES,
        areAllNecessaryFieldsProvided,
        setTemplate,
        getCompletionPercentage,
        setCopyContext,
        copyContext,
        renderJsonResult,
      }}
    >
      {children}
    </TemplateEngineContext.Provider>
  );
};

export const useTemplateEngineContext = () => useContext(TemplateEngineContext);
