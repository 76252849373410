import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { testId } from '@/tests';

export const ActiveStatus = ({ status, classNames }) => {
  const cn = cx(`${classNames} h-2 w-2 rounded-full`, {
    'bg-green-500': status,
    'bg-red-500': !status,
  });

  return <div className={cn} {...testId('active-status')} />;
};

ActiveStatus.propTypes = {
  status: PropTypes.bool,
};

ActiveStatus.defaultProps = {
  status: false,
};
