import { Button as ButtonAnt, Modal, Form, message, Tooltip } from 'antd';
import React, { useState, Fragment } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { getAnonymizedDataForSignatureWithoutLogging, signAnonymizedCertificate } from '@/services/consultations.service';
import moment from 'moment';
import { testId } from '@/tests';
import Card from '../../Card/Card.component';
import Button from '../../Button2/Button.component';
import logoXlSvg from '../../../assets/images/logo-xl-white.svg';
import ModalApproveWithoutLogging from './ModalApproveWithoutLogging.component';

const SignatureWithoutLogging = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get('documentationUuid');
  const token = searchParams.get('token');
  const { data } = useQuery(['signature-without-logging', token], getAnonymizedDataForSignatureWithoutLogging(id, token), {
    onError: (err) => {
      navigate(`/consultations/${id}`);
      message.error(`${err.response.data.message}`);
    },
  });
  const [form] = Form.useForm();

  const arrayOfData = [];

  if (data) {
    arrayOfData.push(data?.data);
  }

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const onClose = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const mutationOptions = {
    onSuccess: async () => {
      message.success('Podpis został poprawnie złożony.');
      navigate('/auth');
    },
    onError: async (err) => {
      message.error(`Problem z podpisaniem konsultacji. ${err.response.data.message}`);
    },
  };

  const mutation = useMutation(signAnonymizedCertificate, mutationOptions);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        mutation.mutate({ token, password: values.password });
      })
      .catch((err) => {
        console.log('Validate Failed:', err);
      });
  };

  return (
    <div className="h-screen w-full bg-gray-800">
      <div className="flex flex-col items-center justify-center py-20 text-white">
        <img className="w-100" src={logoXlSvg} alt="Logo e-Konsylium" />
      </div>
      <div className="grid grid-cols-5 bg-gray-800 pb-20">
        <div className="col-span-3 col-start-2 ">
          <Card
            title={
              <div className="flex flex-row items-center justify-between py-4">
                <div>
                  <h1 className="text-xl font-extrabold uppercase">Podpis konsultacji</h1>
                  <p className=" text-sm text-gray-600">Dane konsultacji</p>
                </div>
              </div>
            }
          >
            {arrayOfData?.map((item) => (
              <div className="my-4 grid grid-cols-6 border-gray-300 drop-shadow" key={item?.uuid}>
                <div className="col-span-6 col-start-1 grid pb-2 xl:col-span-1">
                  <h1 className="text-lg font-semibold">Konsultacja</h1>
                </div>
                <div className="col-span-6 col-start-1 rounded-md bg-white px-5 py-8 drop-shadow xl:col-span-5 xl:col-start-2">
                  <div className="flex flex-col justify-between sm:flex-row">
                    <div>
                      <div className="grid grid-cols-7 gap-4">
                        <span className="col-span-7 col-start-1 font-semibold sm:col-span-2">ID:</span>
                        <span className="col-span-7 col-start-1 sm:col-span-5 sm:col-start-3" {...testId('consultation-id')}>
                          {item?.uuid}
                        </span>
                      </div>
                      {item?.consultationDate ? (
                        <div className="grid grid-cols-7 gap-4">
                          <span className="col-span-7 col-start-1  font-semibold sm:col-span-2">Termin konsultacji:</span>
                          <span className="col-span-7 col-start-1 sm:col-span-5 sm:col-start-3" {...testId('consultation-date')}>
                            {moment(item?.consultationDate).format('YYYY-MM-DD HH:mm')}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <Link to={`/consultations/${item?.uuid}`}>
                        <Tooltip title="Dostęp po zalogowaniu">
                          <Button>Pokaż konsultację</Button>
                        </Tooltip>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-span-6 col-start-1 mt-4 grid pb-2 xl:col-span-1">
                  <h1 className="text-lg font-semibold">Lekarz zlecający</h1>
                </div>
                <div className="col-span-6 col-start-1 rounded-md bg-white px-5 py-8 drop-shadow xl:col-span-5 xl:col-start-2 xl:mt-4">
                  <div className="grid grid-cols-1 gap-2 xl:grid-cols-2">
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Imię:</span>
                      <span className="col-span-5 col-start-1 break-words px-5 sm:col-span-4 sm:col-start-2" {...testId('requesting-doctor-name')}>
                        {item.requestingDoctor?.name}
                      </span>
                    </div>
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Nazwisko:</span>
                      <span className="col-span-5 col-start-1 break-words px-5 sm:col-span-4 sm:col-start-2" {...testId('requesting-doctor-surname')}>
                        {item.requestingDoctor?.surname}
                      </span>
                    </div>
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Jednostka:</span>
                      <span className="col-span-5 col-start-1 break-words px-5 sm:col-span-4 sm:col-start-2" {...testId('requesting-doctor-unit-name')}>
                        {item.requestingDoctor?.unitName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-span-6 col-start-1 mt-4 grid pb-2 xl:col-span-1">
                  <h1 className="text-lg font-semibold">Konsultant</h1>
                </div>
                <div className="col-span-6 col-start-1 rounded-md bg-white px-5 py-8 drop-shadow xl:col-span-5 xl:col-start-2 xl:mt-4">
                  <div className="grid grid-cols-1 gap-2 xl:grid-cols-2">
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Imię:</span>
                      <span className="col-span-5 col-start-1 break-words px-5 sm:col-span-4 sm:col-start-2" {...testId('consultant-doctor-name')}>
                        {item.consultantDoctor?.name}
                      </span>
                    </div>
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Nazwisko:</span>
                      <span className="col-span-5 col-start-1 break-words px-5 sm:col-span-4 sm:col-start-2" {...testId('consultant-doctor-surname')}>
                        {item.consultantDoctor?.surname}
                      </span>
                    </div>
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Jednostka:</span>
                      <span className="col-span-5 col-start-1 break-words px-5 sm:col-span-4 sm:col-start-2 " {...testId('consultant-doctor-unit-name')}>
                        {item.consultantDoctor?.unitName}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-span-6 col-start-1 mt-4 grid pb-2 xl:col-span-1">
                  <h1 className="text-lg font-semibold">Pacjent</h1>
                </div>
                <div className="col-span-6 col-start-1 rounded-md bg-white px-5 py-8 drop-shadow xl:col-span-5 xl:col-start-2 xl:mt-4">
                  <div className="grid grid-cols-1 gap-2 xl:grid-cols-2">
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Imię:</span>
                      <span className="col-span-5 col-start-1 break-words px-5 sm:col-span-4 sm:col-start-2" {...testId('patient-name')}>
                        {item.patient?.name}
                      </span>
                    </div>
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Nazwisko:</span>
                      <span className="col-span-5 col-start-1 break-words px-5 sm:col-span-4 sm:col-start-2" {...testId('patient-surname')}>
                        {item.patient?.surname}
                      </span>
                    </div>
                    <div className="grid grid-cols-5">
                      <span className="col-span-5 col-start-1 font-semibold sm:col-span-1">Identyfikator:</span>
                      <span className="col-span-5 col-start-1 ml-4 px-5 sm:col-span-4 sm:col-start-2 sm:2xl:ml-0" {...testId('patient-identity')}>
                        {item.patient?.identity}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex justify-between pt-4">
              <div className="flex items-end">
                <Link to="/consultations">
                  <ButtonAnt type="link">Logowanie</ButtonAnt>
                </Link>
              </div>
              <Button color="darkblue" size="md" onClick={showModal}>
                Podpisz
              </Button>
            </div>
          </Card>
          <div className="flex justify-center pt-20 text-gray-500">ver. {process.env.REACT_APP_VERSION}, e-Konsylium</div>
          <Modal
            title={
              <div className="flex flex-row items-center justify-between">
                <div>
                  <h1 className="text-xl font-extrabold uppercase">Podpis konsultacji</h1>
                  <p className=" text-sm text-gray-600">Wprowadź podpis</p>
                </div>

                <div>
                  <div className="mr-8 rounded-md bg-gray-300 py-1 px-2 text-xs text-gray-600" {...testId('id-of-consultation')}>
                    Id: {data?.data?.uuid}
                  </div>
                </div>
              </div>
            }
            footer={[
              <Fragment key="footer">
                <div className="pb-5">
                  <Button onClick={onClose} color="default" size="custom-lg" margin="mr-6">
                    Anuluj
                  </Button>

                  <Button color="darkblue" size="custom-lg" onClick={onFinish}>
                    Podpisz
                  </Button>
                </div>
              </Fragment>,
            ]}
            width={1000}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={onClose}
          >
            <Form onFinish={onFinish} form={form} name="signatureWithoutLogging">
              <ModalApproveWithoutLogging data={data?.data} />
            </Form>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default SignatureWithoutLogging;
