import React, { Fragment, useState, useEffect } from 'react';
import { isArray } from 'lodash';
import { Link } from 'react-router-dom';
import { Disclosure, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { classNames } from '../../utils/classNames';

const Navbar = ({ items, defaultSelectedKeys, logo, placementMenu, rightRender, fixed }) => {
  const [selectedKey, setSelectedKey] = useState(defaultSelectedKeys);
  const handleLinkClick = (route) => {
    setSelectedKey(route);
  };

  useEffect(() => {
    setSelectedKey(defaultSelectedKeys);
  }, [defaultSelectedKeys]);

  return (
    <Disclosure as="nav" className={`bg-darkblue ${fixed ? 'fixed top-0 z-10 mt-0 w-full' : undefined}`}>
      {({ open }) => (
        <>
          <div className="bg-darkblue">
            <div className="mx-auto max-w-screen-2xl px-2 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between space-x-6 md:justify-start md:space-x-0">
                {/* Logo */}
                <div className="inset-y-0 left-0 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? <XMarkIcon className="block h-6 w-6" aria-hidden="true" /> : <Bars3Icon className="block h-6 w-6" aria-hidden="true" />}
                  </Disclosure.Button>
                </div>
                <div className={`flex justify-start lg:w-auto ${placementMenu === 'center' ? 'lg:flex-1' : undefined}`}>
                  {logo && (
                    <div className="flex flex-shrink-0 cursor-pointer items-center">
                      <Link to="/consultations">
                        <img className="block h-6 w-auto lg:hidden" src={logo} alt="E-konsylium" />
                        <img className="hidden h-8 w-auto lg:block" src={logo} alt="E-konsylium" />
                      </Link>
                    </div>
                  )}
                </div>
                {/* Menu */}
                <div className=" hidden space-x-4 md:flex md:pl-6">
                  {items?.map((item) => {
                    const isActive = Boolean(item.key.includes(selectedKey));
                    return (
                      <div>
                        <Link
                          to={item.route}
                          key={item.key}
                          className={`text-smtransition mx-4 w-fit whitespace-nowrap border-b-2 py-2 pt-0.5 duration-200 ease-out lg:text-base ${
                            isActive ? 'border-lightblue text-white' : 'border-transparent text-gray-400'
                          }`}
                          onClick={() => handleLinkClick(item.route)}
                        >
                          {item.title}
                        </Link>
                      </div>
                    );
                  })}
                </div>
                {/* Profile */}
                <div className="flex items-center justify-end md:flex-1 ">{rightRender}</div>
              </div>
            </div>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Disclosure.Panel className="md:hidden">
              <div className=" space-y-1 px-2 pt-2 pb-3">
                {items.map((item) => {
                  const extractedPath = isArray(item.key) ? item.key[0] : item.key;
                  const isActive = Boolean(item.key.includes(selectedKey));
                  return (
                    <Disclosure.Button
                      onClick={() => handleLinkClick(extractedPath)}
                      to={item.route}
                      key={item.key}
                      as={Link}
                      className={classNames(
                        isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'block w-fit rounded-md px-3 py-2 text-sm lg:text-base',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.title}
                    </Disclosure.Button>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
};

Navbar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      key: PropTypes.arrayOf(PropTypes.string),
      route: PropTypes.string,
    }),
  ),
  defaultSelectedKeys: PropTypes.string,
  logo: PropTypes.node,
  placementMenu: PropTypes.oneOf(['center', 'left']),
  rightRender: PropTypes.element,
  fixed: PropTypes.bool,
};

Navbar.defaultProps = {
  items: [],
  defaultSelectedKeys: [],
  logo: null,
  placementMenu: 'center',
  rightRender: null,
  fixed: false,
};

export default Navbar;
