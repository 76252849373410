import ls from 'local-storage';

export default () => {
  const token = ls('token');
  // Basic YWRtaW46YWRtaW4=
  // const token = 'Basic YWRtaW46YWRtaW4=';
  // const token = 'Basic YWRtaW46YWRtaW4=';
  // const cond = 'admin:admin';
  // const encodedToken = Buffer.from(cond).toString('base64');
  // const token = `Basic ${encodedToken}`;

  if (!token) return null;

  return `Bearer ${token}`;
};
