import { isEmpty, isArray } from 'lodash';

import { GetFieldValuesFunction } from '../types';
import { GlobalValidationRule, globalValidators } from './types';

export const performGlobalValidation = (rules: GlobalValidationRule[], getFieldValues: GetFieldValuesFunction): string[] | null => {
  const errors = rules
    // eslint-disable-next-line array-callback-return, consistent-return
    .map((validationRule) => {
      const fieldsToCheck = Object.values(getFieldValues(isArray(validationRule.fields) ? validationRule.fields : [validationRule.fields]));
      const condition = validationRule.condition ?? 'ALL';

      const isValid = globalValidators[validationRule.requirement.type];

      if (condition === 'ALL') {
        // If every field has a value return null otherwise return an error message
        return fieldsToCheck.every(isValid) ? null : validationRule.requirement.message;
      }
      if (condition === 'ONE_OF') {
        // If any of given fields has a value return null otherwise return an error message
        return fieldsToCheck.some(isValid) ? null : validationRule.requirement.message;
      }

      return null;
    })
    .filter(Boolean);

  return isEmpty(errors) ? null : errors;
};
