import React, { createContext, useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useIdle, useTimeoutFn } from 'react-use';

export const AppLogoutContext = createContext(undefined);

export const useActiveUser = () => useContext(AppLogoutContext);

const AppLogoutProvider = ({ timeToLogout, sessionTimeNotActive, children }) => {
  const navigate = useNavigate();
  const isIdle = useIdle(sessionTimeNotActive);
  const logoutAction = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/auth');
  };
  const [, cancel, reset] = useTimeoutFn(() => logoutAction(), timeToLogout);

  useEffect(() => {
    if (isIdle) {
      reset();
    } else {
      cancel();
    }
  }, [isIdle]);

  const AppLogoutContextValues = useCallback(
    () => ({
      isActiveUser: !isIdle,
    }),
    [isIdle],
  );

  // w przyszłości można dać okno modalne

  return <AppLogoutContext.Provider value={AppLogoutContextValues()}>{children}</AppLogoutContext.Provider>;
};

AppLogoutProvider.propTypes = {
  timeToLogout: PropTypes.number,
  sessionTimeNotActive: PropTypes.number,
};

AppLogoutProvider.defaultProps = {
  timeToLogout: 10000,
  sessionTimeNotActive: 5000,
};

export default AppLogoutProvider;
