// eslint-disable-next-line no-unused-vars
import moment from 'moment';

export const validatepesel = (pesel, isGetDate = undefined) => {
  if (pesel === null || pesel === undefined) return false;
  if (pesel.length !== 11) return false;

  let result = false;
  let year = parseInt(pesel.substring(0, 2), 10);
  let month = parseInt(pesel.substring(2, 4), 10);
  const day = parseInt(pesel.substring(4, 6), 10);

  if (month >= 80) {
    year += 1800;
    month -= 80;
  } else if (month >= 60) {
    year += 2200;
    month -= 60;
  } else if (month >= 40) {
    year += 2100;
    month -= 40;
  } else if (month >= 20) {
    year += 2000;
    month -= 20;
  } else {
    year += 1900;
  }
  if (isGetDate) return moment(`${year}-${month}-${day}`);

  if (moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').isValid()) {
    const tabnr = '1379137913';
    let suma = 0;

    if (pesel.length === 11) {
      for (let i = 0; i < tabnr.length; i += 1) {
        suma += parseInt(tabnr.charAt(i), 10) * parseInt(pesel.charAt(i), 10);
      }
      const modu = suma % 10;
      if (modu === 0) {
        result = parseInt(pesel.charAt(10), 10) === 0;
      } else {
        result = 10 - modu === parseInt(pesel.charAt(10), 10);
      }
    } else {
      return false;
    }

    return result;
  }
  return result;
};

type Gender = 'MALE' | 'FEMALE';

export const getGenderFromPesel = (pesel: number): Gender => {
  const genderDigit = parseInt(pesel.toString().charAt(9), 10);
  return genderDigit % 2 === 0 ? 'FEMALE' : 'MALE';
};
