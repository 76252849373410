/* eslint-disable */
import React from 'react';
import Markdown from 'markdown-to-jsx';
import { Modal } from 'antd';
import changelogContent from '@/CHANGELOG.md';
import { Typography } from 'antd';
import { testId } from '@/tests';

const { Title, Link } = Typography;

export const ChangelogModal = ({ onClose, open }) => {
  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      title={
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-xl font-extrabold uppercase">Lista zmian</h1>
        </div>
      }
      className="modal-styles input-styles"
      width={1000}
      open={open}
      onCancel={onClose}
      footer={null}
      {...testId('changelog-modal')}
    >
      <Markdown
        options={{
          overrides: {
            h2: {
              component: Title,
              props: {
                level: 5,
              },
            },
            h3: {
              component: 'strong',
            },
            a: {
              component: Link,
              props: {
                target: '_blank',
              },
            },
            ul: {
              component: 'ul',
              props: {
                style: {
                  listStyle: 'disc',
                  paddingLeft: 32,
                },
              },
            },
          },
        }}
      >
        {changelogContent}
      </Markdown>
    </Modal>
  );
};
