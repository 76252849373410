import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert, Button } from 'antd';

import logoXlSvg from '../assets/images/logo-xl-white.svg';

const NodeNationalErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className=" h-screen overflow-auto bg-gray-800 p-10">
      <div className="flex flex-col items-center justify-center text-white">
        <img className="w-80 pb-10" src={logoXlSvg} alt="Logo E-konsylium" />
        <div className="my-form-wrapper w-[500px] rounded-md  p-5">
          <h1 className="pb-10 text-center text-3xl font-medium text-gray-100">Logowanie</h1>
          <Alert message="Błąd" description="Węzeł krajowy - problem z autoryzacją." type="error" showIcon className="mx-auto mb-5 w-full" />
        </div>
        <Button onClick={() => navigate('/auth')} type="primary">
          Powrót do logowania
        </Button>
      </div>
    </div>
  );
};

NodeNationalErrorPage.propTypes = {};

export default NodeNationalErrorPage;
