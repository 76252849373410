import React, { FC } from 'react';
import { testId as testAttribute } from '@/tests';
import { GreenPointProps } from '@/components/GreenPoint/types';

const GreenPoint: FC<GreenPointProps> = ({ isActive, testId }) => {
  if (isActive) return <div className="h-3 w-3 rounded-full border-green-700 bg-green-500" {...testAttribute(`active-${testId}`)} />;

  return <div className="h-3 w-3 rounded-full border-green-700 bg-gray-500" {...testAttribute(`inactive-${testId}`)} />;
};

export default GreenPoint;
