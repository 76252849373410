import React from 'react';
import classNames from 'classnames';
import { Checkbox, Form } from 'antd';

import { testIdFactory } from '@/tests';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { CheckboxField } from '../../types';
import { getValidationRules } from '../service';

export const CheckboxFormField: FormFieldComponent<CheckboxField> = (props) => {
  const { name, label, description, variant = 'normal', isDisabled, isHidden, isRequired, isNecessary, testId } = props;

  if (isHidden) {
    return null;
  }

  const validations = getValidationRules(props);

  const checkBoxFieldClasses = classNames('mb-0', { 'header-form-checkbox': variant === 'header' }, { 'py-1': variant !== 'header' }, { 'pt-0': variant === 'no-data' });
  const checkBoxLabelClasses = classNames('max-h-max', { 'font-semibold': ['light', 'normal'].includes(variant) }, { 'text-slate-600': variant === 'no-data' });

  const testAttribute = testIdFactory(testId);

  return (
    <Form.Item name={name} valuePropName="checked" className={checkBoxFieldClasses} rules={validations} required={isRequired || isNecessary}>
      <Checkbox className={checkBoxLabelClasses} disabled={isDisabled} {...testAttribute(name)}>
        <FormFieldLabel label={label} description={description} required={isRequired || isNecessary} showRequiredMark />
      </Checkbox>
    </Form.Item>
  );
};
