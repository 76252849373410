import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { useMutation } from 'react-query';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const BtnWithRequest = ({ titleBtn, titleMessage, content, okText, cancelText, request, errorMessage, successMessage, onSuccess, className, type, disabled, form }) => {
  const [open, setOpen] = useState(false);
  const mutation = useMutation(request, {
    onError: () => {
      console.log(mutation.error);
      message.error(errorMessage);
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
      message.success(successMessage);
      setOpen(false);
      if(form) form.resetFields();
    },
  });

  const handleOk = () => {
    // console.log(onValidate());

    if (form) {
      form.validateFields().then(() => {
        mutation.mutate();
      });
    } else {
      mutation.mutate();
    }
  };

  const showConfirm = () => {
    setOpen(true);
  };

  const title = (
    <div className="flex items-center gap-4">
      <ExclamationCircleOutlined />
      <span>{titleMessage}</span>
    </div>
  );

  return (
    <>
      <button
        onClick={showConfirm}
        size="large"
        type="button"
        className={`rounded py-1 px-2 ${className}  ${type === 'button' && !disabled && 'bg-blue-antd-500 text-white'} ${disabled && 'cursor-not-allowed'} ${
          disabled && type === 'button' && 'bg-gray-300 text-gray-500'
        } `}
        disabled={disabled}
      >
        {titleBtn}
      </button>
      <Modal
        title={title}
        open={open}
        onOk={handleOk}
        onCancel={() => setOpen(false)}
        okText={okText}
        cancelText={cancelText}
        okButtonProps={{ className: 'bg-blue-700', loading: mutation.isLoading }}
        loading={mutation.isLoading}
        afterClose={() => form && form.resetFields()}
      >
        {content}
      </Modal>
    </>
  );
};

BtnWithRequest.propTypes = {
  titleBtn: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'text']),
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
};

BtnWithRequest.defaultProps = {
  titleBtn: null,
  content: null,
  okText: null,
  cancelText: null,
  errorMessage: null,
  successMessage: null,
  className: null,
  type: 'button',
  onSuccess: () => null,
  disabled: false,
};

export default BtnWithRequest;
