export const redirectToAuthService = (authRequest: string) => {
  console.log('Try to prepare form with auth request');
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = process.env.REACT_APP_NATIONAL_NODE_URL;
  // form.action = 'https://int.login.gov.pl/login/SingleSignOnService '

  const hiddenInput = document.createElement('input');
  hiddenInput.type = 'hidden';
  hiddenInput.name = 'SAMLRequest';
  hiddenInput.setAttribute('value', authRequest);
  form.appendChild(hiddenInput);

  document.body.append(form);

  console.log('Submitting form');
  form.submit();
};
