import React from 'react';
import { Alert, Button, Col, Empty } from 'antd';
import { TiInfo } from 'react-icons/ti';
// import { cardiologyConsultationResult as consultationResultTemplate } from '@/fakes/consultationResultTemplates';
// import { pediatricConsultationResult as consultationResultTemplate } from '@/fakes/consultationResultTemplates';
import { testId as testAttribute } from '@/tests';
import Card from '../Card/Card.component';
import CardHeader from '../CardHeader/CardHeader.component';

import { ResultRenderer } from '../TemplateEngine/ResultRenderer';

const ConsultationResultDetails = ({ data, status, onEdit, onDisplayPDFClicked, showEditButton, showDisplayPDFButton }) => {
  const { teleconsultation } = status;

  const templateValues = data.consultationResult;
  const template = data.templateConsultationResult; // backend
  // const template = consultationResultTemplate; // local fakes

  const isConsultationResultHidden = ['NOT_ORDERED'].some((state) => state === teleconsultation?.state);

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Card
        title={
          <CardHeader
            title="Wynik konsultacji"
            onEdit={onEdit}
            isVisibleEditBtn={showEditButton}
            testId="consultation-result"
            addons={
              showDisplayPDFButton && (
                <Button onClick={onDisplayPDFClicked} type="link" {...testAttribute(`card-header-show-hl7-button`)}>
                  pokaż wynik w formacie HL7 CDA
                </Button>
              )
            }
          />
        }
      >
        {isConsultationResultHidden ? (
          <div className="flex-col">
            <Alert className="mx-auto max-w-md" description="W celu wyświetlenia wyniku konsultacji należy zlecić konsultację." type="info" showIcon icon={<TiInfo />} />
          </div>
        ) : (
          <div>{!template || templateValues === null ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> : <ResultRenderer template={template} values={templateValues} />}</div>
        )}
      </Card>
    </Col>
  );
};

export default ConsultationResultDetails;
