import React from 'react';

import { testIdFactory } from '@/tests';
import { DisplayFieldComponent } from '../types';
import { NumericField, CalculatedField } from '../../types';
import { FieldValue } from '../components';
import { DisplayFieldLabel } from '../../components';
import { decimalFormatter } from '../../service';

export const NumberDisplayField: DisplayFieldComponent<NumericField | CalculatedField> = ({
  name,
  testId,
  label,
  isHidden,
  suffix,
  value,
  isNecessary,
  tooltip,
  precision,
  fieldHasNoData,
}) => {
  if (isHidden) {
    return null;
  }

  const testAttribute = testIdFactory(testId);

  return (
    <div className="dynamic-field-result flex flex-col rounded bg-white p-2">
      <DisplayFieldLabel label={label} suffix={suffix} tooltip={tooltip} />
      <div className="flex break-all p-2 align-middle text-gray-600">
        <FieldValue name={name} testId={testId} value={value} isNecessary={isNecessary} fieldHasNoData={fieldHasNoData}>
          {decimalFormatter(precision)(value)}
          {!label && suffix && (
            <span className="break-words font-medium text-black" {...testAttribute(`${name}-suffix`)}>
              &nbsp;[{suffix}]
            </span>
          )}
        </FieldValue>
      </div>
    </div>
  );
};
