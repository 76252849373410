/* eslint-disable */
import http from '../utils/http';
import withAccessToken from '../utils/withAccessToken';

export const zoomSignature = (requestBody) => () => http.post(process.env.REACT_APP_ZOOM_SIGNATURE_ENDPOINT, requestBody, withAccessToken()).then((response) => response);

export const getSignature = (meetingNumber) => () => http.get(`/zoom/generateSignatureToken/by-zoom-id?zoomId=${meetingNumber}`, withAccessToken()).then((response) => response);

const ZoomService = {
  zoomSignature,
  getSignature,
};

export default ZoomService;
