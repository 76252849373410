import React from 'react';
import { Form } from 'antd';
import FilesUpload from './FilesUpload.component';

const Files = () => (
  <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
    <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
      <div>
        <h1 className="text-lg font-semibold">Wyniki badań</h1>
        <p className="text-gray-500">Badania laboratoryjne PDF, DICOM, pliki graficzne</p>
      </div>
    </div>
    <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
      <Form.Item label="Zalecane wyniki badań laboratoryjnych [PDF, pliki graficzne, pozostałe]" name="otherFiles">
        <FilesUpload accept=".pdf, .txt, .jpeg, .jpg, .png, .mp4, .avi, .wmv" type="others" />
      </Form.Item>
      <Form.Item
        label={
          <span>
            Zalecane wyniki badań obrazowych <span className="font-bold">DICOM</span>
          </span>
        }
        name="dicomFiles"
      >
        <FilesUpload type="dicom" data="dataDicom" />
      </Form.Item>
    </div>
  </div>
);

export default Files;
