import { Col, Row, Skeleton, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import { TemplateEngineContextProvider } from '@/components/TemplateEngine';
import { testId } from '@/tests';
import { InviteToConsultationModal } from '@/components/Modals/Consultation';
import { ConsultationResultPDFModal } from '@/components/Modals/ConsultationResultPDFModal.component';
import { useConsultationActions } from '@/utils/useConsultationActions';
import { TbRefresh } from 'react-icons/tb';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb.component';
import Button from '../components/Button2/Button.component';
import { getConsultationsById, getReferralsById, getStatusById } from '../services/consultations.service';
import ApprovedBy from '../components/ConsultationDetails/ApprovedByDetails.component';
import ConsultantDetails from '../components/ConsultationDetails/ConsultantDetails.component';
import ConsultationDateDetails from '../components/ConsultationDetails/ConsultationDateDetails.component';
import ConsultationResultDetails from '../components/ConsultationDetails/ConsultationResultDetails.component';
import DoctorDetails from '../components/ConsultationDetails/DoctorDetails.component';
import FilesDetails from '../components/ConsultationDetails/FileDetails/FilesDetails.component';
import PatientDetails from '../components/ConsultationDetails/PatientDetails.component';
import QualificationCardDetails from '../components/ConsultationDetails/QualificationCardDetails.component';
import StatusDetails from '../components/ConsultationDetails/StatusDetails.component';
import ZoomConsultation from '../components/ConsultationDetails/ZoomConsultation.component';
import AddConsultationModalComponent from '../components/Modals/AddConsultationModal.component';
import ReferralDetails from '../components/ConsultationDetails/ReferralDetails.component';
import AuthContext from '../contexts/Auth.context';
import { useGetQuery } from '../hooks/useGetQuery.hook';
import { generateMeddreamToken } from '../services/files.service';
import DropDownAction from '../components/DropDownAction/DropDownAction.component';
import ConsultationCorrection from '../components/Correction/ConsultationCorrection.component';
import ConsultationSupplement from '../components/Supplement/ConsultationSupplement.component';
import BtnWithRequestStartCallModal from '../components/BtnWithRequest/BtnWithRequestStartCallModal.component';
import { onJoinMeetingClick } from '../utils/onJoinMeetingClick';

const ConsultationDetailsPage = () => {
  const [activeItem, setActiveItem] = useState();
  const [open, setOpen] = useState(false);
  const [isInvitationModalOpen, setInvitationModalOpen] = useState(false);
  const [mode, setMode] = useState('edit');
  const [pdfResultModalMode, setPdfResultModalMode] = useState('CLOSED');
  const [openCallConfirm, setOpenCallConfirm] = useState(false);

  const [current, setCurrent] = useState();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    isLoading,
    isError,
    data: response,
    refetch: refetchConsultation,
  } = useQuery(['consultation-id', id], getConsultationsById(id), {
    onError: (err) => {
      if (err.response?.status === 403) {
        message.error(err.response?.data?.message);
        navigate(`/consultations`);
      }
    },
  });
  const { data: dataStatus, isLoading: isLoadingStatus, isFetching: isFetchingStatus, refetch: refetchConsultationStatus } = useQuery(['status/history', id], getStatusById(id));
  // Referrals
  const { data: dataReferrals, isLoading: isLoadingReferrals, isFetching: isFetchingReferrals } = useQuery(['referrals', id], getReferralsById(id));
  const data = response ? response.data : undefined;
  const { unit, user } = useContext(AuthContext);

  const userName = `${user.name} ${user.lastname}`;
  const url = `/zoom/${data?.zoomMeeting}/${id}/${userName}`;

  const { canAct } = useConsultationActions({
    hasDeadline: Boolean(data?.deadline?.consultationDate),
    consultationStatus: dataStatus?.data?.teleconsultation?.state,
    documentationStatus: dataStatus?.data?.documentation?.state,
    consultationMode: dataStatus?.data?.teleconsultation?.mode,
    usersData: {
      doctorId: data?.doctor?.doctorId,
      consultantList: data?.consultant?.consultantList,
      userDoctorId: user.doctorId,
    },
  });

  const { data: token } = useQuery(['meddream-token', id, user.doctorId], generateMeddreamToken(id, user.doctorId));

  const isCurrentUserRequestingDoctor =
    data?.consultant?.consultantList !== (null && undefined) && !(data?.consultant?.consultantList.find((item) => item.consultantId === user.doctorId) !== (null || undefined));

  const isCurrentUserMatchingDoctor = data?.doctor.doctorId === user.doctorId;

  const canCurrentUserSignConsultation = () => {
    if (!data?.consultant?.consultantApprovedList) return false;

    const currentUserToApprove = data?.consultant?.consultantApprovedList.find((item) => item.consultantId === user.doctorId);

    if (!currentUserToApprove) return false;
    if (currentUserToApprove.consultantApproved) return false;
    if (!currentUserToApprove.consultantRequired) return false;

    return canAct('SIGN_CONSULTATION_RESULT');
  };

  const onClose = () => {
    setOpen(false);
    setActiveItem({});
  };

  const onEdit = (newCurrent, newMode, item) => () => {
    setCurrent(newCurrent);
    setOpen(true);
    if (newMode) setMode(newMode);
    if (item) setActiveItem(item);
  };

  const showComponent = () => {
    switch (current) {
      case 'patient':
        return ['patient'];
      case 'consultant':
        return ['consultant'];
      case 'referral':
        return ['referral'];
      case 'files':
        return ['files'];
      case 'qualification_card':
        return ['qualification_card'];
      case 'approved_by':
        return ['approved_by'];
      case 'consultation_result':
        return ['consultation_result'];
      case 'approved_by_signin':
        return ['approved_by_signin'];
      default:
        return ['doctor'];
    }
  };

  const breadCrumbData = [
    {
      title: 'Lista konsultacji',
      link: '/consultations',
    },
    {
      title: 'Dane podstawowe',
      link: null,
    },
    {
      title: `ID: ${id}`,
      link: null,
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { queryList } = useGetQuery();

  useEffect(() => {
    if (
      _.has(dataStatus, 'data') &&
      dataStatus.data &&
      data &&
      queryList.some((item) => item.key === 'signature' && item.value === 'true') &&
      data.consultant.consultantApprovedList &&
      data.consultant.consultantApprovedList.find((item) => item.consultantId === user.doctorId) &&
      data.consultant.consultantApprovedList.find((item) => item.consultantId === user.doctorId).consultantRequired &&
      !data.consultant.consultantApprovedList.find((item) => item.consultantId === user.doctorId).consultantApproved &&
      !(dataStatus?.data?.teleconsultation?.state === 'IN_CORRECTION')
    ) {
      onEdit('approved_by_signin')();
    }

    if (data && queryList.some((item) => item.key === 'consultantedit' && item.value === 'true')) {
      onEdit('consultant')();
    }
    if (data && queryList.some((item) => item.key === 'call' && item.value === 'true')) {
      onJoinMeetingClick(userName, url);
    }
  }, [data]);

  const handleSuccessfulInvitation = () => {
    refetchConsultation();
    refetchConsultationStatus();
  };

  const handleSuccessRequest = (value) => {
    if (value === 'approved_by_signin') setPdfResultModalMode('CLOSED');
  };

  if (isLoading) {
    return <Skeleton />;
  }

  if (isError) {
    return <div className="mx-auto">Problem z pobraniem danych</div>;
  }

  const refreshPage = async () => {
    await Promise.all(queryClient.refetchQueries(['consultation-id', id]), queryClient.refetchQueries(['status/history', id]), queryClient.refetchQueries(['referrals', id]));
  };

  return (
    <div>
      <div className="mb-2 flex justify-between ">
        <div className="flex flex-row">
          <Breadcrumb className="mb-1" data={breadCrumbData} />
        </div>
        <div className="flex gap-2">
          <div className="flex items-center">
            <Button color="darkblue" size="sm" onClick={refreshPage} icon={<TbRefresh className="h-5 w-4 text-white" />}>
              Odśwież
            </Button>
          </div>
          <div>
            {canAct('ORDER_CONSULTATION') && dataStatus?.data.teleconsultation.state === 'NOT_ORDERED' && (
              <Button color="darkblue" size="sm" onClick={onEdit('consultant', 'edit')} {...testId('order-consultation-button')}>
                Zleć konsultację
              </Button>
            )}
            {data?.consultant?.consultantList && !isCurrentUserRequestingDoctor && (
              <ConsultationCorrection idConsultation={id} onEdit={onEdit('approved_by')} status={_.has(dataStatus, 'data') && dataStatus.data} user={user} />
            )}
            {data?.consultant?.consultantList && isCurrentUserMatchingDoctor && (
              <ConsultationSupplement idConsultation={id} status={_.has(dataStatus, 'data') && dataStatus.data} user={user} />
            )}
          </div>
          <div>
            <DropDownAction
              idConsultation={id}
              consultationState={dataStatus?.data.teleconsultation.state}
              documentationState={dataStatus?.data.documentation.state}
              consultationMode={dataStatus?.data.teleconsultation.mode}
              consultantList={data?.consultant?.consultantList}
              doctorId={data?.doctor.doctorId}
              deadline={data?.deadline.consultationDateFrom}
              onInviteToConsultationClicked={() => setInvitationModalOpen(true)}
              data={data}
            />
          </div>
        </div>
      </div>
      <TemplateEngineContextProvider>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={18}>
            <Row gutter={[16, 16]}>
              <ConsultationDateDetails
                data={data}
                isCurrentUserMatchingDoctor={isCurrentUserMatchingDoctor}
                status={_.has(dataStatus, 'data') && dataStatus.data}
                onEdit={onEdit('consultant', 'edit')}
                showEditButton={canAct('ORDER_CONSULTATION')}
              />
              <ConsultantDetails
                data={data}
                isCurrentUserMatchingDoctor={isCurrentUserMatchingDoctor}
                status={_.has(dataStatus, 'data') && dataStatus.data}
                onEdit={onEdit('consultant', 'edit')}
                showEditButton={canAct('ORDER_CONSULTATION')}
              />
              <DoctorDetails data={data} />
              <PatientDetails data={data} status={_.has(dataStatus, 'data') && dataStatus.data} onEdit={onEdit('patient', 'edit')} showEditButton={canAct('EDIT_PATIENT')} />
              <ReferralDetails data={dataReferrals?.data.referrals || []} onEdit={onEdit} showActionBtn={canAct('ADD_REFERRAL')} />

              <QualificationCardDetails
                status={_.has(dataStatus, 'data') && dataStatus.data}
                data={data}
                onEdit={onEdit('qualification_card', 'edit')}
                showEditButton={canAct('EDIT_QUALIFICATION_CARD')}
              />
              <FilesDetails
                data={data}
                status={_.has(dataStatus, 'data') && dataStatus.data}
                onEdit={onEdit('files', 'edit')}
                token={token}
                showEditButton={canAct('EDIT_FILES')}
              />
              <ConsultationResultDetails
                status={_.has(dataStatus, 'data') && dataStatus.data}
                data={data}
                onEdit={onEdit('consultation_result', 'edit')}
                showEditButton={canAct('EDIT_CONSULTATION_RESULT')}
                showDisplayPDFButton={canAct('DISPLAY_RESULT_PDF')}
                onDisplayPDFClicked={() => setPdfResultModalMode('PREVIEW')}
              />
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={6}>
            <Row gutter={[16, 16]}>
              <StatusDetails data={_.has(dataStatus, 'data') && dataStatus.data} isLoading={isLoadingStatus} isFetching={isFetchingStatus} />
              <ApprovedBy
                data={data}
                status={_.has(dataStatus, 'data') && dataStatus.data}
                onEdit={onEdit}
                canAddDoctors={canAct('SELECT_DOCTORS_TO_SIGN') && data?.cons}
                canSign={canAct('SIGN_CONSULTATION_RESULT')}
                onSignClick={() => {
                  setPdfResultModalMode('SIGN');
                }}
              />
              <ZoomConsultation data={data} />
            </Row>
          </Col>
        </Row>
        <BtnWithRequestStartCallModal data={data} openCallConfirm={openCallConfirm} setOpenCallConfirm={setOpenCallConfirm} id={id} />
        <AddConsultationModalComponent
          open={open}
          onClose={onClose}
          show={showComponent()}
          onEditApprovedBy={onEdit('approved_by', 'edit')}
          mode={mode}
          uuid={id}
          activeItem={activeItem}
          current={current}
          data={{ ...data, ...activeItem }}
          status={_.has(dataStatus, 'data') && dataStatus.data}
          onSuccessRequest={handleSuccessRequest}
        />
        {canAct('INVITE_TO_CONSULTATION') && (
          <InviteToConsultationModal
            open={isInvitationModalOpen}
            onClose={() => setInvitationModalOpen(false)}
            consultationId={id}
            unitId={unit.id}
            doctorOrderingId={data.doctor.doctorId}
            consultantList={data.consultant.consultantList}
            onSuccessfulInvitation={handleSuccessfulInvitation}
          />
        )}
        <ConsultationResultPDFModal
          mode={pdfResultModalMode}
          onClose={() => setPdfResultModalMode('CLOSED')}
          pdfUrl={data.summaryPdfFileUrl}
          consultationId={id}
          canSign={canCurrentUserSignConsultation()}
          onSignClick={onEdit('approved_by_signin')}
        />
      </TemplateEngineContextProvider>
    </div>
  );
};

ConsultationDetailsPage.propTypes = {};

export default ConsultationDetailsPage;
