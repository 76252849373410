import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { testId } from '@/tests';

const Button = ({ color, size, children, rounded, outline, block, onClick, loading, disabled, icon, submit, margin, className, ...props }) => {
  const defaultClasses = 'justify-center rounded-md inline-flex gap-1 items-center border shadow-sm transition duration-200 ease-in-out focus:outline-none ';
  const classes = classnames(
    defaultClasses,
    className,
    {
      'text-xs px-3 py-1.5': size === 'xs',
      'text-sm px-4 py-2': size === 'sm',
      'text-base px-5 py-2.5': size === 'md',
      'text-base px-5 py-3': size === 'lg',
      'text-lg px-5 py-3.5': size === 'xl',
      'text-base px-9 py-3': size === 'custom-lg',
    },
    {
      'text-gray-700 border-gray-300 bg-white hover:bg-gray-50 active:bg-gray-100': color === 'default' && !disabled,
      'text-white border-gray-800 bg-gray-800 hover:bg-gray-900 active:bg-gray-700': color === 'dark' && !disabled,
      'text-white border-green-700 bg-green-700 hover:bg-green-800 active:bg-green-900': color === 'green' && !disabled,
      'text-white border-emerald-400 bg-emerald-400 hover:bg-emerald-500 active:bg-emerald-600': color === 'greenlight' && !disabled,
      'text-white border-red-700 bg-red-700 hover:bg-red-800 active:bg-red-900': color === 'red' && !disabled,
      'text-white border-yellow-400 bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-600': color === 'yellow' && !disabled,
      'text-white border-purple-700 bg-purple-700 hover:bg-purple-800 active:bg-purple-900': color === 'purple' && !disabled,
      'text-white border-darkblue hover:border-lightblue bg-darkblue hover:bg-lightblue active:bg-lightblue': color === 'darkblue' && !disabled,
    },
    { 'text-gray-400 border-gray-300 bg-gray-200 cursor-default': disabled },
    {
      'mr-6': margin === 'mr-6',
    },
    {
      // 'rounded-md': !rounded,
      'rounded-full': rounded,
    },
    {
      'bg-transparent': outline && color === 'default',
      'bg-transparent text-gray-800 hover:text-white': outline && color === 'dark',
      'bg-transparent text-green-700 hover:text-white': outline && color === 'green',
      'bg-transparent text-emerald-500 hover:text-white': outline && color === 'greenlight',
      'bg-transparent text-red-700 hover:text-white': outline && !disabled && color === 'red',
      'bg-transparent text-yellow-400 hover:text-white': outline && color === 'yellow',
      'bg-transparent text-purple-700 hover:text-white': outline && color === 'purple',
      'bg-transparent text-darkblue hover:text-white': outline && color === 'darkblue',
    },
    {
      'w-full': block,
    },
  );
  return (
    <button {...testId('button-component')} className={classes} type={submit ? 'submit' : 'button'} onClick={!disabled ? onClick : () => null} {...props}>
      {loading && (
        <svg className="-ml-1 mr-2 h-4 w-4 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle className="stroke-gray-400 opacity-25" cx="12" cy="12" r="10" strokeWidth="4" />
          <path
            className="fill-gray-800 opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      )}
      {icon}
      {children}
    </button>
  );
};

Button.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'custom-lg']),
  color: PropTypes.oneOf(['default', 'dark', 'blue', 'green', 'red', 'yellow', 'purple', 'greenlight', 'darkblue']),
  rounded: PropTypes.bool,
  outline: PropTypes.bool,
  block: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  submit: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  icon: PropTypes.node,
};

Button.defaultProps = {
  size: 'sm',
  children: null,
  color: 'default',
  rounded: false,
  outline: false,
  block: false,
  onClick: () => null,
  loading: false,
  disabled: false,
  submit: false,
  icon: null,
};

export default Button;
