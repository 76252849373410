import React, { FC } from 'react';
import { FieldTooltip as FieldTooltipType } from '../../types';
import { FieldTooltip } from '../FieldTooltip';

interface DisplayFieldLabelProps {
  className?: string;
  label: string;
  suffix?: string;
  tooltip?: FieldTooltipType;
}

export const DisplayFieldLabel: FC<DisplayFieldLabelProps> = ({ label, className, tooltip, suffix }) => {
  if (!label) {
    return null;
  }
  return (
    <div style={{ width: '100%' }} className={`flex flex-row ${className} justify-between`}>
      <span className={`break-words font-medium text-black ${className}`}>
        {label} {suffix ? ` [${suffix}]` : ''}
      </span>
      {tooltip && tooltip.type === 'text' && <FieldTooltip fieldTooltip={tooltip} />}
    </div>
  );
};
