import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Radio, Form } from 'antd';

import { testIdFactory } from '@/tests';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { RadioField as BaseRadioField } from '../../types';
import { getValidationRules } from '../service';

interface RadioField extends BaseRadioField {
  isRelatedRadioSelected?: boolean;
}

export const RadioFormField: FormFieldComponent<RadioField> = (props) => {
  const { name, label, description, isDisabled, isHidden, isRequired, isNecessary, isRelatedRadioSelected, testId } = props;

  const { setFieldValue } = Form.useFormInstance();

  if (isHidden) {
    return null;
  }

  useEffect(() => {
    if (isRelatedRadioSelected) {
      setFieldValue(name, false);
    }
  }, [isRelatedRadioSelected]);

  const validations = getValidationRules(props);

  const radioFieldClasses = classNames('mb-0 py-1');

  const testAttribute = testIdFactory(testId);

  return (
    <Form.Item name={name} valuePropName="checked" className={radioFieldClasses} rules={validations} required={isRequired || isNecessary}>
      <Radio className="max-h-max font-semibold" disabled={isDisabled} {...testAttribute(name)}>
        <FormFieldLabel label={label} description={description} required={isRequired || isNecessary} showRequiredMark />
      </Radio>
    </Form.Item>
  );
};
