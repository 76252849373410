import React, { FC } from 'react';
import { Button, Popover } from 'antd';

import { LinkOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FieldTooltip as FieldTooltipType } from '../../types';

const ICON_COLOR = '#758398';

interface FieldTooltipProps {
  fieldTooltip: FieldTooltipType;
}

export const FieldTooltip: FC<FieldTooltipProps> = ({ fieldTooltip }) => {
  const { type, content } = fieldTooltip;

  if (type === 'link') {
    return (
      <Popover content={content}>
        <a href={fieldTooltip.url} target="_blank" rel="noreferrer">
          <Button type="text" style={{ color: ICON_COLOR, cursor: 'pointer' }} icon={<LinkOutlined />} size="small" />
        </a>
      </Popover>
    );
  }

  return (
    <Popover content={content}>
      <Button type="text" style={{ color: ICON_COLOR, cursor: 'default' }} icon={<QuestionCircleOutlined />} size="small" />
    </Popover>
  );

  return null;
};
