import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { getRedirectionParams } from '@/core/Redirection';
import './assets/style/app.less';
import App from './App';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      console.log(error);
      console.log(query);
      if (
        query.queryKey !== 'verify' &&
        error.response?.status === 401 &&
        window.location.pathname !== '/auth' &&
        window.location.pathname !== '/signature' &&
        window.location.pathname !== '/reset'
      ) {
        const { pathname, search } = window.location;

        const redirectionParams = getRedirectionParams(pathname, search);
        if (redirectionParams) {
          window.location.assign(`/auth?${redirectionParams.toString()}`);
        } else {
          window.location.assign(`/auth`);
        }
        localStorage.removeItem('token');
      }
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      //     refetchOnmount: true,
      //     refetchOnReconnect: false,
      retry: false,
      //     staleTime: 5 * 60 * 1000,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
