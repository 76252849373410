import './styles.less';
import 'react-phone-input-2/lib/style.css';

import React, { FC } from 'react';
import { Form } from 'antd';
import ExternalPhoneInput, { CountryData } from 'react-phone-input-2';
import pl from 'react-phone-input-2/lang/pl.json';
import { testId } from '@/tests';

export interface PhoneInputProps {
  value?: string;
  onChange?: (value: string) => void;
}

export const PhoneInput: FC<PhoneInputProps> = ({ value, onChange }) => (
  <ExternalPhoneInput
    containerClass="eka-phone-input w-28"
    country="pl"
    preferredCountries={['pl', 'de', 'gb']}
    localization={pl}
    value={value}
    onChange={onChange}
    {...testId('phone-input')}
  />
);

export type PhoneInputDetailedValue = {
  prefix: string;
  number: string;
  maxNumberOfCharacter: number;
};
export interface PhoneInputDetailedProps {
  value?: PhoneInputDetailedValue;
  onChange?: (value: PhoneInputDetailedValue) => void;
}

export const PhoneInputDetailed: FC<PhoneInputDetailedProps> = ({ value, onChange }) => {
  const { status } = Form.Item.useStatus();
  return (
    <ExternalPhoneInput
      containerClass="eka-phone-input !w-28"
      inputClass={status === 'error' && 'border-red-500 border-1'}
      buttonClass={status === 'error' && 'border-red-500 border-1'}
      country="pl"
      preferredCountries={['pl', 'de', 'gb']}
      localization={pl}
      value={`${value?.prefix || ''}${value?.number || ''}`}
      onChange={(v, data: CountryData) => {
        const prefix = 'dialCode' in data ? data.dialCode : '';
        const maxNumberOfCharacter = data.format.split('.').length - prefix.length - 1;
        onChange?.({
          prefix,
          number: v.startsWith(prefix) ? v.slice(prefix.length) : v,
          maxNumberOfCharacter,
        });
      }}
      {...testId('phone-input-detailed')}
    />
  );
};
