import { inviteToConsultation } from '@/services/consultations.service';

type PhoneNumber = {
  number: number;
  prefix: number;
};

type InvitationPhone = {
  prefix: number;
  number: number;
};

type InviteToConsultationCommonParams = {
  documentationId: string;
  consultationZone?: string;
  invitorUnitId: number;
  nationalNumber?: number;
  countryCode?: number;
};

type InviteToConsultationByEmailParams = {
  email: string;
  phoneNumber: PhoneNumber;
} & InviteToConsultationCommonParams;

type InviteToConsultationByListParams = {
  doctorIds: number[];
} & InviteToConsultationCommonParams;

export interface Data {
  invitationEmail: string;
  invitationPhone: InvitationPhone;
}

export const inviteDoctorToConsultation = (params: InviteToConsultationByEmailParams | InviteToConsultationByListParams) =>
  inviteToConsultation(params).then((response) => response.data);

// export const inviteDoctorToConsultation = (params: InviteToConsultationByEmailParams | InviteToConsultationByListParams) =>
//   new Promise<void>((resolve) => {
//     setTimeout(() => {
//       resolve();
//     }, 2000);
//   });
