import React from 'react';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import plPL from 'antd/lib/locale/pl_PL';
import Router from './router';
import AuthContext from './contexts/Auth.context';
import ZoomContext from './contexts/Zoom.context';
// eslint-disable-next-line no-unused-vars
import './contexts/Message.context';
import { useAuth } from './hooks/useAuth.hook';
import { useZoom } from './hooks/useZoom.hook';

import 'moment/locale/pl';

moment.locale('pl');

const App = () => {
  const authState = useAuth();
  const zoomState = useZoom({});

  return (
    <AuthContext.Provider value={authState}>
      <ZoomContext.Provider value={zoomState}>
        <ConfigProvider locale={plPL}>
          <Router />
        </ConfigProvider>
      </ZoomContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
