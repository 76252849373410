import React, { FC, ReactNode } from 'react';
import { FieldTooltip as FieldTooltipType } from '../../types';
import { FieldTooltip } from '../FieldTooltip';

interface FieldLabelProps {
  className?: string;
  label?: string;
  description?: string;
  showRequiredMark?: boolean;
  required?: boolean;
  fieldTooltip?: FieldTooltipType;
  addons?: ReactNode;
}

export const FormFieldLabel: FC<FieldLabelProps> = ({ label, className, required, showRequiredMark, description, fieldTooltip, addons }) =>
  label ? (
    <div style={{ width: '100%' }} className={`flex flex-row ${className} items-center justify-between`}>
      <div className="flex flex-row">
        {required && showRequiredMark ? <span className="custom-required pr-1">*</span> : null}
        {label}
        <span className="pl-2 font-extralight">{description}</span>
      </div>
      {fieldTooltip && <FieldTooltip fieldTooltip={fieldTooltip} />}
      {addons && addons}
    </div>
  ) : null;
