import classNames from 'classnames';
import React from 'react';

import { testIdFactory } from '@/tests';
import { DisplayFieldComponent } from '../types';
import { RadioField } from '../../types';

export const RadioDisplayField: DisplayFieldComponent<RadioField> = ({ className, label, isHidden, testId, name }) => {
  if (isHidden) {
    return null;
  }

  const testAttribute = testIdFactory(testId);

  const labelClasses = classNames('break-words text-black mt-0');

  return (
    <div className={className}>
      <div className={labelClasses} {...testAttribute(`${name}-option`)}>
        {label}
      </div>
    </div>
  );
};
