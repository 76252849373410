import { isArray } from 'lodash';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PAGE_PARAMS_SCOPE, ParamsConfig } from './types';
import { usePageParamsContext } from './PageParamsContext';
import { cleanParamsObject } from './service';

export const usePageParams = (scope: PAGE_PARAMS_SCOPE, config: ParamsConfig) => {
  const { getStoredPageParams, updateStoredValues } = usePageParamsContext();
  const [, setSearchParams] = useSearchParams();

  const searchParams = getStoredPageParams(scope);

  const serializers = Object.values(config).map((value) => value[0]);
  const deserializers = Object.entries(config).map(([key, value]) => ({ name: key, deserializer: value[1] }));

  const queryParams = useMemo(() => {
    const searchParamsObject = Array.from(searchParams.entries()).reduce((result, entry) => {
      const currentSearchParams = result;
      const [key, value] = entry;

      // If key already exists
      if (currentSearchParams[key]) {
        // If query params object is already an Array - add to it
        if (isArray(currentSearchParams[key])) {
          currentSearchParams[key].push(value);
          return currentSearchParams;
        }
        // If not - create a new array
        currentSearchParams[key] = [currentSearchParams[key], value];
      } else {
        currentSearchParams[key] = value;
      }
      return currentSearchParams;
    }, {});

    return Object.fromEntries(deserializers.map(({ name, deserializer }) => [name, deserializer(searchParamsObject)]));
  }, [searchParams, config]);

  const setQueryParams = (params) => {
    const cleanedParams = serializers.reduce((rawParams, serializer) => ({ ...rawParams, ...cleanParamsObject(serializer(params)) }), {});
    setSearchParams(cleanedParams, { replace: true });
  };

  const clearQueryParams = () => {
    setSearchParams({}, { replace: true });
    updateStoredValues(scope, new URLSearchParams());
  };

  return { queryParams, setQueryParams, clearQueryParams };
};
