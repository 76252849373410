import { testId } from '@/tests';

import { Checkbox, Col, Input, Row, Space, Spin } from 'antd';
import React, { Dispatch, FC, useContext, useState } from 'react';
import { TiWarning } from 'react-icons/ti';
import Table, { ColumnsType, TableProps } from 'antd/lib/table';

import { DoctorAvatar, DoctorPersonals } from '@/components/Personals/DoctorPersonals';
import { SearchOutlined } from '@ant-design/icons';
import { FilterValue } from 'antd/lib/table/interface';
import { useQuery } from 'react-query';
import { getListOfDoctors } from '@/services/consultations.service';
import { FORMATS } from '@/constants/dates.constants';
import moment from 'moment';
import AuthContext from '@/contexts/Auth.context';
import { Consultant, SelectableDoctor } from './types';

interface InviteBySelectionFormProps {
  unitId: number;
  doctorOrderingId: number;
  consultantList: Consultant[];
  selectedDoctorIds: number[];
  setSelectedDoctorIds: Dispatch<React.SetStateAction<number[]>>;
}

export const InviteBySelectionForm: FC<InviteBySelectionFormProps> = ({ unitId, consultantList, selectedDoctorIds, setSelectedDoctorIds, doctorOrderingId }) => {
  const [filteredInfo, setFilteredInfo] = useState<Record<string, FilterValue | null>>({});
  const { user } = useContext(AuthContext);
  const { data: doctors, isLoading } = useQuery(
    ['consultants', unitId],
    getListOfDoctors({
      consultantUnitId: unitId,
      dateFrom: moment().startOf('day').format(FORMATS.API_DATE_TIME),
      dateTo: moment().add(30, 'days').startOf('day').format(FORMATS.API_DATE_TIME),
    }),
    {
      select(response) {
        if (!response.data?.doctors) {
          return [];
        }

        return response.data.doctors
          .map((doctor) => ({
            userId: doctor.id,
            doctor: {
              name: `${doctor.name} ${doctor.surname}`,
              specialty: doctor.specialty,
            },
            invited: consultantList.some((consultant) => consultant.consultantId === doctor.id) || doctor.id === doctorOrderingId,
          }))
          .filter((doctor) => doctor.userId !== user.doctorId);
      },
    },
  );

  const handleSelectionChange = (doctorId: number, isSelected: boolean) => {
    if (isSelected) {
      setSelectedDoctorIds((currentSelectedDoctorIds) => [...currentSelectedDoctorIds, doctorId]);
    } else {
      setSelectedDoctorIds((currentSelectedDoctorIds) => currentSelectedDoctorIds.filter((id) => id !== doctorId));
    }
  };

  const handleFiltersChange: TableProps<SelectableDoctor>['onChange'] = (_, filters) => {
    setFilteredInfo(filters);
  };

  const columns: ColumnsType<SelectableDoctor> = [
    {
      dataIndex: 'doctor',
      key: 'avatar',
      render: () => <DoctorAvatar />,
      align: 'right',
      width: 50,
    },
    {
      dataIndex: 'doctor',
      key: 'doctor',
      title: 'Lekarz',
      render: (doctor) => <DoctorPersonals title="dr" name={doctor.name} specialty={doctor.specialty} />,
      // eslint-disable-next-line react/no-unstable-nested-components
      filteredValue: filteredInfo.doctor || null,
      filtered: true,
      onFilter: (filterValue, record) => record.doctor.name.toLowerCase().includes(filterValue.toString().toLowerCase()),
    },
    {
      dataIndex: 'userId',
      title: 'Wybrani lekarze',
      key: 'selected',
      width: 150,
      align: 'center',
      sorter: (a) => (selectedDoctorIds.includes(a.userId as unknown as number) ? 1 : -1),
      render: (doctorId, record) => (
        <Checkbox
          disabled={record.invited}
          checked={selectedDoctorIds.includes(doctorId) || record.invited}
          onChange={(e) => handleSelectionChange(doctorId, e.target.checked)}
          {...testId('doctor-select-checkbox')}
        />
      ),
    },
  ];

  return (
    <Row>
      <Col span={12} offset={6} className="relative flex flex-col justify-center">
        <Row>
          <Col span={16} className="py-2">
            <Input
              addonBefore={<SearchOutlined />}
              autoFocus
              onChange={({ target }) => setFilteredInfo({ doctor: target.value ? [target.value] : [] })}
              placeholder="Filtruj po imieniu i nazwisku..."
              allowClear
              {...testId('doctor-name-filter')}
            />
          </Col>
          <Col span={24}>
            <Spin spinning={isLoading}>
              <Table
                size="small"
                columns={columns}
                dataSource={doctors}
                rowKey="userId"
                pagination={{
                  pageSize: 10,
                  showSizeChanger: false,
                  hideOnSinglePage: true,
                }}
                onChange={handleFiltersChange}
                locale={{
                  emptyText: (
                    <p className="text-gray-500">
                      {filteredInfo && Array.isArray(filteredInfo.doctor) && filteredInfo.doctor.length > 0 ? 'Brak lekarzy spełniających kryteria' : 'Brak danych'}
                    </p>
                  ),
                  triggerDesc: 'Pokaż wybranych na początku tabeli',
                  triggerAsc: 'Pokaż wybranych na końcu tabeli',
                  cancelSort: 'Usuń sortowanie',
                }}
              />
              <p className="text-gray-500" {...testId('selected-doctors-count')}>
                Liczba wybranych lekarzy: {selectedDoctorIds.length}
              </p>
            </Spin>
          </Col>
        </Row>
        <Space className="mt-4 flex content-center justify-center">
          <TiWarning size={24} />
          <div>Do wybranych lekarzy zostanie wysłany link do konsultacji.</div>
        </Space>
      </Col>
    </Row>
  );
};
