import React from 'react';

import { Button, Form, Input, Tooltip } from 'antd';

import { testIdFactory } from '@/tests';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { MemoField } from '../../types';
import { getValidationRules } from '../service';
import { useTemplateEngineContext } from '../../TemplateEngineContext';

export const TextAreaFormField: FormFieldComponent<MemoField> = (props) => {
  const { name, label, description, isDisabled, isHidden, isRequired, isNecessary, tooltip, testId, allowCopyingFromQualificationCard } = props;

  const { copyContext } = useTemplateEngineContext();
  const { setFieldValue, getFieldValue } = Form.useFormInstance();

  const qualificationCardMemoToCopy = copyContext && allowCopyingFromQualificationCard && copyContext[allowCopyingFromQualificationCard];

  const fieldValueWithPreviousCopy = getFieldValue(name) === undefined ? `${qualificationCardMemoToCopy} ` : `${getFieldValue(name)} ${qualificationCardMemoToCopy} `;

  const handleCopyingData = () => {
    if (typeof fieldValueWithPreviousCopy === 'string') {
      setFieldValue(name, fieldValueWithPreviousCopy);
    } else {
      console.error(`Pole z którego kopiujesz nie jest typu string. ${allowCopyingFromQualificationCard}`);
    }
  };

  if (isHidden) {
    return null;
  }

  const validations = getValidationRules(props);

  const testAttribute = testIdFactory(testId);

  return (
    <Form.Item
      rules={validations}
      label={
        label ? (
          <FormFieldLabel
            label={label}
            description={description}
            required={isRequired || isNecessary}
            fieldTooltip={tooltip}
            addons={
              allowCopyingFromQualificationCard && (
                <Tooltip title={qualificationCardMemoToCopy ? '' : 'Brak danych do skopiowania'}>
                  <Button disabled={!qualificationCardMemoToCopy} onClick={handleCopyingData}>
                    Kopiuj z karty kwalifikacji
                  </Button>
                </Tooltip>
              )
            }
          />
        ) : null
      }
      className="mb-0 py-2"
      name={name}
      required={isRequired || isNecessary}
    >
      <Input.TextArea rows={4} className="w-full drop-shadow-sm" disabled={isDisabled} {...testAttribute(name)} />
    </Form.Item>
  );
};
