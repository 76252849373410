import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button as ButtonAnt, DatePicker, Form, Input, message, Modal, Radio, Select, Spin, Upload } from 'antd';
import { get } from 'lodash';
import moment from 'moment/moment';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { TiInfo } from 'react-icons/ti';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import AuthContext from '../../../contexts/Auth.context';
import {
  addDicomExamination,
  addDicomSeriesToExisting,
  addExamination,
  addFile,
  deleteDicomFileFromService,
  deleteFileFromService,
  editFile,
  moveStaggingDicom,
} from '../../../services/files.service';
import withAccessToken from '../../../utils/withAccessToken';
import Button from '../../Button2/Button.component';
import { useTimer } from '../../../layaout/Auth.Layout';

const { Dragger } = Upload;
const { Option } = Select;

let lastLoaded = 0;
let loadedBuffer = 0;
let loadedPerThree = 0;
let timeLeft = Infinity;

const MINIMUM_FILE_SIZE_TO_ENABLE_TIMER = 50;

const displayTimeLeft = (time) => {
  if (time === Infinity) return null;
  if (time <= 0) return null;
  const timeInMinutes = (time / 60).toFixed(0);
  const secondsLeft = time % 60;

  return `${timeInMinutes}:${secondsLeft.toString().padStart(2, '0')}`;
};

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const clearLatestLoadedStateForTimer = () => {
  lastLoaded = 0;
  loadedBuffer = 0;
  loadedPerThree = 0;
  timeLeft = Infinity;
};

const ModalFilesUpload = ({ open, onClose, type, mode, data, examinationId, fileId, accept, dicomSeries }) => {
  const [selectedValue, setselectedValue] = useState(1);
  const [responseFile, setResponseFile] = useState([]);
  const [fileName, setFileName] = useState();
  const [examinationSelect, setExaminationSelect] = useState([]);
  const [dicomSeriesId, setDicomSeriesId] = useState();
  const [hideDeleteSeriesButton, setHideDeleteSeriesButton] = useState(true);
  const [uploadedDicomFilesArray, setUploadedDicomFilesArray] = useState([]);
  const [otherFilesStatusError, setOtherFilesStatusError] = useState(false);
  const [uploadedOtherFilesArray, setUploadedOtherFilesArray] = useState([]);
  const [isDirectory, setIsDirectory] = useState(null);
  const [timerSteering, setTimerSteering] = useState();

  const { turnOnTimer, turnOffTimer } = useTimer();

  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { unit } = useContext(AuthContext);

  const files = form.getFieldValue('file');

  const isAnyFileHasUploadingStatus = files?.some((item) => item.status === 'uploading');

  const otherFilesWarningMessage = uploadedOtherFilesArray[uploadedOtherFilesArray.length - 1]?.fileList[0]?.response?.message;

  const handleRadioDicomChange = (e) => {
    setIsDirectory(e.target.value);
  };

  useEffect(() => {
    setExaminationSelect(data);
  }, [data]);

  const mutationService = {
    onSuccess: async () => {
      if (id) await queryClient.refetchQueries(['documentation', id]);
    },
    onError: (err) => {
      console.log(err);
      message.error(`Problem z poprawnym usunięciem ${type === 'others' ? 'pliku' : 'serii'}.`);
    },
  };
  const mutationDeleteDicomSeriesService = useMutation(deleteDicomFileFromService, mutationService);

  const onDeleteDicomService = () => {
    mutationDeleteDicomSeriesService.mutate({
      id,
      dicomSeriesId,
      dicomUuid: dicomSeries.data.dicomSeriesUuid,
      unit,
    });
    queryClient.refetchQueries(['dicom-series', id]);
    setUploadedDicomFilesArray([]);
    setHideDeleteSeriesButton(true);
    form.resetFields(['file', 'examinationDate']);
  };
  const mutationDeleteServiceFile = useMutation(deleteFileFromService, mutationService);

  const onDeleteService = () => {
    mutationDeleteServiceFile.mutate({
      id,
      fileName,
      unit,
    });
    setUploadedOtherFilesArray([]);
    setOtherFilesStatusError(false);
    form.resetFields(['file']);
  };

  const handleModalClose = () => {
    if (type === 'others' && responseFile?.length !== 0) {
      onDeleteService();
      setResponseFile([]);
      setUploadedOtherFilesArray([]);
    }
    if (type === 'dicom' && files?.fileList?.length !== 0 && files !== undefined) {
      setHideDeleteSeriesButton(true);
      onDeleteDicomService();
      setUploadedDicomFilesArray([]);
    }
    onClose?.();
    setOtherFilesStatusError(false);
    setIsDirectory(null);
    clearLatestLoadedStateForTimer();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (loadedBuffer !== 0) {
        loadedPerThree = loadedBuffer;
      }

      loadedBuffer = 0;
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const draggerOtherFilesProps = {
    name: 'file',
    maxCount: 1,
    action: `${process.env.REACT_APP_API_URL_FILES}/unit/${unit.id}/documentation/${id}/binary`,
    ...withAccessToken(),
    beforeUpload(file) {
      const MAX_SIZE_OF_UPLOADED_FILE = 500;
      const fileSizeLimit = file.size / 1024 / 1024 < MAX_SIZE_OF_UPLOADED_FILE;
      setTimerSteering(file.size / 1024 / 1024);
      if (!fileSizeLimit) {
        message.error(`Maksymalny rozmiar pliku to ${MAX_SIZE_OF_UPLOADED_FILE} MB.`);
        setResponseFile([]);
        setOtherFilesStatusError(false);
        form.resetFields(['file', 'examinationDate']);
        return false;
      }

      const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();

      if (!accept.includes(fileExtension)) {
        message.error(`Błędny format pliku.`);
        setResponseFile([]);
        setOtherFilesStatusError(false);
        form.resetFields(['file', 'examinationDate']);
        return false;
      }

      return true;
    },
    onChange(info) {
      if (info.event) {
        const deltaLoaded = info.event.loaded - lastLoaded;
        lastLoaded = info.event?.loaded;
        loadedBuffer += deltaLoaded;
        const remaining = (info.event?.total ?? 0) - (info.event?.loaded ?? 0);
        const speed = loadedPerThree / 3;
        timeLeft = moment.duration((remaining / speed).toFixed(0), 'seconds').asSeconds();
      }

      if (info.file.status === undefined) {
        turnOnTimer();
        form.setFieldValue('file', undefined);
        return;
      }
      setUploadedOtherFilesArray((newUploadedOtherFilesArray) => [...newUploadedOtherFilesArray, info]);
      setOtherFilesStatusError(false);
      if (info.file.status === 'uploading') {
        turnOffTimer();
      }
      if (info.file.status === 'done') {
        turnOnTimer();
        const latestFile = info.file.response.files[0];
        const prevFile = responseFile[0];
        setResponseFile([latestFile]);
        setFileName(latestFile.fileName);
        if (prevFile) {
          message.success(`Plik został zastąpiony`);
        } else {
          message.success(`Plik został przesłany pomyślnie.`);
        }
        clearLatestLoadedStateForTimer();
      }

      if (info.file.status === 'removed') {
        turnOnTimer();
        onDeleteService();
        setResponseFile([]);
        clearLatestLoadedStateForTimer();
      } else if (info.file.status === 'error') {
        turnOnTimer();
        message.error(`Przesyłanie pliku nie powiodło się. ${info?.file?.response?.message} `);
        setOtherFilesStatusError(true);
        clearLatestLoadedStateForTimer();
      }
    },
  };

  const draggerDicomProps = {
    name: 'file',
    multiple: true,
    ...withAccessToken(),
    action: `${process.env.REACT_APP_API_URL_FILES}/unit/${unit.id}/documentation/${id}/dicom/${dicomSeries?.data?.dicomSeriesUuid}`,
    onChange(info) {
      const deleteButtons = document.querySelectorAll('.ant-upload-list-item-card-actions-btn');
      deleteButtons.forEach((deleteButton) => {
        if (deleteButton.firstChild && deleteButton.firstChild.classList.contains('custom-upload-list-item-span')) {
          deleteButton.remove();
        }
      });
      setUploadedDicomFilesArray((newUploadedDicomFilesArray) => [...newUploadedDicomFilesArray, info]);
      if (info.file.status === 'done') {
        setHideDeleteSeriesButton(false);
        setDicomSeriesId(info.file.response.dicomSeriesFolderName);
      } else if (info.file.status === 'error') {
        message.error(`Przesyłanie pliku ${info.file.name} do serii nie powiodło się.`);
      }
      clearLatestLoadedStateForTimer();
    },
    showUploadList: {
      showRemoveIcon: true,
      // eslint-disable-next-line react/no-unstable-nested-components
      removeIcon: (file) => (file.status === 'done' || file.status === 'uploading' ? <span className="custom-upload-list-item-span" /> : null),
    },
  };

  const moveStagging = useMutation(moveStaggingDicom);

  const mutationOptions = {
    onSuccess: async () => {
      if (mode === 'addDicomExamination' || mode === 'addDicomSeries') {
        await moveStagging.mutate({ id, dicomUuid: dicomSeries.data.dicomSeriesUuid, unit });
        queryClient.refetchQueries(['dicom-series', id]);
      }
      onClose();
      if (mode === 'addExamination' || mode === 'addDicomExamination') {
        message.success('Badanie zostało poprawnie dodane.');
      } else if (mode === 'addFile') {
        message.success('Plik został poprawnie dodany do badania.');
      } else if (mode === 'editFile') {
        message.success('Plik został poprawnie edytowany.');
      } else if (mode === 'addDicomSeries') {
        message.success('Seria DICOM została poprawnie dodana.');
      }
      if (id) await queryClient.refetchQueries(['documentation', id]);
      if (id) await queryClient.refetchQueries(['consultation-id']);
      if (id) await queryClient.refetchQueries(['status/history', id]);
      if (type === 'dicom') {
        queryClient.refetchQueries(['meddream-token']);
      }
    },
    onError: (err) => {
      console.log(err);
      if (mode === 'addExamination' || mode === 'addDicomExamination') {
        message.error(`${err.response.data.message}`);
        if (mode === 'addExamination') {
          onDeleteService();
          form.resetFields(['file', 'examinationDate']);
        }
      } else if (mode === 'addFile') {
        message.error('Problem z poprawnym dodaniem pliku.');
      } else if (mode === 'editFile') {
        message.error('Problem z poprawną edycją pliku.');
      } else if (mode === 'addDicomSeries') {
        message.error('Problem z poprawnym dodaniem serii DICOM');
      }
    },
  };

  const getEndpoint = () => {
    if (mode === 'addExamination') return addExamination;
    if (mode === 'addFile') return addFile;
    if (mode === 'editFile') return editFile;
    if (mode === 'addDicomExamination') return addDicomExamination;
    if (mode === 'addDicomSeries') return addDicomSeriesToExisting;
  };

  const mutation = useMutation(getEndpoint(), mutationOptions);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const dicomSeriesFolderName = values.file ? dicomSeries.data.dicomSeriesUuid : null;
        mutation.mutate({
          ...values,
          files: responseFile,
          examinationIdSource: examinationId,
          id,
          fileId,
          dicomSeriesFolderName,
          examinationDate: values.examinationDate ? values.examinationDate.format('YYYY-MM-DDTHH:mm:ss[Z]').toString() : null,
        });
        setResponseFile([]);
        setHideDeleteSeriesButton(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!data || !open) return;
    const examination = data.find((item) => item.examinationId === examinationId);
    if (mode === 'addFile' || mode === 'addDicomSeries') {
      form.setFieldsValue({ ...data, ...examination });
    } else if (mode === 'editFile') {
      const fileIndex = examination?.files.find((item) => item.fileId === fileId);
      const newData = { ...examination, ...fileIndex, examinationDate: get(fileIndex, 'examinationDate') && moment(fileIndex.examinationDate) };
      form.setFieldsValue(newData);
    }
  }, [data, open]);

  const onAfterClose = () => {
    form.resetFields();
  };

  const handleChange = (e) => {
    setselectedValue(e.target.value);
  };

  const handleTitle = () => {
    const title = {
      addExamination: 'Dodawanie badania',
      addFile: 'Dodawanie pliku',
      addDicomSeries: 'Dodawanie serii dicom',
      addDicomExamination: 'Dodawanie badania dicom',
      editFile: 'Edycja pliku',
    };

    const subtitle = {
      addExamination: 'Wybierz badanie',
      addFile: 'Wybierz plik',
      addDicomSeries: 'Wybierz serie',
      addDicomExamination: 'Wybierz badanie DICOM',
      editFile: 'Wprowadź dane',
    };

    return (
      <div>
        <h1 className="text-xl font-extrabold uppercase">{title[mode]}</h1>
        <p className="text-sm text-gray-600">{subtitle[mode]}</p>
      </div>
    );
  };

  const disabledDate = (current) => {
    const today = moment().startOf('day');
    const tenYearsAgoLimit = moment().subtract(10, 'years').startOf('day');
    if (current && current > today && !current.isSame(today, 'day')) {
      return true;
    }
    if (current && current < tenYearsAgoLimit) {
      return true;
    }
    return false;
  };

  const dicomFilesStatusError = uploadedDicomFilesArray[uploadedDicomFilesArray.length - 1]?.fileList.filter((obj) => obj.status === 'error')[0]?.response?.message;

  return (
    <Modal
      maskClosable={false}
      closable={!mutation.isLoading}
      title={<div className="flex flex-row items-center">{handleTitle()}</div>}
      className="modal-styles input-styles"
      width={1000}
      afterClose={onAfterClose}
      destroyOnClose
      centered
      open={open}
      onCancel={handleModalClose}
      footer={[
        <Fragment key="footer">
          <div className="pb-5">
            <Button disabled={mutation.isLoading} onClick={handleModalClose} color="default" size="custom-lg" margin="mr-6">
              Anuluj
            </Button>
            <Button
              color="darkblue"
              size="custom-lg"
              margin="mr-6"
              onClick={onFinish}
              disabled={isAnyFileHasUploadingStatus || dicomFilesStatusError !== undefined || otherFilesStatusError}
            >
              Dodaj
            </Button>
          </div>
        </Fragment>,
      ]}
    >
      <Spin spinning={mutation.isLoading}>
        <Form layout="vertical" form={form} name="filesModal" onFinish={onFinish}>
          <Form.Item>
            <Radio.Group onChange={handleChange} value={selectedValue}>
              {type === 'dicom' ? (
                <div>
                  <Radio value={1}>zasoby dyskowe</Radio>
                  {/* <Radio value={2}>system PACS</Radio> */}
                </div>
              ) : (
                <Radio value={1}>zasoby dyskowe</Radio>
              )}
            </Radio.Group>
          </Form.Item>
          {selectedValue === 1 && (
            <div className="tooltip-hidden-for-files-status-error gray-bg mb-4">
              {(mode === 'addExamination' || mode === 'addDicomExamination') && (
                <>
                  <div className="w-full">
                    <Form.Item
                      label="Nazwa badania"
                      name="examinationName"
                      className="mr-4 w-full"
                      rules={[
                        { required: true, message: 'Pole wymagane!' },
                        { max: 128, message: 'Pole przyjmuje maksymalnie 128 znaków!' },
                      ]}
                    >
                      <Input className="drop-shadow-sm" />
                    </Form.Item>
                  </div>

                  <div>
                    <p>{mode === 'addDicomExamination' ? 'Dodawanie serii plików DICOM - wybierz plik lub katalog' : 'Plik'}</p>
                    <div>
                      {mode === 'addDicomExamination' ? (
                        <div className={isDirectory !== null ? 'pb-2' : null}>
                          <Radio.Group onChange={handleRadioDicomChange} value={isDirectory}>
                            <Radio value={false}>Pliki</Radio>
                            <Radio value>Katalog</Radio>
                          </Radio.Group>
                        </div>
                      ) : null}
                    </div>
                    <Form.Item
                      name="file"
                      className="dicom-catalog-validation"
                      getValueFromEvent={normFile}
                      getValueProps={(v) => v}
                      rules={[
                        ({ getFieldValue }) => ({
                          validator() {
                            const hasFile = Array.isArray(files) ? files.length > 0 : !!files;
                            const hasDate = !!getFieldValue('examinationDate');
                            if (!hasFile && !hasDate) {
                              return Promise.resolve();
                            }
                            if (hasFile && hasDate) {
                              return Promise.resolve();
                            }
                            if (hasFile && !hasDate) {
                              return Promise.reject(new Error(''));
                            }
                            return Promise.reject(
                              new Error(
                                `${
                                  mode === 'addDicomExamination' && isDirectory === null
                                    ? 'Wybierz „Pliki” albo „Katalog”!'
                                    : isDirectory !== null
                                    ? 'Wybierz również serię!'
                                    : 'Wybierz również plik!'
                                }`,
                              ),
                            );
                          },
                        }),
                      ]}
                    >
                      {mode === 'addExamination' || (mode === 'addDicomExamination' && isDirectory !== null) ? (
                        <Dragger accept={accept} {...(mode === 'addDicomExamination' ? { ...draggerDicomProps, directory: isDirectory } : draggerOtherFilesProps)}>
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                          </p>
                          <div className="ant-upload-text text-base font-medium">
                            {mode === 'addDicomExamination' ? (
                              isDirectory ? (
                                'Kliknij lub przeciągnij katalog do tego obszaru, aby przesłać zawarte w nim pliki lub serie.'
                              ) : (
                                'Kliknij lub przeciągnij plik lub serię do tego obszaru, aby go przesłać.'
                              )
                            ) : (
                              <div className="flex flex-col">
                                <span>Kliknij lub przeciągnij plik do tego obszaru, aby go przesłać. Dopuszczalne formaty plików:</span>
                                <span className="font-semibold">.pdf, .txt, .jpeg, .jpg, .png, .avi, .mp4, .wmv</span>
                                <span>Pliki do badania należy dodawać pojedyńczo.</span>
                              </div>
                            )}
                          </div>
                        </Dragger>
                      ) : null}
                    </Form.Item>
                    <span className="float-right -mt-6">{timerSteering > MINIMUM_FILE_SIZE_TO_ENABLE_TIMER ? displayTimeLeft(timeLeft) : null}</span>
                    {mode === 'addDicomExamination' ? (
                      !hideDeleteSeriesButton && files?.length !== 0 ? (
                        <div className="pb-4">
                          <ButtonAnt onClick={onDeleteDicomService} disabled={isAnyFileHasUploadingStatus}>
                            Usuń serie
                            {/* USUŃ SERIE */}
                          </ButtonAnt>
                        </div>
                      ) : null
                    ) : null}
                    <Form.Item
                      label={mode === 'addDicomExamination' ? 'Data serii' : 'Data badania'}
                      name="examinationDate"
                      rules={[
                        ({ setFieldValue }) => ({
                          validator(_, value) {
                            const hasDate = !!value;
                            const hasFile = Array.isArray(files) ? files.length > 0 : !!files;
                            if (Array.isArray(files) && files.length === 0) {
                              setFieldValue('file', undefined);
                              return Promise.resolve();
                            }
                            if (!hasFile && !hasDate) {
                              return Promise.resolve();
                            }
                            if (hasFile && hasDate) {
                              return Promise.resolve();
                            }
                            if (!hasFile && hasDate) {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Wybierz również datę!'));
                          },
                        }),
                      ]}
                    >
                      <DatePicker className="w-max" disabledDate={disabledDate} inputReadOnly />
                    </Form.Item>
                  </div>
                  {(mode === 'addDicomExamination' && dicomFilesStatusError) || (mode === 'addExamination' && otherFilesStatusError) ? (
                    <Alert
                      className="consultant-info-alert"
                      description={
                        mode === 'addDicomExamination' ? (
                          <div>{dicomFilesStatusError}</div>
                        ) : (
                          <p className="font-semibold not-italic">
                            Wystąpił błąd{otherFilesWarningMessage ? ':' : '.'} {otherFilesWarningMessage} Aby kontynuować usuń plik.
                          </p>
                        )
                      }
                      type="warning"
                      showIcon
                      icon={<TiInfo />}
                    />
                  ) : null}
                </>
              )}
              {(mode === 'addDicomSeries' || mode === 'addFile') && (
                <>
                  <div className="w-full">
                    <Form.Item label="Nazwa badania" name="examinationId" className="mr-4 w-full">
                      <Select className="drop-shadow-sm" disabled>
                        {examinationSelect?.map((examination) => (
                          <Option value={examination.examinationId} key={examination.examinationId}>
                            <p>{examination.examinationName}</p>
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <p>{mode === 'addDicomSeries' ? 'Dodawanie serii plików DICOM - wybierz plik lub katalog' : null}</p>
                    <div>
                      {mode === 'addDicomSeries' ? (
                        <div className={isDirectory !== null ? 'pb-2' : null}>
                          <Radio.Group onChange={handleRadioDicomChange} value={isDirectory}>
                            <Radio value={false}>Pliki</Radio>
                            <Radio value>Katalog</Radio>
                          </Radio.Group>
                        </div>
                      ) : null}
                    </div>
                    <Form.Item
                      name="file"
                      label={mode === 'addDicomSeries' ? null : 'Plik'}
                      rules={[
                        {
                          required: true,
                          message: `${
                            mode === 'addDicomSeries' && isDirectory === null
                              ? 'Wybierz „Pliki” albo „Katalog”!'
                              : isDirectory !== null
                              ? 'Wybierz również serię!'
                              : 'Wybierz również plik!'
                          }`,
                        },
                      ]}
                      className="dicom-catalog-validation"
                      getValueFromEvent={normFile}
                      getValueProps={(v) => v}
                    >
                      {mode === 'addFile' || (mode === 'addDicomSeries' && isDirectory !== null) ? (
                        <Dragger accept={accept} {...(mode === 'addDicomSeries' ? { ...draggerDicomProps, directory: isDirectory } : draggerOtherFilesProps)}>
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                          </p>
                          <div className="ant-upload-text block text-base font-medium">
                            {mode === 'addDicomSeries' ? (
                              isDirectory ? (
                                'Kliknij lub przeciągnij katalog do tego obszaru, aby przesłać zawarte w nim pliki lub serie.'
                              ) : (
                                'Kliknij lub przeciągnij plik lub serię do tego obszaru, aby go przesłać.'
                              )
                            ) : (
                              <div className="flex flex-col">
                                <span>Kliknij lub przeciągnij plik do tego obszaru, aby go przesłać. Dopuszczalne formaty plików:</span>
                                <span className="font-semibold">.pdf, .txt, .jpeg, .jpg, .png, .avi, .mp4, .wmv</span>
                                <span>Pliki do badania należy dodawać pojedyńczo.</span>
                              </div>
                            )}
                          </div>
                        </Dragger>
                      ) : null}
                    </Form.Item>
                    {mode === 'addDicomSeries' ? (
                      !hideDeleteSeriesButton && files?.length !== 0 ? (
                        <div className="pb-4">
                          <ButtonAnt onClick={onDeleteDicomService} disabled={isAnyFileHasUploadingStatus}>
                            Usuń serie
                            {/* USUŃ SERIE */}
                          </ButtonAnt>
                        </div>
                      ) : null
                    ) : null}
                    <Form.Item label={mode === 'addDicomSeries' ? 'Data serii' : 'Data badania'} name="examinationDate" rules={[{ required: true, message: 'Pole wymagane!' }]}>
                      <DatePicker className="w-max" disabledDate={disabledDate} inputReadOnly />
                    </Form.Item>
                    {(mode === 'addDicomSeries' && dicomFilesStatusError) || (mode === 'addFile' && otherFilesStatusError) ? (
                      <Alert
                        className="consultant-info-alert"
                        description={
                          mode === 'addDicomSeries' ? (
                            <div>{dicomFilesStatusError}</div>
                          ) : (
                            <p className="font-semibold not-italic">
                              Wystąpił błąd{otherFilesWarningMessage ? ':' : '.'} {otherFilesWarningMessage} Aby kontynuować usuń plik.
                            </p>
                          )
                        }
                        type="warning"
                        showIcon
                        icon={<TiInfo />}
                      />
                    ) : null}
                  </div>
                </>
              )}
              {mode === 'editFile' && (
                <>
                  <div className="w-full">
                    <Form.Item label="Nazwa badania" name="examinationId" className="mr-4 w-full">
                      <Select className="drop-shadow-sm">
                        {examinationSelect
                          .filter((examination) => examination.examinationType === 'OTHER')
                          .map((examination) => (
                            <Option value={examination.examinationId} key={examination.examinationId}>
                              <p>{examination.examinationName}</p>
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item label="Data badania" name="examinationDate">
                      <DatePicker className="w-max" disabledDate={disabledDate} inputReadOnly />
                    </Form.Item>
                  </div>
                </>
              )}
            </div>
          )}
          {/* {selectedValue === 2 && (
            <div>
              <PacsTable />
            </div>
            //
          )} */}
        </Form>
      </Spin>
    </Modal>
  );
};

export default ModalFilesUpload;
