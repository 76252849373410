// /* eslint-disable */
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { Tag } from 'antd';
import { testId } from '@/tests';
import AuthContext from '../../contexts/Auth.context';
import DataTime from '../DataTime/DataTime.component';
import Button from '../Button2/Button.component';
import AddConsultationModalComponent from '../Modals/AddConsultationModal.component';
import { ChangelogModal } from '../ChangelogModal';

const showChangelog = process.env.REACT_APP_ENVIRONMENT === 'dev' || process.env.REACT_APP_ENVIRONMENT === 'sbx';

const HeaderDownBar = () => {
  const [open, setOpen] = useState(false);
  const [changelogOpen, setChangelogOpen] = useState(false);
  const { unit, user } = useContext(AuthContext);

  const showModal = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const remainingDaysOfPasswordExpiration = (days) => {
    if (days === -1) return <p className="font-bold text-red-600">Twoje hasło wygasło.</p>;
    if (days === 0) return <p className="font-bold text-red-600">Twoje hasło wygaśnie dzisiaj.</p>;
    if (days === 1) return <p className="font-bold text-red-600">Twoje hasło wygaśnie za 1 dzień. Możesz je zresetować tutaj.</p>;
    return <p className="font-bold text-red-600"> Twoje hasło wygaśnie za {days} dni. Możesz je zresetować tutaj.</p>;
  };

  return (
    <div className="fixed left-0 top-16 z-[3] w-full border-b-gray-900 bg-gray-200">
      <div className="mx-auto max-w-screen-2xl px-2 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center  justify-between md:space-x-10">
          <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="hidden items-center font-medium md:inline-flex">{unit.name}</li>
            <li className="hidden  items-center text-gray-600 md:inline-flex" {...testId('username')}>
              {user.name} {user.lastname}
            </li>
            <li className="inline-flex  items-center">
              <div className="flex flex-col leading-4 md:hidden">
                <span className="mt-1 font-medium">{unit.name}</span>
                <span className="text-sm text-gray-600">
                  {user.name} {user.lastname}
                </span>
              </div>
            </li>
            <li className="hidden items-center text-gray-600 md:inline-flex">
              <DataTime formatTime="HH:mm" />
            </li>
          </ol>

          <div className="inline-flex items-center space-x-4">
            <div
              style={{ cursor: showChangelog ? 'pointer' : 'auto' }}
              onClick={() => {
                if (showChangelog) {
                  setChangelogOpen(true);
                }
              }}
            >
              v. {process.env.REACT_APP_VERSION}
            </div>
            <Tag color="pink">{process.env.REACT_APP_ENVIRONMENT}</Tag>
            <Button onClick={showModal} icon={<AiOutlinePlus className="h-5 w-4" />} color="darkblue" size="sm" {...testId('add-consultation-button')}>
              Dodaj konsultację
            </Button>
            <AddConsultationModalComponent open={open} onClose={onClose} show={['doctor', 'patient']} current="newConsultation" mode="add" />
            {showChangelog && <ChangelogModal open={changelogOpen} onClose={() => setChangelogOpen(false)} />}
          </div>
        </div>
      </div>
      {user.remainingDaysOfPasswordExpiration !== null && user.remainingDaysOfPasswordExpiration <= 7 && (
        <div className="flex h-min w-full items-center bg-red-200 py-1 md:space-x-10">
          <div className="mx-auto w-full max-w-screen-2xl px-2 sm:px-6 lg:px-8">
            <div className="flex w-full max-w-screen-2xl items-center justify-start md:space-x-10">
              {remainingDaysOfPasswordExpiration(user.remainingDaysOfPasswordExpiration)}
              <Link to="/reset?changepassword=true">
                <Button color="red" className="border-red-600 bg-transparent font-bold text-red-600 hover:bg-red-600 hover:text-white">
                  Zmień hasło
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

HeaderDownBar.propTypes = {};

export default HeaderDownBar;
