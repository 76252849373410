import { defaultHeaders } from './http';
import getAuthToken from './getAuthToken';
import getUnitId from './getUnitId';
import getTimeZone from './getTimeZone';
import getUserId from './getUserId';

export default () => ({
  ...defaultHeaders,
  headers: {
    Authorization: getAuthToken(),
    'X-Unit-Id': getUnitId(),
    'X-User-Id': getUserId(),
    'X-Time-Zone': getTimeZone(),
  },
});

