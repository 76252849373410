// import { Select, Space, TimePicker, Button as ButtonAnt } from 'antd';
import { Select, Space, TimePicker, Checkbox, Button as ButtonAnt } from 'antd';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import React from 'react';
import { AiOutlineTeam } from 'react-icons/ai';

const { Option } = Select;

const ScheduleTimePicker = ({ calendarFilters, onCalendarFiltersChange, localFilters, onLocalFiltersChange, calendarsList = [] }) => {
  // Filters managed in browser URL
  const handleTimeChange = (time) => {
    onCalendarFiltersChange({ ...calendarFilters, time });
  };
  const handleHourRange = (hour) => {
    onCalendarFiltersChange({ ...calendarFilters, hour });
  };
  const handleCalendarChange = (selectedValue) => {
    onCalendarFiltersChange({ ...calendarFilters, selectedCalendar: selectedValue });
  };
  // Filters managed by parent component
  const handleFreeTime = (event) => {
    const isSelected = event.target.checked;
    onLocalFiltersChange({ ...localFilters, consultationFreeTime: isSelected });
  };
  const handleConsutationTime = (event) => {
    const isSelected = event.target.checked;

    onLocalFiltersChange({ ...localFilters, consultationTime: isSelected });
  };
  const toggleConsultationGroup = () => {
    onLocalFiltersChange({ ...localFilters, consultationGroup: !localFilters.consultationGroup });
  };

  return (
    <>
      <div className="float-left w-56">
        <h1 className="text-l decoration-blue-800">Właściciel kalendarza</h1>
        <Space>
          <Select
            labelInValue
            value={calendarFilters.selectedCalendar}
            onChange={handleCalendarChange}
            optionFilterProp="label"
            className="w-48"
            options={calendarsList}
            loading={isEmpty(calendarsList)}
          />
        </Space>
      </div>
      <div className="float-left w-40">
        <h1 className="text-l decoration-blue-800">Przedział czasowy</h1>
        <Space>
          <Select value={calendarFilters.time} onChange={handleTimeChange}>
            {/* <Option value="00:15">15 minut</Option> */}
            <Option value="00:30">30 minut</Option>
            <Option value="1:00">1 godzina</Option>
            <Option value="2:00">2 godziny</Option>
          </Select>
        </Space>
      </div>
      <div className="float-left mr-6 w-40">
        <h1 className="text-l decoration-blue-800">Przedział godzinowy</h1>
        <Space>
          <TimePicker.RangePicker
            value={calendarFilters.hour}
            onChange={handleHourRange}
            className="timepicker-style w-32"
            format="HH"
            allowClear={false}
            showTime={{ format: 'HH:mm' }}
            // order={false}
            inputReadOnly
          />
        </Space>
      </div>
      <Checkbox checked={localFilters.consultationFreeTime} onChange={handleFreeTime} on className="ant-checkbox-styles float-left pt-4 pr-10">
        <p className="float-left pb-1">wolny termin</p>{' '}
      </Checkbox>
      <Checkbox checked={localFilters.consultationTime} onChange={handleConsutationTime} className="ant-checkbox-red-styles float-left mr-8 pt-4">
        <p className="float-left pb-1">zajęty termin</p>{' '}
      </Checkbox>
      <ButtonAnt
        className={`float-left mt-5 h-max p-1 text-2xl ${localFilters.consultationGroup ? 'ant-buttonGroupChecked-styles pt-3' : 'ant-buttonGroupUnchecked-styles pt-3'}`}
        onClick={toggleConsultationGroup}
        icon={
          localFilters.consultationGroup ? (
            <AiOutlineTeam className="ml-2 -mt-2 border-black text-2xl" />
          ) : (
            <AiOutlineTeam className="ml-2 -mt-2 border-black text-2xl opacity-10" />
          )
        }
      />
      <AiOutlineTeam className="float-left mx-1 mt-2 ml-5 h-max w-10 p-1 pt-3 text-2xl" />
      <p className="float-left pt-7">konsultacja grupowa</p>
    </>
  );
};

ScheduleTimePicker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  calendarFilters: PropTypes.object, // Remove propTypes in favor of typescript
  onCalendarFiltersChange: PropTypes.func,
};

ScheduleTimePicker.defaultProps = {
  calendarFilters: {},
  onCalendarFiltersChange: () => null,
};

export default ScheduleTimePicker;
