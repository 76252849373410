import React from 'react';
// import PropTypes from 'prop-types';
// import { FiVideo } from 'react-icons/fi';
import { Spin, Empty } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Card from '../Card/Card.component';
// import Button from '../Button2/Button.component';

const NextConsultation = ({ dataWidget, isLoading, onClick }) => {
  if (!dataWidget) {
    return (
      <Spin spinning={isLoading}>
        <Card title="Najbliższa konsultacja" titleNoWrap>
          <div className="h-[76px]">
            <div className="flex justify-center">
              <Empty className="-my-0.5" image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span className="text-black">Brak danych.</span>} />
            </div>
          </div>
        </Card>
      </Spin>
    );
  }

  const { teleconsultation, nearetsSlotWithConsultations } = dataWidget;

  if (!(teleconsultation && nearetsSlotWithConsultations)) {
    return (
      <Spin spinning={isLoading}>
        <Card title="Najbliższa konsultacja" titleNoWrap>
          <div className="h-[76px]">
            <div className="flex justify-center">
              <Empty
                className="-my-0.5"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span className="text-black">Brak najbliższych konsultacji, w których uczestniczysz.</span>}
              />
            </div>
          </div>
        </Card>
      </Spin>
    );
  }

  return !moment(teleconsultation.consultationDate).isSame(moment(nearetsSlotWithConsultations.dateFromNearestConsultation)) ? (
    <Card title="Najbliższa konsultacja" titleNoWrap>
      <div className="h-14">
        <div className="flex">
          <div className="basis-3/4 text-sm font-medium ">
            <div className="block">
              {moment(teleconsultation.consultationDate).local().format('YYYY-MM-DD')} godz. {moment(teleconsultation.consultationDate).local().format('HH:mm')}
            </div>
            <p className="block"> {teleconsultation.requestingDoctor} </p>
            <p className="block text-gray-500"> {teleconsultation.orderingUnit}</p>
          </div>
          <div className="basis-1/4">
            {/* <Button onClick={() => null} color="darkblue" icon={<FiVideo className="h-5 w-5" />}> */}
            {/*  {' '} */}
            {/*  dołącz */}
            {/* </Button> */}
          </div>
        </div>
      </div>
      <Link to={`/consultations/${teleconsultation.documentationId}`} className="flex cursor-pointer justify-end text-sm text-gray-700 hover:text-lightblue">
        szczegóły
      </Link>
    </Card>
  ) : (
    <Card title="Najbliższe konsultacje" titleNoWrap>
      <div className="h-14">
        <div className="flex">
          <div className="basis-3/4 text-sm font-medium ">
            <div className="block">
              {moment(teleconsultation.consultationDate).local().format('YYYY-MM-DD')} godz. {moment(teleconsultation.consultationDate).local().format('HH:mm')}
            </div>
            <p className="block"> {nearetsSlotWithConsultations.numberOfRemainingConsultation} Liczba pozostałych konsultacji </p>
            <p className="block"> {nearetsSlotWithConsultations.numberOfAllConsultation} Liczba wszystkich konsultacji </p>
          </div>
          <div className="basis-1/4">
            {/* <Button onClick={() => null} color="darkblue" icon={<FiVideo className="h-5 w-5" />}> */}
            {/*  {' '} */}
            {/*  dołącz */}
            {/* </Button> */}
          </div>
        </div>
      </div>
      <span onClick={onClick} className="flex cursor-pointer justify-end text-sm text-gray-700 hover:text-lightblue">
        pokaż
      </span>
    </Card>
  );
};

// NextConsultation.propTypes = {};

export default NextConsultation;
