export const requestBody = (filters, pagination, sorter) => {
  const sort = {
    field: sorter ? sorter.field : null,
    order: sorter ? sorter.order : null,
  };

  const { pageSize, current } = pagination;

  const paginate = {
    current: current || 1,
    pageSize: pageSize || 10,
  };

  return {
    filter: filters || {},
    pagination: paginate,
    sorter: sort,
  };
};
