/* eslint-disable */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, message, Modal, Spin } from 'antd';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import {
  addConsultantApprovedBy,
  addConsultation,
  addReferral,
  addSignUsingCertificate,
  consultantUpdate,
  editConsultationResult,
  editPatient,
  editQualificationCard,
  updateReferral,
} from '../../services/consultations.service';
import { getConsultant } from '../../helpers/getConsultant';
import Button from '../Button2/Button.component';
import Files from './Files/Files.component';
import Consultant from './Consultant/Consultant.component';
import ConsultationResult from './ConsultationResult.component';
import Doctor from './Doctor.component';
import Patient from './Patient.component';
import QualificationCard from './QualificationCard.component';
import AuthContext from '../../contexts/Auth.context';
import ApprovedBy from './ApprovedBy.component';
import ApprovedBySignIn from './ApprovedBySignIn.component';
import { testIdFactory } from '@/tests';
import { useTemplateEngineContext } from '../TemplateEngine';
import Referral from './Referral.component';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getModalTitle } from '@/utils/getTitle';

const { confirm } = Modal;

const testId = testIdFactory('consultation-modal');

const AddConsultationModal = ({ show, onClose, open, mode, uuid, current, data, onEditApprovedBy, status, activeItem, onSuccessRequest }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { user, unit } = useContext(AuthContext);
  const certificatePassword = Form.useWatch('certificatePassword', form);
  const [onError, setOnError] = useState(false);
  const modalTitle = getModalTitle(current, mode);

  const {
    getFinalFormValues,
    scrollToField,
    validationMessages,
    onErrorSubmission,
    onSuccessfulSubmission,
    areAllNecessaryFieldsProvided,
    getCompletionPercentage,
    renderJsonResult,
  } = useTemplateEngineContext();

  const showConfirm = (titleMessage, contentMessage, okText, cancelText) => {
    confirm({
      style: { top: '45vh' },
      title: titleMessage,
      icon: <ExclamationCircleOutlined />,
      content: contentMessage,
      okText,
      cancelText,
      okButtonProps: { className: 'bg-blue-700' },
      onOk() {
        onEditApprovedBy();
      },
      onCancel() {
        console.log('Anuluj');
      },
    });
  };

  const mutationOptions = {
    onSuccess: async () => {
      if (typeof onSuccessRequest === 'function') onSuccessRequest(current);
      onClose();
      if (mode === 'add' && current === 'newConsultation') {
        message.success('Konsultacja została poprawnie dodana.');
        queryClient.refetchQueries(['consultations']);
      } else {
        message.success('Konsultacja została poprawnie zaktualizowana.');
      }

      if (current === 'referral') await queryClient.refetchQueries(['referrals', id]);

      if (current === 'consultation_result' && status?.teleconsultation?.state === 'ORDERED')
        showConfirm('Czy chcesz dodać osoby wymagane do podpisania konsultacji?', '', 'Dodaj', 'Rezygnuję');

      if (id) {
        await queryClient.refetchQueries(['consultation-id', id]);
        await queryClient.refetchQueries(['status/history']);
      }
    },
    onError: (err) => {
      console.log(err);
      if (mode === 'edit' && current === 'approved_by') {
        message.error(`Problem z aktualizacją listy konsultantów wymaganych do zatwierdzenia. Opis: ${err.response.data.message}.`, 10);
      } else if (mode === 'edit' && current === 'approved_by_signin') {
        message.error(`Problem z podpisaniem konsultacji. ${err.response.data.message}`, 10);
        setOnError(true);
      } else {
        message.error(`Problem z ${mode === 'add' ? 'dodaniem' : 'edycją'} konsultacji. Opis: ${err.response.data.message}.`, 10);
      }
    },
  };

  const getEndpoint = () => {
    if (mode === 'add' && current === 'newConsultation') return addConsultation;
    if (mode === 'edit' && current === 'patient') return editPatient;
    if (mode === 'edit' && current === 'consultant') return consultantUpdate;
    if (mode === 'edit' && current === 'doctor') return editPatient;
    if (mode === 'edit' && current === 'qualification_card') return editQualificationCard;
    if (mode === 'edit' && current === 'approved_by') return addConsultantApprovedBy;
    if (mode === 'edit' && current === 'approved_by_signin') return addSignUsingCertificate;
    if (mode === 'add' && current === 'referral') return addReferral;
    if (mode === 'edit' && current === 'referral') return updateReferral;
    if (mode === 'edit' && current === 'consultation_result') return editConsultationResult;
  };

  const mutation = useMutation(getEndpoint(), mutationOptions);

  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        const transformValues = (v) => (getFinalFormValues ? getFinalFormValues(v) : v);
        const getJsonResult = (v) => (renderJsonResult ? renderJsonResult(v) : undefined);

        // Mutacja
        if (current === 'approved_by') {
          const doctorId = [];
          const doctorsState = [];
          values.doctorId.map((item) => {
            doctorId.push({ doctorId: item });
          });
          values.required.map((item, index) => {
            doctorsState.push({ doctorId: doctorId[index].doctorId, required: item });
          });
          // warunek na •	Wybieram lekarzy do podpisu – nie ma możliwości zapisania pustej listy
          if (doctorsState.find((item) => item.required === true) === undefined)
            message.warning('Lista konsultantów do podpisu nie może być pusta. Proszę wybrać przynajmniej jednego konsultanta.');
          else mutation.mutate({ uuid, doctorsState: doctorsState });
        } else if (current === 'approved_by_signin') {
          mutation.mutate({ uuid, password: values.certificatePassword, doctorId: user.doctorId });
        } else if (current === 'referral') {
          mutation.mutate({ ...values, patientPhoneNumber: values.patientPhone.number, patientPhoneNumberPrefix: `+${values.patientPhone.prefix}`, uuid, id: activeItem?.id });
        } else {
          mutation.mutate({
            ...values,
            documentationUuid: id,
            ...getConsultant(_.has(values, 'consultant') && values.consultant, _.has(values, 'groupForSingleDoctor') && values.groupForSingleDoctor),
            qualificationCard: current === 'qualification_card' ? { ...transformValues(values), internal_resultDescription: getJsonResult(transformValues(values)) } : null,
            isCompleteDocumentation: current === 'qualification_card' ? areAllNecessaryFieldsProvided(values) : undefined,
            resultConsultation: current === 'consultation_result' ? { ...transformValues(values), internal_resultDescription: getJsonResult(transformValues(values)) } : null,
            userId: user.id,
            patientDateOfBirth: _.has(values, 'patientDateOfBirth') ? values.patientDateOfBirth.format('YYYY-MM-DDTHH:mm:ss[Z]').toString() : null,
            uuid,
            percentageQualificationCard: current === 'qualification_card' ? getCompletionPercentage(values) : undefined,
          });
        }
        onSuccessfulSubmission?.();
      })
      .catch((validationError) => {
        console.log('Validate Failed:', validationError);
        if (validationError && Array.isArray(validationError.errorFields) && validationError.errorFields.length > 0) {
          const firstError = validationError.errorFields[0];
          const fieldName = Array.isArray(firstError.name) ? firstError.name[0] : null;
          if (fieldName) {
            scrollToField?.(fieldName);
          }
        }
        onErrorSubmission?.();
      });
  };

  const onAfterClose = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (data && open) {
      form.setFieldsValue({
        requestingDoctorId: user && user.doctorId,
        doctorId: user && user.doctorId,
        doctorLastname: user && user.lastname,
        doctorUnit: unit && unit.name,
        // consultantMode: 'scheduled',
      });
    }

    const getConsultantId = (doctorId, groupId) => {
      if (doctorId) {
        return `dr-${doctorId}`;
      }
      if (groupId) {
        return `gr-${groupId}`;
      }
      return null;
    };

    const getUnitId = (consultantList) => {
      if (consultantList && consultantList.length > 0) {
        return consultantList[0].consultantUnitId;
      }
      return null;
    };

    const getGroupId = (consultantList) => {
      if (consultantList && consultantList.length === 1) {
        return consultantList[0].consultantGroupId;
      }
      return null;
    };

    if (data && open) {
      const newData = {
        ...data,
        ...data.patient,
        ...data.qualificationCard,
        ...data.consultationResult,
        doctorId: mode === 'edit' && _.has(data, 'doctor.id') ? data.doctor.id : user.doctorId,
        doctorUnitName: _.has(data, 'doctor.id') ? data.doctor.name : null,
        patientDateOfBirth: _.has(data, 'patient.patientDateOfBirth') ? moment(data.patient.patientDateOfBirth) : null,
        // patientPesel: _.has(data, 'patient.patientIdentity') ? data.patient.patientIdentity : null,

        // Consultant
        consultantUnitId: getUnitId(data.consultant.consultantList),
        // consultant: 'dr-1',
        consultant: getConsultantId(data.deadline.doctorId, data.deadline.groupId),
        groupForSingleDoctor: getGroupId(data.consultant.consultantList),
        slotId: data.deadline.slotId,
        mode: _.has(data, 'deadline.consultationMode') ? data.deadline.consultationMode : null,
        consultationDate: data.deadline.consultationDateFrom,
        reason: data.deadline.consultationReason,
        // Referral
        patientPhone: {
          prefix: data.patientPhoneNumberPrefix ? data.patientPhoneNumberPrefix.substring(1) : 48,
          number: data.patientPhoneNumber,
        },
        // consultationDateString: _.has(data, 'deadline.consultationDate')
        //   ? `${moment('2023-03-06T09:30:00Z').format('YYYY-MM-DD')} godz. ${moment('2023-03-06T09:30:00Z').format('HH:mm')}`
        //   : moment(),
        // consultationDate: _.has(data, 'deadline.consultationDate')
        //   ? `${moment(data.deadline.consultationDate).format('YYYY-MM-DD')} godz. ${moment(data.deadline.consultationDate).format('HH:mm')}`
        //   : null,
      };
      form.setFieldsValue(newData);
      /*
       * TODO write dynamic mappers between data DTO <-> antD form object, based on template
       * to get rid of special cases like above and below
       */
      form.setFieldValue('plannedDatepicker', data.consultationResult?.plannedDatepicker ? moment(data.consultationResult.plannedDatepicker) : null);
    }
  }, [data, open]);

  useEffect(() => {
    setOnError(false);
  }, [certificatePassword]);

  return (
    <Modal
      closable={!mutation.isLoading}
      destroyOnClose
      afterClose={onAfterClose}
      maskClosable={false}
      {...testId('add-consultation-modal')}
      // maskClosable={false}
      title={
        <div className="flex flex-row items-center justify-between">
          <div>
            <h1 className="text-xl font-extrabold uppercase">{modalTitle.title}</h1>
            <p className=" text-sm text-gray-600">{modalTitle.description}</p>
          </div>
          {mode === 'edit' && (
            <div>
              <div className="mr-8 rounded-md bg-gray-300 py-1 px-2 text-xs text-gray-600" {...testId('consultation-id')}>
                Id: {id}
              </div>
            </div>
          )}
        </div>
      }
      className="modal-styles input-styles"
      width={1000}
      open={open}
      onCancel={onClose}
      footer={[
        <Fragment key="footer">
          <div className="pb-5">
            {show.includes('files') ? (
              <Button disabled={mutation.isLoading} onClick={onClose} color="default" size="custom-lg" margin="mr-6" {...testId('files-close-button')}>
                Zamknij
              </Button>
            ) : (
              <Button disabled={mutation.isLoading} onClick={onClose} color="default" size="custom-lg" margin="mr-6" {...testId('files-cancel-button')}>
                Anuluj
              </Button>
            )}

            {show.includes('files') ||
            (show.includes('approved_by_signin') &&
              data.consultant.consultantApprovedList !== (null || undefined) &&
              data.consultant.consultantApprovedList.find((item) => item.consultantId === user.doctorId).consultantApproved === true) ? null : (
              <Button disabled={mutation.isLoading || onError} loading={mutation.isLoading} onClick={onFinish} color="darkblue" size="custom-lg" {...testId('save-button')}>
                Zapisz
              </Button>
            )}
          </div>
        </Fragment>,
      ]}
    >
      <Spin spinning={mutation.isLoading}>
        <Form
          layout="vertical"
          form={form}
          name="consultation"
          onFinish={onFinish}
          scrollToFirstError
          initialValues={{ doctorId: mode === 'edit' && _.has(data, 'doctor.id') ? data.doctor.id : user.doctorId }}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && onError) e.preventDefault();
          }}
          validateMessages={validationMessages}
          preserve
        >
          {show.includes('doctor') && <Doctor form={form} mode={mode} />}
          {show.includes('patient') && <Patient form={form} data={data} />}
          {show.includes('referral') && <Referral form={form} data={data} />}

          {/*{mode === 'add' && (*/}
          {/*  <div className="grid grid-cols-6 pt-4">*/}
          {/*    <div className="col-span-7 col-start-2 grid">*/}
          {/*      <p className="font-light">UWAGA! Konsultacja zostanie zarejestrowana w trybie roboczym.</p>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
          {show.includes('consultant') && (
            <Consultant
              form={form}
              data={data}
              // defaultMode="scheduled"
            />
          )}
          {show.includes('files') && <Files />}
          {show.includes('qualification_card') && <QualificationCard data={data} />}
          {show.includes('consultation_result') && <ConsultationResult data={data} />}
          {show.includes('approved_by') && <ApprovedBy data={data} user={user} />}
          {show.includes('approved_by_signin') && <ApprovedBySignIn data={data} />}
        </Form>
      </Spin>
    </Modal>
  );
};

AddConsultationModal.propTypes = {
  mode: PropTypes.string,
};

AddConsultationModal.defaultProps = {
  mode: 'add',
};

export default AddConsultationModal;
