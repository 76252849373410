import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { testId as testAttribute } from '@/tests';

const CardHeader = ({ title, onEdit, isVisibleEditBtn, addons,  testId }) => (
  <div className="flex flex-row items-center justify-between" {...testAttribute(`card-header-${testId}`)}>
    <p {...testAttribute('card-header-title')}>{title}</p>
    <div className="flex flex-row items-center gap-3">
    {addons && <div>{addons}</div>}
    {isVisibleEditBtn && (
      <Button onClick={onEdit} type="link" {...testAttribute(`card-header-${testId}-edit-button`)}>
        edycja
      </Button>
    )}
    </div>
  </div>
);

CardHeader.propTypes = {
  title: PropTypes.string,
  onEdit: PropTypes.func,
  isVisibleEditBtn: PropTypes.bool,
  testId: PropTypes.string,
};

CardHeader.defaultProps = {
  title: null,
  onEdit: () => null,
  isVisibleEditBtn: false,
  testId: 'component',
};

export default CardHeader;
