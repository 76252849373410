/* eslint-disable */
import { Badge, Button as ButtonAnt, Col, Drawer, Empty, Menu, Skeleton, Spin } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { AiFillPushpin, AiOutlineArrowLeft, AiOutlineArrowRight, AiOutlineClose, AiOutlineFullscreen, AiOutlinePushpin } from 'react-icons/ai';
import { BsCircle } from 'react-icons/bs';
import { TbRefresh } from 'react-icons/tb';
import ls from 'local-storage';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import AuthContext from '@/contexts/Auth.context';
import Button from '../../Button2/Button.component';
import Card from '../../Card/Card.component';
import DocumentIcon from '../../CustomIcon/DocumentIcon.component';

const FilesDetails = ({ onEdit, data, status, isLoading, token, showEditButton }) => {
  const [isActive, setIsActive] = useState(false);
  const [open, setOpen] = useState(true);
  const [frozen, setFrozen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { teleconsultation, documentation } = status;

  const handleFullScreen = useFullScreenHandle();
  const queryClient = useQueryClient();

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const onChangeFullScreen = useCallback((state) => {
    if (state === true) {
      setIsFullScreen(true);
    } else if (state === false) {
      setIsFullScreen(false);
    }
  });

  useEffect(() => {
    if (isFullScreen) {
      handleFullScreen.enter();
    } else {
      handleFullScreen.exit();
    }
  }, [isFullScreen]);

  const onClose = () => {
    setOpen(false);
  };

  const showDrawer = () => {
    if (frozen) {
      setIsActive(true);
    } else {
      setOpen(true);
    }
  };

  const hideDrawer = () => {
    setOpen(false);
    setIsActive(false);
  };

  const pinClick = () => {
    setIsActive(!isActive);
    setFrozen(!frozen);
    setOpen(false);
  };

  const frozenHandle = () => {
    setFrozen(!frozen);
    setIsActive(!isActive);
    setOpen(true);
  };

  const refreshFiles = () => {
    queryClient.refetchQueries(['consultation-id']);
    queryClient.refetchQueries(['meddream-token', id, user.doctorId]);
  };

  if (isLoading) {
    return <Skeleton />;
  }

  let listOfExaminationsDicom = [];
  if (data.examinations.some((examination) => examination.examinationType === 'DICOM')) {
    listOfExaminationsDicom.push({
      label: 'Badania obrazowe',
      key: 'dicom',
      icon: (
        <div className="h-max w-max pb-2">
          <div className="flex flex-row items-center justify-center">
            <DocumentIcon />
          </div>
          <p className="-my-6 mr-3 text-exdicom font-extrabold uppercase">dic</p>
        </div>
      ),
    });
  }

  const listOfExaminationsFiles = data.examinations
    .filter((examination) => examination.examinationType === 'OTHER')
    .map((examination) => ({
      label: `${examination.examinationName}`,
      key: `examination-${examination.examinationId}`,
      children:
        examination.files.length === 0
          ? null
          : examination.files.map((fileItem) => {
              const fileExtension = fileItem.fileUrl.split('.').pop().toUpperCase();
              return {
                label: `z dnia ${fileItem.examinationDate}`,
                key: `file-${fileItem.fileId}`,
                icon: (
                  <div className="h-max w-max pb-2">
                    <div className="flex flex-row items-center justify-center">
                      <BsCircle className="mr-2 h-2 w-2 rounded-full bg-red-300" />
                      <DocumentIcon />
                    </div>
                    <p className={`-my-6 ${fileExtension === 'PNG' || fileExtension === 'MP4' ? 'ml-2' : 'ml-3'} text-exdicom font-extrabold uppercase`}>{fileExtension}</p>
                  </div>
                ),
                examinationdate: `${fileItem.examinationDate}`,
                extension: fileExtension,
                fileurl: fileItem.fileUrl,
              };
            }),
    }));

  const listOfExaminations = [...listOfExaminationsDicom, ...listOfExaminationsFiles];

  let selectedMenuKey = listOfExaminations.some((file) => file.key === 'dicom')
    ? 'dicom'
    : listOfExaminations.map((file) => (file.children?.length > 0 ? file.children[0].key : null)).find((key) => key !== null);

  if (selectedMenuKey === undefined) {
    selectedMenuKey = listOfExaminations[0]?.key;
  }

  const [currentSelection, setCurrentSelection] = useState(`${selectedMenuKey}`);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sourceUrl, setSourceUrl] = useState(null);

  const handleMenuClick = ({ key }) => {
    if (key === 'dicom') {
      setSelectedFile(key);
      setCurrentSelection(key);
      data.examinations.every((exam) => exam.dicomSeriesList.length === 0) ? null : queryClient.refetchQueries(['meddream-token', id, user.doctorId]);
    } else {
      const selectedFileMenu = listOfExaminations
        .map((file) => file.children || [])
        .flat()
        .find((file) => file.key === key);
      setSelectedFile(selectedFileMenu);
      setCurrentSelection(key);
    }
  };

  useEffect(() => {
    if (selectedFile !== 'dicom') {
      const token = ls('token');
      const requestOptions = {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      };
      if (selectedFile && selectedFile.fileurl) {
        setIsPending(true);
        fetch(selectedFile.fileurl, requestOptions)
          .then((response) => response.blob())
          .then((blob) => {
            const objectUrl = URL.createObjectURL(blob);
            setSourceUrl(objectUrl);
            setIsPending(false);
          });
      }
    }
  }, [selectedFile]);

  const countExaminationsForBadge =
    data.examinations.filter((exam) => exam.examinationType === 'OTHER').length + (data.examinations.some((exam) => exam.examinationType === 'DICOM') ? 1 : 0);

  const announcementCondition = data.examinations.every((exam) => exam.dicomSeriesList.length === 0) || selectedFile === null || selectedFile === undefined;

  return (
    <FullScreen handle={handleFullScreen} onChange={onChangeFullScreen}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card
          title={
            <div className="flex flex-row justify-between">
              <div className="flex flex-row items-center">
                <p className="mr-4">Wyniki badań</p>
                <div>
                  {data.examinations.length === 0 ? null : (
                    <div>
                      {open || isActive ? (
                        <Badge count={<p className="ant-badge-count">{countExaminationsForBadge}</p>} offset={[3, 7]} className="h-full w-full">
                          <Button type="primary" onClick={hideDrawer} icon={<AiOutlineArrowLeft className="h-max w-max text-black" />}>
                            Ukryj pliki
                          </Button>
                        </Badge>
                      ) : (
                        <Badge count={<p className="ant-badge-count">{countExaminationsForBadge}</p>} offset={[3, 7]} className="h-full w-full">
                          <Button type="primary" onClick={showDrawer} icon={<AiOutlineArrowRight className="h-max w-max text-black" />}>
                            Pokaż pliki
                          </Button>
                        </Badge>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-row items-center justify-center">
                {data.examinations.length === 0 ? null : (
                  <div className="flex flex-row ">
                    <ButtonAnt className="mr-4 flex items-center justify-center" onClick={refreshFiles} icon={<TbRefresh className="h-6 w-6 text-black" />} />
                    <ButtonAnt className="flex items-center justify-center" onClick={toggleFullScreen} icon={<AiOutlineFullscreen className="h-6 w-6 text-black" />} />
                  </div>
                )}
                {showEditButton && !isFullScreen && (
                  <ButtonAnt onClick={onEdit} type="link">
                    edycja
                  </ButtonAnt>
                )}
              </div>
            </div>
          }
        >
          {data.examinations.length === 0 ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          ) : (
            <div className="flex flex-row">
              <div className="file-container-height relative flex w-full overflow-hidden ">
                {isActive && (
                  <div className="pin-files-width px-6">
                    <div className="flex justify-end">
                      <div className="max-w-max pb-4" onClick={frozenHandle}>
                        {frozen ? <AiFillPushpin className="h-6 w-6 cursor-pointer text-black" /> : <AiOutlinePushpin className="h-6 w-6 cursor-pointer text-black" />}
                      </div>
                    </div>
                    <Menu onClick={handleMenuClick} mode="inline" items={listOfExaminations} className="bg-gray-50" />
                  </div>
                )}
                {!isActive && announcementCondition && open ? <div className="empty-or-announcement-in-files-details px-6" /> : null}

                <>
                  {selectedFile === 'dicom' ? (
                    data.examinations.every((exam) => exam.dicomSeriesList.length === 0) ? (
                      <div className="flex w-full items-center justify-center">
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Brak danych do wyświetlenia</span>} />
                      </div>
                    ) : (
                      <iframe title="Files" src={`${process.env.REACT_APP_DICOM_URL}?jwt=${token?.data?.accessToken}`} className="source-file w-full" />
                    )
                  ) : (
                    <>
                      {isPending ? (
                        <Spin className="flex w-full items-center justify-center" />
                      ) : (
                        <div className="w-full">
                          {sourceUrl && selectedFile && selectedFile.examinationdate && (
                            <div className="w-full">
                              <div className="flex w-full flex-col">
                                <p className="flex items-center justify-end">Data badania: {selectedFile.examinationdate}</p>
                                {selectedFile.extension !== 'MP4' ? (
                                  <embed src={sourceUrl} className="source-file w-full" />
                                ) : (
                                  <video src={sourceUrl} className="source-file w-full" style={{ height: '600px' }} controls></video>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="flex h-full items-center justify-center">
                            {(data.examinations.some((exam) => exam.files.length !== 0) && selectedFile === null) ||
                            (data.examinations.every((exam) => exam.dicomSeriesList.length !== 0) && selectedFile === null) ? (
                              <span className="text-center text-2xl">Aby zobaczyć podgląd pliku, wybierz badanie z listy znajdującej się po lewej stronie.</span>
                            ) : (
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>Brak danych do wyświetlenia</span>} />
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </>
                <Drawer
                  extra={
                    <div onClick={pinClick}>
                      {isActive ? <AiFillPushpin className="h-6 w-6 cursor-pointer text-black" /> : <AiOutlinePushpin className="h-6 w-6 cursor-pointer text-black" />}
                    </div>
                  }
                  placement="left"
                  closeIcon={<AiOutlineClose className="h-6 w-6 text-black" />}
                  onClose={onClose}
                  open={open}
                  getContainer={false}
                  mask={false}
                  style={{
                    position: 'absolute',
                  }}
                >
                  <div className="w-full bg-gray-50 pr-4">
                    <Menu onClick={handleMenuClick} mode="inline" items={listOfExaminations} className="bg-gray-50" />
                  </div>
                </Drawer>
              </div>
            </div>
          )}
        </Card>
      </Col>
    </FullScreen>
  );
};

export default FilesDetails;
