import classnames from 'classnames';
import React, { useState } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { testId as testAttribute } from '@/tests';

export const MaskedText = ({ children, onClick, maskCharacter, tooltipContent, className, testId }) => {
  const [isMasked, setIsMasked] = useState(true);

  const toggleMaskedState = () => {
    setIsMasked(!isMasked);
    if (typeof onClick === 'function') {
      onClick(!isMasked);
    }
  };

  const classNames = classnames(className, 'cursor-pointer');

  return isMasked ? (
    <Tooltip title={tooltipContent}>
      <span className={classNames} onClick={toggleMaskedState} {...testAttribute(`masked-${testId}`)}>
        {children
          .split('')
          .map(() => maskCharacter)
          .join('')}
      </span>
    </Tooltip>
  ) : (
    <span className={classNames} onClick={toggleMaskedState} {...testAttribute(`unmasked-${testId}`)}>
      {children}
    </span>
  );
};

MaskedText.propTypes = {
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  maskCharacter: PropTypes.string,
  tooltipContent: PropTypes.string,
  testId: PropTypes.string,
};

MaskedText.defaultProps = {
  onClick: () => null,
  maskCharacter: '*',
  tooltipContent: 'Kliknij aby pokazać',
  testId: 'text',
};
