/* eslint-disable */
import React from 'react';
import { Form, Table, Checkbox, Avatar, Image, Tag } from 'antd';
import doctorSvg from '../../assets/images/doctor.svg';
import { nanoid } from 'nanoid';

const ApprovedBy = ({ data, user }) => {
  const isCurrentUserRequestingDoctor =
    data?.consultant?.consultantList !== (null && undefined) && !(data?.consultant?.consultantList.find((item) => item.consultantId === user.doctorId) !== (null || undefined));
  console.log(!isCurrentUserRequestingDoctor);
  const columns = [
    {
      title: 'Lekarz',
      align: 'center',
      key: (index) => index,
      render: (record) => (
        <div className="grid grid-cols-2 items-center justify-center">
          <div>
            <Avatar className="h-14 w-14 border-4 border-sky-300" src={<Image className="h-16 w-16 rounded-full object-cover" src={doctorSvg} preview={false} />} />
          </div>
          <div className="pt-2">
            <h1 className="w-full font-bold">
              dr {record.consultantName} {record.consultantSurname}
            </h1>
            <p className="w-full text-gray-500">{record.consultantSpecialty}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Wymagani do zatwierdzenia konsultacji',
      align: 'center',
      render: (record, text, index) => {
        const recordInApprovedList = data.consultant.consultantApprovedList && data.consultant.consultantApprovedList.find((item) => item.consultantId === record.consultantId);

        return (
          <>
            <Form.Item name={['doctorId', index]} initialValue={record.consultantId} className="h-0 align-middle" />
            {!recordInApprovedList || recordInApprovedList.consultantApproved === false ? (
              <Form.Item
                name={['required', index]}
                initialValue={
                  !isCurrentUserRequestingDoctor && user.doctorId === record.consultantId ? true : recordInApprovedList ? recordInApprovedList.consultantRequired : false
                }
                valuePropName="checked"
                className="align-middle"
              >
                <Checkbox disabled={!isCurrentUserRequestingDoctor && user.doctorId === record.consultantId} />
              </Form.Item>
            ) : (
              // : ''}
              <Form.Item name={['required', index]} initialValue>
                <Tag>Podpisano</Tag>
              </Form.Item>
            )}
          </>
        );
      },
    },
  ];
  // console.log(data.consultant.consultantList);

  return (
    <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
      <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
        <div>
          <h1 className="text-lg font-semibold">Zatwierdzone przez:</h1>
          <p className="text-gray-500">Lista lekarzy</p>
        </div>
      </div>
      <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
        <Table
          rowKey={() => nanoid()}
          columns={columns}
          dataSource={data.consultant.consultantList}
          pagination={false}
          className="ant-table-approvedby-styles"
          scroll={{ x: 'max-content' }}
        />
        {/* <Table columns={columns} dataSource={data.consultant.consultantList.find((item) => ((item.consultantId === data.consultant.consultantApprovedList.consultantId)))} pagination={false} className="ant-table-approvedby-styles" /> */}
      </div>
    </div>
  );
};

export default ApprovedBy;
