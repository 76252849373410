import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Col } from 'antd';
import { testId } from '@/tests';
import Headline from '../Headline/Headline.component';
import Card from '../Card/Card.component';
import dateSvg from '../../assets/images/date.svg';

const ConsultationDateDetails = ({ data, status, onEdit, extra, showEditButton }) => (
  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
    <Card title={<Headline titleContent="Termin konsultacji" disabled={!showEditButton} status={status} onEditTitle={onEdit} />}>
      <div className="flex h-32 gap-4 bg-gray-50 sm:grid sm:px-4">
        <div className="flex flex-row justify-between">
          <div className="over flex h-32 flex-col overflow-y-auto">
            {_.has(data, 'deadline.consultationDateFrom') && data.deadline.consultationDateFrom ? (
              <div className="flex content-center items-center">
                <img src={dateSvg} alt="Termin konsultacji" className="h-24" />
                <div className="flex flex-col  justify-center px-4 text-sm text-gray-900  sm:col-span-2 sm:mt-0">
                  <h3 className="text-xl font-medium leading-6 text-gray-900" {...testId('consultation-date')}>
                    {data && moment(data.deadline.consultationDateFrom).format('YYYY-MM-DD')}{' '}
                  </h3>
                  <p {...testId('consultation-time')}>godz. {data && moment(data.deadline.consultationDateFrom).local().format('HH:mm')}</p>
                </div>
                {extra}
              </div>
            ) : status.teleconsultation?.mode === 'URGENT' ? (
              <div className="mr-8 rounded-md bg-green-300 py-1 px-2 text-black">
                Konsultacje w trybie NAGŁYM nie wymagają wypełnienia karty kwalifikacyjnej i załączania wyników badań. Dokumentacja będzie pomocna przy ocenie stanu pacjenta ale
                nie niezbędna.
              </div>
            ) : status.teleconsultation?.mode === 'OFFLINE' ? (
              <div className="mr-8 rounded-md bg-gray-300 py-1 px-2 text-xs text-black">Konsultacja w trybie OFFLINE</div>
            ) : (
              <div className="mr-8 h-6 rounded-md bg-red-600 py-1 px-2 text-xs text-white">Nie wybrano terminu</div>
            )}
          </div>
        </div>
      </div>
    </Card>
  </Col>
);

export default ConsultationDateDetails;
