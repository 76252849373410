import moment from 'moment';
import { ensureArray, serializeDate } from '../service';

// PAGINATION
export const serializePagination = (pagination) => {
  const { pageSize, current } = pagination;

  return {
    current,
    pageSize,
  };
};

export const deserializePagination = (queryParams) => {
  const { pageSize, current } = queryParams;

  return {
    current: current ? parseInt(current, 10) : 1,
    pageSize: pageSize ? parseInt(pageSize, 10) : 10,
  };
};

// FILTERS

const initialFiltersInfo = {
  consultant: null,
  patient: null,
  requestingDoctor: null,
  statusConsultation: [],
  statusDocumentation: [],
  type: [],
  consultationDateFrom: undefined,
  consultationDateTo: undefined,
  registrationDateFrom: undefined,
  registrationDateTo: undefined,
  modeConsultation: [],
};

export const serializeFiltersInfo = (filtersInfo) => {
  const {
    consultant,
    patient,
    requestingDoctor,
    statusConsultation,
    statusDocumentation,
    type,
    consultationDateFrom,
    consultationDateTo,
    registrationDateFrom,
    registrationDateTo,
    modeConsultation,
  } = filtersInfo;

  return {
    consultant,
    patient,
    requestingDoctor,
    statusConsultation,
    statusDocumentation,
    type,
    consultationDateFrom: serializeDate(consultationDateFrom, undefined, false),
    consultationDateTo: serializeDate(consultationDateTo, undefined, false),
    registrationDateFrom: serializeDate(registrationDateFrom),
    registrationDateTo: serializeDate(registrationDateTo),
    modeConsultation,
  };
};

export const deserializeFiltersInfo = (queryParams) => {
  const {
    consultant,
    patient,
    requestingDoctor,
    statusConsultation,
    statusDocumentation,
    type,
    consultationDateFrom,
    consultationDateTo,
    registrationDateFrom,
    registrationDateTo,
    modeConsultation,
  } = queryParams;

  return {
    ...initialFiltersInfo,

    consultant: consultant || initialFiltersInfo.consultant,
    patient: patient || initialFiltersInfo.patient,
    requestingDoctor: requestingDoctor || initialFiltersInfo.requestingDoctor,
    statusConsultation: ensureArray(statusConsultation) || initialFiltersInfo.statusConsultation,
    statusDocumentation: ensureArray(statusDocumentation) || initialFiltersInfo.statusDocumentation,
    type: ensureArray(type) || initialFiltersInfo.type,
    consultationDateFrom: consultationDateFrom ? moment(consultationDateFrom).local() : initialFiltersInfo.consultationDateFrom,
    consultationDateTo: consultationDateTo ? moment(consultationDateTo).local() : initialFiltersInfo.consultationDateTo,
    registrationDateFrom: registrationDateFrom ? moment(registrationDateFrom) : initialFiltersInfo.registrationDateFrom,
    registrationDateTo: registrationDateTo ? moment(registrationDateTo) : initialFiltersInfo.registrationDateTo,
    modeConsultation: ensureArray(modeConsultation) || initialFiltersInfo.modeConsultation,
  };
};

// SORTER

const initialSorter = {
  field: null,
  order: null,
};

export const serializeSorter = (sorter) => {
  const { field, order } = sorter;

  if (!field || !order) {
    return {};
  }

  return {
    field,
    order,
  };
};

export const deserializeSorter = (queryParams) => {
  const { field, order } = queryParams;

  if (!field || !order) {
    return initialSorter;
  }

  return {
    field,
    order,
  };
};
