import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
// import Auth from '@/components/Auth/Auth.component';
import { PageParamsContextProvider } from '@/core/PageParams';
import NodeNationalErrorPage from '@/pages/NodeNationalError.page';
import NodeNationalSuccessPage from '@/pages/NodeNationalSuccess.page';
import AuthLayout from './layaout/Auth.Layout';
// import ErrorPage from './pages/Error.page';
import ConsultationsPage from './pages/Consultations.page';
import ConsultationDetailsPage from './pages/ConsultationDetails.page';
import SchedulePage from './pages/Schedule.page';
import UserProfilePage from './pages/UserProfile.page';
import LoginPage from './pages/Login.page';
import ResetPassword from './pages/ResetPassword.page';
// import ZoomPage from './pages/Zoom.page';
import AppLogoutProvider from './components/AppLogout/AppLogout.provider';
import SignatureWithoutLogging from './components/Modals/SignatureWithoutLoggingIn/SignatureWithoutLogging.component';
import AuthContext from './contexts/Auth.context';

const Schedule = () => {
  const { user } = useContext(AuthContext);
  const isUserProvideConsultation = user.provideConsultation;
  return isUserProvideConsultation ? <SchedulePage /> : <Navigate to="/consultations" />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AppLogoutProvider timeToLogout={parseInt(process.env.REACT_APP_TIME_TO_LOGOUT, 10)} sessionTimeNotActive={parseInt(process.env.REACT_APP_SESSION_TIME_NOT_ACTIVE, 10)}>
        <PageParamsContextProvider>
          <AuthLayout />
        </PageParamsContextProvider>
      </AppLogoutProvider>
    ),
    // errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <ConsultationsPage />,
      },
      {
        path: '/consultations',
        element: <ConsultationsPage />,
      },
      {
        path: '/consultations/:id',
        element: <ConsultationDetailsPage />,
      },
      {
        path: '/schedule',
        element: <Schedule />,
      },
      {
        path: '/user-profile',
        element: <UserProfilePage />,
      },
    ],
  },
  // {
  //   path: '/zoom/:meetingNumber/:idConsultation',
  //   element: (
  //     <Auth>
  //       <ZoomPage />
  //     </Auth>
  //   ),
  // },
  {
    path: '/auth',
    element: <LoginPage />,
  },
  {
    path: '/signature',
    element: <SignatureWithoutLogging />,
  },
  {
    path: '/auth/failed',
    element: <NodeNationalErrorPage />,
  },
  {
    path: '/auth/success',
    element: <NodeNationalSuccessPage />,
  },
  {
    path: '/reset',
    element: <ResetPassword />,
  },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
