/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect } from 'react';
import { Alert, Modal, Spin } from 'antd';
import { testId } from '@/tests';
import { getConsultationResultPDF } from '@/utils/pdfPreview';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import Button from '../Button2/Button.component';

type PDFModalMode = 'PREVIEW' | 'SIGN' | 'CLOSED';

export const getConsultationResultPDFDownloadURL = (pdfUrl) => `${pdfUrl}?download=true`;

interface ConsultationResultPDFModalProps {
  pdfUrl?: string;
  canSign: boolean;
  consultationId: string;
  onSignClick: () => void;
  mode: PDFModalMode;
  onClose: () => void;
}

export const ConsultationResultPDFModal: FC<ConsultationResultPDFModalProps> = ({ pdfUrl, consultationId, canSign, mode, onClose, onSignClick }) => {
  const queryClient = useQueryClient();

  const open = mode !== 'CLOSED';

  const { data, isLoading, isError, error } = useQuery<string, AxiosError<{ message: string }>>(['consultationResultPDF', pdfUrl], () => getConsultationResultPDF(pdfUrl), {
    enabled: Boolean(open && pdfUrl),
  });

  const handleSignClick = () => {
    onSignClick();
  };

  useEffect(() => {
    if (!pdfUrl && open) {
      queryClient.refetchQueries(['consultation-id', consultationId]);
    }
  }, [pdfUrl, consultationId, open]);

  return (
    <Modal
      closable={isLoading}
      destroyOnClose
      maskClosable={false}
      {...testId('consultation-result-pdf-modal')}
      title={
        <div className="flex flex-row items-center justify-between">
          <h1 className="text-xl font-extrabold uppercase">{mode === 'PREVIEW' ? 'Wynik konsultacji w formacie HL7 CDA' : 'Podpisz wynik konsultacji certyfikatem ZUS'}</h1>
        </div>
      }
      className="pdf-modal modal-styles input-styles"
      width="max(80vw, 1200px)"
      open={open}
      onCancel={onClose}
      footer={
        <div className="flex w-full flex-row items-center justify-end gap-5 pb-5">
          {data && (
            <>
              {mode === 'PREVIEW' && (
                <a href={getConsultationResultPDFDownloadURL(pdfUrl)} rel="noreferrer">
                  {/* @ts-ignore */}
                  <Button color="darkblue" size="custom-lg">
                    Pobierz
                  </Button>
                </a>
              )}
              {mode === 'SIGN' && canSign && (
                <>
                  {/* @ts-ignore */}
                  <Button color="darkblue" size="custom-lg" disabled={!data} onClick={handleSignClick}>
                    Podpisz certyfikatem ZUS
                  </Button>
                </>
              )}
            </>
          )}
          {/* @ts-ignore */}
          <Button color="default" size="custom-lg" onClick={onClose}>
            Zamknij
          </Button>
        </div>
      }
    >
      <div style={{ height: 'max(70vh, 700px)' }} className="relative col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
        <Spin spinning={isLoading} className="h-full" wrapperClassName="h-full">
          {pdfUrl ? (
            isError ? (
              <Alert message="Wystąpił błąd podczas pobierania dokumentu" description={error?.response?.data?.message || ''} type="error" showIcon />
            ) : (
              data && (
                <object title="Wynik konsultacji HL7 CDA" data={`data:application/pdf;base64,${data}`} width="100%" height="100%" type="application/pdf">
                  <div className="flex h-full flex-col items-center justify-center">
                    <p className="font-semibold">Twoja przeglądarka nie obsługuje wyświetlania plików PDF.</p>
                    <p>Możesz bezpośrednio pobrać wynik konsultacji klikając na przycisk poniżej:</p>
                    <a href={getConsultationResultPDFDownloadURL(pdfUrl)} rel="noreferrer">
                      {/* @ts-ignore */}
                      <Button color="darkblue" size="sm">
                        Pobierz
                      </Button>
                    </a>
                  </div>
                </object>
              )
            )
          ) : (
            <Alert message="Wynik konsultacji jest obecnie generowany" description="Spróbuj ponownie za chwilę" type="info" showIcon />
          )}
        </Spin>
      </div>
    </Modal>
  );
};
