import React from 'react';

import { isArray } from 'lodash';
import { DisplayFieldComponent } from '../types';
import { DictionaryField, SelectField } from '../../types';
import { FieldValue } from '../components';
import { DisplayFieldLabel } from '../../components';

export const SelectDisplayField: DisplayFieldComponent<SelectField | DictionaryField> = ({
  name,
  testId,
  value,
  label,
  isHidden,
  isNecessary,
  multiselect,
  tooltip,
  fieldHasNoData,
}) => {
  if (isHidden) {
    return null;
  }

  return (
    <div className="dynamic-field-result flex flex-col rounded bg-white p-2">
      <DisplayFieldLabel label={label} tooltip={tooltip} />
      {multiselect ? (
        <div className="flex flex-col break-all p-2 align-middle text-gray-600">
          {isArray(value) &&
            value.map((v, index) => (
              <FieldValue key={index} name={`${name}-${index}`} testId={`${testId}-${index}`} value={v} isNecessary={isNecessary} fieldHasNoData={fieldHasNoData}>
                {v}
              </FieldValue>
            ))}
        </div>
      ) : (
        <div className="flex break-all p-2 align-middle text-gray-600">
          <FieldValue name={name} testId={testId} value={value} isNecessary={isNecessary} fieldHasNoData={fieldHasNoData}>
            {value}
          </FieldValue>
        </div>
      )}
    </div>
  );
};
