import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useCookie } from 'react-use';
import ls from 'local-storage';
import { useNavigate } from 'react-router-dom';
import { Alert, Form, Select, Spin } from 'antd';
import Button from '@/components/Button2/Button.component';
import logoXlSvg from '../assets/images/logo-xl-white.svg';

const { Option } = Select;

const NodeNationalSuccessPage = () => {
  const [token] = useCookie('X-TOKEN');
  const navigate = useNavigate();

  if (!token) {
    return <div>Brak tokena.</div>;
  }

  // Dekodowanie tokena-a
  const { uid, units } = jwtDecode(token);

  // Zapis token/userId do localstorage
  ls('userId', uid);
  ls('token', token);

  // Sprawdzenie ile jednostek
  const unitIds = Object.keys(units);

  const handleFinish = (values) => {
    ls('unitId', values.unitId);
    navigate('/consultations');
  };

  useEffect(() => {
    // Przekierowanie jesli tylko jedna jednostka
    if (unitIds.length === 1) {
      ls('unitId', unitIds[0]);
      navigate('/consultations');
    }
  }, [unitIds]);

  return (
    <div className=" h-screen overflow-auto bg-gray-800 p-10">
      <div className="flex flex-col items-center justify-center text-white">
        <img className="w-80 pb-10" src={logoXlSvg} alt="Logo E-konsylium" />

        {unitIds.length === 1 && (
          <>
            <Spin spinning />
            <div className="my-form-wrapper w-[500px] rounded-md  p-5">
              <h1 className="pb-10 text-center text-3xl font-medium text-gray-100">Logowanie</h1>
              <Alert
                message="Węzeł krajowy - autoryzacja poprawna"
                description="Za chwilę zostaniesz przekierowany do e-Konsylium."
                type="success"
                showIcon
                className="mx-auto mb-5 w-full"
              />
            </div>
          </>
        )}

        {unitIds.length > 1 && (
          <div className="my-form-wrapper w-[400px] rounded-md bg-white p-10">
            <h1 className="pb-10 text-center text-3xl font-medium text-gray-800">Logowanie</h1>
            <Form
              layout="horizontal"
              name="loginUnit"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={handleFinish}
              autoComplete="off"
              size="large"
            >
              <Form.Item
                name="unitId"
                label="Wybór jednostki"
                rules={[
                  {
                    required: true,
                    message: 'Pole obowiązkowe!',
                  },
                ]}
              >
                <Select style={{ width: '100%' }} placeholder="wybierz">
                  {unitIds.map((id) => (
                    <Option key={id} value={id}>
                      {units[id]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 14, span: 10 }} className=" mb-0">
                <Button submit block color="darkblue">
                  Wybierz
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

NodeNationalSuccessPage.propTypes = {};

export default NodeNationalSuccessPage;
