import React, { useState } from 'react';

import { Button, Form, Select, Tooltip } from 'antd';

import { testIdFactory } from '@/tests';
import { useQuery } from 'react-query';
import { getDictionaryValues } from '@/services/dictionaries.service';
import { debounce } from 'lodash';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { DictionaryField } from '../../types';
import { getValidationRules } from '../service';
import { useTemplateEngineContext } from '../../TemplateEngineContext';

const DEBOUNCE_TIME = 500;
const MINIMUM_SEARCH_QUERY_LENGTH = 2;

export const DictionaryFormField: FormFieldComponent<DictionaryField> = (props) => {
  const { name, label, description, dictionaryName, isDisabled, isHidden, isRequired, isNecessary, tooltip, multiselect, testId, allowCopyingFromQualificationCard } = props;
  const [searchQuery, setSearchQuery] = useState<string>('');
  const { copyContext } = useTemplateEngineContext();
  const { getFieldValue, setFieldValue } = Form.useFormInstance();

  const qualificationCardFieldValueToCopy = copyContext && allowCopyingFromQualificationCard && copyContext[allowCopyingFromQualificationCard];
  const chosenSelectOptions = getFieldValue(name) === undefined ? [] : getFieldValue(name);

  const ensureQualificationIsArray = Array.isArray(qualificationCardFieldValueToCopy) ? qualificationCardFieldValueToCopy : [];

  const arrayUniformityCheck = ensureQualificationIsArray?.every((option) => chosenSelectOptions?.includes(option));

  const handleCopyingData = () => {
    const newValue = chosenSelectOptions?.concat(ensureQualificationIsArray.filter((item) => !chosenSelectOptions?.includes(item)));
    if (Array.isArray(newValue)) {
      setFieldValue(name, newValue);
    } else {
      console.error(`Pole z którego kopiujesz nie jest typu array. ${allowCopyingFromQualificationCard}`);
    }
  };

  const handleSearch = debounce((value: string) => {
    setSearchQuery(value);
  }, DEBOUNCE_TIME);

  const { data, isFetching } = useQuery(['dictionary', dictionaryName, searchQuery], () => getDictionaryValues(dictionaryName, searchQuery), {
    enabled: !isHidden && searchQuery.length >= MINIMUM_SEARCH_QUERY_LENGTH,
  });

  if (isHidden) {
    return null;
  }

  const validations = getValidationRules(props);
  const testAttribute = testIdFactory(testId);
  const dictionaryItems = data?.data?.dictionary ?? [];
  const options = dictionaryItems.map((item) => ({ label: `${item.code} ${item.label}`, value: `${item.code} ${item.label}` }));
  const optionsWithoutDuplicates = options.filter((option, index) => options.findIndex((o) => o.value === option.value) === index);
  return (
    <div>
      <Form.Item
        rules={validations}
        label={
          label ? (
            <FormFieldLabel
              label={label}
              description={description}
              required={isRequired || isNecessary}
              fieldTooltip={tooltip}
              addons={
                allowCopyingFromQualificationCard && (
                  <Tooltip title={!arrayUniformityCheck ? '' : 'Brak danych do skopiowania'}>
                    <Button disabled={arrayUniformityCheck} onClick={handleCopyingData}>
                      Kopiuj z karty kwalifikacji
                    </Button>
                  </Tooltip>
                )
              }
            />
          ) : null
        }
        className={`dropdown-parent-${name} mb-0 py-1`}
        name={name}
        required={isRequired || isNecessary}
      >
        <Select
          className="w-full drop-shadow-sm"
          disabled={isDisabled}
          mode={multiselect ? 'multiple' : undefined}
          placeholder="Zacznij wpisywać aby wyszukać"
          allowClear={!(isRequired || isNecessary)}
          showSearch
          onSearch={handleSearch}
          options={optionsWithoutDuplicates}
          loading={isFetching}
          getPopupContainer={() => document.querySelector(`.dropdown-parent-${name}`)}
          {...testAttribute(name)}
        />
      </Form.Item>
    </div>
  );
};
