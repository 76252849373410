import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import Clock from 'react-live-clock';
import { testId } from '@/tests';

const DataTime = ({ formatTime }) => (
  <div {...testId('data-time-component')}>
    {moment().format('DD MMMM, YYYY')} <Clock format={formatTime} ticking timezone="Europe/Warsaw" />
  </div>
);

DataTime.propTypes = {
  formatTime: PropTypes.string,
};

DataTime.defaultProps = {
  formatTime: 'HH:mm',
};
export default DataTime;
