import React, { FC } from 'react';

import { Field, FormTemplate } from '../types';
import { useDynamicField } from '../useDynamicField';

import {
  TextDisplayField,
  CheckboxDisplayField,
  NumberDisplayField,
  LabelDisplayField,
  TextAreaDisplayField,
  DatepickerDisplayField,
  RadioDisplayField,
  SelectDisplayField,
} from './Fields';
import { DisplayFieldProps } from './types';
import { doesFieldHasNoDataChecked } from '../service';
import { SeparatorField, SpaceField } from '../CommonField/Fields';

interface DisplayFieldAdditionalProps {
  formTemplate: FormTemplate;
  field: Field;
  value: unknown;
  getFieldValue: (name: string) => unknown;
}

export const DisplayField: FC<DisplayFieldProps & DisplayFieldAdditionalProps> = ({ field, value, className, formTemplate, getFieldValue }) => {
  const { name, type } = field;

  const { getFieldBehavior, getFieldDescriptor } = useDynamicField(name, formTemplate);
  const fieldDescriptor = getFieldDescriptor();
  const fieldHasNoData = doesFieldHasNoDataChecked(fieldDescriptor, getFieldValue);

  const testIdPrefix = formTemplate.name ? `result-${formTemplate.name}` : 'form';

  const fieldBehavior = getFieldBehavior(getFieldValue, fieldHasNoData);

  switch (type) {
    case 'text':
      return <TextDisplayField className={className} {...field} {...fieldBehavior} value={value as string} testId={testIdPrefix} fieldHasNoData={fieldHasNoData} />;
    case 'memo':
      return <TextAreaDisplayField className={className} {...field} {...fieldBehavior} value={value as string} testId={testIdPrefix} fieldHasNoData={fieldHasNoData} />;
    case 'datepicker':
      return <DatepickerDisplayField className={className} {...field} {...fieldBehavior} value={value as string} testId={testIdPrefix} fieldHasNoData={fieldHasNoData} />;
    case 'checkbox':
      return <CheckboxDisplayField className={className} {...field} {...fieldBehavior} value={value as boolean} testId={testIdPrefix} fieldHasNoData={fieldHasNoData} />;
    case 'calculated':
    case 'inputNumber':
      return <NumberDisplayField className={className} {...field} {...fieldBehavior} value={value as number} testId={testIdPrefix} fieldHasNoData={fieldHasNoData} />;
    case 'label':
      return <LabelDisplayField className={className} {...field} {...fieldBehavior} testId={testIdPrefix} fieldHasNoData={fieldHasNoData} />;
    case 'radio':
      return <RadioDisplayField className={className} {...field} {...fieldBehavior} value={value as string} testId={testIdPrefix} fieldHasNoData={fieldHasNoData} />;
    case 'dictionary':
    case 'select':
      return <SelectDisplayField className={className} {...field} {...fieldBehavior} value={value as string} testId={testIdPrefix} fieldHasNoData={fieldHasNoData} />;
    case 'separator':
      return <SeparatorField {...field} {...fieldBehavior} />;
    case 'space':
      return <SpaceField {...field} {...fieldBehavior} />;
    default:
      return null;
  }
};
