import React, { useContext } from 'react';
import { Avatar, Badge, Col, Empty } from 'antd';
import { AiOutlineCheck } from 'react-icons/ai';
import PropTypes from 'prop-types';
import CardHeader from '@/components/CardHeader/CardHeader.component';
import Card from '../Card/Card.component';
import Button from '../Button2/Button.component';
import AuthContext from '../../contexts/Auth.context';

import doctorSvg from '../../assets/images/doctor.svg';

// eslint-disable-next-line no-unused-vars
const ApprovedBy = ({ data, status, onEdit, onSignClick, canAddDoctors, canSign }) => {
  const { teleconsultation, documentation } = status;
  const { user } = useContext(AuthContext);
  const disableStatus = ['COMPLETED', 'TO_SIGN', 'FINISHED'];
  let empty = true;
  if (data.consultant.consultantApprovedList !== null && data.consultant.consultantApprovedList !== undefined) {
    data.consultant.consultantApprovedList.map((item) => {
      if (item.consultantRequired) {
        empty = false;
      }
      return 0;
    });
  } else {
    empty = false;
  }
  if (data.consultant.consultantApprovedList === null && data.consultant.consultantList !== null) {
    empty = true;
  }
  const isVisible = ['IN_CORRECTION'].some((state) => state === documentation?.state) && data.consultant.consultantApprovedList.length !== 1;

  const isVisibleAddConsultantToApprove = (() => {
    const { consultant } = data;
    const consultantList = consultant?.consultantList ?? [];
    return (
      ['ORDERED'].some((item) => item === teleconsultation?.state && consultantList.some((userConsultant) => userConsultant.consultantId === user.doctorId)) &&
      data?.consultationResult
    );
  })();

  const isVisibleMessageConsultantToApprove = (() => {
    const { consultant } = data;
    const consultantList = consultant?.consultantList ?? [];
    return data?.consultationResult === null && consultantList.some((userConsultant) => userConsultant.consultantId === user.doctorId);
  })();

  return (
    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Card
        title={
          // <Headline
          //   titleContent="Zatwierdzone przez"
          //   disabled={
          //     data.consultant.consultantApprovedList === null ||
          //     data.consultant.consultantList === null ||
          //     empty ||
          //     data.consultant.consultantApprovedList.find((item) => item.consultantApproved === false) === (null || undefined) ||
          //     data.consultant.consultantList.length === 1
          //   }
          //   status={status}
          //   onEditTitle={onEdit('approved_by')}
          // />
          <CardHeader title="Zatwierdzone przez" onEdit={onEdit('approved_by')} isVisibleEditBtn={isVisible} />
        }
      >
        <div className="grid grid-cols-2 py-4">
          {data.consultant.consultantApprovedList === null && data.consultant.consultantList === null ? (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="col-span-2 text-black" />
          ) : (
            ''
          )}
          {data.consultant.consultantApprovedList &&
            data.consultant.consultantApprovedList.map((item) => {
              if (item.consultantRequired) {
                return (
                  <div className="mb-5 flex flex-col items-center justify-center" key={data.consultant.consultantApprovedList.indexOf(item)}>
                    {!item.consultantApproved ? (
                      <Avatar className="h-16 w-16 grayscale" src={<img className="rounded-full border-solid object-cover" src={doctorSvg} />} />
                    ) : (
                      <Badge count={<AiOutlineCheck className="h-6 w-6 rounded-full bg-lime-400 p-1 text-white" />} offset={[15, 5]} status="success">
                        <Avatar className="h-16 w-16 border-4 border-cyan-500" src={<img className="rounded-full object-cover" src={doctorSvg} />} />
                      </Badge>
                    )}
                    <div className="flex flex-col items-center justify-center pt-2">
                      <h1 className="w-max font-bold">
                        dr {item.consultantName} {item.consultantSurname}
                      </h1>
                      <p className="w-max text-gray-500">{item.consultantSpecialty}</p>
                    </div>
                    <div className="items-center justify-center pt-2">
                      {item.consultantApproved ? (
                        <div className="mb-10" />
                      ) : (
                        <Button color="darkblue" onClick={onSignClick} disabled={!canSign || !(!item.consultantApproved && item.consultantId === user.doctorId)}>
                          Podpisz
                        </Button>
                      )}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          {canAddDoctors || empty ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className="col-span-2"
              description={<span className="text-black">Brak wybranych konsultantów, którzy są wymagani do zatwierdzenia.</span>}
            >
              {isVisibleAddConsultantToApprove ? (
                <Button color="darkblue" onClick={onEdit('approved_by')}>
                  Dodaj
                </Button>
              ) : (
                isVisibleMessageConsultantToApprove && <span className="text-black">Nie możesz zlecić podpisania konsultacji, dopóki nie zostanie wypełniony jej wynik.</span>
              )}
            </Empty>
          ) : status && empty && (disableStatus.includes(status?.documentation.state) || disableStatus.includes(status?.teleconsultation.state)) ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              className="col-span-2"
              description={<span className="text-black">Brak możliwości edycji, kiedy konsultacja jest zakończona lub dokumentacja jest zamknięta. </span>}
            />
          ) : null}
        </div>
      </Card>
    </Col>
  );
};

ApprovedBy.propTypes = {
  onEdit: PropTypes.func,
};

ApprovedBy.defaultProps = {
  onEdit: () => null,
};

export default ApprovedBy;
