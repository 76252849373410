/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import { Form, Input, Select } from 'antd';
import { useFetchDoctors } from '../../api/conslutation.api';

const { Option } = Select;

const getUnitName = (doctors, doctorId) => {
  const doctor = doctors.find((item) => item.id === doctorId);
  if (!doctor) return undefined;
  return doctor.unit.name;
};

const Doctor = ({ onChange, form, mode }) => {
  const [doctors, setDoctors] = useState([]);
  const [groups, setGroups] = useState([]);
  const [unitName, setUnitName] = useState();

  const { isFetching } = useFetchDoctors({
    onSuccess: (response) => {
      setDoctors(response.data.doctors);
      setGroups(response.data.groups);
    },
  });

  const onChangeDoctor = (doctorId) => {
    if (typeof onChange === 'function') {
      onChange(value);
    }
    form.setFieldsValue({ doctorUnitName: getUnitName(doctors, doctorId) });
  };

  useEffect(() => {
    const doctorId = form.getFieldValue('doctorId');
    if (doctorId) form.setFieldsValue({ doctorUnitName: getUnitName(doctors, doctorId) });
  }, [mode, doctors]);

  return (
    <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
      <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
        <div>
          <h1 className="text-lg font-semibold">Lekarz zlecający</h1>
          <p className="text-gray-500">Dane personalne</p>
        </div>
      </div>
      <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
        <div className="flex flex-row justify-between">
          <Form.Item className="w-full" label="Imię i nazwisko" name="doctorId">
            <Select className="w-full drop-shadow-sm" style={{ width: '100%' }} onChange={onChangeDoctor} loading={isFetching} disabled>
              {doctors?.map((doctor) => (
                <Option value={doctor.id} key={doctor.id}>
                  <div className="flex-col">
                    <p>
                      {doctor.name} {doctor.surname}
                    </p>
                    <p className="font-light text-gray-500">{doctor.specialty}</p>
                  </div>
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <Form.Item className="" label="Jednostka" name="doctorUnitName">
          <Input className="drop-shadow-sm" placeholder="Jednostka" value={unitName} disabled />
        </Form.Item>
      </div>
    </div>
  );
};

export default Doctor;
