/* eslint-disable */
import React, { useState } from 'react';
import { Form, Input, Select, DatePicker } from 'antd';
import moment from 'moment';
import { validatepesel } from '../../utils/validatepesel';

const Patient = ({ form, data }) => {
  const [identity, setIdentity] = useState(data?.patient === undefined ? 'PESEL' : data.patient.patientIdentificationType);
  const [sex, setSex] = useState('');
  const regex = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0105-\u017b\u017C\u0104\u0022\-\s]*)$/;
  const regexIdentity = /^([A-Za-z0-9\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0105-\u017b\u017C\u0104\u0022\-\s]*)$/;

  const noSpaces = (e) => {
    const rgx = /^[\s]*$/;
    if (e.target.value.match(rgx)) {
      return e.target.value.slice(0, -1);
    }
    return e.target.value;
  };

  const disabledDate = (current) => {
    const today = moment().add(30, 'days').startOf('day');
    const yearsLimit = moment('1870-01-01').startOf('day');
    if (current && current > today && !current.isSame(today, 'day')) {
      return true;
    }
    if (current && current < yearsLimit) {
      return true;
    }
    return false;
  };

  return (
    <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
      <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
        <div>
          <h1 className="text-lg font-semibold">Pacjent</h1>
          <p className="text-gray-500">Dane personalne</p>
        </div>
      </div>
      <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
        <div className="grid grid-cols-2 gap-4">
          <Form.Item
            name="patientName"
            label="Imię"
            getValueFromEvent={noSpaces}
            rules={[
              { required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' },
              { max: 64, message: 'Pole przyjmuje maksymalnie 64 znaki!', validateTrigger: 'onSubmit' },
              {
                pattern: regex,
                message: 'Wprowadzono niedozwolone znaki!',
                validateTrigger: 'onChange',
              },
            ]}
          >
            <Input className="drop-shadow-sm" placeholder="Imię" onChange={noSpaces} />
          </Form.Item>
          <Form.Item
            name="patientSecondName"
            label="Drugie imię"
            getValueFromEvent={noSpaces}
            rules={[
              { max: 64, message: 'Pole przyjmuje maksymalnie 64 znaki!', validateTrigger: 'onSubmit' },
              {
                pattern: regex,
                message: 'Wprowadzono niedozwolone znaki!',
                validateTrigger: 'onChange',
              },
            ]}
          >
            <Input className="drop-shadow-sm" placeholder="Drugie imię" onChange={noSpaces} />
          </Form.Item>
          <Form.Item
            name="patientSurname"
            label="Nazwisko"
            getValueFromEvent={noSpaces}
            rules={[
              { required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' },
              { max: 64, message: 'Pole przyjmuje maksymalnie 64 znaki!', validateTrigger: 'onSubmit' },
              {
                pattern: regex,
                message: 'Wprowadzono niedozwolone znaki!',
                validateTrigger: 'onChange',
              },
            ]}
          >
            <Input className="drop-shadow-sm" placeholder="Nazwisko" onChange={noSpaces} />
          </Form.Item>
          <Form.Item label="Płeć" name="patientGender" rules={[{ required: true, message: 'Pole wymagane!' }]}>
            <Select
              className="pr-0.5"
              dropdownMatchSelectWidth={false}
              onChange={(value) => {
                setSex(value);
              }}
              options={[
                { value: 'MALE', label: 'mężczyzna' },
                { value: 'FEMALE', label: 'kobieta' },
              ]}
            />
          </Form.Item>
          <div>
            <div className="flex flex-row items-start">
              <Form.Item
                label={
                  <div className="flex flex-row items-center">
                    <span className="custom-required">*</span>
                    <span>{identity === 'PESEL' ? 'Pesel' : 'Inny identyfikator'}</span>
                  </div>
                }
                name="identificationType"
                initialValue={data?.patient === undefined ? 'PESEL' : data.patient.patientIdentificationType}
                className="w-1/2"
              >
                <Select
                  className="pr-0.5"
                  dropdownMatchSelectWidth={false}
                  onChange={(value) => {
                    setIdentity(value);
                    form.resetFields(['patientIdentity', 'patientDateOfBirth']);
                  }}
                  options={[
                    { value: 'PESEL', label: 'PESEL' },
                    { value: 'OTHER', label: 'Inny identyfikator' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                label={
                  <div className="flex flex-row items-center">
                    <span></span>
                  </div>
                }
                name="patientIdentity"
                className="w-1/2 ant-hide-require-styles"
                onChange={() => {
                  if (identity === 'PESEL' && validatepesel(form.getFieldValue('patientIdentity'), false)) {
                    form.setFieldsValue({ patientDateOfBirth: validatepesel(form.getFieldValue('patientIdentity'), true) });
                  }
                }}
                rules={
                  identity !== 'PESEL'
                    ? [
                        { required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' },
                        { max: 128, message: 'Pole przyjmuje maksymalnie 128 znaków!', validateTrigger: 'onSubmit' },
                        {
                          pattern: regexIdentity,
                          message: 'Wprowadzono niedozwolone znaki!',
                          validateTrigger: 'onChange',
                        },
                      ]
                    : [
                        { required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' },
                        { len: 11, message: 'Pole musi zawierać 11 cyfr!', validateTrigger: 'onSubmit' },
                        { pattern: /^(\d+)$/, message: 'Pole przyjmuje tylko cyfry!', validateTrigger: 'onChange' },
                        {
                          validator: async (_, patientIdentity) => {
                            if (!validatepesel(patientIdentity, false)) {
                              return Promise.reject(new Error('Podany numer PESEL jest nieprawidłowy!'));
                            }
                          },
                          validateTrigger: 'onChange',
                        },
                      ]
                }
              >
                <Input className="drop-shadow-sm" placeholder={identity === 'PESEL' ? 'Pesel' : 'Inny identyfikator'} maxLength={identity === 'PESEL' ? '11' : ''} />
              </Form.Item>
            </div>
          </div>
          <Form.Item
            name="patientDateOfBirth"
            label="Data urodzenia"
            rules={[
              { required: true, message: 'Pole wymagane!' },
              {
                validator: async (_, patientDateOfBirth) => {
                  if (disabledDate(patientDateOfBirth)) {
                    return Promise.reject(new Error('Najpóźniejsza data urodzenia może mieć maksymalnie 30 dni w przód od dnia dzisiejszego!'));
                  }
                },
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <DatePicker className="w-full drop-shadow-sm" placeholder="Data urodzenia" disabledDate={disabledDate} inputReadOnly />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

export default Patient;
