import { createContext } from 'react';

interface UnitContext {
  unit: unknown;
  units: unknown[];
  user: Partial<{
    id: number;
    doctorId: number;
    name: string;
    lastname: string;
  }>;
}

const AuthContext = createContext<UnitContext>({
  unit: {},
  units: [],
  user: {},
});

export default AuthContext;
