import { Rule } from 'antd/lib/form';
import { memoize } from 'lodash';
import { CheckboxField, Field, FieldBehavior, FormField } from '../types';
import { StaticValidationDescription, StaticValidationDescriptionObject } from '../validation/types';
import { FormFieldComponentProps } from './types';
import { DEFAULT_VALIDATION_MESSAGES } from '../validation/messages';
import { getNoDataFieldName, getRelatedFieldsNames } from '../service';

const isValidationDescriptionObject = <T>(validationDescription: StaticValidationDescription<T>): validationDescription is StaticValidationDescriptionObject<T> =>
  !Array.isArray(validationDescription) && typeof validationDescription === 'object';

const extractValidationDescription = <T>(validationDescription: StaticValidationDescription<T>) =>
  isValidationDescriptionObject(validationDescription) ? { value: validationDescription.value, message: validationDescription.message } : { value: validationDescription };

const createValidationRule = (condition: (fieldValue: unknown) => boolean, defaultErrorMessage: string, errorMessage?: string) => ({
  validator: (_, fieldValue) => {
    if (fieldValue === null || fieldValue === undefined) {
      return Promise.resolve();
    }
    return condition(fieldValue) ? Promise.resolve() : Promise.reject(new Error(defaultErrorMessage));
  },
  message: errorMessage,
});

export const getValidationRules = <T extends Field>(fieldComponentProps: FormFieldComponentProps<T>): Rule[] => {
  const validationRules: Rule[] = [];

  if (fieldComponentProps.isRequired) {
    validationRules.push({ required: true });
  }

  const staticValidation = fieldComponentProps.validation;

  if (!staticValidation) {
    return validationRules;
  }

  // Minimum length of field
  if (staticValidation.minLength) {
    const { value, message } = extractValidationDescription(staticValidation.minLength);

    validationRules.push({ min: value, message });
  }

  // Maximum length of field
  if (staticValidation.maxLength) {
    const { value, message } = extractValidationDescription(staticValidation.maxLength);

    validationRules.push({ max: value, message });
  }

  const defaultMessages = DEFAULT_VALIDATION_MESSAGES.custom;

  // Minimum numeric value
  if (staticValidation.minNumericValue) {
    const { value, message } = extractValidationDescription(staticValidation.minNumericValue);

    validationRules.push(createValidationRule((fieldValue) => (fieldValue as number) >= value, defaultMessages.minNumericValue, message));
  }

  // Maximum numeric value
  if (staticValidation.maxNumericValue) {
    const { value, message } = extractValidationDescription(staticValidation.maxNumericValue);

    validationRules.push(createValidationRule((fieldValue) => (fieldValue as number) <= value, defaultMessages.maxNumericValue, message));
  }

  // Numeric range
  if (staticValidation.numericRange) {
    const { value, message } = extractValidationDescription(staticValidation.numericRange);

    validationRules.push(createValidationRule((fieldValue) => (fieldValue as number) >= value[0] && (fieldValue as number) <= value[1], defaultMessages.numericRange, message));
  }

  return validationRules;
};

export const getNoDataCheckboxConfiguration = (fieldDescriptor: FormField): CheckboxField & FieldBehavior => ({
  type: 'checkbox',
  name: getNoDataFieldName(fieldDescriptor),
  label: 'Brak danych',
  isRequired: false,
  isDisabled: false,
  isHidden: false,
  isNecessary: false,
  isVisibleInResult: false,
  value: null,
  variant: 'no-data',
});

// OPTIMALIZATION: Field should update ONLY when one of relatedFields changed
export const shouldUpdateForm = memoize((formField: FormField) => (prevValues, currentValues) => {
  // Special cases
  if (formField.type === 'calculated') {
    return true;
  }

  const relatedFieldNames = getRelatedFieldsNames(formField);

  return relatedFieldNames.reduce((result, relatedField) => {
    if (result) {
      return true;
    }
    return prevValues[relatedField] !== currentValues[relatedField];
  }, false);
});
