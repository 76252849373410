import React, { Fragment } from 'react';
import { Modal, Popover } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { nanoid } from 'nanoid';
import Button from '../Button2/Button.component';
import { MaskedText } from '../MaskedText';
import ZoomConsultation from './BtnZoomConsultation.component';
import { FORMATS } from '../../constants/dates.constants';

const BtnWithRequestStartCallModal = ({ data, openCallConfirm, setOpenCallConfirm, id }) => {
  const contentTooltipForGroup = data.consultant.consultantList && (
    <div>
      {data.consultant.consultantList.map((person) => (
        <p key={nanoid()}>
          {person.consultantName} {person.consultantSurname}
        </p>
      ))}
    </div>
  );
  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      title={
        <div className="flex flex-row items-center justify-between">
          <div>
            <h1 className="text-xl font-extrabold">Dołącz do e-Konsylium</h1>
            <p className=" text-sm text-gray-600">Dane konsultacji</p>
          </div>
          <div>
            <div className="mr-8 rounded-md bg-gray-300 py-1 px-2 text-xs text-gray-600">Id: {id}</div>
          </div>
        </div>
      }
      className="modal-styles input-styles"
      width={1000}
      open={openCallConfirm}
      onCancel={() => {
        setOpenCallConfirm(false);
      }}
      footer={[
        <Fragment key="footer">
          <div className="pb-5">
            <Button onClick={() => setOpenCallConfirm(false)} color="red" size="custom-lg" margin="mr-6">
              Odrzuć
            </Button>
            <ZoomConsultation data={data} className="float-right" />
          </div>
        </Fragment>,
      ]}
    >
      <div className="mt-2 grid grid-cols-6 gap-4 border-gray-300 drop-shadow md:mt-4">
        <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
          <div>
            <h1 className="text-lg font-semibold">Lekarz zlecający</h1>
          </div>
        </div>
        <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
          <div className="grid grid-cols-2 gap-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt className="font-semibold text-black">Imię:</dt>
              <dd className="break-words text-black sm:col-span-2 sm:mt-0">{data && data.doctor.doctorName}</dd>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt className="font-semibold text-black">Nazwisko:</dt>
              <dd className="break-words text-black sm:col-span-2 sm:mt-0">{data && data.doctor.doctorSurname}</dd>
            </div>
            <div className="sm:grid sm:col-span-2 sm:grid-cols-6 sm:gap-4 sm:px-2">
              <dt className="font-semibold text-black">Jednostka:</dt>
              <dd className="flex break-words text-black col-span-3 sm:mt-0">{data && data.doctor.doctorUnitName}</dd>
            </div>
          </div>
        </div>
        {data.consultant.groupName ? (
          <>
            <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
              <div>
                <h1 className="text-lg font-semibold">Grupa</h1>
              </div>
            </div>
            <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
              <div>
                <Popover content={contentTooltipForGroup} title={data.consultant.groupName}>
                  <span className="cursor-pointer rounded-md bg-darkblue py-1 px-2 text-xs text-white">{data.consultant.groupName}</span>
                </Popover>
                <p className=" pt-1 text-gray-500">{data.consultant.consultantList[0].consultantUnitName}</p>
              </div>
            </div>
          </>
        ) : (
          data.consultant.consultantList && (
            <>
              <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
                <div>
                  <h1 className="text-lg font-semibold">{data.consultant.consultantList.length < 1 ? <>Konsultant</> : <>Konsultanci</>}</h1>
                </div>
              </div>
              {data.consultant.consultantList.map((consultant) => (
                <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
                      <dt className="font-semibold text-black">Imię:</dt>
                      <dd className="break-words text-black sm:col-span-2 sm:mt-0">{consultant.consultantName}</dd>
                    </div>
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
                      <dt className="font-semibold text-black">Nazwisko:</dt>
                      <dd className="break-words text-black sm:col-span-2 sm:mt-0">{consultant.consultantSurname}</dd>
                    </div>
                    <div className="sm:grid sm:col-span-2 sm:grid-cols-6 sm:gap-4 sm:px-2">
                      <dt className="font-semibold text-black">Jednostka:</dt>
                      <dd className="break-words text-black sm:col-span-5 sm:mt-0">{consultant.consultantUnitName}</dd>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )
        )}
        <div className="col-span-6 col-start-1 grid pb-2 md:col-span-1">
          <div>
            <h1 className="text-lg font-semibold">Pacjent</h1>
          </div>
        </div>
        <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
          <div className="grid grid-cols-2 gap-4">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt className="font-semibold text-black">Imię:</dt>
              <dd className="break-words text-black sm:col-span-2 sm:mt-0">
                {data && data.patient.patientName} {data && data.patient.patientSecondName}
              </dd>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt className="font-semibold text-black">Nazwisko:</dt>
              <dd className="break-words text-black sm:col-span-2 sm:mt-0">{data && data.patient.patientSurname}</dd>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
              <dt className="font-semibold text-black">{data.patient.patientIdentificationType === 'PESEL' ? 'PESEL:' : 'Inny identyfikator:'}</dt>
              <dd className="break-words text-black sm:col-span-2 sm:mt-0">
                {data.patient.patientIdentity.substring(0, 3)}
                <MaskedText>{data.patient.patientIdentity.substring(3)}</MaskedText>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

BtnWithRequestStartCallModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  openCallConfirm: PropTypes.bool,
  setOpenCallConfirm: PropTypes.bool,
  id: PropTypes.string,
};

BtnWithRequestStartCallModal.defaultProps = {
  data: null,
  openCallConfirm: false,
  setOpenCallConfirm: false,
  id: null,
};

export default BtnWithRequestStartCallModal;
