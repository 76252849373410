import React, { createContext, FC, PropsWithChildren, useContext, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import { PAGE_PARAMS_SCOPE } from './types';

interface PageParamsContextProps {
  getStoredPageParams: (scope: PAGE_PARAMS_SCOPE) => URLSearchParams;
  updateStoredValues: (scope: PAGE_PARAMS_SCOPE, value: URLSearchParams) => void;
}

export const PageParamsContext = createContext<PageParamsContextProps>({} as PageParamsContextProps);

const locationToScopeMap: Record<string, PAGE_PARAMS_SCOPE> = {
  '/': 'CONSULTATIONS',
  '/consultations': 'CONSULTATIONS',
  '/schedule': 'CALENDAR',
};

export const PageParamsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [storageValue, setStorageValue] = useSessionStorage('eKonsylium-PageParams');

  const updateStoredValues = (scope: PAGE_PARAMS_SCOPE, value: URLSearchParams) => {
    setStorageValue((currentStorageValue) => ({
      ...currentStorageValue,
      [scope]: value.toString(),
    }));
  };

  const getStoredPageParams = (scope: PAGE_PARAMS_SCOPE): URLSearchParams => {
    const urlParams = storageValue?.[scope];
    if (!urlParams) {
      return new URLSearchParams();
    }
    return new URLSearchParams(urlParams);
  };

  useEffect(() => {
    const currentScope = locationToScopeMap[location.pathname];
    if (!currentScope) {
      return;
    }
    if (location.search.length > 0) {
      updateStoredValues(currentScope, searchParams);
    } else {
      setSearchParams(getStoredPageParams(currentScope));
    }
  }, [location.pathname, location.search]);

  return <PageParamsContext.Provider value={{ getStoredPageParams, updateStoredValues }}>{children}</PageParamsContext.Provider>;
};

export const usePageParamsContext = () => useContext(PageParamsContext);
