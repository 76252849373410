/* eslint-disable */

import React from 'react';
import { Col, Button } from 'antd';
import Headline from '../Headline/Headline.component';
import Card from '../../components/Card/Card.component';
import { MaskedText } from '../MaskedText';
import { testId } from '@/tests';

const PatientDetails = ({ data, status, onEdit, showEditButton }) => {
  return (
    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
      <Card
        title={
          // <div className="flex flex-row items-center justify-between">
          //   <p>Pacjent</p>
          //   <Button onClick={onEdit} type="link">
          //     edycja
          //   </Button>
          // </div>
          <Headline titleContent="Pacjent" disabled={!showEditButton} status={status} onEditTitle={onEdit} />
        }
      >
        <div className="flex h-[120px] flex-col overflow-auto">
          <div className="bg-gray-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
            <dt className="font-semibold">Imię i nazwisko:</dt>
            <dd className="break-words sm:col-span-2 sm:mt-0" {...testId('patient-info')}>
              {data && data.patient.patientName} {data && data.patient.patientSecondName} {data && data.patient.patientSurname}
            </dd>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 sm:py-2">
            <dt className="font-semibold">Płeć:</dt>
            <dd className="sm:col-span-2 sm:mt-0">{data && data.patient.patientGender === 'FEMALE' ? 'kobieta' : 'mężczyzna'}</dd>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4 sm:pb-2">
            <dt className="font-semibold">{data.patient.patientIdentificationType === 'PESEL' ? 'PESEL:' : 'Inny identyfikator:'}</dt>
            <dd className="sm:col-span-2 sm:mt-0" {...testId('patient-identity')}>
              {data && <MaskedText testId="pesel">{data.patient.patientIdentity}</MaskedText>}
            </dd>
          </div>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-4">
            <dt className="font-semibold">Data urodzenia:</dt>
            <dd className="sm:col-span-2 sm:mt-0" {...testId('patient-birthdate')}>
              {data && data.patient.patientDateOfBirth}
            </dd>
          </div>
        </div>
      </Card>
    </Col>
  );
};

export default PatientDetails;
