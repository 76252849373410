import React from 'react';

export const userMenu = [
  // { title: 'Profil użytkownika', href: '#', key: 'profile' },
  // { title: 'Ustawienia', href: '#', key: 'settings' },
  // {
  //   type: 'divider',
  // },
  { title: 'Wyloguj', href: '#', key: 'logout' },
];

export const fontSizeMenu = [
  { title: <span className="text-lg">Duża czcionka</span>, href: '#', key: 'large' },
  { title: <span className="text-base">Średnia czcionka</span>, href: '#', key: 'medium' },
  { title: <span className="text-sm">Mała czcionka</span>, href: '#', key: 'small' },
];

export const statusMenu = [
  { title: 'Kosultacja zakończone', href: '#', key: 'large' },
  { title: 'Zmiana terminu', href: '#', key: 'medium' },
];
