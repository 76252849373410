import { testId } from '@/tests';
import React, { FC, PropsWithChildren } from 'react';

interface DropDownActionButtonProps {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const DropDownActionButton: FC<PropsWithChildren<DropDownActionButtonProps>> = ({ className, onClick, disabled, children }) => (
  <button {...testId('dropDownActionButton-component')} type="button" className={`rounded py-1 px-2 ${className} ${disabled && 'cursor-not-allowed'}`} disabled={disabled} onClick={onClick}>
    {children}
  </button>
);
