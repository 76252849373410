/* eslint-disable */
import { DeleteOutlined } from '@ant-design/icons';
import { Button as ButtonAnt, Input, message, Popconfirm } from 'antd';
import { throttle } from 'lodash';
import { nanoid } from 'nanoid';
import React, { useRef, useState, useEffect } from 'react';
import { BsCircle, BsPencil } from 'react-icons/bs';

const Cell = ({ record, onDeleteFile, onChangeName, onEditFile, examinationId, type, onDeleteDicomService }) => {
  const [titleEdit, setTitleEdit] = useState(false);
  const [nameValue, setNameValue] = useState(record.examinationName);
  const nameInput = useRef(null);

  useEffect(() => {
    setNameValue(record.examinationName);
  }, [record]);

  const handleNameEdit = (examinationId) => (e) => {
    if (!nameValue || nameValue.trim() === '') {
      e.preventDefault();
      setTitleEdit(false);
      setNameValue(record.examinationName);
    } else {
      if (typeof onChangeName === 'function' && record.examinationName !== e.target.value && e.target.value !== undefined) {
        onChangeName({ examinationName: nameValue, examinationId: record.examinationId });
      }
      setTitleEdit(!titleEdit);
      setNameValue(record.examinationName);
    }
  };

  const handleChangeName = (e) => {
    setNameValue(e.target.value);
  };

  const throttledShowError = throttle(() => {
    message.error('Nazwa badania nie może być pusta');
  }, 1000);

  const handleNameKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!nameValue || nameValue.trim() === '') {
        e.preventDefault();
        throttledShowError();
      } else {
        nameInput.current.blur();
        setTitleEdit(false);
      }
    }
  };

  const handleDeleteFile =
    ({ fileId, examinationId, dicomSeriesId, fileName }, index) =>
    () => {
      type === 'others'
        ? typeof onDeleteFile === 'function' && onDeleteFile({ fileId, examinationId, fileName })
        : typeof onDeleteDicomService === 'function' && onDeleteDicomService({ dicomSeriesId, examinationId, dicomUuid: record.dicomSeriesList[index].dicomSeriesFolderName });
    };

  const handleEditFile = (fileId) => () => {
    if (typeof onEditFile === 'function') onEditFile(fileId, examinationId);
  };

  return (
    <div>
      {titleEdit ? (
        <Input autoFocus ref={nameInput} value={nameValue} className="w-full" onKeyDown={handleNameKeyDown} onChange={handleChangeName} onBlur={handleNameEdit(record.id)} />
      ) : (
        <h1 onClick={handleNameEdit(record.id)} className="break-all">
          {nameValue}
        </h1>
      )}
      <div>
        {type === 'others' ? (
          <div>
            {record.files &&
              record.files.map((file, index) => {
                const fileName = file.fileUrl.split('/').pop();
                return (
                  <div key={nanoid()} className="flex flex-row items-center justify-between">
                    <div className="flex flex-row items-center">
                      <BsCircle className="mr-2 h-2 w-2 rounded-full bg-red-300" />
                      <p className="font-normal">z dnia {file.examinationDate}</p>
                    </div>
                    <div className="my-1 flex flex-row items-center gap-1">
                      <ButtonAnt className="flex items-center justify-center" icon={<BsPencil onClick={handleEditFile(file.fileId)} />} />
                      <Popconfirm
                        title="Czy chcesz usunąć plik?"
                        okText="Tak"
                        cancelText="Nie"
                        onConfirm={handleDeleteFile({ fileId: file.fileId, examinationId: record.examinationId, fileName: fileName }, index)}
                      >
                        <ButtonAnt icon={<DeleteOutlined />}>Usuń plik</ButtonAnt>
                      </Popconfirm>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          <div>
            {record.dicomSeriesList &&
              record.dicomSeriesList.map((series, index) => (
                <div key={nanoid()} className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center">
                    <BsCircle className="mr-2 h-2 w-2 rounded-full bg-red-300" />
                    <p className="font-normal">z dnia {series.examinationDate}</p>
                  </div>

                  <div className="my-1 gap-1">
                    <Popconfirm
                      title="Czy chcesz usunąć serie?"
                      okText="Tak"
                      cancelText="Nie"
                      onConfirm={handleDeleteFile({ examinationId: record.examinationId, dicomSeriesId: series.dicomSeriesId }, index)}
                    >
                      <ButtonAnt icon={<DeleteOutlined />}>Usuń serie</ButtonAnt>
                    </Popconfirm>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Cell;
