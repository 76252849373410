import { testId } from '@/tests';
import { Col, Form, Input, Row, Space } from 'antd';
import React, { FC } from 'react';
import { TiWarning } from 'react-icons/ti';
import { PhoneInputDetailed } from '@/components/FormItems';
import { plural } from '@/utils/plural';
import { Data } from './service';

interface InviteByMailFormProps {
  onChange: (value: Data | null) => void;
}

const validateMessages = {
  // required: 'Pole wymagane!',
  min: (num) => `Pole musi zawierać conajmniej ${num} ${plural('znak', 'znaki', 'znaków', num)}!`,
  max: (num) => `Pole musi zawierać maksymalnie ${num} ${plural('znak', 'znaki', 'znaków', num)}!`,
};

export const InviteByMailForm: FC<InviteByMailFormProps> = ({ onChange }) => {
  const [form] = Form.useForm();

  const handleValuesChange = () => {
    form.validateFields(['invitationEmail', 'invitationPhone']).catch((result) => {
      if (result.errorFields.length > 0) {
        onChange(null);
      } else {
        onChange(result.values);
      }
    });
  };

  console.log(form.getFieldError('invitationPhone'));

  return (
    <Row>
      <Col span={12} offset={6} className="flex flex-col justify-center">
        <Form form={form} layout="vertical" name="form-invitation-email" onValuesChange={handleValuesChange}>
          <Form.Item
            label={<p className="font-semibold">Podaj email lekarza zapraszanego</p>}
            name="invitationEmail"
            labelCol={{ span: 24 }}
            rules={[
              {
                type: 'email',
                message: 'Adres email jest niepoprawny',
              },
              {
                validator(_, value) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  if (!form.getFieldValue('invitationPhone')?.number && !value) return Promise.reject('Pole email lub telefon muszą być wypełnione');
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input type="email" placeholder="Wpisz e-mail..." {...testId('invitation-email')} allowClear />
          </Form.Item>
          <Form.Item>
            <span>lub</span>
          </Form.Item>
          <Form.Item
            name="invitationPhone"
            label={<span className="font-semibold">Numer telefonu</span>}
            // required
            rules={[
              {
                validator(_, value) {
                  // eslint-disable-next-line prefer-promise-reject-errors
                  if (!form.getFieldValue('invitationEmail') && !value?.number) return Promise.reject('Pole email lub telefon muszą być wypełnione');
                  if (value?.number.length === 0) return Promise.resolve();
                  if (value?.number.length < 9) return Promise.reject(validateMessages.min(9));

                  return Promise.resolve();
                },
              },
            ]}
          >
            <PhoneInputDetailed
            // isError={form.getFieldError('invitationPhone').length > 0}
            />
          </Form.Item>
          <Space className="flex content-center justify-center">
            <TiWarning size={24} />
            <div>Na podane dane zostanie wysłany link do video konferencji.</div>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};
