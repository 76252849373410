import { useLocation } from 'react-router-dom';

export const useGetQuery = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const queryList = [];
  let result = true;
  query.forEach((value, key) => {
    queryList.push({ key, value });
  });
  const setGetQuery = (key, value) => {
    if (queryList.find((item) => item.key === key && item.value === value) !== (null || undefined)) {
      return result;
    }
    result = false;
    return result;
  };
  return { queryList, setGetQuery };
};
