import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Form, message } from 'antd';
import { useMutation, useQuery } from 'react-query';
import SendMailForm from '@/components/Forms/SendMailForm.component';
import NewPasswordForm from '@/components/Forms/NewPasswordForm.component';
import ChangePasswordForm from '@/components/Forms/ChangePasswordForm.component';
import logoXlSvg from '../assets/images/logo-xl-white.svg';
import { sendMail, resetPassword, changePassword, verifyToken } from '../services/auth.service';

const ResetPassword = () => {
  const [disabled, setDisabled] = useState(false);
  const [bodyRequest, setBodyRequest] = useState();
  const [form] = Form.useForm();
  const [messageInfo, setMessageInfo] = useState({ message: false, type: false });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get('token');
  let mode = 'sendMail';

  if (searchParams.get('token') !== (undefined || null) && disabled && !messageInfo.type) mode = 'reset';
  if (searchParams.get('changepassword')) mode = 'changePassword';

  const getEndpoint = () => {
    if (mode === 'reset') return resetPassword;
    if (mode === 'changePassword') return changePassword;
    if (mode === 'sendMail') return () => Promise.resolve();
    return () => Promise.resolve();
  };

  if (searchParams.get('token') !== (undefined || null)) {
    const checkToken = useQuery(['resetPasswordToken'], () => verifyToken(resetPasswordToken), {
      // enabled: false,
      // retry: false,
      onSuccess: () => {
        mode = 'reset';
        setDisabled(true);
      },
      onError: (err) => {
        mode = 'sendMail';
        setMessageInfo(err.response.data.message);
        setDisabled(false);
        setMessageInfo({ message: err.response.data.message, type: 'error' });
        message.error(err.response.data.message);
        console.error(err.response.data.message);
      },
    });
  }

  const { isLoading, refetch } = useQuery(['reset', bodyRequest], sendMail(bodyRequest), {
    enabled: false,
    retry: false,
    onSuccess: () => {
      mode = '';
      setMessageInfo({ message: 'Wiadomość e-mail z linkiem do resetu hasła została wysłana.', type: 'success' });
      setDisabled(true);
    },
    onError: (err) => {
      if (mode === 'reset') {
        setMessageInfo({ message: 'Nie udało się wysłać wiadomości e-mail.', type: 'error' });
        message.erorr('Nie udało się wysłać wiadomości e-mail.');
      }
      if (mode === 'changePassword') setMessageInfo({ message: 'Nie udało się zmienić hasła.', type: 'error' });
      console.error(err);
    },
  });

  const mutationOptions = {
    onSuccess: async () => {
      navigate('/auth');
      message.success('Hasło zostało zmienione.');
    },
    onError: async (err) => {
      if (mode === 'reset' && err.response.data.message === 'Token dostępowy jest nieważny.') {
        mode = 'sendMail';
        setMessageInfo(err.response.data.message);
        setDisabled(false);
      }
      message.error(`${err.response.data.message}`);
    },
  };

  const mutation = useMutation(getEndpoint(), mutationOptions);

  const onFinish = (values) => {
    if (mode !== 'sendMail') {
      form
        .validateFields()
        .then(() => {
          if (mode === 'reset') mutation.mutate({ resetPasswordToken, requestBody: values });
          if (mode === 'changePassword') mutation.mutate(values);
        })
        .catch((err) => {
          console.log('Validate Failed:', err);
        });
    } else {
      setBodyRequest(values);
      setDisabled(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  useEffect(() => {
    if (bodyRequest) {
      refetch();
    }
  }, [bodyRequest]);

  return (
    <div className=" h-screen overflow-auto bg-gray-800 p-10">
      <div className="flex flex-col items-center justify-center text-white">
        <img className="w-80 pb-10" src={logoXlSvg} alt="Logo E-konsylium" />
        <div className="my-form-wrapper w-[400px] rounded-md bg-white p-10">
          <h1 className="pb-10 text-center text-3xl font-medium text-gray-800">Reset hasła</h1>
          {messageInfo.message && (
            <Alert
              message={messageInfo.message}
              // description={messageInfo.message}
              type={messageInfo.type}
              showIcon
              className="mx-auto mb-5 max-w-[290px]"
            />
          )}
          <Form
            layout="horizontal"
            name="loginUser"
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            size="large"
          >
            {mode === 'sendMail' && !disabled && <SendMailForm isLoading={isLoading} />}
            {mode === 'reset' && <NewPasswordForm />}
            {mode === 'changePassword' && <ChangePasswordForm />}
          </Form>
        </div>
      </div>
    </div>
  );
};

ResetPassword.propTypes = {};

export default ResetPassword;
