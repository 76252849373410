/* eslint-disable */

import React from 'react';
import Icon from '@ant-design/icons';

const DocumentSvg = () => (
  <svg width="24px" height="32px" viewBox="0 0 24 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Dashboard-Demo" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" fillOpacity="0.650475543">
      <g id="04---Dane-konsultacji----pliki" transform="translate(-51.000000, -899.000000)" fill="#000000" fillRule="nonzero">
        <g id="Group-99" transform="translate(24.000000, 702.000000)">
          <g id="Group-97" transform="translate(23.000000, 197.000000)">
            <g id="9040355_filetype_jpg_icon" transform="translate(4.000000, 0.000000)">
              <path
                d="M24,8.99919814 L24,27.9975053 C24,30.2064475 22.209139,31.9971489 20,31.9971489 L18,31.9971489 L18,29.9973271 L20,29.9973271 C21.1045695,29.9973271 22,29.1019764 22,27.9975053 L22,8.99919814 L18,8.99919814 C16.3431458,8.99919814 15,7.65617206 15,5.99946543 L15,1.99982181 L4,1.99982181 C2.8954305,1.99982181 2,2.89517253 2,3.99964362 L2,21.9980399 L0,21.9980399 L0,3.99964362 C-4.4408921e-16,1.79070144 1.790861,4.05776094e-16 4,0 L15,0 L24,8.99919814 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
const DocumentIcon = (props) => <Icon component={DocumentSvg} {...props} />;
export default DocumentIcon;
