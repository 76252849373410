import React, { useEffect, useRef } from 'react';

import { Form, InputNumber } from 'antd';
import { Parser } from 'expr-eval';

import { CalculatedField, decimalFormatter } from '@/components/TemplateEngine';
import { testIdFactory } from '@/tests';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { getValidationRules } from '../service';

const parser = new Parser({
  operators: {
    add: true,
    subtract: true,
    multiply: true,
    divide: true,
    factorial: true,
    power: true,

    remainder: false,
    concatenate: false,
    conditional: false,
    logical: false,
    comparison: false,
    in: false,
    assignment: false,
  },
});

export const CalculatedFormField: FormFieldComponent<CalculatedField> = (props) => {
  const { name, label, description, isDisabled, isHidden, isRequired, isNecessary, suffix, precision, formula, editable, tooltip, testId } = props;
  const dependenciesCache = useRef(new Map());
  const cache = dependenciesCache.current;

  const form = Form.useFormInstance();

  if (isHidden) {
    return null;
  }

  const expression = parser.parse(formula);
  const variables = expression.variables();

  const dependenciesValues = form.getFieldsValue(variables);
  const dependentValuesChanged = Object.entries(dependenciesValues)
    .map(([key, value]) => value !== cache.get(key))
    .some(Boolean);

  useEffect(() => {
    if (dependentValuesChanged) {
      try {
        if (Object.values(dependenciesValues).every((value) => value !== undefined && value !== null)) {
          const result = expression.evaluate(
            variables.reduce(
              (variablesObject, currentVariable) => ({
                ...variablesObject,
                [currentVariable]: form.getFieldValue(currentVariable),
              }),
              {},
            ),
          );
          if (Number.isFinite(result)) {
            form.setFieldValue(name, parseFloat(result).toFixed(precision));
          }
        }
      } catch {
        console.warn(`Calculated field ${name} has error in its formula`);
      }
      // Fill cache
      variables.forEach((variable) => {
        cache.set(variable, form.getFieldValue(variable));
      });
    }
  });

  const validations = getValidationRules(props);

  const testAttribute = testIdFactory(testId);

  return (
    <Form.Item
      label={label ? <FormFieldLabel label={label} description={description} required={isRequired || isNecessary} fieldTooltip={tooltip} /> : null}
      className="mb-0 py-1 drop-shadow-sm"
      rules={validations}
      name={name}
      required={isRequired || isNecessary}
      dependencies={variables}
    >
      <InputNumber
        className="w-full break-words drop-shadow-sm"
        addonAfter={suffix}
        disabled={isDisabled}
        precision={precision}
        readOnly={!editable}
        decimalSeparator=","
        formatter={decimalFormatter(precision)}
        {...testAttribute(name)}
      />
    </Form.Item>
  );
};
