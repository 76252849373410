import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { testId } from '@/tests';

const Headline = ({ titleContent, disabled, status, onEditTitle }) => {
  const { teleconsultation, documentation } = status;
  let disableStatus = ['COMPLETED', 'TO_SIGN', 'FINISHED'];
  if (titleContent === 'Zatwierdzone przez') disableStatus = [];

  return (
    <div className="flex flex-row items-center justify-between">
      <p {...testId('title')}>{titleContent}</p>
      {!disabled && !(disableStatus.includes(documentation?.state) || disableStatus.includes(teleconsultation?.state)) ? (
        <Button onClick={onEditTitle} type="link" {...testId('editButton')}>
          edycja
        </Button>
      ) : null}
    </div>
  );
};

Headline.propTypes = {
  onEditTitle: PropTypes.func,
};

Headline.defaultProps = {
  onEditTitle: () => null,
};

export default Headline;
