/* eslint-disable */
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { verify } from '../../services/auth.service';
import { getRedirectionParams } from '@/core/Redirection';
import LoadingPage from '../LoadingPage/LoadingPage.component';
import AuthContext from '../../contexts/Auth.context';

const Auth = ({ children }) => {
  const { setUnit, setUser } = useContext(AuthContext);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const { isLoading } = useQuery('verify', verify(), {
    refetchOnWindowFocus: false,
    onSuccess: (res) => {
      setUser(res.data.user);
      setUnit(res.data.unit);
      if (res.data.user.forcePasswordChange) {
        navigate('/reset?changepassword=true');
      }
      if (pathname === '/auth') {
        navigate('/consultations');
      }
    },
    onError: (err) => {
      if (err.response?.status === 401) {
        const redirectionParams = getRedirectionParams(pathname, search);

        if (redirectionParams) {
          navigate({ pathname: `/auth`, search: `?${redirectionParams.toString()}` });
        } else {
          navigate(`/auth`);
        }
      }
    },
  });

  if (isLoading) {
    return <LoadingPage loading={isLoading} description="Trwa autoryzacja..." />;
  }

  return <div>{children}</div>;
};

Auth.propTypes = {
  children: PropTypes.node,
};
Auth.defaultProps = {
  children: null,
};

export default Auth;
