import React from 'react';

import { Form, DatePicker } from 'antd';
import moment from 'moment';

import { testIdFactory } from '@/tests';
import { FormFieldComponent } from '../types';
import { FormFieldLabel } from '../../components';
import { DatepickerField } from '../../types';
import { getValidationRules } from '../service';

const limitDateRange = (current) => {
  const tenYearsAgo = moment().subtract(10, 'years').startOf('day');
  const tenYearsFromNow = moment().add(10, 'years').startOf('day');

  if (current && (current > tenYearsFromNow || current < tenYearsAgo)) {
    return true;
  }
  return false;
};

export const DateFormField: FormFieldComponent<DatepickerField> = (props) => {
  const { name, label, description, isDisabled, isHidden, isRequired, isNecessary, testId } = props;

  if (isHidden) {
    return null;
  }

  const validations = getValidationRules(props);

  const testAttribute = testIdFactory(testId);

  return (
    <Form.Item
      label={label ? <FormFieldLabel label={label} description={description} required={isRequired || isNecessary} /> : null}
      className="mb-0 py-1 drop-shadow-sm"
      name={name}
      rules={validations}
      required={isRequired || isNecessary}
      initialValue={moment()}
    >
      <DatePicker className="w-max" disabled={isDisabled} disabledDate={limitDateRange} inputReadOnly {...testAttribute(name)} />
    </Form.Item>
  );
};
