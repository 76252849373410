/* eslint-enabled */
import React, { useState, useContext, Fragment } from 'react';
import { Modal, message, Form, Spin, Image, Avatar, Checkbox, Table } from 'antd';
import { useMutation } from 'react-query';
import { PhoneOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';
import Button from '../Button2/Button.component';
import doctorSvg from '../../assets/images/doctor.svg';
import AuthContext from '../../contexts/Auth.context';
import BtnWithRequestStartCallModal from './BtnWithRequestStartCallModal.component';

const BtnWithRequestStartCall = ({ titleBtn, request, errorMessage, successMessage, onSuccess, className, type, disabled, consultantList, data }) => {
  const [open, setOpen] = useState(false);
  const [openCallConfirm, setOpenCallConfirm] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [requestingDoctor] = useState([
    {
      consultantId: data.doctor ? data.doctor.doctorId : undefined,
      consultantName: data.doctor ? data.doctor.doctorName : undefined,
      consultantSurname: data.doctor ? data.doctor.doctorSurname : undefined,
      consultantUnitName: data.doctor ? data.doctor.doctorUnitName : undefined,
    },
  ]);

  const isCurrentUserRequestingDoctor =
    consultantList !== (null && undefined) &&
    consultantList.find((item) => item.consultantId === data.doctor.doctorId) !== (null || undefined) &&
    data.doctor.doctorId === user.doctorId;

  const mutation = useMutation(request, {
    onError: (error) => {
      message.error(`${errorMessage} Opis: ${error.response.data.message}`, 10);
    },
    onSuccess: () => {
      if (typeof onSuccess === 'function') onSuccess();
      message.success(successMessage);
      setOpen(false);
      // setOpenCallConfirm(true);
    },
  });

  const onFinish = () => {
    if (form) {
      form.validateFields().then((values) => {
        const doctorId = [];
        if (values.doctorId !== undefined)
          // eslint-disable-next-line array-callback-return
          values.doctorId.map((item, index) => {
            if (values.add[index]) doctorId.push(item);
          });
        if (values.requestingDoctorId !== undefined)
          // eslint-disable-next-line array-callback-return
          values.requestingDoctorId.map((item, index) => {
            if (values.requestingAdd[index]) doctorId.push(item);
          });
        // warunek na •	Wybieram lekarzy do wdzwonienia – nie ma możliwości zapisania pustej listy
        if (doctorId.length === 0) message.warning('Lista konsultantów do wdzwonienia nie może być pusta. Proszę wybrać przynajmniej jednego konsultanta.');
        else {
          mutation.mutate({ doctorIds: doctorId });
        }
      });
    } else {
      mutation.mutate();
    }
  };

  const showConfirm = () => {
    setOpen(true);
  };

  const columns = [
    {
      title: 'Lekarz',
      align: 'center',
      key: (index) => index,
      render: (record) => (
        <div className="grid grid-cols-2 items-center justify-center">
          <div>
            <Avatar className="h-14 w-14 border-4 border-sky-300" src={<Image className="h-16 w-16 rounded-full object-cover" src={doctorSvg} preview={false} />} />
          </div>
          <div className="pt-2">
            <h1 className="w-full font-bold">
              dr {record.consultantName} {record.consultantSurname}
            </h1>
            <p className="w-full text-gray-500">{record.consultantSpecialty}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'Wybrani lekarze',
      align: 'center',
      render: (record, text, index) => (
        <>
          <Form.Item
            name={data.doctor.doctorId === record.consultantId ? ['requestingDoctorId', index] : ['doctorId', index]}
            initialValue={record.consultantId}
            className="h-0 align-middle"
          />
          <Form.Item
            name={data.doctor.doctorId === record.consultantId ? ['requestingAdd', index] : ['add', index]}
            initialValue={false}
            valuePropName="checked"
            className="align-middle"
          >
            <Checkbox />
          </Form.Item>
        </>
      ),
    },
  ];
  const isDisbaledOnCheck = () => {
    if ((form.getFieldValue('add') || []).filter((item) => item === true).length + (form.getFieldValue('requestingAdd') || []).filter((item) => item === true).length === 0)
      return true;
    return false;
  };

  return (
    <>
      <button
        onClick={showConfirm}
        size="large"
        type="button"
        className={`rounded py-1 px-2 ${className}  ${type === 'button' && !disabled && 'bg-blue-antd-500 text-white'} ${disabled && 'cursor-not-allowed'} ${
          disabled && type === 'button' && 'bg-gray-300 text-gray-500'
        } `}
        disabled={disabled}
      >
        {titleBtn}
      </button>
      <Form layout="vertical" form={form} name="consultation" onFinish={onFinish} scrollToFirstError preserve>
        <Modal
          closable={!mutation.isLoading}
          destroyOnClose
          afterClose={() => form && form.resetFields()}
          maskClosable={false}
          title={
            <div className="flex flex-row items-center justify-between">
              <div>
                <h1 className="text-xl font-extrabold uppercase">Wdzwanianie uczestników</h1>
              </div>
              {/* <div> */}
              {/*  <div className="mr-8 rounded-md bg-red-600 py-1 px-2 text-xs text-white">Robocza</div> */}
              {/* </div> */}
              <div>
                <div className="mr-8 rounded-md bg-gray-300 py-1 px-2 text-xs text-gray-600">Id: {id}</div>
              </div>
            </div>
          }
          className="modal-styles input-styles"
          width={1000}
          open={open}
          onCancel={() => {
            setOpen(false);
          }}
          footer={[
            <Fragment key="footer">
              <Form.Item shouldUpdate>
                {() => (
                  <div className="pb-5">
                    <Button
                      disabled={mutation.isLoading}
                      onClick={() => {
                        setOpen(false);
                      }}
                      color="default"
                      size="custom-lg"
                      margin="mr-6"
                    >
                      Anuluj
                    </Button>
                    <Button
                      disabled={mutation.isLoading || isDisbaledOnCheck()}
                      loading={mutation.isLoading}
                      onClick={() => {
                        onFinish();
                      }}
                      color="greenlight"
                      size="custom-lg"
                    >
                      Wdzwoń
                      <PhoneOutlined className="float-right" />
                    </Button>
                  </div>
                )}
              </Form.Item>
            </Fragment>,
          ]}
        >
          <Spin spinning={mutation.isLoading}>
            {consultantList.filter((item) => item.consultantId !== data.doctor.doctorId && item.consultantId !== user.doctorId).length !== 0 && (
              <>
                <h1 className="text-lg font-semibold">
                  {data.consultant.groupName !== null ? <p>Lista konsultantów - {data.consultant.groupName}</p> : <p>Lista konsultantów</p>}
                </h1>
                <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
                  <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow">
                    <Table
                      rowKey={(item) => item.consultantId}
                      columns={columns}
                      dataSource={
                        !isCurrentUserRequestingDoctor
                          ? consultantList.filter((item) => item.consultantId !== data.doctor.doctorId && item.consultantId !== user.doctorId)
                          : consultantList.filter((item) => item.consultantId !== user.doctorId)
                      }
                      pagination={false}
                      className="ant-table-approvedby-styles"
                      scroll={{ x: 'max-content' }}
                    />
                  </div>
                </div>
                <Form.Item shouldUpdate>
                  {() => (
                    <p className="float-right text-lg font-semibold text-gray-500">
                      Liczba wybranych lekarzy: {(form.getFieldValue('add') || []).filter((item) => item === true).length}
                    </p>
                  )}
                </Form.Item>
              </>
            )}
            {!isCurrentUserRequestingDoctor && (
              <>
                <h1 className="text-lg font-semibold">Lekarz zlecający</h1>
                <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
                  <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow">
                    <Table
                      rowKey={() => nanoid()}
                      columns={columns}
                      dataSource={requestingDoctor}
                      pagination={false}
                      className="ant-table-approvedby-styles"
                      scroll={{ x: 'max-content' }}
                    />
                  </div>
                </div>
                <Form.Item shouldUpdate>
                  {() => (
                    <p className="float-right text-lg font-semibold text-gray-500">
                      Liczba wybranych lekarzy: {(form.getFieldValue('requestingAdd') || []).filter((item) => item === true).length}
                    </p>
                  )}
                </Form.Item>
              </>
            )}
          </Spin>
        </Modal>
      </Form>
      <BtnWithRequestStartCallModal data={data} openCallConfirm={openCallConfirm} setOpenCallConfirm={setOpenCallConfirm} id={id} />
    </>
  );
};

BtnWithRequestStartCall.propTypes = {
  titleBtn: PropTypes.string,
  request: PropTypes.func,
  errorMessage: PropTypes.string,
  successMessage: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.oneOf(['button', 'text']),
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

BtnWithRequestStartCall.defaultProps = {
  titleBtn: null,
  request: () => null,
  errorMessage: null,
  successMessage: null,
  className: null,
  type: 'button',
  onSuccess: () => null,
  disabled: false,
  data: null,
};

export default BtnWithRequestStartCall;
