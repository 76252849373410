import React from 'react';

import { testIdFactory } from '@/tests';
import { DisplayFieldComponent } from '../types';
import { TextField } from '../../types';
import { FieldValue } from '../components';
import { DisplayFieldLabel } from '../../components';

export const TextDisplayField: DisplayFieldComponent<TextField> = ({ name, testId, suffix, value, label, isHidden, isNecessary, fieldHasNoData, tooltip }) => {
  if (isHidden) {
    return null;
  }

  const testAttribute = testIdFactory(testId);

  return (
    <div className="dynamic-field-result flex flex-col rounded bg-white p-2">
      <DisplayFieldLabel label={label} suffix={suffix} tooltip={tooltip} />
      <div className="flex break-all p-2 align-middle text-gray-600">
        <FieldValue name={name} testId={testId} value={value} isNecessary={isNecessary} fieldHasNoData={fieldHasNoData}>
          {value}
          {!label && suffix && (
            <span className="break-words font-medium text-black" {...testAttribute(`${name}-suffix`)}>
              &nbsp;[{suffix}]
            </span>
          )}
        </FieldValue>
      </div>
    </div>
  );
};
