import { Alert, Form, Input } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React, { FC, useState } from 'react';
import { performGlobalValidation } from '../service';
import { GlobalValidationRule } from '../types';
import { useTemplateEngineContext } from '../../TemplateEngineContext';

interface GlobalFormValidationProps {
  rules?: GlobalValidationRule[];
}

export const GlobalFormValidation: FC<GlobalFormValidationProps> = ({ rules = [] }) => {
  const { getFieldsValue } = Form.useFormInstance();
  const [errorMessages, setErrorMessages] = useState<string[] | null>(null);
  const { alreadySubmittedOnce } = useTemplateEngineContext();

  const wrapperClasses = classNames({ 'pb-3': errorMessages && alreadySubmittedOnce() && !isEmpty(errorMessages) });

  const validator = () => {
    const errors = performGlobalValidation(rules, getFieldsValue);
    setErrorMessages(errors);
    return !errors ? Promise.resolve() : Promise.reject();
  };

  const classes = classNames('hidden', 'mb-0');
  const dependencies = Array.from(new Set(rules.map((rule) => rule.fields).flat()));

  return (
    <div className={wrapperClasses} id="globalValidation">
      <Form.Item name="globalValidation" rules={[{ validator, message: '' }]} initialValue={null} className={classes} dependencies={dependencies}>
        <Input type="hidden" className={classes} />
      </Form.Item>
      {errorMessages && alreadySubmittedOnce() && (
        <div className="flex flex-col gap-1">
          {errorMessages.map((message, index) => (
            <Alert key={index} showIcon message={message} type="error" />
          ))}
        </div>
      )}
    </div>
  );
};
