import { testId } from '@/tests';
import { Col, message, Modal, Radio, Row, Space } from 'antd';
import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import JSButton from '@/components/Button2/Button.component';
import { InviteByMailForm } from './InviteByMailForm';
import { InviteBySelectionForm } from './InviteBySelectionForm';
import { Consultant } from './types';
import { Data, inviteDoctorToConsultation } from './service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Button = JSButton as any;

type InvitationMode = 'INSIDE_UNIT' | 'OUTSIDE_UNIT';

interface InviteToConsultationModalProps {
  consultationId: string;
  consultantList: Consultant[];
  unitId: number;
  doctorOrderingId: number;
  open: boolean;
  onClose?: () => void;
  onSuccessfulInvitation?: () => void;
}

export const InviteToConsultationModal: FC<InviteToConsultationModalProps> = ({
  consultationId,
  unitId,
  doctorOrderingId,
  consultantList,
  open,
  onClose,
  onSuccessfulInvitation,
}) => {
  const [selectedInvitationMode, setSelectedInvitationMode] = useState<InvitationMode>('INSIDE_UNIT');
  const [data, setData] = useState<Data | null>(null);
  const [selectedDoctorIds, setSelectedDoctorIds] = useState<number[]>([]);

  const inviteMutationOptions = {
    onSuccess: async () => {
      if (selectedInvitationMode === 'OUTSIDE_UNIT') {
        if (data.invitationEmail) {
          message.success(`Zaproszenie do dołączenia do konsultacji zostało wysłane na adres: ${data.invitationEmail}`);
          onSuccessfulInvitation?.();
          return onClose?.();
        }
        if (data.invitationPhone)
          message.success(`Zaproszenie do dołączenia do konsultacji zostało wysłane na nr tel.: +${data.invitationPhone.prefix} ${data.invitationPhone.number}`);
        onSuccessfulInvitation?.();
        return onClose?.();
      }

      message.success(`Pomyślnie zaproszono ${selectedDoctorIds.length} ${selectedDoctorIds.length > 1 ? 'lekarzy' : 'lekarza'} do konsultacji`);
      onClose?.();
      onSuccessfulInvitation?.();
    },
    onError: () => {
      if (selectedInvitationMode === 'OUTSIDE_UNIT') {
        if (data.invitationEmail) {
          message.error(`Zaproszenie do dołączenia do konsultacji nie zostało wysłane na adres: ${data.invitationEmail}`);
          return;
        }
        if (data.invitationPhone)
          message.error(`Zaproszenie do dołączenia do konsultacji nie zostało wysłane na nr tel.: +${data.invitationPhone.prefix} ${data.invitationPhone.number}`);
        return;
      }

      message.error(`Nie udało się zaprosić ${selectedDoctorIds.length} ${selectedDoctorIds.length > 1 ? 'lekarzy' : 'lekarza'} do konsultacji`);
    },
  };

  const inviteMutation = useMutation(inviteDoctorToConsultation, inviteMutationOptions);

  const handleInviteClick = () => {
    if (selectedInvitationMode === 'OUTSIDE_UNIT') {
      inviteMutation.mutateAsync({
        email: data.invitationEmail,
        phoneNumber: data?.invitationPhone?.number
          ? {
              number: data?.invitationPhone?.number,
              prefix: data?.invitationPhone?.prefix,
            }
          : null,
        documentationId: consultationId,
        consultationZone: 'Europe/Warsaw',
        invitorUnitId: unitId,
      });
    } else {
      inviteMutation.mutateAsync({ doctorIds: selectedDoctorIds, documentationId: consultationId, consultationZone: 'Europe/Warsaw', invitorUnitId: unitId });
    }
  };

  const closeModal = () => {
    setSelectedDoctorIds([]);
    onClose?.();
  };

  const afterClose = () => {
    setSelectedInvitationMode('INSIDE_UNIT');
    setData(null);
  };

  const isInviteButtonDisabled = () => {
    if (selectedInvitationMode === 'OUTSIDE_UNIT') {
      return data === null;
    }
    return selectedDoctorIds.length === 0;
  };

  return (
    <Modal
      afterClose={afterClose}
      destroyOnClose
      centered
      {...testId('invite-to-consultation-modal')}
      title={
        <div className="flex flex-row items-center justify-between">
          <div>
            <h1 className="text-xl font-extrabold uppercase">Zapraszanie lekarza do konsultacji</h1>
          </div>

          <div className="mr-8 rounded-md bg-gray-300 py-1 px-2 text-xs text-gray-600" {...testId('consultation-id')}>
            Id: {consultationId}
          </div>
        </div>
      }
      className="modal-styles input-styles"
      width={900}
      open={open}
      onCancel={closeModal}
      footer={
        <Space size="middle" className="py-2">
          <Button color="default" onClick={closeModal} size="md" {...testId('invite-modal-close-button')}>
            Zamknij
          </Button>
          <Button
            color="darkblue"
            type="primary"
            size="md"
            onClick={handleInviteClick}
            disabled={isInviteButtonDisabled()}
            loading={inviteMutation.isLoading}
            {...testId('invite-modal-save-button')}
          >
            Zaproś
          </Button>
        </Space>
      }
    >
      <Row justify="center" gutter={[16, 16]}>
        <Col span={24}>
          <div className="flex justify-center">
            <Radio.Group name="radiogroup" defaultValue="INSIDE_UNIT" onChange={(radioEvent) => setSelectedInvitationMode(radioEvent.target.value)} value={selectedInvitationMode}>
              <Radio value="INSIDE_UNIT">lekarz z jednostki medycznej</Radio>
              <Radio value="OUTSIDE_UNIT">lekarz spoza jednostki medycznej</Radio>
            </Radio.Group>
          </div>
        </Col>
        <Col span={24}>
          {selectedInvitationMode === 'OUTSIDE_UNIT' ? (
            <InviteByMailForm
              onChange={(value) => {
                setData(value);
              }}
            />
          ) : (
            <InviteBySelectionForm
              selectedDoctorIds={selectedDoctorIds}
              setSelectedDoctorIds={setSelectedDoctorIds}
              unitId={unitId}
              consultantList={consultantList}
              doctorOrderingId={doctorOrderingId}
            />
          )}
        </Col>
      </Row>
    </Modal>
  );
};
