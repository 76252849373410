import React, { FC } from 'react';

import { testId } from '@/tests';

interface DoctorPersonalsProps {
  name: string;
  title?: string;
  specialty?: string;
}

export const DoctorPersonals: FC<DoctorPersonalsProps> = ({ name, title, specialty }) => (
  <div className="flex flex-col text-ellipsis" {...testId('whole-doctor-personals')}>
    <span className="font-semibold" {...testId('doctor-name')}>
      {title && `${title} `}
      {name}
    </span>
    <span className="text-sm text-gray-500" style={{ lineHeight: 1 }} {...testId('doctor-specialty')}>
      {specialty}
    </span>
  </div>
);
