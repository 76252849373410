import React from 'react';
import { Form, Avatar, Image, Input } from 'antd';
import { testId } from '@/tests';
import doctorSvg from '../../../assets/images/doctor.svg';

const ModalApproveWithoutLogging = ({ data }) => (
  <div className="mt-2 grid grid-cols-6 border-gray-300 drop-shadow md:mt-4">
    <div className="col-span-6 col-start-1 rounded-md bg-white p-5 drop-shadow md:col-start-2">
      <div className="flex flex-col items-center justify-around sm:flex-row">
        <div className="flex flex-row ">
          <div className="flex flex-col items-center justify-center pt-2">
            <Avatar className="h-20 w-20 border-4 border-sky-300" src={<Image className="h-20 w-20 rounded-full object-cover" src={doctorSvg} preview={false} />} />
          </div>
          <div className="ml-4 flex w-1/2 flex-col items-center justify-center pt-2">
            <div className="font-semibold" {...testId('consultant-doctor-data')}>
              dr {data?.consultantDoctor.name} {data?.consultantDoctor.surname} <span className="font-normal">{data?.consultantDoctor.speciality}</span>
            </div>
            <span {...testId('consultant-doctor-unit-name')}>{data?.consultantDoctor.unitName}</span>
          </div>
        </div>
        <div>
          <span className="font-semibold" {...testId('patient-identity')}>
            PESEL: {data?.patient.identity}
          </span>
        </div>
      </div>

      <div className="flex items-center justify-center pt-2">
        <Form.Item name="password" label="Wprowadź hasło certyfikatu ZUS:" rules={[{ required: true, message: 'Pole wymagane!', validateTrigger: 'onSubmit' }]}>
          <Input.Password className="w-50 float-right" controls={false} autoFocus />
        </Form.Item>
      </div>
    </div>
  </div>
);

export default ModalApproveWithoutLogging;
